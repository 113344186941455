import { Component, OnInit, ViewChild } from "@angular/core";
import { DataExploreModel } from "../../../models/data-explore/data-explore.model";
import { Router, ActivatedRoute } from "@angular/router";
import { UploadStep2Component } from "../upload-csv/upload-step2/upload-step2.component";
import { UploadStep3Component } from "../upload-csv/upload-step3/upload-step3.component";

@Component({
	selector: "app-upload-csv",
	templateUrl: "./upload-csv.component.html",
	styleUrls: ["./upload-csv.component.scss"]
})
export class UploadCsvComponent implements OnInit {
	@ViewChild(UploadStep2Component) upTwo: UploadStep2Component;
	@ViewChild(UploadStep3Component) upThree: UploadStep3Component;

	curStep: number = 1;
	enable1stNext: boolean = true;
	message: string;
	enable2ndNext: boolean = true;
	step1: boolean = true;
	step2: boolean = false;
	step3: boolean = false;
	enableIngest: boolean = false;
	showModal: boolean = false;

	delimiterList = [
		{ name: "Comma", value: "comma" },
		{ name: "Semicolon", value: "semi" },
		{ name: "Pipe", value: "pipe" },
		{ name: "Tab", value: "tab" }
	];

	delimiter = this.delimiterList[0].value;

	constructor(
		private dataExploreModel: DataExploreModel,
		private route: ActivatedRoute,
		private router: Router
	) {}

	ngOnInit() {
		this.dataExploreModel.headerRows = undefined;
		this.dataExploreModel.columnSlider = undefined;
		this.dataExploreModel.columnName = undefined;
		this.dataExploreModel.columnType = undefined;
		this.dataExploreModel.hiveDatabaseName = undefined;
		this.dataExploreModel.hiveTableName = undefined;
		this.dataExploreModel.saveTableColumns = undefined;
		this.dataExploreModel.savedRecords = undefined;
		this.dataExploreModel.updatedRecords = undefined;
		this.dataExploreModel.totalFiles = undefined;
		this.dataExploreModel.conditionType = undefined;
		this.dataExploreModel.dataIngestionColumns = [];
		this.dataExploreModel.headerCheck = undefined;
		this.dataExploreModel.fileDetails = undefined;
		this.dataExploreModel.responseData = undefined;
		this.dataExploreModel.delimiter = undefined;
		localStorage.setItem("disableSignal", "");
	}

	receiveMessage($event) {
		this.enable1stNext = false;
		// if (localStorage.getItem("disableSignal") == "disableNext") {
		// 	this.enable1stNext = true;
		// } else {
		// 	this.enable1stNext = false;
		// }
	}

	disableMessage($event) {
		this.enable2ndNext = false;
	}

	clearMessage($event) {
		this.enable2ndNext = true;
	}

	clearIngest($event) {
		this.enableIngest = true;
	}

	setStep(step: number) {
		this.curStep = step;
	}

	next() {
		this.curStep += 1;
	}

	prev() {
		this.curStep -= 1;
	}

	stepOne() {
		this.curStep += 1;
		this.showModal = false;

		if (this.dataExploreModel.responseData !== undefined) {
			if (this.curStep == 2) {
				this.step2 = true;
			} else {
				this.step2 = false;
			}
		}
	}

	stepTwo() {
		this.curStep += 1;
		this.dataExploreModel.totalFiles = undefined;
		this.upTwo.showDetails();

		if (this.curStep == 3) {
			this.step3 = true;
		}
	}

	prevTwo() {
		this.curStep -= 1;
	}

	prevThree() {
		this.curStep -= 1;
	}

	stepThree() {
		this.upThree.ingestData();
	}

	goToStep2() {
		if (this.dataExploreModel.responseData !== undefined) {
			this.curStep = 2;
			this.step3 = false;
		}
	}

	goBack() {
		this.dataExploreModel.editMode = undefined;
		this.dataExploreModel.headerRows = undefined;
		this.dataExploreModel.columnSlider = undefined;
		this.dataExploreModel.columnName = undefined;
		this.dataExploreModel.columnType = undefined;
		this.dataExploreModel.hiveDatabaseName = undefined;
		this.dataExploreModel.hiveTableName = undefined;
		this.dataExploreModel.saveTableColumns = undefined;
		this.dataExploreModel.savedRecords = undefined;
		this.dataExploreModel.updatedRecords = undefined;
		this.dataExploreModel.totalFiles = undefined;
		this.dataExploreModel.conditionType = undefined;
		this.dataExploreModel.headerCheck = undefined;
		this.dataExploreModel.dataIngestionColumns = [];
		this.dataExploreModel.fileDetails = undefined;
		this.dataExploreModel.responseData = undefined;
		this.dataExploreModel.delimiter = undefined;
		//	localStorage.setItem("disableSignal", "");

		this.router.navigate(["/data-explorer"]);
	}

	getDelimiter(event) {
		if (event !== null) {
			this.dataExploreModel.delimiter = event;
			this.enable1stNext = false;
			//localStorage.setItem("disableSignal", "disableNext");
		} else {
			this.enable1stNext = true;
			//localStorage.setItem("disableSignal", "");
		}
	}

	close() {
		this.showModal = false;
	}

	modalEvent($event) {
		this.showModal = true;
		this.dataExploreModel.delimiter = this.delimiter;
		this.enable1stNext = false;
	}
}
