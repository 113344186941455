interface schema {
	schema?: any;
	catalog?: any;
	uuid?: any;
	version?: any;
	last_modified?: any;
	connectionId?: any;
}
interface prestoCatalogSchemaList {
	prestoCatalogSchemaList: schema[];
}
interface dbList {
	connectionId?: any;
	catalog?: any;
	dbType?: any;
	uuid?: any;
	version?: any;
	last_modified?: any;
	numberOfSchema?: any;
	schemaList?: schema[];
	qubzDataWarehouse?: any;
	publicName?: any;
	description?: any;
	publish?: any;
	tags?: [];
}
interface prestoData {
	prestoCatalogList: dbList[];
}

import { Component, OnInit } from "@angular/core";
import { DataExplorerService } from "../../services/data-explorer/data-explorer.service";
import swal from "sweetalert2";
import { Router } from "@angular/router";
import * as moment from "moment";
import { debounceTime } from "rxjs/operators";
import { FormControl } from "@angular/forms";

@Component({
	selector: "app-data-catalog",
	templateUrl: "./data-catalog.component.html",
	styleUrls: ["./data-catalog.component.scss"]
})
export class DataCatalogComponent implements OnInit {
	load;
	dbListArr: dbList[];
	dbImage = [
		{ type: "mysql", value: "../../../assets/images/mysql.svg" },
		{ type: "postgresql", value: "../../../assets/images/postgresql_logo.svg" },
		{ type: "redshift", value: "../../../assets/images/redshift.svg" },
		{ type: "sqlserver", value: "../../../assets/images/sqlserver.svg" },
		{ type: "oracle", value: "../../../assets/images/oracle-icon.svg" },
		{ type: "hive2", value: "../../../assets/images/hive2.png" },
		{ type: "iq_warehouse", value: "../../../assets/images/warehouse.svg" },
		{ type: "memsql", value: "../../../assets/images/memsql.svg" },
		{ type: "mongodb", value: "../../../assets/images/mongodb.svg" },
		{ type: "bigquery", value: "../../../assets/images/bigquery.svg" },
		{ type: "kafka", value: "../../../assets/images/catalog_kafka.svg" },
		{ type: "snowflake", value: "../../../assets/images/snowflake-icon.svg" }
		// { type: "salesforce", value: "../../../assets/images/salesforce-logo.svg" } hiding for now. Will enable once we implement from backend.
	];

	newDbListArr = [];
	newDbList;
	createUserReqModal: boolean;
	selectedCatalogObj = {
		catalogDataSourceType: "",
		catalogDescription: "",
		catalogId: "",
		catalogName: "",
		catalogPublicName: "",
		//description: "",
		// processedBy: "",
		// processedDate: "",
		// processorId: "",
		reason: "",
		//rejectReason: "",
		requestedDate: "",
		requesterId: "",
		requesterName: "",
		status: "",
		requestTracker: []
	};
	userNameDetails;
	isDisabled: boolean = true;
	catalogRequest;
	page: number = 0;
	isRevoked = [];
	newCatalogRequest;
	publicName;
	dataSource;
	description;
	isExpand = [];
	reqButton = [];
	tagData = [];
	newTagData;
	searchtagTxt = null;
	searchTxt: FormControl = new FormControl();
	finalArray = [];
	pageNo: number = 0;
	searchText
	constructor(
		private dataExplorerService: DataExplorerService,
		private router: Router
	) {}

	ngOnInit() {
		localStorage.setItem("catalogList", "");
		localStorage.setItem("filteredResult", "");
		localStorage.setItem("flagStatus", "");
		this.dbList();
		this.userNameDetails = JSON.parse(
			sessionStorage.getItem("userNameDetails")
		);
		if (this.searchTxt !== undefined) {
			this.searchTxt.valueChanges.pipe(debounceTime(200)).subscribe(res => {
				this.applyFilter(res);
			});
		}
	}

	dbList() {
		this.load = true;
		this.dataExplorerService.getConnectionsList().subscribe(
			(data: prestoData) => {
				this.dbListArr = data.prestoCatalogList;
				for (let row of this.dbListArr) {
					if (row.qubzDataWarehouse == false) {
						let catalogObj = {
							catalog: row.catalog,
							connectionId: row.connectionId,
							publicName: row.publicName,
							description: row.description,
							dbType: row.dbType,
							numberOfSchema: row.numberOfSchema,
							qubzDataWarehouse: row.qubzDataWarehouse,
							uuid: row.uuid,
							version: row.version,
							publish: row.publish,
							tags: row.tags?row.tags:[]
						};
						if (row.publicName !== undefined) {
							if (row.publish) {
								this.newDbListArr.push(catalogObj);
								this.reduce();
							}
						}
					}
				}
				this.load = false;
			},
			error => {
				this.load = false;
				swal("Failed to load Data!", error.error.msg, "error");
			}
		);
	}

	loadDbImage(type, warehouse) {
		let img;
		if (!warehouse) {
			this.dbImage.filter(item => {
				if (item.type === type) img = item.value;
			});
		} else {
			img = "../../../assets/iqsandbox_icon.svg";
		}

		return img;
	}

	reduce() {
		let res = [];
		if (this.newDbListArr.length > 0) {
			this.newDbListArr.map(function (item) {
				var existItem = res.find(x => x.catalog === item.catalog);
				if (!existItem) {
					res.push(item);
				}
			});
			this.newDbList = res;
			localStorage.setItem("catalogList", JSON.stringify(this.newDbList));
			this.finalArray = this.newDbList;
			this.getcatlalogRequestList();
		}
	}

	createRquest(dbObj) {
		this.createUserReqModal = true;
		this.getSelectedItemVal(dbObj);
	}

	getSelectedItemVal(item) {
		let catalogId;
		let catalogName;
		let reqDate = moment().format("YYYY-MM-DDTHH:mm:ss") + "Z";
		let requesterId;
		let requesterName;

		if (item.connectionId) {
			catalogId = item.connectionId;
		} else {
			catalogId = "";
		}

		if (catalogId) {
			this.selectedCatalogObj.catalogId = catalogId;
		} else {
			this.selectedCatalogObj.catalogId = "";
		}

		if (item.catalog) {
			catalogName = item.catalog;
		} else {
			catalogName = "";
		}

		if (catalogName) {
			this.selectedCatalogObj.catalogName = catalogName;
		} else {
			this.selectedCatalogObj.catalogName = "";
		}

		if (item.dbType) {
			this.dataSource = item.dbType;
		} else {
			this.dataSource = "";
		}

		if (this.dataSource) {
			this.selectedCatalogObj.catalogDataSourceType = this.dataSource;
		} else {
			this.selectedCatalogObj.catalogDataSourceType = "";
		}

		if (this.userNameDetails.uuid) {
			requesterId = this.userNameDetails.uuid;
		} else {
			requesterId = "";
		}

		if (requesterId) {
			this.selectedCatalogObj.requesterId = requesterId;
		} else {
			this.selectedCatalogObj.requesterId = "";
		}

		this.selectedCatalogObj.requestedDate = reqDate;
		if (this.userNameDetails.username) {
			requesterName = this.userNameDetails.username;
		} else {
			requesterName = "";
		}

		if (requesterName) {
			this.selectedCatalogObj.requesterName = requesterName;
		} else {
			this.selectedCatalogObj.requesterName = "";
		}
		this.selectedCatalogObj.status = "PENDING";

		if (item.publicName) {
			this.publicName = item.publicName;
		} else {
			this.publicName = "";
		}

		if (this.publicName !== undefined) {
			this.selectedCatalogObj.catalogPublicName = this.publicName;
		} else {
			this.selectedCatalogObj.catalogPublicName = "";
		}

		if (item.description) {
			this.description = item.description;
			if (this.description == "null") {
				this.description = "";
			}
		} else {
			this.description = "";
		}

		if (this.description !== undefined) {
			this.selectedCatalogObj.catalogDescription = this.description;
		} else {
			this.selectedCatalogObj.catalogDescription = "";
		}
	}

	close() {
		this.createUserReqModal = false;
		this.selectedCatalogObj.reason = "";
	}

	submit() {
		this.load = true;
		const param = {
			catalogDataSourceType: this.selectedCatalogObj.catalogDataSourceType,
			catalogDescription: this.selectedCatalogObj.catalogDescription,
			catalogId: this.selectedCatalogObj.catalogId,
			catalogName: this.selectedCatalogObj.catalogName,
			catalogPublicName: this.selectedCatalogObj.catalogPublicName,
			isCompleted: false,
			//last_modified: 0,
			// processedBy: this.selectedCatalogObj.processedBy,
			// processedDate: this.selectedCatalogObj.processedDate,
			// processorId: this.selectedCatalogObj.processorId,
			reason: this.selectedCatalogObj.reason,
			//rejectReason: this.selectedCatalogObj.rejectReason,
			requestedDate: this.selectedCatalogObj.requestedDate,
			requesterId: this.selectedCatalogObj.requesterId,
			requesterName: this.selectedCatalogObj.requesterName,
			status: this.selectedCatalogObj.status,
			requestTracker: this.selectedCatalogObj.requestTracker
			// uuid: "string",
			// version: "string"
		};

		this.dataExplorerService.saveCatalogRequest(param).subscribe(
			data => {
				swal(
					"Success!",
					"Your Request has been submitted for approval",
					"success"
				).then(status => {
					if (status.value == true) {
						this.load = false;
						this.router.navigate(["/user-request"]);
					}
				});
			},
			error => {
				this.load = false;
				swal("Oops!", error.error.msg, "error");
			}
		);
	}

	hasRole(roles) {
		let user = JSON.parse(sessionStorage.getItem("loggedUser"));
		for (let role of user.authorities) {
			if (role.authority == roles) return "admin";
		}
		return "user";
	}

	checkVal() {
		if (this.selectedCatalogObj.reason !== "") {
			this.isDisabled = false;
		} else {
			this.isDisabled = true;
		}
	}

	getcatlalogRequestList() {
		let size;
		this.dataExplorerService
			.getCatalogRequest(this.page, 10, "")
			.subscribe(data => {
				this.catalogRequest = data;
				if (this.catalogRequest.page.totalElements !== 0) {
					size = this.catalogRequest.page.totalElements;
				} else {
					size = 10;
				}
				this.dataExplorerService
					.getCatalogRequest(this.page, size, "")
					.subscribe(data => {
						this.newCatalogRequest = data;
						for (let db of this.finalArray) {
							for (let catReq of this.newCatalogRequest.page.content) {
								let index = this.finalArray.findIndex(el => {
									return el.connectionId == db.connectionId;
								});
								if (this.userNameDetails.uuid == catReq.requesterId) {
									if (db.connectionId == catReq.catalogId) {
										if (catReq.status == "PENDING") {
											this.isRevoked[index] = true;
											this.reqButton[index] = "Request Pending";
										}

										if (catReq.status == "APPROVED") {
											this.isRevoked[index] = true;
											this.reqButton[index] = "Request Approved";
										}
									}
								}
							}
						}
					});
			});
	}

	search() {
		for (let [key, value] of Object.entries(this.finalArray)) {
			let param = <any>{};
			param = value;
			for (let catReq of this.catalogRequest.page.content) {
				let index = this.finalArray.findIndex(el => {
					return el.connectionId == param.connectionId;
				});
				if (this.userNameDetails.uuid == catReq.requesterId) {
					if (param.connectionId == catReq.catalogId) {
						if (catReq.status == "PENDING") {
							this.isRevoked[index] = true;
							this.reqButton[index] = "Request Pending";
						}

						if (catReq.status == "APPROVED") {
							this.isRevoked[index] = true;
							this.reqButton[index] = "Request Approved";
						}
					}
				}
			}
		}
	}

	isExpandToggle(index) {
		this.isExpand[index] = !this.isExpand[index];
	}

	tagreduce() {
		let res = [];
		if (this.tagData.length > 0) {
			this.tagData.map(function (item) {
				var existItem;
				if (item != undefined) {
					existItem = res.find(
						x => x.publicName === item.publicName && x.tagName === item.tagName
					);
					if (!existItem) {
						res.push(item);
					}
				}
			});
			this.newTagData = res;
		}
	}

	applyFilter(word) {
		if (!word) return (this.finalArray = this.newDbList);
		else {
			word = word.toLowerCase();
			this.finalArray = this.newDbList
				.map((x: any) => {
					const data =
						x.publicName.toLowerCase().indexOf(word) >= 0
							? x.publicName
							: x.tags !== undefined &&
							  x.tags.filter(
									(d: any) => d.tagName.toLowerCase().indexOf(word) >= 0
							  );
					return { ...x, tags: data };
				})
				.filter(x => x.tags !== undefined && x.tags.length > 0);
		}
	}
}
