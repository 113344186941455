import {
	Component,
	OnInit,
	AfterContentChecked,
	ViewChild
} from "@angular/core";
import { DataExplorerService } from "../../../../services/data-explorer/data-explorer.service";
import swal from "sweetalert2";
import { DataSourceSteponeComponent } from "../../../../components/data-explore/data-sources/data-source-stepone/data-source-stepone.component";
import { Router } from "@angular/router";
import { FormGroup } from "@angular/forms";

@Component({
	selector: "app-create-data-source",
	templateUrl: "./create-data-source.component.html",
	styleUrls: ["./create-data-source.component.scss"]
})
export class CreateDataSourceComponent implements OnInit, AfterContentChecked {
	load;
	catalogForm: any;
	sourceForm;
	newDbList;
	dbTypes = [];

	dbImages = [
		{ type: "mysql", value: "../../../assets/images/mysql.svg" },
		{ type: "postgresql", value: "../../../assets/images/postgresql_logo.svg" },
		{ type: "redshift", value: "../../../assets/images/redshift.svg" },
		{ type: "sqlserver", value: "../../../assets/images/sqlserver.svg" },
		{ type: "oracle", value: "../../../assets/images/oracle-icon.svg" },
		{ type: "hive2", value: "../../../assets/images/hive2.png" },
		// { type: "iq_warehouse", value: "../../../assets/images/warehouse.svg" },
		{ type: "memsql", value: "../../../assets/images/memsql.svg" },
		{ type: "mongodb", value: "../../../assets/images/mongodb.svg" },
		{ type: "bigquery", value: "../../../assets/images/bigquery.svg" },
		{ type: "kafka", value: "../../../assets/images/catalog_kafka.svg" },
		{ type: "snowflake", value: "../../../assets/images/snowflake-icon.svg" },
		{ type: "iqsandbox", value: "../../../assets/images/iqsandbox_icon.svg" }
		// { type: "salesforce", value: "../../../assets/images/salesforce-logo.svg" } hiding for now. Will enable once we implement from backend.
	];

	isEdit;
	dbType = [];
	sourceType;
	showkafka = {
		showkafka_c: false,
		showexplore_c: false
	};
	showsourceDiv = {
		puopen: false,
		puopenbg: false,
		stepone: true,
		stepthree: false,
		cancelbtn: true,
		nextbtn: true,
		backbtn: false,
		submitbtn: false,
		steptwo: false
	};
	showCreateScreen: boolean = false;
	iscatalogFormValid: boolean = true;
	showSourceType: boolean = true;
	issourceFormValid: boolean = true;
	disableInputs: boolean = false;
	fileToUpload;
	testStatus = false;
	saveStatus = false;
	testButton = true;
	selectedConnectionId = null;
	selectedDbType;
	@ViewChild(DataSourceSteponeComponent)
	dataSourceSteponeComponent: DataSourceSteponeComponent;
	catalogpublicSame: boolean = false;
	isNextEnabled: boolean = true;
	searchText = null;

	showDiv = {
		puopen: false,
		puopenbg: false,
		stepone: true,
		configstreaming: false,
		cancelbtn: true,
		nextbtn: true,
		backbtn: false,
		submitbtn: false,
		stepTwo: false
	};

	isConfigFormValid: boolean = true;
	isKafkaFormValid: boolean = true;
	isJsonFormValid: boolean = true;
	kafkaForm: any;
	jsonForm: FormGroup;
	configForm: any;
	isSubmitEnabled: boolean = true;
	jsonCodeSnippet: string;
	connectionId;
	step1: boolean = true;
	step2: boolean = false;
	fileName;
	publicNamekeywordcheck;
	catalogNamekeywordcheck;
	newDbTypes = [];

	constructor(
		private dataExplorerService: DataExplorerService,
		private router: Router
	) {}

	ngOnInit() {
		if (
			localStorage.getItem("dbTypelist") !== "" &&
			localStorage.getItem("dbTypelist") !== null
		) {
			this.dbTypes = JSON.parse(localStorage.getItem("dbTypelist"));
			this.filterList();
		}

		localStorage.setItem("newStatus", "");
		localStorage.setItem("newactiveStatus", "");
		localStorage.setItem("fileUpload", "");
		localStorage.setItem("tag", "");

		if (
			localStorage.getItem("sourceType") !== "" &&
			localStorage.getItem("sourceType") !== null
		) {
			this.sourceType = localStorage.getItem("sourceType");
		}

		if (
			localStorage.getItem("connectionId") !== "" &&
			localStorage.getItem("connectionId") !== null
		) {
			this.connectionId = localStorage.getItem("connectionId");
		}

		if (
			localStorage.getItem("isEdit") !== "" &&
			localStorage.getItem("isEdit") !== null
		) {
			this.isEdit = JSON.parse(localStorage.getItem("isEdit"));
			if (this.isEdit == true) {
				this.catalogpublicSame = false;
				this.showkafka.showexplore_c = true;
				this.showkafka.showkafka_c = false;
				this.getConnectionDetails();
			}
		}
	}

	ngAfterContentChecked(): void {
		if (
			localStorage.getItem("newactiveStatus") !== "" &&
			localStorage.getItem("newactiveStatus") !== null
		) {
			let index = JSON.parse(localStorage.getItem("newactiveStatus"));
			this.showCreateScreen = false;
			this.showkafka = {
				showkafka_c: false,
				showexplore_c: false
			};
			this.dbType[index] = true;
			localStorage.setItem("newactiveStatus", "");
		}

		if (this.isEdit && this.step1 == true) {
			this.changeConnectionDetails();
		}
	}

	loadDbImage(id, i) {
		let img;
		this.dbImages.filter(item => {
			if (item.type === id) img = item.value;
		});

		return img;
	}

	showScreen(dbType, i) {
		this.dbType = [];

		if (dbType) {
			this.sourceType = dbType;
			if (this.sourceType == "kafka") {
				localStorage.setItem("clearVal", "clearValues");
			} else {
				localStorage.setItem("resetCatalog", "plzreset");
			}
			this.dbType[i] = true;
			localStorage.setItem("activeStatus", i);
		}
	}

	showNext() {
		this.showCreateScreen = true;
		if(this.sourceType=='iq_warehouse'){
			this.showsourceDiv = {
				puopen: false,
				puopenbg: false,
				stepone: false,
				stepthree: false,
				cancelbtn: true,
				nextbtn: true,
				backbtn: false,
				submitbtn: false,
				steptwo: true
			};
		}else{
			this.showsourceDiv = {
				puopen: false,
				puopenbg: false,
				stepone: true,
				stepthree: false,
				cancelbtn: true,
				nextbtn: true,
				backbtn: false,
				submitbtn: false,
				steptwo: false
			};
		}
		if (this.sourceType == "kafka") {
			this.showkafka = {
				showkafka_c: true,
				showexplore_c: false
			};
		} else {
			this.showkafka = {
				showkafka_c: false,
				showexplore_c: true
			};
		}

		if (
			localStorage.getItem("activeStatus") !== "" &&
			localStorage.getItem("activeStatus") !== null
		) {
			let index = JSON.parse(localStorage.getItem("activeStatus"));
			localStorage.setItem("newStatus", JSON.stringify(index));
			localStorage.setItem("activeStatus", "");
		}
	}

	catalogFormValid(status: boolean) {
		this.iscatalogFormValid = status;
	}

	catalogFormData(form: any) {
		this.catalogForm = form;
	}

	sourceFormValid(status: boolean) {
		this.issourceFormValid = status;
	}
	sourceFormData(form: any) {
		this.sourceForm = form;
	}

	fileUpload($event) {
		this.fileToUpload = $event;
		if (this.fileToUpload) {
			this.fileName = this.fileToUpload.name;
			this.testButton = true;
		}
	}

	showdataSourceBtn(tab) {
		if (tab == 1) {
			if (this.showsourceDiv.stepone == true) {
				if (localStorage.getItem("clearVal")) {
					this.showsourceDiv.nextbtn = true;
				} else {
					localStorage.setItem("prevBtn", "InPreBtn");
					this.showsourceDiv.nextbtn = false;
				}
			}
		} else if (tab == 2) {
			if (this.showsourceDiv.steptwo == true) {
				if (localStorage.getItem("clearVal")) {
					this.showsourceDiv.nextbtn = true;
				} else {
					localStorage.setItem("prevBtn", "InPreBtn");
					this.showsourceDiv.nextbtn = false;
				}
			}
		} else {
			if (this.showsourceDiv.stepthree == true) {
				if (localStorage.getItem("toSecond")) {
					if (localStorage.getItem("prevBtn")) {
						this.showsourceDiv.submitbtn = false;
						this.showsourceDiv.backbtn = false;
					} else {
						this.showsourceDiv.submitbtn = false;
						this.showsourceDiv.backbtn = false;
					}
				}
			}
		}
	}

	testConnection() {
		this.disableInputs = true;
		this.load = true;
		let connection;
		const formData: FormData = new FormData();
		let tags;
		if (
			localStorage.getItem("tag") !== "" &&
			localStorage.getItem("tag") != null
		) {
			tags = JSON.parse(localStorage.getItem("tag"));
		}
		if (
			localStorage.getItem("sourceResponse") !== "" &&
			localStorage.getItem("sourceResponse") !== null
		) {
			let sourcejddbcResponse = JSON.parse(
				localStorage.getItem("sourceResponse")
			);
			let sourceObj = {
				hostName: sourcejddbcResponse.hostname,
				userName: sourcejddbcResponse.username,
				config: sourcejddbcResponse.config,
				portNumber: sourcejddbcResponse.port,
				database: sourcejddbcResponse.defaultDatabase,
				password: sourcejddbcResponse.password,
				warehouse: sourcejddbcResponse.snowflakeWarehouse
			};
			this.sourceForm = sourceObj;
		}
		if (this.sourceType == "iq_warehouse") {
			connection = {
				hostname: this.sourceForm.hostName,
				username: this.sourceForm.userName,
				password: this.sourceForm.password,
				port: this.sourceForm.portNumber,
				defaultDatabase: this.sourceForm.database,
				dbType: "iq_warehouse",
				qubzDataWarehouse: true,
				catalogName: this.catalogForm.catalogName,
				publish: this.catalogForm.publishName,
				tags: tags,
				snowflakeWarehouse: null
			};
		} else if (this.sourceType == "bigquery") {
			let tagsList = [];
			for (let tag of tags) {
				let tagobj = tag.tagName;
				tagsList.push(tagobj);
			}
			let publishName;
			if (this.catalogForm.publishName == null) {
				publishName = false;
			} else {
				publishName = this.catalogForm.publishName;
			}
			formData.append("keyFile", this.fileToUpload, this.fileToUpload.name);
			connection = {
				hostname: this.sourceForm.hostName,
				username: this.sourceForm.userName,
				port: this.sourceForm.portNumber,
				defaultDatabase: this.sourceForm.database,
				dbType: this.sourceType,
				catalogName: this.catalogForm.catalogName,
				publicName: this.catalogForm.publicName,
				description: this.catalogForm.description,
				publish: publishName,
				tags: tagsList
			};
		} else {
			if (this.sourceType == "memsql") {
				connection = {
					hostname: this.sourceForm.hostName,
					username: this.sourceForm.userName,
					password: this.sourceForm.password,
					port: this.sourceForm.portNumber,
					defaultDatabase: this.sourceForm.database,
					dbType: this.sourceType,
					qubzDataWarehouse: false,
					config: this.sourceForm.config,
					catalogName: this.catalogForm.catalogName,
					publicName: this.catalogForm.publicName,
					description: this.catalogForm.description,
					publish: this.catalogForm.publishName,
					tags: tags,
					snowflakeWarehouse: null
				};
			} else if (this.sourceType == "snowflake") {
				connection = {
					hostname: this.sourceForm.hostName,
					username: this.sourceForm.userName,
					password: this.sourceForm.password,
					port: this.sourceForm.portNumber,
					defaultDatabase: this.sourceForm.database,
					dbType: this.sourceType,
					qubzDataWarehouse: false,
					catalogName: this.catalogForm.catalogName,
					publicName: this.catalogForm.publicName,
					description: this.catalogForm.description,
					publish: this.catalogForm.publishName,
					tags: tags,
					snowflakeWarehouse: this.sourceForm.warehouse
				};
			} else {
				connection = {
					hostname: this.sourceForm.hostName,
					username: this.sourceForm.userName,
					password: this.sourceForm.password,
					port: this.sourceForm.portNumber,
					defaultDatabase: this.sourceForm.database,
					dbType: this.sourceType,
					qubzDataWarehouse: false,
					catalogName: this.catalogForm.catalogName,
					publicName: this.catalogForm.publicName,
					description: this.catalogForm.description,
					publish: this.catalogForm.publishName,
					tags: tags,
					snowflakeWarehouse: null
				};
			}
		}

		let data = <any>{};
		if (this.sourceType == "bigquery") {
			this.dataExplorerService
				.testConnectionBigQurey(formData, connection)
				.subscribe(
					data => {
						this.load = false;
						this.testStatus = true;
						this.testButton = false;
						swal("Success!", "Connection tested successfully", "success");
					},
					error => {
						swal(
							"Ooops!",
							"Connection failed. <br> Please check the configuration details.",
							"error"
						);
						this.testStatus = false;
						this.saveStatus = false;
						this.load = false;
					}
				);
		} else {
			this.dataExplorerService.testConnectionDetails(connection).subscribe(
				data => {
					let res = <any>{};
					res = data;
					this.disableInputs = false;
					if (res.connectionTestResponse.status == "SUCCESS") {
						swal("Success!", "Connection tested successfully", "success");
						this.testStatus = true;
						this.testButton = false;
						this.load = false;
					} else {
						swal(
							"Ooops!",
							"Connection failed. <br> Please check the configuration details.",
							"error"
						);
						this.testStatus = false;
						this.saveStatus = false;
						this.load = false;
					}
				},
				error => {
					this.load = false;
					this.disableInputs = false;
					swal("Failed", error.error.msg, "error");
				}
			);
		}
	}

	editCatalogDetails() {
		let schemaNo;
		if (
			localStorage.getItem("nofSchema") !== "" &&
			localStorage.getItem("nofSchema") !== null
		) {
			schemaNo = localStorage.getItem("nofSchema");
		}
		this.disableInputs = true;
		this.load = true;
		let tags;
		if (
			localStorage.getItem("tag") !== "" &&
			localStorage.getItem("tag") != null
		) {
			tags = JSON.parse(localStorage.getItem("tag"));
		}

		let catalogDetails = {
			catalog: this.catalogForm.catalogName,
			connectionId: this.connectionId,
			dbType: this.sourceType,
			description: this.catalogForm.description,
			last_modified: 0,
			numberOfSchema: schemaNo,
			publicName: this.catalogForm.publicName,
			publish: this.catalogForm.publishName,
			qubzDataWarehouse: false,
			tags: tags
		};
		this.dataExplorerService
			.editCatalogDetails(catalogDetails)
			.subscribe(data => {
				this.disableInputs = false;
				this.load = false;
				this.isEdit = false;
				swal("Success!", "Catalog information updated successfully", "success");
				this.router.navigate(["/data-source"]);
			});
	}

	saveConnection() {
		this.disableInputs = true;
		this.load = true;
		let connection;
		const formData: FormData = new FormData();
		let tags;
		if (
			localStorage.getItem("tag") !== "" &&
			localStorage.getItem("tag") != null
		) {
			tags = JSON.parse(localStorage.getItem("tag"));
		}
		if (
			localStorage.getItem("sourceResponse") !== "" &&
			localStorage.getItem("sourceResponse") !== null
		) {
			let sourcejddbcResponse = JSON.parse(
				localStorage.getItem("sourceResponse")
			);
			let sourceObj = {
				hostName: sourcejddbcResponse.hostname,
				userName: sourcejddbcResponse.username,
				config: sourcejddbcResponse.config,
				portNumber: sourcejddbcResponse.port,
				database: sourcejddbcResponse.defaultDatabase,
				password: sourcejddbcResponse.password
			};

			// if (this.sourceType == "bigquery") {
			// 	sourceObj.password = "";
			// }
			this.sourceForm = sourceObj;
		}
		if (this.sourceType == "iq_warehouse") {
			connection = {
				hostname: this.sourceForm.hostName,
				username: this.sourceForm.userName,
				password: this.sourceForm.password,
				port: this.sourceForm.portNumber,
				defaultDatabase: this.sourceForm.database,
				dbType: this.sourceType,
				qubzDataWarehouse: true,
				catalogName: "iq_warehouse",
				dataExplorer: true,
				publish: false,
				publicName: "iq_warehouse",
				tags: tags,
				snowflakeWarehouse: null
			};
			console.log(connection);
		} else if (this.sourceType == "bigquery") {
			let tagsList = [];
			for (let tag of tags) {
				let tagobj = tag.tagName;
				tagsList.push(tagobj);
			}

			let publishName;
			if (this.catalogForm.publishName == null) {
				publishName = false;
			} else {
				publishName = this.catalogForm.publishName;
			}

			formData.append("keyFile", this.fileToUpload, this.fileToUpload.name);
			connection = {
				hostname: this.sourceForm.hostName,
				username: this.sourceForm.userName,
				port: this.sourceForm.portNumber,
				defaultDatabase: this.sourceForm.database,
				dbType: this.sourceType,
				catalogName: this.catalogForm.catalogName,
				publicName: this.catalogForm.publicName,
				description: this.catalogForm.description,
				publish: publishName,
				tags: tagsList
			};
		} else {
			if (this.sourceType == "memsql") {
				connection = {
					hostname: this.sourceForm.hostName,
					username: this.sourceForm.userName,
					password: this.sourceForm.password,
					port: this.sourceForm.portNumber,
					defaultDatabase: this.sourceForm.database,
					dbType: this.sourceType,
					config: this.sourceForm.config,
					catalogName: this.catalogForm.catalogName,
					dataExplorer: true,
					publicName: this.catalogForm.publicName,
					description: this.catalogForm.description,
					publish: this.catalogForm.publishName,
					tags: tags,
					snowflakeWarehouse: null
				};
			} else if (this.sourceType == "snowflake") {
				connection = {
					hostname: this.sourceForm.hostName,
					username: this.sourceForm.userName,
					password: this.sourceForm.password,
					port: this.sourceForm.portNumber,
					defaultDatabase: this.sourceForm.database,
					dbType: this.sourceType,
					catalogName: this.catalogForm.catalogName,
					dataExplorer: true,
					publicName: this.catalogForm.publicName,
					description: this.catalogForm.description,
					publish: this.catalogForm.publishName,
					tags: tags,
					snowflakeWarehouse: this.sourceForm.warehouse
				};
			} else {
				connection = {
					hostname: this.sourceForm.hostName,
					username: this.sourceForm.userName,
					password: this.sourceForm.password,
					port: this.sourceForm.portNumber,
					defaultDatabase: this.sourceForm.database,
					dbType: this.sourceType,
					catalogName: this.catalogForm.catalogName,
					dataExplorer: true,
					publicName: this.catalogForm.publicName,
					description: this.catalogForm.description,
					publish: this.catalogForm.publishName,
					tags: tags,
					snowflakeWarehouse: null
				};
			}
		}
		if (this.isEdit) {
			let data = <any>{};
			let param;
			param = {
				connectionId: this.connectionId
			};
			connection.connectionId = this.connectionId;
			if (this.sourceType == "bigquery") {
				this.dataExplorerService
					.editConnectionDetailsforBigQuery(formData, connection)
					.subscribe(
						data => {
							let res = <any>{};
							res = data;
							this.disableInputs = false;
							this.load = false;
							this.isEdit = false;
							this.saveStatus = true;
							this.testStatus = false;
							swal(
								"Success!",
								"Connection information updated successfully",
								"success"
							);
							this.router.navigate(["/data-source"]);
						},
						error => {
							swal("Ooops!", error.error.msg, "error");
							this.disableInputs = false;
							this.load = false;
						}
					);
			} else {
				this.dataExplorerService
					.editConnectionDetails(connection, param)
					.subscribe(
						data => {
							let res = <any>{};
							res = data;
							this.disableInputs = false;
							this.load = false;
							this.saveStatus = true;
							this.testStatus = false;
							swal(
								"Success!",
								"Connection information updated successfully",
								"success"
							);
							this.router.navigate(["/data-source"]);
						},
						error => {
							swal("Ooops!", error.error.msg, "error");
							this.disableInputs = false;
							this.load = false;
						}
					);
			}
		} else {
			let data = <any>{};
			if (this.sourceType == "bigquery") {
				this.dataExplorerService
					.saveConnectionBigQurey(formData, connection)
					.subscribe(
						data => {
							let res = <any>{};
							res = data;
							this.load = false;
							this.saveStatus = true;
							this.testStatus = false;
							this.disableInputs = false;
							swal(
								"Success!",
								"Connection information has been saved",
								"success"
							);
							this.router.navigate(["/data-source"]);
						},
						error => {
							swal("Ooops!", error.error.msg, "error");
							this.disableInputs = false;
							this.load = false;
						}
					);
			} else {
				this.dataExplorerService.saveConnectionDetails(connection).subscribe(
					data => {
						let res = <any>{};
						res = data;
						this.load = false;
						this.saveStatus = true;
						this.testStatus = false;
						this.disableInputs = false;
						swal(
							"Success!",
							"Connection information has been saved",
							"success"
						);
						this.router.navigate(["/data-source"]);
					},
					error => {
						swal("Ooops!", error.error.msg, "error");
						this.disableInputs = false;
						this.load = false;
					}
				);
			}
		}
	}

	changeConnectionDetails() {
		if (this.sourceType !== null) {
			//this.noDataMsg = true;
			if (this.sourceType == "kafka") {
				this.showkafka.showkafka_c = true;
				this.showkafka.showexplore_c = false;
				this.showDiv.stepone = true;
				this.showDiv.stepTwo = false;
				this.showDiv.configstreaming = false;
				if (localStorage.getItem("clearVal") == "clearValues") {
					this.showDiv.stepone = true;
					this.showDiv.configstreaming = false;
					this.showDiv.backbtn = false;
					this.showDiv.submitbtn = false;
					this.showDiv.nextbtn = true;
					this.showDiv.cancelbtn = !this.showDiv.cancelbtn;
					localStorage.setItem("toSecond", "msg2nd");
				}
			} else {
				this.testStatus = false;
				this.saveStatus = false;
				this.testButton = true;

				if (
					localStorage.getItem("sourceResponse") !== "" &&
					localStorage.getItem("sourceResponse") !== null
				) {
					let sourcejddbcResponse = JSON.parse(
						localStorage.getItem("sourceResponse")
					);
					let sourceObj = {
						hostName: sourcejddbcResponse.hostname,
						userName: sourcejddbcResponse.username,
						config: sourcejddbcResponse.config,
						portNumber: sourcejddbcResponse.port,
						database: sourcejddbcResponse.defaultDatabase,
						password: sourcejddbcResponse.password
					};

					if (this.step2 == true && this.sourceType == "bigquery") {
						sourceObj.password = "";
						this.testButton = false;
					}
					this.sourceForm = sourceObj;
				}

				if (this.sourceType) {
					this.selectedDbType = this.sourceType;
				}
				if (this.selectedDbType == "hive2") {
					this.testStatus = true;
				}
				if (this.selectedDbType == "iq_warehouse") {
					if (this.dataSourceSteponeComponent !== null) {
						this.dataSourceSteponeComponent.catalogForm.value.catalogName =
							"iq_warehouse";
					}
					this.testStatus = true;
				}
				//  else if (this.selectedDbType == "iq_warehouse") {
				// 	this.testStatus = true;
				// }
			}
		}
	}

	checkcatalogpublicSame(status: boolean) {
		this.catalogpublicSame = status;
		if (this.catalogpublicSame) {
			this.isNextEnabled = true;
		} else {
			this.isNextEnabled = false;
		}
	}

	publicNamekeywordCheck(status: boolean) {
		this.publicNamekeywordcheck = status;
	}

	catalogNamekeywordCheck(status: boolean) {
		this.catalogNamekeywordcheck = status;
	}

	goPrevious() {
		if (
			localStorage.getItem("newStatus") !== "" &&
			localStorage.getItem("newStatus") !== null
		) {
			let index = JSON.parse(localStorage.getItem("newStatus"));
			localStorage.setItem("newactiveStatus", JSON.stringify(index));
		}
	}

	showBtn(tab) {
		if (tab == 1) {
			if (this.showDiv.stepone == true) {
				if (localStorage.getItem("clearVal")) {
					this.showDiv.nextbtn = true;
				} else {
					localStorage.setItem("prevBtn", "InPreBtn");
					this.showDiv.nextbtn = false;
				}
			}
		} else if (tab == 2) {
			if (this.showDiv.stepTwo == true) {
				if (localStorage.getItem("clearVal")) {
					this.showDiv.nextbtn = true;
				} else {
					localStorage.setItem("prevBtn", "InPreBtn");
					this.showDiv.nextbtn = false;
				}
			}
		} else {
			if (this.showDiv.configstreaming == true) {
				if (localStorage.getItem("toSecond")) {
					if (localStorage.getItem("prevBtn")) {
						this.showDiv.submitbtn = false;
						this.showDiv.backbtn = false;
					} else {
						this.showDiv.submitbtn = false;
						this.showDiv.backbtn = false;
					}
				}
			}
		}
	}

	previous() {
		localStorage.setItem("prevBtn", "InPreBtn");
	}

	configFormValid(status: boolean) {
		this.isConfigFormValid = status;
	}

	kafkaFormValid(status: boolean) {
		this.isKafkaFormValid = status;
	}

	configFormData(form: any) {
		this.configForm = form;
	}

	kafkaFormData(form: any) {
		this.kafkaForm = form;
	}
	jsonFormData(form: FormGroup) {
		this.jsonForm = form;
	}
	jsonFormDataValid(status: boolean) {
		this.isJsonFormValid = status;
	}

	jsonParseSnippet(event) {
		this.jsonCodeSnippet = event;
		if (this.jsonCodeSnippet !== "") {
			if (this.testJSON(this.jsonCodeSnippet)) {
				this.isSubmitEnabled = false;
			} else {
				this.isSubmitEnabled = true;
			}
		} else {
			this.isSubmitEnabled = true;
		}
	}

	testJSON(text) {
		if (typeof text !== "string") {
			return false;
		}
		try {
			let json = JSON.parse(text);
			return typeof json === "object";
		} catch (error) {
			return false;
		}
	}

	submitData() {
		let body;
		let tags;
		if (
			localStorage.getItem("tag") !== "" &&
			localStorage.getItem("tag") != null
		) {
			tags = JSON.parse(localStorage.getItem("tag"));
		}

		swal({
			type: "warning",
			title: "Are you sure you want to save the streaming table?",
			showCancelButton: true,
			confirmButtonText: "Yes",
			cancelButtonText: "No"
		}).then(
			status => {
				if (status.value == true) {
					let errors: any = null;
					this.load = true;
					let server = [];
					this.kafkaForm.servers.forEach(element => {
						server.push(element.host + ":" + element.port);
					});
					let table = {};
					this.jsonForm.value.tableColumns.forEach(ele => {
						if (ele.comment !== "derived time dimension") {
							table = {
								...table,
								[ele.columnName]: ele.columnName
							};
						}
					});
					let colData = [];
					this.jsonForm.value.tableColumns.map(item => {
						if (item.comment !== "derived time dimension")
							colData = [
								...colData,
								{
									name: item.columnName,
									datatype: item.columnType,
									comment: item.comment,
									id: item.id,
									colName: item.columnName
								}
							];
						else
							colData = [
								...colData,
								{
									name: item.columnName,
									datatype: item.columnType,
									id: item.id,
									comment: item.comment,
									colName: item.columnName
								}
							];
					});

					let tagsList = [];
					if(tags){
						for (let tag of tags) {
							let tagobj = tag.tagName;
							tagsList.push(tagobj);
						}
					}
				

					body = {
						catalogName: this.configForm.catalogName,
						publicName: this.configForm.publicName,
						description: this.configForm.description,
						tableNames:
							this.configForm.schema + "." + this.configForm.tableName,
						topic: this.kafkaForm.topic,
						connectorName: "kafka",
						hideInternalColumns: false,
						nodes: server,
						publish: true,
						tags: tagsList,
						columns: colData
					};

					this.dataExplorerService.saveKafkaStreamingCatalog(body).subscribe(
						result => {
							swal(
								"Success!",
								"Kafka source successfully added",
								"success"
							).then(status => {
								if (status.value == true) {
									localStorage.setItem("clearVal", "clearValues");
									this.router.navigate(["/data-source"]);
									this.load = false;
								}
							});
						},
						error => {
							errors = error;
							swal("Failed to save Kafka source", errors.error.msg, "error");
							this.load = false;
						},
						() => {
							if (!errors) {
								swal(
									"Success!",
									"Kafka source successfully added",
									"success"
								).then(status => {
									if (status.value == true) {
										localStorage.setItem("clearVal", "clearValues");
										this.router.navigate(["/data-source"]);
										this.load = false;
									}
								});
							}
						}
					);
				}
			},
			dismiss => {
				if (dismiss === "cancel") {
				}
			}
		);
	}

	getConnectionDetails() {
		let param;
		param = {
			connectionId: this.connectionId
		};
		this.load = true;
		this.dataExplorerService.getConnectionIdDetails(param).subscribe(
			data => {
				if (data["jdbcConnectionResponse"].qubzDataWarehouse == true) {
					data["jdbcConnectionResponse"].dbType = "qubz";
				}
				let jdbcRes = [];
				jdbcRes = data["jdbcConnectionResponse"];
				localStorage.setItem("catalogResponse", JSON.stringify(jdbcRes));
				this.load = false;
			},
			error => {
				this.load = false;
				swal("Failed to load Connection Details", error.error.msg, "error");
			}
		);
	}

	setTab(tab) {
		if (tab == 1) {
			this.step1 = true;
			this.step2 = false;
		} else {
			this.step1 = false;
			this.step2 = true;
		}
		this.changeConnectionDetails();
	}

	filterList() {
		for (let [key, value] of Object.entries(this.dbTypes)) {
			let db = <any>{};
			db = value;
			if (db.id !== "iqsandbox") {
				this.newDbTypes.push(db);
			}
		}
	}
}
