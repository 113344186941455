import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Component({
	selector: "app-limits",
	templateUrl: "./limits.component.html",
	styleUrls: ["./limits.component.scss"]
})
export class LimitsComponent implements OnInit {
	isEdit: boolean = false;
	@Input() limits: number = undefined;
	@Output() change: EventEmitter<number> = new EventEmitter();

	ngOnInit() {
		if (this.limits) this.isEdit = true;
	}

	toggle() {
		this.isEdit = !this.isEdit;
		if (!this.isEdit) {
			this.limits = 0;
			this.change.emit(this.limits);
		}
	}
	onChange2(item) {}

	clearVal(lim) {
		this.isEdit = false;
		if (lim == 0) {
			this.limits = undefined;
		}
	}
	clear() {
		this.isEdit = false;
		this.limits = undefined;
	}
}
