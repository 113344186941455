import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UserManagementService } from 'src/app/services/users/user-management.service';

@Component({
  selector: 'app-password-reset-page',
  templateUrl: './password-reset-page.component.html',
  styleUrls: ['./password-reset-page.component.scss']
})
export class PasswordResetPageComponent implements OnInit {
  form: FormGroup;
  errMsg: String;
  //userId: String;
  userData;

  constructor(private userManagement: UserManagementService) { }

  ngOnInit() {
    //Need a check to verify person is coming from a token and set user email to display in text
    this.form = new FormGroup({
      password: new FormControl(null, [Validators.required]),
      npassword: new FormControl(null, [Validators.required])
    })
    this.errMsg = ''
  }

  updatePassword(){
    if ( this.form.value.password === this.form.value.npassword) {
      
      this.errMsg = '';
    } else {
      this.errMsg = "Passwords do not match."
     
    }
  }
}
