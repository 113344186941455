import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
	name: "newFilter"
})
export class NewFilterPipe implements PipeTransform {
	transform(items: any[], searchText: string): any[] {
		if (!items) return [];
		if (!searchText) return items;
		searchText = searchText.toLowerCase();
		return items.filter(it => {
			let searchItems;
			if (it.table) {
				searchItems = it.table.toLowerCase().includes(searchText);
			} else if (it.schema) {
				searchItems = it.schema.toLowerCase().includes(searchText);
			} else if (it.catalog) {
				searchItems = it.catalog.toLowerCase().includes(searchText);
			}
			return searchItems;
		});
	}
}
