import { FormControl } from '@angular/forms';
import { QueryFilter } from '../sql-query-filter';

export class FilterForm {
  notCondition=new FormControl()
  column = new FormControl()
  operation = new FormControl()
  value = new FormControl()
  andorCondition = new FormControl()
  

  constructor(
    filter: QueryFilter
  ) {
    this.notCondition.setValue(filter.notCondition)
    this.column.setValue(filter.column)
    this.operation.setValue(filter.operation)
    this.value.setValue(filter.value)
    this.andorCondition.setValue(filter.andorCondition)
  }
}
