import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { DataCatalogService } from "../../../../services/data-catalog/data-catalog.service";
import swal from "sweetalert2";
import { DataExploreModel } from "../../../../models/data-explore/data-explore.model";
import { Router, ActivatedRoute } from "@angular/router";
import { DataIngestionService } from "../../../../services/data-ingestion/data-ingestion.service";

@Component({
	selector: "app-upload-step3",
	templateUrl: "./upload-step3.component.html",
	styleUrls: ["./upload-step3.component.scss"]
})
export class UploadStep3Component implements OnInit {
	ingestCreate: any = [];
	fileName: any;
	rowCount: any;
	columnCount: any;
	ingestJsonCreated: boolean = true;
	load;
	@Output() clearEvent = new EventEmitter<string>();
	@Output() disableEvent = new EventEmitter<string>();

	constructor(
		private dataExploreModel: DataExploreModel,
		private dataCatalogService: DataCatalogService,
		private router: Router,
		private dataIngestionService: DataIngestionService
	) {}

	ngOnInit() {
		this.fileName = this.dataExploreModel.fileDetails.fileName;
		//this.rowCount = this.dataExploreModel.fileSize;
		this.columnCount = this.dataExploreModel.columnCount;
	}

	ingestData() {
		let splitData = this.dataExploreModel.responseData.split(".");
		this.load = true;
		this.dataIngestionService
			.saveDataIngestion(
				this.dataExploreModel.saveTableColumns,
				this.dataExploreModel.hiveDatabaseName.toLowerCase(),
				this.dataExploreModel.hiveTableName.toLowerCase(),
				this.dataExploreModel.delimiter,
				splitData[0],
				this.dataExploreModel.headerCheck
			)
			.subscribe(
				data => {
					//this.ingestCreate = data.toString();
					swal(
						"Success!",
						"Data Ingestion Job submitted successfully",
						"success"
					);
					this.load = false;
					this.clearEvent.emit();
					this.router.navigate(["/jobs"]);
				},
				error => {
					swal("Oops...", error.error.msg, "error");
					this.load = false;
					this.disableEvent.emit();
				}
			);
	}

	syncCheck() {
		let syncSlider = this.dataExploreModel.syncSlider;
		if (syncSlider == true) {
			this.syncData();
		}
	}

	syncData() {
		this.dataCatalogService.synchronize(this.ingestCreate).subscribe(
			data => {
				swal("Success", "Data Ingestion has been synchronized", "success");
				this.router.navigate(["/data-ingestion/ingestion-type"]);
			},
			error => {
				swal("Failed", error.error.msg, "error");
			}
		);
	}
}
