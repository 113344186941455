interface column {
	SchemaId?: any;
	catalog?: any;
	column?: any;
	last_modified?: any;
	schema?: any;
	table?: any;
	tableId?: any;
	type?: any;
	uuid?: any;
	version?: any;
}
interface prestoTableColumnList {
	prestoTableColumnList: column[];
}
interface dbTable {
	catalog?: any;
	last_modified?: any;
	schema?: any;
	schemaId?: any;
	table?: any;
	uuid?: any;
	version?: any;
	columns?: column[];
}
interface prestoSchemaTableList {
	prestoSchemaTableList: dbTable[];
}

import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CoreServicesService } from "src/app/services/core-services/core-services.service";
import { ToastrService } from "ngx-toastr";
import { VisualQueryBuilderService } from "../visual-query-builder.service";
import { DataExplorerService } from "../../../../services/data-explorer/data-explorer.service";
import swal from "sweetalert2";

@Component({
	selector: "app-table",
	templateUrl: "./table.component.html",
	styleUrls: ["./table.component.scss"]
})
export class TableComponent implements OnInit {
	data = { type: 1, metaColumns: [] };
	titleMessage: string;
	bodyMessage: string;
	id: string;
	emails: string;
	selTable: string;
	selFunction: string;
	isTable: boolean = true;
	columnListing;
	removeerrMsg: boolean = true;
	showTableCol: boolean = false;
	showColumns: boolean = true;
	SelectedDetails;
	filteredNewColumns = [];
	filtereColArray;
	//aliasField:boolean=true;
	//alias;
	//aliasName;

	avgFunctions = [
		{
			name: "SUM(expression)",
			value: "SUM",
			usage: "Computes the sum of the column values given by expression"
		},
		{
			name: "AVG(expression)",
			value: "AVG",
			usage: "Computes the average value of a column given by expression"
		},
		{
			name: "MIN(expression)",
			value: "MIN",
			usage: "Finds the minimum value in a column given by expression"
		},
		{
			name: "MAX(expression)",
			value: "MAX",
			usage: "Finds the maximum value in a column given by expression"
		},
		{
			name: "COUNT(expression)",
			value: "COUNT",
			usage: "Counts the rows defined by the expression"
		},
		{
			name: "COUNT(*)",
			value: "COUNT(*)",
			usage: "Counts all rows in the specified table or view"
		}
	];
	@Output() output: EventEmitter<any> = new EventEmitter();
	constructor(
		public activeModal: NgbActiveModal,
		private coreService: CoreServicesService,
		private toastr: ToastrService,
		private builderService: VisualQueryBuilderService,
		private dataExplorerService: DataExplorerService
	) {}

	ngOnInit() {
		var body = document.body;
		body.classList.add("qbmodal");
		this.isTable = false;
		if (this.builderService.selectedField) {
			this.SelectedDetails = this.builderService.selectedField;
			let table = this.SelectedDetails.table;
			this.getcolumns(table);
		}
	}

	ngOnDestroy() {
		var body = document.body;
		body.classList.remove("qbmodal");
	}
	getcolumns(table) {
		this.showTableCol = true;
		this.selTable = table;

		for (let param of this.builderService.tables) {
			for (let com of param) {
				if (table == com.catalog + "." + com.schema + "." + com.table) {
					this.dataExplorerService.getColumnListOnTable(com.uuid).subscribe(
						(data: prestoTableColumnList) => {
							this.columnListing = data.prestoTableColumnList;
							this.builderService.colListing = this.columnListing;
							this.showColumns = false;

							if (this.columnListing.length > 0) {
								this.removeerrMsg = true;
								this.checkDataType();
							} else {
								this.removeerrMsg = false;
							}
						},
						error => {
							swal("Failed to load Data", error.error.msg, "error");
							this.showColumns = true;
						}
					);
				}
			}
		}
	}
	selcol(selection) {
		if (this.selFunction) {
			selection.by = this.selFunction;
		}
		this.output.emit(selection);
		this.ngOnDestroy();
	}

	close() {
		this.showTableCol = false;
		this.activeModal.close(true);
	}

	checkDataType() {
		for (let [key, value] of Object.entries(this.columnListing)) {
			let res2 = <any>{};
			res2 = value;

			if (res2.column !== undefined) {
				if (
					[
						"INTEGER",
						"DOUBLE",
						"NUMERIC",
						"REAL",
						"DECIMAL(10,0)",
						"DECIMAL(10,2)",
						"BIGINT",
						"CHAR(4)",
						"CHAR(1)",
						"CHAR(2)",
						"CHAR(6)",
						"CHAR(5)",
						"DECIMAL(10,3)",
						"DECIMAL(3,2)",
						"DECIMAL(6,2)",
						"BOOLEAN"
					].includes(res2.type.toUpperCase())
				) {
					let columnDtl = {
						column: res2.column
					};

					this.filteredNewColumns.push(columnDtl);
					this.colReduce();
				}
			}
		}
	}

	colReduce() {
		let res = [];
		this.filteredNewColumns.map(function (item) {
			var existItem = res.find(x => x.column == item.column);
			if (!existItem) {
				res.push(item);
			}
		});
		this.filtereColArray = res;
	}

	showTable(func) {
		if (func == "COUNT(*)") {
			this.builderService.countAll = func;
			this.isTable = true;
			this.activeModal.close(true);
		} else {
			//   this.aliasField=true;
			this.isTable = false;
		}

		//   if(this.builderService.aliasName){
		// 	  this.alias=this.builderService.aliasName;
		// 	  this.aliasName=this.alias;
		//   }
		//   this.aliasField=false;
	}

	//   getAlias(alias){
	// 	if(alias){
	// 	    this.builderService.aliasName=alias;
	// 		this.aliasField=true;
	// 		this.isTable=false;
	// 	}
	//   }
}
