interface column {
	SchemaId?: any;
	catalog?: any;
	column?: any;
	last_modified?: any;
	schema?: any;
	table?: any;
	tableId?: any;
	type?: any;
	uuid?: any;
	version?: any;
}
interface prestoTableColumnList {
	prestoTableColumnList: column[];
}
interface dbTable {
	catalog?: any;
	last_modified?: any;
	schema?: any;
	schemaId?: any;
	table?: any;
	uuid?: any;
	version?: any;
	columns?: column[];
}
interface prestoSchemaTableList {
	prestoSchemaTableList: dbTable[];
}

import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CoreServicesService } from "src/app/services/core-services/core-services.service";
import { ToastrService } from "ngx-toastr";
import { VisualQueryBuilderService } from "../../visual-query-builder.service";
import { DataExplorerService } from "../../../../../services/data-explorer/data-explorer.service";
import swal from "sweetalert2";

@Component({
	selector: "app-table-sort",
	templateUrl: "./table-sort.component.html",
	styleUrls: ["./table-sort.component.scss"]
})
export class TableSortComponent implements OnInit {
	data = { type: 1, metaColumns: [] };
	titleMessage: string;
	bodyMessage: string;
	id: string;
	emails: string;
	selTable: string;
	selFunction: string;
	isTable: boolean = true;
	columnListing;
	removeerrMsg: boolean = true;
	showTableCol: boolean = false;
	showColumns: boolean = true;
	SelectedDetails;
	selectedTableCol;
	@Output() output: EventEmitter<any> = new EventEmitter();

	constructor(
		public activeModal: NgbActiveModal,
		private coreService: CoreServicesService,
		private toastr: ToastrService,
		private builderService: VisualQueryBuilderService,
		private dataExplorerService: DataExplorerService
	) {}

	ngOnInit() {
		var body = document.body;
		body.classList.add("qbmodal");
		if (this.builderService.selectedField) {
			this.SelectedDetails = this.builderService.selectedField;
			let table = this.SelectedDetails.table;
			this.getcolumns(table);
		}
	}

	ngOnDestroy() {
		var body = document.body;
		body.classList.remove("qbmodal");
	}

	getcolumns(table) {
		this.builderService.colListingArray = [];
		this.showTableCol = true;
		this.selTable = table;
		if (this.builderService.selectedTableSort) {
			this.selectedTableCol = this.builderService.selectedTableSort;

			for (let [key, value] of Object.entries(this.selectedTableCol)) {
				let res2 = <any>{};
				res2 = value;
				if (table == res2.catalog + "." + res2.schema + "." + res2.table) {
					let tableDtl = {
						catalog: res2.catalog,
						schema: res2.schema,
						table: res2.table,
						column: res2.column,
						type: res2.type
					};
					this.builderService.colListingArray.push(tableDtl);
					this.reduce();
				}
			}
		} else {
			this.builderService.selectedTableArr = [];
			this.builderService.selectedTableSort = undefined;
			for (let param of this.builderService.tables) {
				for (let com of param) {
					if (table == com.catalog + "." + com.schema + "." + com.table) {
						this.dataExplorerService.getColumnListOnTable(com.uuid).subscribe(
							(data: prestoTableColumnList) => {
								this.columnListing = data.prestoTableColumnList;
								this.builderService.colListing = this.columnListing;
								this.showColumns = false;

								if (this.columnListing.length > 0) {
									this.removeerrMsg = true;
								} else {
									this.removeerrMsg = false;
								}
							},
							error => {
								swal("Failed to load Data", error.error.msg, "error");
								this.showColumns = true;
							}
						);
					}
				}
			}
		}
	}
	selcol(selection) {
		if (this.selFunction) {
			selection.by = this.selFunction;
		}
		this.output.emit(selection);
		this.ngOnDestroy();
	}

	close() {
		this.showTableCol = false;
		this.activeModal.close(true);
	}

	reduce() {
		let res = [];
		this.builderService.colListingArray.map(function (item) {
			var existItem = res.find(
				x =>
					x.catalog == item.catalog &&
					x.schema == item.schema &&
					x.table == item.table &&
					x.column == item.column &&
					x.type == item.type
			);
			if (!existItem) {
				res.push(item);
			}
		});
		this.columnListing = res;
		this.showColumns = false;
		if (this.columnListing.length > 0) {
			this.removeerrMsg = true;
		} else {
			this.removeerrMsg = false;
		}
	}
}
