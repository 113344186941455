import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	AfterContentChecked
} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TableComponent } from "../table/table.component";
import { AggregateEl, ColumnEl, IMetaColumn, JoinEl } from "../data";
import { VisualQueryBuilderService } from "../visual-query-builder.service";

@Component({
	selector: "app-groupby",
	templateUrl: "./groupby.component.html",
	styleUrls: ["./groupby.component.scss"]
})
export class GroupbyComponent implements OnInit, AfterContentChecked {
	@Input() visualQueryForm;
	@Input() metaColumns: IMetaColumn[] = [];
	@Input() metaTabels: String[] = [];
	@Input() tables: JoinEl[] = [];
	@Input() from: string = "";
	isEdit: boolean = false;
	element: ColumnEl;
	@Input() aggEl: AggregateEl;
	isWarning;
	isWarningFun;
	@Output() change: EventEmitter<AggregateEl> = new EventEmitter();
	selectedTableCols;
	aliasName;
	countAll;
	@Input() invalidBtnMsg: boolean = true;
	invalidgrpMsg: boolean = true;

	constructor(
		public modalService: NgbModal,
		private builderService: VisualQueryBuilderService
	) {
		this.aggEl = {
			groupBy: [{ table: "", field: "", by: "", type: "", alias: "" }],
			aggColumns: [{ table: "", field: "", by: "SUM", type: "", alias: "" }]
		};
	}
	ngOnInit() {
		if (!this.aggEl) {
			this.aggEl = {
				groupBy: [{ table: "", field: "", by: "", type: "", alias: "" }],
				aggColumns: [{ table: "", field: "", by: "SUM", type: "", alias: "" }]
			};
		}
		this.isEdit = this.aggEl.groupBy.length > 1;
		if (!this.isWarningFun) {
			this.aggEl.aggColumns.length > 1;
		}
	}
	toggleFunc() {
		if (!this.from) {
			this.isWarningFun = true;
			return;
		}
		this.isWarningFun = false;
		this.aggEl.aggColumns = [
			{ table: "", field: "", by: "", type: "", alias: "" }
		];
		this.change.emit(this.aggEl);
	}

	toggle() {
		if (!this.from) {
			this.isWarning = true;
			return;
		}
		this.isWarning = false;
		this.isEdit = !this.isEdit;
		if (!this.isEdit) {
			this.aggEl.groupBy = [
				{ table: "", field: "", by: "", type: "", alias: "" }
			];
			this.change.emit(this.aggEl);
		}
	}

	addEl(el) {
		this.builderService.selectedField = undefined;
		this.builderService.newselectedTableCols = undefined;
		this.builderService.newSelectedJoinCols = undefined;
		this.builderService.comboList = undefined;
		this.builderService.joinComboList = undefined;
		//this.builderService.aliasName=undefined;
		this.builderService.countAll = undefined;
		this.countAll = undefined;
		let e = this.aggEl.groupBy.filter(f => f.field == "New item");
		if (e.length > 0) {
			return;
		}
		this.element = {
			table: this.from,
			field: "New item",
			by: "",
			type: "",
			alias: ""
		};
		//this.visualQueryForm.status = "INVALD";
		this.aggEl.groupBy.push(this.element);
		this.colDialog(1);
	}
	delEl(el) {
		this.builderService.selectedField = undefined;
		this.builderService.newselectedTableCols = undefined;
		this.builderService.newSelectedJoinCols = undefined;
		this.builderService.comboList = undefined;
		this.builderService.joinComboList = undefined;
		//this.builderService.aliasName=undefined;
		this.builderService.countAll = undefined;
		this.countAll = undefined;
		this.invalidgrpMsg = true;
		let index = this.aggEl.groupBy.indexOf(el);
		if (index != -1) this.aggEl.groupBy.splice(index, 1);
		this.change.emit(this.aggEl);
		//this.visualQueryForm.status = "VALID";
	}

	handleEl(el) {
		if (el.field == "") this.addEl(el);
	}

	colDialog(type) {
		let all = [];
		if (this.tables) {
			let fromTables = this.tables
				.map(e => e.from.table)
				.reduce((acc, value) => {
					return !acc.includes(value) ? acc.concat(value) : acc;
				}, []);

			let toTables = this.tables
				.map(e => e.to.table)
				.reduce((acc, value) => {
					return !acc.includes(value) ? acc.concat(value) : acc;
				}, []);

			all = [...fromTables, ...toTables].reduce((acc, value) => {
				return !acc.includes(value) ? acc.concat(value) : acc;
			}, []);
		} else all.push(this.from);
		if (all.length == 1) {
			this.element.table = all[0];
			this.element.by = "";
		}
		const modalRef = this.modalService.open(TableComponent);
		modalRef.componentInstance.titleMessage = "Select column";
		modalRef.componentInstance.bodyMessage = "";
		modalRef.componentInstance.selTable = this.element.table;
		modalRef.componentInstance.id = "1";
		modalRef.componentInstance.data = {
			metaColumns: this.metaColumns,
			metaTabels: all,
			type: type,
			element: this.element
		};
		modalRef.componentInstance.output.subscribe(el => {
			this.element.field = el.column;
			this.element.by = el.by;
			this.element.table = el.table;
			//   this.element.alias=this.builderService.aliasName;
			let index = this.aggEl.groupBy.indexOf(this.element);
			this.change.emit(this.aggEl);
		});

		return modalRef.result;
	}

	addElAgg(el) {
		this.builderService.selectedField = undefined;
		if (this.isEdit) {
			this.builderService.newselectedTableCols = undefined;
			this.builderService.newSelectedJoinCols = undefined;
			this.builderService.comboList = undefined;
			this.builderService.joinComboList = undefined;
			this.builderService.countAll = undefined;
			this.countAll = undefined;
			//this.builderService.aliasName=undefined;
		}
		let e = this.aggEl.aggColumns.filter(f => f.field == "New item");
		if (e.length > 0) {
			return;
		}
		this.element = {
			table: this.from,
			field: "New item",
			by: "",
			type: "",
			alias: ""
		};
		//this.visualQueryForm.status = "INVALD";
		this.aggEl.aggColumns.push(this.element);
		this.colDialog(3);
	}
	delElAgg(el) {
		this.builderService.selectedField = undefined;
		this.invalidBtnMsg = true;
		if (this.isEdit) {
			this.builderService.newselectedTableCols = undefined;
			this.builderService.newSelectedJoinCols = undefined;
			this.builderService.comboList = undefined;
			this.builderService.joinComboList = undefined;
			this.builderService.countAll = undefined;
			this.countAll = undefined;
			//this.builderService.aliasName=undefined;
		}
		let index = this.aggEl.aggColumns.indexOf(el);
		if (index != -1) this.aggEl.aggColumns.splice(index, 1);
		this.change.emit(this.aggEl);
		//this.visualQueryForm.status = "VALID";
	}

	get(field) {
		this.builderService.selectedField = field;
	}

	ngAfterContentChecked() {
		if (this.isEdit) {
			if (this.builderService.newselectedTableCols) {
				if (this.builderService.newselectedTableCols.length > 0) {
					this.selectedTableCols = this.builderService.newselectedTableCols;
					for (let i = 0; i < this.selectedTableCols.length; i++) {
						if (this.selectedTableCols[i].field !== "") {
							this.element = {
								table: this.selectedTableCols[i].table,
								field: this.selectedTableCols[i].field,
								by: "",
								type: "",
								alias: ""
							};
							this.aggEl.groupBy.push(this.element);
							this.reduce();
						} else {
							for (let j = 0; j < this.aggEl.groupBy.length; j++) {
								if (
									this.selectedTableCols[i].table ===
										this.aggEl.groupBy[j].table &&
									this.selectedTableCols[i].field !==
										this.aggEl.groupBy[j].field
								) {
									const index = this.aggEl.groupBy.findIndex(
										item =>
											item.table === this.selectedTableCols[i].table &&
											item.field !== this.selectedTableCols[i].field
									);
									this.aggEl.groupBy.splice(index, 1);
									this.builderService.selectedTableCols = [];
								}
							}
						}
					}
				}
			}
			if (this.builderService.comboList) {
				this.remove();
			}
			if (this.builderService.newSelectedJoinCols) {
				if (this.builderService.newSelectedJoinCols.length > 0) {
					for (
						let i = 0;
						i < this.builderService.newSelectedJoinCols.length;
						i++
					) {
						if (
							this.builderService.newSelectedJoinCols[i].field !== undefined
						) {
							if (this.builderService.newSelectedJoinCols[i].field !== "") {
								this.element = {
									table: this.builderService.newSelectedJoinCols[i].table,
									field: this.builderService.newSelectedJoinCols[i].field,
									by: "",
									type: "",
									alias: ""
								};
								this.aggEl.groupBy.push(this.element);
								this.reduce();
							} else {
								for (let j = 0; j < this.aggEl.groupBy.length; j++) {
									if (
										this.builderService.newSelectedJoinCols[i].table ===
											this.aggEl.groupBy[j].table &&
										this.builderService.newSelectedJoinCols[i].field !==
											this.aggEl.groupBy[j].field
									) {
										const index = this.aggEl.groupBy.findIndex(
											item =>
												item.table ===
													this.builderService.newSelectedJoinCols[i].table &&
												item.field !==
													this.builderService.newSelectedJoinCols[i].field
										);
										this.aggEl.groupBy.splice(index, 1);
										this.builderService.selectedJoinCols = [];
									}
								}
							}
						}
					}
				}
			}

			if (this.builderService.joinComboList) {
				this.removeJoin();
			}
		}

		if (this.builderService.countAll) {
			this.countAll = this.builderService.countAll;
			let countSplit = this.builderService.countAll.slice(0, 5);
			this.element.by = countSplit;
			this.element.table = "";
			this.element.field = "*";
			this.change.emit(this.aggEl);
			//this.visualQueryForm.status = "VALID";
			this.builderService.countAll = undefined;
			this.countAll = undefined;
		}
	}

	reduce() {
		let res = [];
		this.aggEl.groupBy.map(function (item) {
			var existItem = res.find(
				x => x.table === item.table && x.field === item.field
			);
			if (!existItem) {
				res.push(item);
			}
		});
		this.aggEl.groupBy = res;
		this.change.emit(this.aggEl);
	}
	remove() {
		for (let i = 0; i < this.builderService.comboList.length; i++) {
			for (let j = 0; j < this.aggEl.groupBy.length; j++) {
				if (
					this.builderService.comboList[i].table ===
						this.aggEl.groupBy[j].table &&
					this.builderService.comboList[i].field === this.aggEl.groupBy[j].field
				) {
					const index = this.aggEl.groupBy.findIndex(
						item =>
							item.table == this.builderService.comboList[i].table &&
							item.field == this.builderService.comboList[i].field
					);
					this.aggEl.groupBy.splice(index, 1);
					this.change.emit(this.aggEl);
				}
			}
		}
	}
	removeJoin() {
		for (let i = 0; i < this.builderService.joinComboList.length; i++) {
			for (let j = 0; j < this.aggEl.groupBy.length; j++) {
				if (
					this.builderService.joinComboList[i].table ===
						this.aggEl.groupBy[j].table &&
					this.builderService.joinComboList[i].field ===
						this.aggEl.groupBy[j].field
				) {
					if (this.aggEl.groupBy[j].field !== "") {
						const index = this.aggEl.groupBy.findIndex(
							item =>
								item.table === this.builderService.joinComboList[i].table &&
								item.field === this.builderService.joinComboList[i].field
						);
						this.aggEl.groupBy.splice(index, 1);
					}
				}
			}
		}
	}

	clearValue() {
		if (this.builderService.sqlQuery.groupBy == undefined) {
			this.isEdit = false;
			if (!this.aggEl) {
				this.aggEl = {
					groupBy: [{ table: "", field: "", by: "", type: "", alias: "" }],
					aggColumns: [{ table: "", field: "", by: "SUM", type: "", alias: "" }]
				};
			}
		}
	}

	clearAll() {
		this.isEdit = false;
		if (!this.isEdit || !this.isWarningFun) {
			this.aggEl = {
				groupBy: [{ table: "", field: "", by: "", type: "", alias: "" }],
				aggColumns: [{ table: "", field: "", by: "", type: "", alias: "" }]
			};
			this.change.emit(this.aggEl);
		}
	}
}
