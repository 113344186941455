import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl,Validators } from '@angular/forms';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  form: FormGroup;
  errMsg: string ='';

  constructor(private router: Router) { }

  ngOnInit() {
    this.form = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email])
    })
    this.errMsg='';
  }
  cancel() {
    this.router.navigateByUrl('')
  }
  resetPassword() {
    const emailInput = {
      email:this.form.value.email
    }
    //this.router.navigateByUrl('/password-reset-page')
    //need to pass this to a service that is connected to a new API
  }
}
