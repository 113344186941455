import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../../config';

@Injectable({
  providedIn: 'root'
})
export class VirtualSourceService {
  static url = Config.url + '/datasource';
  constructor(private http: HttpClient) { }
  getConnectionDetails(projectId) {
    return this.http.get(VirtualSourceService.url+'/'+projectId);
  }
  setConnectionDetails(connection) {
    return this.http.post(VirtualSourceService.url, connection);
  }
  testConnectionDetails(connection) {
    return this.http.post(VirtualSourceService.url+'/connection', connection);
  }
  getProjectId(projectName){
    return this.http.get(Config.url+'/projects/'+projectName);
  }
  synchronize(projectName,cardinality){
    return this.http.post(Config.url+'/tables/'+projectName+'/sync/'+cardinality,{});
  
  }

  testConnectionBigQurey(body,connection){
    return this.http.post(VirtualSourceService.url+'/connection/bigquery?hostname='+connection.hostname+'&port='+connection.port+'&username='+connection.username+'&defaultDatabase='+connection.defaultDatabase+'&additionalConfig='+connection.additionalConfig+'&dbType='+connection.dbType+'&projectId='+connection.projectId,body);
  }

  saveConnectionBigQurey(body,connection){
    return this.http.post(VirtualSourceService.url+'/bigquery?hostname='+connection.hostname+'&port='+connection.port+'&username='+connection.username+'&defaultDatabase='+connection.defaultDatabase+'&additionalConfig='+connection.additionalConfig+'&dbType='+connection.dbType+'&projectId='+connection.projectId,body);
  }
}
