import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Config } from "../../config";

@Injectable({
	providedIn: "root"
})
export class DataIngestionService {
	static url = Config.url + "/dataingestion/csvfile";
	// static url1  = Config.url + '/dataingestionjobs' ;
	constructor(private http: HttpClient) {}

	getFileHeaders(fileName, headerPresent, delimit) {
		return this.http.get(
			DataIngestionService.url +
				"?filename=" +
				fileName +
				"&headerPresent=" +
				headerPresent +
				"&delimit=" +
				delimit
		);
	}

	saveUploadedFile(formData) {
		let httpOptions = {
			headers: new HttpHeaders({
				"Content-Type": "multipart/form-data"
			})
		};
		return this.http.post(DataIngestionService.url, formData);
	}

	saveDataIngestion(
		dataIngestionColumns,
		databaseName,
		tableName,
		delimit,
		fileName,
		headerPresent
	) {
		return this.http.post(
			DataIngestionService.url +
				"/hive" +
				"?databaseName=" +
				databaseName +
				"&tableName=" +
				tableName +
				"&delimit=" +
				delimit +
				"&fileName=" +
				fileName +
				"&headerPresent=" +
				headerPresent,
			dataIngestionColumns
		);
	}
}
