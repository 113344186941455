import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ValidationError } from "ngx-awesome-uploader";
import swal from "sweetalert2";
import { FilePreviewModel } from "ngx-awesome-uploader";
import { DataExploreModel } from "../../../../models/data-explore/data-explore.model";
import { FileProgressComponent } from "../../../../components/data-explore/upload-csv/file-progress/file-progress.component";
import { DataIngestionService } from "../../../../services/data-ingestion/data-ingestion.service";

@Component({
	selector: "app-upload-step1",
	templateUrl: "./upload-step1.component.html",
	styleUrls: ["./upload-step1.component.scss"]
})
export class UploadStep1Component implements OnInit {
	adapter = new FileProgressComponent(
		this.http,
		this.dataExploreModel,
		this.dataIngestionService
	);
	result: any;
	responseItem: any = [];
	seedList: any = [];
	load;
	columnName: any;

	constructor(
		private http: HttpClient,
		private dataExploreModel: DataExploreModel,
		private dataIngestionService: DataIngestionService
	) {}
	@Output() modalEvent = new EventEmitter<string>();

	ngOnInit() {}

	onValidationError(error: ValidationError) {
		if (error.error == "EXTENSIONS")
			swal("Ooops!", `Invalid file format`, "warning");
		else if (error.error == "UPLOAD_TYPE")
			swal(
				"Ooops!",
				`To change csv file click Back to Data Sources`,
				"warning"
			);
		let msg = "in error";
		localStorage.setItem("msgToLoad", msg);
	}
	onUploadSuccess(e: FilePreviewModel) {
		swal("Success!", "File uploaded successfully", "success").then(
			status => {
				// if(status.value==true){
				this.dataExploreModel.totalFiles = undefined;
				this.load = false;
				this.modalEvent.emit();
			},
			dismiss => {
				if (dismiss === "cancel") {
				}
			}
		);
	}

	loadonService() {
		if (localStorage.getItem("msgToLoad")) {
			this.load = false;
		} else {
			this.load = true;
		}
	}

	//  handleFileSelect(evt){
	//   let files = evt.target.files; // FileList object
	//   let file = files[0];
	//     let reader = new FileReader();

	//   reader.readAsText(file);
	//   reader.onload = (event: any) => {
	//     let csv = event.target.result; // Content of CSV file
	//     console.log(csv.length);
	//   }
	//  }
}
