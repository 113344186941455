import { Component, OnInit , AfterViewInit,ViewChild } from '@angular/core';
import { DataCatalogService } from '../../../services/data-catalog/data-catalog.service';
import { VirtualSourceService } from '../../../services/virtual-source/virtual-source.service';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from "@angular/forms";
import * as $ from 'jquery';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-data-source',
  templateUrl: './data-source.component.html',
  styleUrls: ['./data-source.component.scss']
})
export class DataSourceComponent implements OnInit,AfterViewInit {
  load;
  form: FormGroup;
  connection: {
	host: '',
	username:'',
	password: ''
  };
  conn;
  testStatus=false;
  saveStatus=false;
  testButton=true;
  show_pwd:boolean=true;
  typePassword;
  projectId;
  project;
  syncStatus;
  selectedDbType;
  cardinality=false;
  @ViewChild('select') select: NgSelectComponent;
  constructor(private formBuilder: FormBuilder,
    private dataCatalogService:DataCatalogService,
    private virtualSourceService:VirtualSourceService,
    private router:Router,
    private route: ActivatedRoute
    ) { }

  users = [
      {id: 'HIVE', name: 'QUBZ'},
      {id: 'hive2', name: 'Hive'},
      {id: 'denodo', name: 'Denodo'},
      {id: 'synapse', name: 'Azure Synapse Analytics'},
      {id: 'mysql', name: 'MySQL'},
      {id: 'redshift', name: 'Amazon Redshift '},
      {id: 'sqlserver', name: 'Microsoft SQL Server'},
      {id: 'postgresql', name: 'PostgreSQL'},
      {id: 'oracle', name: 'Oracle'},
      {id: 'db2', name: 'DB2'},
      {id: 'snowflake', name: 'Snowflake'}
      // {id: 'mongo', name: 'MongoDB'},
      // {id: 'bigquery', name: 'Google BigQuery'},
      // { id: 'memsql', name:'MemSQL'}
  ];

  ngAfterViewInit() {
    $(document).ready(function(){
          var contenth = $( window ).height() - 110 ;
          var sidebarh = $( window ).height() - 108 ;
          $(".pagec").css("height", contenth );
          $(".sidebar-wrapper").css("height", sidebarh );
        });
        this.setFocus();
    }
    
  ngOnInit() {
    
    this.form = this.formBuilder.group({
      sourceType:new FormControl(null, [Validators.required]),
      hostName: new FormControl(null, [Validators.required]),
      userName: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [Validators.required]),
      config: new FormControl(null, []),
      cardinality: new FormControl(false, []),
      portNumber: new FormControl(null, [Validators.required,Validators.pattern('^[0-9]*$')]),
      database: new FormControl(null,[Validators.required, Validators.pattern("^[a-zA-Z0-9_-]+")])
    });
    this.projectId = this.route.snapshot.paramMap.get("projectId");
    this.getConnectionsDetails();
    
  }

  syncDatabase(){
    if(!this.syncStatus){
      swal(
        {
          type: 'warning',
          title: 'Are you sure you want to Sync?',
          text: 'Once the datasource sync is successfully completed, the datasource configured for the project cannot be modified.',
          showCancelButton: true, confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        }
  
      ).then((status) => {
        if (status.value == true) {
            this.load=true;
            this.virtualSourceService.synchronize(this.projectId,this.form.value.cardinality).subscribe(
              data=>{
                this.load=false;
                swal("Success!",'Source sync completed successfully', "success");
                this.router.navigate(['/projects/'+this.projectId+'/data-sources']);
            }, (error) => {
              this.load=false;
              swal("Failed",error.error.msg, "error");
            }); 
        }
      });
    }else{
      this.load=true;
      this.virtualSourceService.synchronize(this.projectId,this.form.value.cardinality).subscribe(
        data=>{
          this.load=false;
          swal("Success!",'Source sync completed successfully', "success");
          this.router.navigate(['/projects/'+this.projectId+'/data-sources']);
      }, (error) => {
        this.load=false;
        swal("Failed",error.error.msg, "error");
      }); 
    }
    

  }
  changeConnectionDetils(){
    this.testStatus=false;
    this.saveStatus=false;
    this.testButton=true;
    this.selectedDbType=this.form.value.sourceType;
  }
  getConnectionsDetails(){
    this.load=true;
    this.virtualSourceService.getProjectId(this.projectId).subscribe(
      data=>{
        let res= <any>{};
        res=data;
        this.project=res.uuid;
        this.virtualSourceService.getConnectionDetails(res.uuid).subscribe(
          data=>{
            let res=<any>{};
            res=data;
            this.conn=res;
            if(res){
              let con=this.users.find(({id}) =>res.dbType===id);	
              if(con && res.isSynced)
              res.dbType=con.name;
              this.form.patchValue({
                sourceType:res.dbType,
                hostName:res.hostname,
                userName:res.username,
                config:res.config,
                password:res.password,
                portNumber:res.port,
                database:res.defaultDatabase,
              });
              this.syncStatus=res.isSynced;
              this.saveStatus=true;
              this.testButton=false;
            }

            this.load=false;
          },
          error => {  
            this.load=false;
          });
      });
   
   
  }
  testConnection(){
    this.load=true;
    let connection;
    if(this.form.value.sourceType=='HIVE'){
      connection={
        'defaultDatabase':null,
        'dbType': this.form.value.sourceType,
        'projectId': this.project,
      }
    }else{
      connection={
        'hostname':this.form.value.hostName,
        'username':this.form.value.userName,
        'password':this.form.value.password,
        'port':this.form.value.portNumber,
        'defaultDatabase':this.form.value.database,
        'dbType': this.form.value.sourceType,
        'config':this.form.value.config,
        'projectId': this.project,
      }
    }
      let data=<any>{};
      this.virtualSourceService.testConnectionDetails(connection).subscribe(
        data=>{
          let res=<any>{};
          res=data;
          this.load=false;
          if(res.status=='SUCCESS'){
            swal("Success!","Connection tested successfully", "success");
            this.testStatus=true;
            this.testButton=false;
          }else{
            swal("Ooops!",'Connection failed. <br> Please check the configuration details.', "error");
            this.testStatus=false;
            this.saveStatus=false;
          }

      }, (error) => {
        this.load=false;
        swal("Failed",error.error.msg, "error");
      }); 
  }
  saveConnection(){
    this.load=true;
      let connection;
      if(this.form.value.sourceType=='HIVE'){
        connection={
          'defaultDatabase':null,
          'dbType': this.form.value.sourceType,
          'projectId': this.project,
        }
      }else{
        connection={
          'hostname':this.form.value.hostName,
          'username':this.form.value.userName,
          'password':this.form.value.password,
          'port':this.form.value.portNumber,
          'config':this.form.value.config,
          'defaultDatabase':this.form.value.database,
          'dbType': this.form.value.sourceType,
          'projectId': this.project,
        }
      }
      let data=<any>{};
      this.virtualSourceService.setConnectionDetails(connection).subscribe(
        data=>{
          let res=<any>{};
          res=data;
          this.load=false;
          this.saveStatus=true;
          this.testStatus=false;
          swal("Success!","Connection information has been saved", "success");
      },
      error => {  
        swal("Ooops!",error.error.msg, "error");
        this.load=false;
      });
  }
  togglePassword () {
    this.typePassword = !this.typePassword;
  };
  setFocus(){
    this.select.focus();
  }
}