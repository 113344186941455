import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { HttpClient } from '@angular/common/http';
import { Config } from '../../config';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
@Injectable({
  providedIn: 'root'
})
export class TableService {
  private activeColumn  = new BehaviorSubject({});
  columnSelected = this.activeColumn.asObservable();
  static url = Config.url + '/tables';

  constructor(private http: HttpClient) { }

  list(params) {
    return this.http.get(TableService.url,{params} );
  }
  get(project, table): Observable<any> {
    return this.http.get(TableService.url + '/' + project + '/' + table);
  }
  loadHiveTable(project, table, body) {
    return this.http.post(TableService.url + '/' + table + '/' + project, body);
  }
  unLoadHiveTable(project, table) {
    return this.http.delete(TableService.url + '/' + table + '/' + project);
  }
  genCardinality(table, project, body) {
    return this.http.put(TableService.url + '/' + project + '/' + table + '/cardinality', body);
  }
  showHiveDatabases() {
    return this.http.get(TableService.url + '/hive');
  }
  showHiveTables(database) {
    return this.http.get(TableService.url + '/hive/' + database);
  }
  getSnapshots(project, table) {
    return this.http.get(TableService.url + '/' + project + '/' + table + '/snapshots');
  }
  getTables(project){
    return this.http.get(TableService.url + '/?project=' + project);
  }
  setActiveColumn(column){
    localStorage.setItem('activeColumn',column);
    this.activeColumn.next(column);
  }
  getActiveColumn() {
    return this.columnSelected;
  }
}
