interface column {
	schedulerId?: any;
	name?: any;
	database?: any;
	table?: any;
	query?: any;
	cronString?: any;
	submitter?: any;
	triggerKeyName?: any;
	triggerKeyGroup?: any;
	status?: any;
	jobCurrentDate?: any;
}

interface MViewSchedulerDetailsList {
	MViewSchedulerDetailsList: column[];
}

import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import { DataExplorerService } from "../../../services/data-explorer/data-explorer.service";
import swal from "sweetalert2";
import { VisualQueryBuilderService } from "../../../components/data-explore/visual-query-builder/visual-query-builder.service";

@Component({
	selector: "app-scheduler-listing",
	templateUrl: "./scheduler-listing.component.html",
	styleUrls: ["./scheduler-listing.component.scss"]
})
export class SchedulerListingComponent implements OnInit {
	public schedulerLisiting = [];
	qubzLicenseExpire: any;
	qubzLicenseExpireMsg: any;
	constructor(
		private dataExploreService: DataExplorerService,
		private router: Router,
		private builderService: VisualQueryBuilderService
	) {}

	ngOnInit() {
		this.qubzLicenseExpire = JSON.parse(
			sessionStorage.getItem("qubzLicenseExpire")
		);
		this.qubzLicenseExpireMsg = JSON.parse(
			sessionStorage.getItem("qubzLicenseExpireMsg")
		);
		this.getViewJobStatus();
		this.clearVal();
		localStorage.setItem("schCronString", "");
	}

	getViewJobStatus() {
		this.dataExploreService
			.getAllJobScheduler()
			.subscribe((data: MViewSchedulerDetailsList) => {
				this.schedulerLisiting = data.MViewSchedulerDetailsList;
				localStorage.setItem(
					"schedularList",
					JSON.stringify(this.schedulerLisiting)
				);
			});
	}

	editScheduler(sch) {
		if(this.qubzLicenseExpire){
			swal("Warning!", this.qubzLicenseExpireMsg, "success");
		}else{
			localStorage.setItem("schDetls", JSON.stringify(sch));
		localStorage.setItem("schName", sch.name);
		localStorage.setItem("schCronString", sch.cronString);
		localStorage.setItem("schDbName", sch.database);
		localStorage.setItem("schTblName", sch.table);

		this.router.navigateByUrl(`/scheduler/${sch.schedulerId}/edit`);
		}
	}

	status(schId: string, currentStatus: string) {
		if(this.qubzLicenseExpire){
			swal("Warning!", this.qubzLicenseExpireMsg, "success");
		}else{
			let currStatus: string;
		if (currentStatus === "Enable") {
			currStatus = "ENABLED";
		} else {
			currStatus = "DISABLED";
		}
		swal({
			type: "warning",
			title:
				'<span class="displayinlineb"> Are you sure you want to &nbsp;</span>' +
				'<span class="lowercase-imp displayinlineb">' +
				currentStatus +
				"</span>" +
				' <span class="displayinlineb">&nbsp; this job schedule?</span>',
			showCancelButton: true,
			confirmButtonText: "Yes",
			cancelButtonText: "No"
		}).then(
			status => {
				if (status.value == true) {
					const params = {
						status: [currStatus]
					};
					this.dataExploreService
						.updateSchedulerStatus(schId, params)
						.subscribe(
							data => {
								swal(
									"Success!",
									"Scheduled job was " + currentStatus + "d.",
									"success"
								);
								this.getViewJobStatus();
							},
							error => {
								let errormsg;
								if (error.error.msg !== null) {
									errormsg = error.error.msg;
									swal(
										"Operation failed!!",
										"Reason:" + "\n" + errormsg,
										"error"
									);
								} else {
									errormsg = error.error.stacktrace;
									let stackTraceSliced = error.error.stacktrace.slice(0, 30);
									swal({
										type: "error",
										title: "Error:Please refer to logs ",
										html: "&nbsp;&nbsp;" + stackTraceSliced + " " + "....",
										showCancelButton: false,
										cancelButtonText: "Cancel",
										confirmButtonText: "OK"
									});
								}
							}
						);
				}
			},
			dismiss => {
				if (dismiss === "cancel") {
				}
			}
		);
		}
	}

	delete(schId: string) {
		if(this.qubzLicenseExpire){
			swal("Warning!", this.qubzLicenseExpireMsg, "success");
		}else{
			swal({
				type: "warning",
				title: "Are you sure you want to delete this job schedule?",
				showCancelButton: true,
				confirmButtonText: "Yes",
				cancelButtonText: "No"
			}).then(
				status => {
					if (status.value == true) {
						const params = {
							schedulerId: schId
						};
						this.dataExploreService.deleteScheduler(params).subscribe(
							data => {
								swal("Success!", "Scheduled job was deleted.", "success");
								this.getViewJobStatus();
							},
							error => {
								let errormsg;
								if (error.error.msg !== null) {
									errormsg = error.error.msg;
									swal(
										"Operation failed!!",
										"Reason:" + "\n" + errormsg,
										"error"
									);
								} else {
									errormsg = error.error.stacktrace;
									let stackTraceSliced = error.error.stacktrace.slice(0, 30);
									swal({
										type: "error",
										title: "Error:Please refer to logs ",
										html: "&nbsp;&nbsp;" + stackTraceSliced + " " + "....",
										showCancelButton: false,
										cancelButtonText: "Cancel",
										confirmButtonText: "OK"
									});
								}
							}
						);
					}
				},
				dismiss => {
					if (dismiss === "cancel") {
					}
				}
			);
		}
	}

	clearVal() {
		this.builderService.getCatalog = undefined;
		this.builderService.getCol = undefined;
		this.builderService.getSchema = undefined;
		this.builderService.getTable = undefined;
		this.builderService.getmainQuery = undefined;
		this.builderService.join = undefined;
		this.builderService.result = undefined;
		this.builderService.tableList = undefined;
		this.builderService.tableList = undefined;
		this.builderService.filters = undefined;
		this.builderService.groupBy = undefined;
		this.builderService.sortBy = undefined;
		this.builderService.limits = undefined;
		this.builderService.multiColumns = undefined;
		this.builderService.colListing = undefined;
		this.builderService.tables = [];
		this.builderService.fromTable = [];
		this.builderService.fromField = [];
		this.builderService.fromselectedTable = undefined;
		this.builderService.toTable = undefined;
		this.builderService.toField = undefined;
		this.builderService.toselectedTable = undefined;
		this.builderService.selectedField = undefined;
		this.builderService.titleList = [];
		this.builderService.selectedTableCols = [];
		this.builderService.newselectedTableCols = undefined;
		this.builderService.selectedTableCols = [];
		this.builderService.newselectedTableCols = undefined;
		this.builderService.castArray = [];
		this.builderService.selectedElementDtls = [];
		this.builderService.selectedTableArr = [];
		this.builderService.selectedTableSort = undefined;
		this.builderService.colListingArray = [];
		this.builderService.selectedJoinCols = [];
		this.builderService.newSelectedJoinCols;
		this.builderService.comboArray = [];
		this.builderService.noColumnerrorMsg = undefined;
		this.builderService.selectSortCols = undefined;
		this.builderService.selectSortColsArr = [];
		this.builderService.selectSortColsJoin = undefined;
		this.builderService.selectSortColsJoinArr = [];
		this.builderService.selectSortColsAll = undefined;
		this.builderService.selectSortColArrAll = [];
		this.builderService.selectSortColsJoinAll = undefined;
		this.builderService.selectSortColsJoinArrAll = [];
		this.builderService.countAll = undefined;
		this.builderService.analyzerQueryObj = undefined;
		this.builderService.removeerrMsg = undefined;
	}
	errorPopUp(){
		if(this.qubzLicenseExpire){
			swal("Warning!", this.qubzLicenseExpireMsg, "success");
		}
	}
}
