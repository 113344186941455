import { Injectable } from "@angular/core";
import { Config } from "../../config";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
@Injectable({
	providedIn: "root"
})
export class DataExplorerService {
	static url = Config.url + "/dataexplorer";
	static murl = Config.url + "/mview_job_schedulers";
	private userSource = new BehaviorSubject({});
	history = this.userSource.asObservable();
	private _queryChanged = new BehaviorSubject<boolean>(false);
	queryUpdated$ = this._queryChanged.asObservable();
	realTimeFormData: any;
	resetLoader=false;
	constructor(private http: HttpClient) {}
	saveConnectionDetails(connection) {
		return this.http.post(DataExplorerService.url, connection);
	}
	testConnectionDetails(connection) {
		return this.http.post(DataExplorerService.url + "/connection", connection);
	}
	getConnectionsList() {
		// return this.http.get(DataExplorerService.url + '/schemas');
		return this.http.get(DataExplorerService.url + "/metadata/catalogs");
	}
	getSchemaList(connectionId) {
		return this.http.get(
			DataExplorerService.url + "/metadata/schema/" + connectionId
		);
	}
	getTableListOnSchema(currentSchemaId) {
		return this.http.get(
			DataExplorerService.url + "/metadata/table/" + currentSchemaId
		);
		// return this.http.get(DataExplorerService.url +'/'+param.catalogName+ '/schemas/'+param.schema+'/tables');
	}
	getColumnListOnTable(currentTableId) {
		return this.http.get(
			DataExplorerService.url + "/metadata/column/" + currentTableId
		);
		//return this.http.get(DataExplorerService.url +'/'+param.catalogName+ '/schemas/'+param.schema+'/tables/'+param.table);
	}
	getPrestoResult(param) {
		return this.http.post(
			DataExplorerService.url +
				"/showData/" +
				param.catalogName +
				"/" +
				param.schema +
				"/" +
				param.table,
			param
		);
	}
	getConnectionIdDetails(param) {
		return this.http.get(DataExplorerService.url + "/" + param.connectionId);
	}
	getNewQueryResult(param) {
		return this.http.post(DataExplorerService.url + "/query", param);
	}
	getResultCount(param){
		return this.http.post(DataExplorerService.url + "/analyserCount", param);
	}
	savehistory(history) {
		this.userSource.next(localStorage.getItem("prestoQueries_history"));
	}
	getHistory() {
		return this.history;
	}
	editConnectionDetails(connection, param) {
		return this.http.put(
			DataExplorerService.url + "/" + param.connectionId,
			connection
		);
	}

	editConnectionDetailsforBigQuery(body, connection) {
		return this.http.post(
			DataExplorerService.url +
				"/" +
				connection.connectionId +
				"/bigquery?hostname=" +
				connection.hostname +
				"&port=" +
				connection.port +
				"&username=" +
				connection.username +
				"&defaultDatabase=" +
				connection.defaultDatabase +
				"&additionalConfig=" +
				connection.additionalConfig +
				"&catalogName=" +
				connection.catalogName +
				"&publicName=" +
				connection.publicName +
				"&description=" +
				connection.description +
				"&dbType=" +
				connection.dbType +
				"&publish=" +
				connection.publish,
			body
		);
	}
	deleteConnectionDetails(param) {
		return this.http.delete(DataExplorerService.url + "/" + param.connectionId);
	}

	saveQuery(param) {
		return this.http.post(DataExplorerService.url + "/dequery/", param);
	}

	getSavedQueries(params) {
		return this.http.get(DataExplorerService.url + "/dequery" + "/" + params);
	}

	removeQuery(queryId) {
		return this.http.delete(DataExplorerService.url + "/dequery/" + queryId);
	}

	saveMaterializedView(database, viewName, param) {
		return this.http.post(
			DataExplorerService.url + "/mviewQuery/" + database + "/" + viewName,
			param
		);
	}

	getListofDatabaseName() {
		return this.http.get(DataExplorerService.url + "/hive");
	}
	getListofDatabaseTables(database) {
		return this.http.get(DataExplorerService.url + "/hive/" + database);
	}
	resetMetadata() {
		return this.http.post(DataExplorerService.url + "/metadata/reset", {});
	}
	getMaterializedJobs() {
		return this.http.get(Config.url + "/dataexplorerjobs");
	}
	getMaterializedJobsbyStatus(status) {
		return this.http.get(Config.url + "/dataexplorerjobs/" + status);
	}
	deletetMaterializedJobs(jobid) {
		return this.http.delete(Config.url + "/dataexplorerjobs/" + jobid);
	}
	jobDetails(jobId) {
		return this.http.get(Config.url + "/dataexplorerjobs/" + jobId + "/steps");
	}
	resetSingleCatalog(connectionId) {
		return this.http.post(Config.url + "/metadata/reset/" + connectionId, {});
	}
	singleCatalogRefresh(connectionId) {
		return this.http.post(
			DataExplorerService.url + "/metadata/reset/" + connectionId,
			{}
		);
	}
	getJobStatusCount() {
		return this.http.get(Config.url + "/dataexplorerjobs/overview", {});
	}
	deleteDatabase(database) {
		return this.http.delete(
			DataExplorerService.url + "/hive/schema/" + database
		);
	}
	deleteTable(table) {
		return this.http.delete(DataExplorerService.url + "/hive/table/" + table);
	}

	getColumnDetails(param) {
		return this.http.get(
			DataExplorerService.url +
				"/" +
				param.catalogName +
				"/schemas/" +
				param.schema +
				"/tables/" +
				param.table
		);
	}
	getJobStepOutput(jobId, stepId) {
		return this.http.get(
			Config.url +
				"/dataexplorerjobs/" +
				jobId +
				"/steps/" +
				stepId +
				"/output/"
		);
	}

	deleteMultipleJobs(body) {
		const options = {
			headers: new HttpHeaders({
				"Content-Type": "application/json"
			}),
			body: body
		};

		return this.http.delete(Config.url + "/dataexplorerjobs/dropall", options);
	}
	getAllMetadata() {
		return this.http.get(DataExplorerService.url + "/autocomplete");
	}
	getAllUserMetadata(user){
		return this.http.get(DataExplorerService.url + "/autocomplete/"+user);
	}
	setFormData(formData: any): void {
		this.realTimeFormData = formData;
	}
	getFormData(): any {
		return this.realTimeFormData;
	}
	getDateTimePatternForV2() {
		return this.http.get(
			DataExplorerService.url + "/tables/supported_datetime_patterns"
		);
	}
	saveKafkaStreamingCatalog(body) {
		return this.http.post(
			DataExplorerService.url +
				"/kafka?catalogName=" +
				body.catalogName +
				"&publicName=" +
				body.publicName +
				"&description=" +
				body.description +
				"&tableNames=" +
				body.tableNames +
				"&connectorName=" +
				body.connectorName +
				"&hideInternalColumns=" +
				body.hideInternalColumns +
				"&nodes=" +
				body.nodes +
				"&topicName=" +
				body.topic +
				"&publish=" +
				body.publish +
				"&tags=" +
				body.tags,
			{ columns: body.columns }
		);
	}
	testConnectionBigQurey(body, connection) {
		return this.http.post(
			DataExplorerService.url +
				"/connection/bigquery?hostname=" +
				connection.hostname +
				"&port=" +
				connection.port +
				"&username=" +
				connection.username +
				"&defaultDatabase=" +
				connection.defaultDatabase +
				"&additionalConfig=" +
				connection.additionalConfig +
				"&catalogName=" +
				connection.catalogName +
				"&publicName=" +
				connection.publicName +
				"&description=" +
				connection.description +
				"&dbType=" +
				connection.dbType +
				"&publish=" +
				connection.publish +
				"&tags=" +
				connection.tags,
			body
		);
	}
	saveConnectionBigQurey(body, connection) {
		return this.http.post(
			DataExplorerService.url +
				"/bigquery?hostname=" +
				connection.hostname +
				"&port=" +
				connection.port +
				"&username=" +
				connection.username +
				"&defaultDatabase=" +
				connection.defaultDatabase +
				"&additionalConfig=" +
				connection.additionalConfig +
				"&catalogName=" +
				connection.catalogName +
				"&publicName=" +
				connection.publicName +
				"&description=" +
				connection.description +
				"&dbType=" +
				connection.dbType +
				"&publish=" +
				connection.publish +
				"&tags=" +
				connection.tags,
			body
		);
	}

	saveMaterializedScheduler(database, viewName, param, schedule) {
		return this.http.post(
			DataExplorerService.murl +
				"/mviewQuery/" +
				database +
				"/" +
				viewName +
				"?schedulerName=" +
				schedule.schedulerName +
				"&cronString=" +
				schedule.cronString,
			param
		);
	}

	validateCronString(cronString) {
		return this.http.get(
			DataExplorerService.murl + "/cron/schedules?cronString=" + cronString
		);
	}

	getAllJobScheduler() {
		return this.http.get(DataExplorerService.murl);
	}

	updateJobScheduler(param) {
		return this.http.put(DataExplorerService.murl, param);
	}

	updateSchedulerStatus(schedulerId, params) {
		return this.http.put(
			DataExplorerService.murl + "/" + schedulerId + "/status",
			{},
			{ params }
		);
	}

	deleteScheduler(params) {
		return this.http.delete(DataExplorerService.murl, { params });
	}

	getCatalog(userName, params) {
		return this.http.put(DataExplorerService.url + "/catalog/" + userName, {
			params
		});
	}

	saveCatalogRequest(param) {
		return this.http.post(Config.url + "/catalogaccess", param);
	}

	getCatalogRequest(page, size, status) {
		const params = new HttpParams()
			.set("page", page)
			.set("size", size)
			.set("status", status);
		return this.http.get(Config.url + "/catalogaccess", { params });
	}

	getUserCatalogRequest(page, size, status, requesterId) {
		const params = new HttpParams()
			.set("page", page)
			.set("size", size)
			.set("status", status)
			.set("requesterId", requesterId);
		return this.http.get(Config.url + "/catalogaccess", { params });
	}

	getCatalogRequestByCatalogId(page, size, catalogId, status) {
		const params = new HttpParams()
			.set("page", page)
			.set("size", size)
			.set("catalogId", catalogId)
			.set("status", status);
		return this.http.get(Config.url + "/catalogaccess", { params });
	}

	getApproveRequest(userName, params) {
		return this.http.put(Config.url + "/catalogaccess/approve/" + userName, {
			params
		});
	}

	deleteRequest(requestId) {
		return this.http.delete(Config.url + "/catalogaccess/delete/" + requestId);
	}

	updateCatalogRequest(param) {
		return this.http.post(Config.url + "/catalogaccess/update", param);
	}

	revokeRequest(userName, params) {
		return this.http.put(Config.url + "/catalogaccess/revoke/" + userName, {
			params
		});
	}

	editCatalogDetails(catalogDetails) {
		return this.http.post(
			DataExplorerService.url + "/edit/catalog/",
			catalogDetails
		);
	}

	serverSideAnalyzer(param) {
		return this.http.post(DataExplorerService.url + "/analyser/", param);
	}

	explainAnalyze(param) {
		return this.http.post(DataExplorerService.url + "/ExplainAnalyse/", param);
	}

	getunscheduledQuery() {
		return this.http.get(DataExplorerService.url + "/UnscheduledMviewqueries");
	}

	// getscheduledQuery() {
	// 	return this.http.get(DataExplorerService.murl + "/ScheduledMviewqueries");
	// }
	resetMetaData() {
		return this.http.get(DataExplorerService.url + "/metadata/reset");
	}
}
