import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { MetaInfoModel } from '../../components/models/metadata.model';

@Injectable()
export class MetadataService {
  dataList: MetaInfoModel[];
  constructor() {}
  setMetadata(data){
    this.dataList=data;
  }
  getMetadata(): Observable<MetaInfoModel[]> {
    return of(this.dataList);
  }
}
