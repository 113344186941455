import { Component, OnInit } from "@angular/core";
import { VisualQueryBuilderService } from "../../components/data-explore/visual-query-builder/visual-query-builder.service";

@Component({
	selector: "app-administration",
	templateUrl: "./administration.component.html",
	styleUrls: ["./administration.component.scss"]
})
export class AdministrationComponent implements OnInit {
	constructor(private builderService: VisualQueryBuilderService) {}

	ngOnInit() {
		this.clearVal();
	}

	clearVal() {
		this.builderService.getCatalog = undefined;
		this.builderService.getCol = undefined;
		this.builderService.getSchema = undefined;
		this.builderService.getTable = undefined;
		this.builderService.getmainQuery = undefined;
		this.builderService.join = undefined;
		this.builderService.result = undefined;
		this.builderService.tableList = undefined;
		this.builderService.filters = undefined;
		this.builderService.groupBy = undefined;
		this.builderService.sortBy = undefined;
		this.builderService.limits = undefined;
		this.builderService.multiColumns = undefined;
		this.builderService.colListing = undefined;
		this.builderService.tables = [];
		this.builderService.fromTable = [];
		this.builderService.fromField = [];
		this.builderService.fromselectedTable = undefined;
		this.builderService.toTable = undefined;
		this.builderService.toField = undefined;
		this.builderService.toselectedTable = undefined;
		this.builderService.selectedField = undefined;
		this.builderService.titleList = [];
		this.builderService.selectedTableCols = [];
		this.builderService.newselectedTableCols = undefined;
		this.builderService.castArray = [];
		this.builderService.selectedElementDtls = [];
		this.builderService.selectedTableArr = [];
		this.builderService.selectedTableSort = undefined;
		this.builderService.colListingArray = [];
		this.builderService.selectedJoinCols = [];
		this.builderService.newSelectedJoinCols;
		this.builderService.comboArray = [];
		this.builderService.noColumnerrorMsg = undefined;
		this.builderService.selectSortCols = undefined;
		this.builderService.selectSortColsArr = [];
		this.builderService.selectSortColsJoin = undefined;
		this.builderService.selectSortColsJoinArr = [];
		this.builderService.selectSortColsAll = undefined;
		this.builderService.selectSortColArrAll = [];
		this.builderService.selectSortColsJoinAll = undefined;
		this.builderService.selectSortColsJoinArrAll = [];
		this.builderService.countAll = undefined;
		this.builderService.analyzerQueryObj = undefined;
		this.builderService.removeerrMsg = undefined;
	}
}
