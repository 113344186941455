import { Component, OnInit } from '@angular/core';
import { LicenceService } from '../../../services/licence/licence.service';
import swal from 'sweetalert2';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
@Component({
  selector: 'app-licence',
  templateUrl: './licence.component.html',
  styleUrls: ['./licence.component.scss']
})
export class LicenceComponent implements OnInit {

  constructor(private licenceService:LicenceService,private router: Router) { }

  ngOnInit() {
  }
  decline() {

        const credentials = {
          username: "",
          password: ""
        };
        sessionStorage.setItem('credentials', JSON.stringify(credentials));
      
        localStorage.clear();
        sessionStorage.clear();
        this.licenceService.logout()
     
  }
  accept(){
    swal({
      type:'warning',
      title: 'Are you sure you want to continue ?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No'
    }).then((status) => {
      if(status.value==true){
        this.licenceService.acceptAggrement().subscribe(
          data=>{
            swal("Success! ","Updated EULA for your account","success");
            this.router.navigate(["dashboard"]);
          }, (error) => {
            swal("Failed",error.error.msg, "error");
          });
      }
    }, (dismiss) => {
      if (dismiss === 'cancel') {
      }
    });
    }
}
