import {Pipe, PipeTransform, Injectable} from '@angular/core';

@Pipe({
    name: 'statusFilter'
})
@Injectable()
export class FilterPipe implements PipeTransform {
        transform(queries1: any, searchText: any): any {
            if(searchText=='All'){
                return queries1;
            }
            
          if(searchText == null) return queries1;
      
          return queries1.filter(function(query){
            return query.status.indexOf(searchText) > -1 ;
          })
        }
}