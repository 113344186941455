import {
	Injectable,
	TemplateRef,
	ApplicationRef,
	ComponentFactoryResolver,
	Injector,
	Component
} from "@angular/core";

import { ModalWindowComponent } from "./modal-window.component";

@Injectable()
export class ModalService {
	constructor(
		private _appRef: ApplicationRef,
		private _cfResolver: ComponentFactoryResolver,
		private _injector: Injector
	) {}

	open(title, content) {
		const contentView = content.createEmbeddedView();
		const modalComponentFactory =
			this._cfResolver.resolveComponentFactory(ModalWindowComponent);
		const modalComponent = modalComponentFactory.create(this._injector, [
			contentView.rootNodes
		]);

		//if (title) modalComponent.instance.title = title;

		document.body.appendChild(modalComponent.location.nativeElement);

		contentView.detectChanges();
		this._appRef.attachView(modalComponent.hostView);
	}
}
