interface column {
	SchemaId?: any;
	catalog?: any;
	column?: any;
	last_modified?: any;
	schema?: any;
	table?: any;
	tableId?: any;
	type?: any;
	uuid?: any;
	version?: any;
}
interface prestoTableColumnList {
	prestoTableColumnList: column[];
}
interface dbTable {
	catalog?: any;
	last_modified?: any;
	schema?: any;
	schemaId?: any;
	table?: any;
	uuid?: any;
	version?: any;
	columns?: column[];
}
interface schema {
	schema?: any;
	catalog?: any;
	uuid?: any;
	version?: any;
	last_modified?: any;
	connectionId?: any;
	tableList?: dbTable[];
}
interface prestoCatalogSchemaList {
	prestoCatalogSchemaList: schema[];
}

interface dbList {
	connectionId?: any;
	catalog?: any;
	dbType?: any;
	uuid?: any;
	version?: any;
	last_modified?: any;
	numberOfSchema?: any;
	schemaList?: schema[];
	qubzDataWarehouse?: any;
}

interface prestoData {
	prestoCatalogList: dbList[];
}

import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import {
	FormGroup,
	FormControl,
	FormBuilder,
	Validators,
	FormArray
} from "@angular/forms";
import { DataExploreModel } from "../../../../models/data-explore/data-explore.model";
import { TableService } from "../../../../services/table/table.service";
import { DataExplorerService } from "../../../../services/data-explorer/data-explorer.service";
import { DataIngestionService } from "../../../../services/data-ingestion/data-ingestion.service";
import swal from "sweetalert2";
import { keywords } from "../upload-step2/keywords";

@Component({
	selector: "app-upload-step2",
	templateUrl: "./upload-step2.component.html",
	styleUrls: ["./upload-step2.component.scss"]
})
export class UploadStep2Component implements OnInit {
	toggleconfig = true;
	activeMenu: boolean = false;
	activeTable = false;
	dataType: any;
	dataTypes = [
		{ name: "string", value: "string" },
		{ name: "date", value: "date" },
		{ name: "integer", value: "integer" },
		{ name: "boolean", value: "boolean" },
		{ name: "double", value: "double" },
		{ name: "bigint", value: "bigint" }
	];
	ingestForm: FormGroup;
	databaseName: any;
	tableName: any;
	showHeaderRows: boolean = true;
	ingestSlider: boolean;
	ingestTblForm: FormGroup;
	columnName: any;
	columnType: any;
	@Output() disableEvent = new EventEmitter<string>();
	headerRows: any;
	tableCols: any = [];
	mockData = [];
	columnSlider: any;
	databaseList: any = [];
	tableNameList: any = [];
	saveHiveDtl = [];
	@Output() clearEvent = new EventEmitter<string>();
	syncSlider: boolean = false;
	columnList: FormArray;
	seedData: any;
	seedData1: any;
	savedTableCols: any;
	updateTableCols: any = [];
	editMode: boolean = false;
	seedData2: any;
	isSyncSliderEnabled: boolean = true;
	isDatabaseDisabled = true;
	isTableDisabled = true;
	unamePattern = "^[A-Za-z0-9_]{1,100}$";
	showDatabaseNameInDropDown: boolean = true;
	showDatabaseNameInTextField: boolean = true;
	responseItem: any = [];
	seedList: any = [];
	headerCheck: boolean = true;
	seedDataa: any;
	defaultvalue;
	load;
	dbListArr: dbList[];
	tablePatternErrorMsg: boolean = true;
	tablePatternError;
	dbPatternErrorMsg: boolean = true;
	dbPatternError;
	dbRequired: boolean = true;
	tblRequired: boolean = true;
	dbRequiredError;
	tableRequiredError;
	columnPatternErrorMsg = [];
	columnPatternError = [];
	enableSave: boolean = false;
	keywordErrorMsg = [];
	keywordError = [];

	constructor(
		private fb: FormBuilder,
		public dataExploreModel: DataExploreModel,
		private tableService: TableService,
		private dataExplorerService: DataExplorerService,
		private dataIngestionService: DataIngestionService
	) {}

	ngOnInit() {
		this.dbList();
		this.dataExploreModel.totalFiles = undefined;
		this.ingestTblForm = this.fb.group({
			columns: this.fb.array([])
		});

		this.columnList = this.ingestTblForm.get("columns") as FormArray;

		if (this.dataExploreModel.headerCheck! !== undefined) {
			this.headerCheck = this.dataExploreModel.headerCheck;
			this.seedDataa = {};
			//this.dataExploreModel.updatedRecords=undefined;
			this.seedList = [];

			const control = <FormArray>this.ingestTblForm.controls["columns"];
			for (let i = control.length - 1; i >= 0; i--) {
				control.removeAt(i);
			}

			this.headersList();
		} else {
			if (this.dataExploreModel.responseData !== undefined) {
				this.dataExploreModel.headerCheck = this.headerCheck;
				this.headersList();
			} else {
				this.headerCheck = false;
			}
		}

		this.disableInputs();
		this.createIngestForm();
		this.retainHiveValues();
		this.showHeaderRows = false;
		let msge = "from default";

		this.setDefaultvalue(this.defaultvalue);
		if (this.dataExploreModel.conditionType !== undefined) {
			this.getDatabaseName(this.ingestForm);
			this.retainHiveValues();
			this.disableEvent.emit();
		} else {
			localStorage.setItem("default", msge);
			this.defaultvalue = this.ingestForm.value.condtitionType = "exist";
			this.ingestForm.controls["conditonType"].setValue("exist");
			this.setDefaultvalue(this.defaultvalue);
		}

		//this.getDatabaseName(this.ingestForm);

		let tableName;
		const reg = new RegExp(this.unamePattern);
		let output;
		if (this.dataExploreModel.hiveTableName) {
			tableName = this.dataExploreModel.hiveTableName;
			output = reg.test(tableName);
			this.ingestForm.controls["tableName"].setValue(tableName);
		} else {
			tableName = this.dataExploreModel.fileDetails.fileName.split(".csv");
			output = reg.test(tableName[0]);
			this.ingestForm.controls["tableName"].setValue(tableName[0]);
		}

		// this.tblRequired = true;
		// this.tableRequiredError = "";
		if (output == false) {
			this.tablePatternErrorMsg = false;
			this.tablePatternError =
				"Only character(s) like underscore, letters and numbers are allowed.";
		} else {
			this.tablePatternErrorMsg = true;
			this.tablePatternError = "";
		}
		localStorage.setItem("editClick", "");
	}

	setDefaultvalue(value) {
		localStorage.getItem("default");
		if (value) {
			this.dataExploreModel.conditionType = value;
			this.showDatabaseNameInDropDown = false;
			this.showDatabaseNameInTextField = true;
			//this.ingestForm.controls['databaseName'].reset();
		}
	}

	loadForm(data) {
		this.dataExploreModel.editMode = this.editMode;
		this.checkcolumnPatternOnLoad(data);
		this.checkkeyWordOnLoad(data);
		if (this.dataExploreModel.editMode !== undefined) {
			if (this.dataExploreModel.editMode == false) {
				for (let [key, value] of Object.entries(data)) {
					let res4 = <any>{};
					res4 = value;
					this.dataExploreModel.columnName = res4.columnName;
					this.dataExploreModel.columnType = res4.columnType;

					const columnFormArray = this.ingestTblForm.get(
						"columns"
					) as FormArray;
					columnFormArray.push(this.createColumns());
				}
			} else {
				for (let [key, value] of Object.entries(data)) {
					let res2 = <any>{};
					res2 = value;
					this.dataExploreModel.columnName = res2.columnName;
					this.dataExploreModel.columnType = res2.columnType;

					const columnFormArray = this.ingestTblForm.get(
						"columns"
					) as FormArray;
					columnFormArray.push(this.createColumns());
				}
				this.ingestTblForm.patchValue(data);
			}
		} else {
			for (let [key, value] of Object.entries(data)) {
				let res1 = <any>{};
				res1 = value;
				this.dataExploreModel.columnName = res1.columnName;
				this.dataExploreModel.columnType = res1.columnType;

				const columnFormArray = this.ingestTblForm.get("columns") as FormArray;
				columnFormArray.push(this.createColumns());
			}
			this.ingestTblForm.patchValue(data);
		}
	}

	showContainer() {
		return {
			"show-o": this.toggleconfig
		};
	}

	createIngestForm() {
		this.ingestForm = this.fb.group({
			conditonType: [],
			databaseName: ["", [Validators.required]],
			tableName: ["", [Validators.required]]
		});
	}

	createColumns(): FormGroup {
		return this.fb.group({
			columnName: [this.dataExploreModel.columnName],
			columnType: [this.dataExploreModel.columnType]
		});
	}

	get columnFormGroups() {
		return this.ingestTblForm.get("columns") as FormArray;
	}

	disableInputs() {
		(<FormArray>this.ingestTblForm.get("columns")).controls.forEach(
			columnName => {
				columnName.enable();
			},
			columnType => {
				columnType.enable();
			}
		);
	}

	saveHiveDtls(form: FormGroup) {
		let HiveDtl = {
			hive: [
				{
					databaseName: form.value.databaseName,
					tableName: form.value.tableName
				}
			]
		};
		this.saveHiveDtl.push(HiveDtl);
		this.dataExploreModel.hiveDatabaseName = form.value.databaseName;
		this.dataExploreModel.hiveTableName = form.value.tableName;
		this.dataExploreModel.saveHivedetails = this.saveHiveDtl;
	}

	clearHiveDtls() {
		this.ingestForm.reset();
		this.clearEvent.emit();
	}

	enableTableDetails() {
		localStorage.setItem("editClick", "editClicked");
		(<FormArray>this.ingestTblForm.get("columns")).controls.forEach(
			columnName => {
				columnName.enable();
			},
			columnType => {
				columnType.enable();
			}
		);
		this.clearEvent.emit();
	}

	saveTableDtls(tblForm) {
		this.editMode = true;
		this.dataExploreModel.editMode = this.editMode;
		this.dataExploreModel.dataIngestionColumns = tblForm.value.columns;
		this.dataExploreModel.headerRecords = tblForm.value.columns;
		if (this.dbPatternErrorMsg == true && this.tablePatternErrorMsg == false) {
			localStorage.setItem("editClick", "");
			this.clearEvent.emit();
		} else if (
			this.dbPatternErrorMsg == false &&
			this.tablePatternErrorMsg == true
		) {
			localStorage.setItem("editClick", "");
			this.clearEvent.emit();
		} else if (
			this.dbPatternErrorMsg == false &&
			this.tablePatternErrorMsg == false
		) {
			localStorage.setItem("editClick", "");
			this.clearEvent.emit();
		} else if (this.dbRequired == false && this.tablePatternErrorMsg == true) {
			localStorage.setItem("editClick", "");
			this.clearEvent.emit();
		} else if (this.tblRequired == false && this.tablePatternErrorMsg == true) {
			localStorage.setItem("editClick", "");
			this.clearEvent.emit();
		} else {
			this.showNext();
		}
	}

	clearTableDtls() {
		this.editMode = false;
		(<FormArray>this.ingestTblForm.get("columns")).controls.forEach(
			columnName => {
				columnName.disable();
			},
			columnType => {
				columnType.disable();
			}
		);

		const control = <FormArray>this.ingestTblForm.controls["columns"];
		for (let i = control.length - 1; i >= 0; i--) {
			control.removeAt(i);
		}

		this.seedData = this.dataExploreModel.headerRecords;
		this.loadForm(this.seedData);
		this.showNext();
		//this.disableEvent.emit();
	}

	retainHiveValues() {
		this.ingestForm.patchValue({
			conditonType: this.dataExploreModel.conditionType,
			databaseName: this.dataExploreModel.hiveDatabaseName,
			tableName: this.dataExploreModel.hiveTableName
		});
	}

	retainTableValues() {
		this.ingestTblForm.patchValue({
			columnName: this.dataExploreModel.columnName,
			columnType: this.dataExploreModel.columnType
		});
	}

	changeColumnName(i) {
		this.dataExploreModel.editMode = this.editMode;
		this.dataExploreModel.columnName = this.ingestTblForm.get(
			`columns.${i}`
		).value.columnName;
	}

	changeColumnType(i) {
		this.dataExploreModel.editMode = this.editMode;
		this.dataExploreModel.columnType = this.ingestTblForm.get(
			`columns.${i}`
		).value.columnType;
	}

	showNext() {
		let db = this.ingestForm.value.databaseName;
		let tbl = this.ingestForm.value.tableName;
		if (db !== "" && tbl == undefined) {
			this.clearEvent.emit();
		} else if (db !== "" && tbl == "") {
			localStorage.setItem("editClick", "");
			this.clearEvent.emit();
		} else if (db == "" && tbl !== "") {
			localStorage.setItem("editClick", "");
			this.clearEvent.emit();
		} else if (db == "" && tbl == "") {
			localStorage.setItem("editClick", "");
			this.clearEvent.emit();
		} else if (db == null && tbl !== "") {
			localStorage.setItem("editClick", "");
			this.clearEvent.emit();
		} else if (db !== undefined && tbl !== undefined) {
			this.saveHiveDtls(this.ingestForm);
			this.disableEvent.emit();
			localStorage.setItem("editClick", "");
		}
	}

	showDetails() {
		this.dataExploreModel.editMode = this.editMode;
		if (this.dataExploreModel.editMode == false) {
			let columnNames = this.dataExploreModel.dataIngestionColumns;
			let columnType = "String";
			for (let param of columnNames) {
				let dataIngestionColumn = {
					columnName: param.columnName.replace(/\s/g, ""),
					comment: "",
					dataType: columnType
				};
				this.tableCols.push(dataIngestionColumn);
			}

			this.dataExploreModel.saveTableColumns = this.tableCols;
			this.dataExploreModel.headerRecords = this.ingestTblForm.value.columns;
		} else {
			let columnNames = this.dataExploreModel.dataIngestionColumns;
			for (let param of columnNames) {
				let dataIngestionColumn = {
					columnName: param.columnName.replace(/\s/g, ""),
					comment: "",
					dataType: param.columnType.replace(/\s/g, "")
				};
				this.tableCols.push(dataIngestionColumn);
			}

			this.dataExploreModel.saveTableColumns = this.tableCols;
			this.savedTableCols = this.ingestTblForm.value.columns;
			this.dataExploreModel.updatedRecords = this.savedTableCols;
		}
	}

	getDatabaseName(form: FormGroup) {
		localStorage.setItem("default", "");
		if (form.value.conditonType == "new") {
			this.showDatabaseNameInTextField = false;
			this.showDatabaseNameInDropDown = true;

			form.controls["databaseName"].reset();
			this.clearEvent.emit();
		} else {
			this.showDatabaseNameInDropDown = false;
			this.showDatabaseNameInTextField = true;
			form.controls["databaseName"].reset();
			this.clearEvent.emit();
		}

		this.dataExploreModel.conditionType = form.value.conditonType;
	}

	getDbName(form: FormGroup) {
		if (form.value.databaseName == null) {
			this.clearEvent.emit();
		} else {
			this.dataExploreModel.hiveDatabaseName = form.value.databaseName;
		}
	}

	getTblName(form: FormGroup) {
		if (form.value.tableName == null) {
			this.clearEvent.emit();
		} else {
			this.dataExploreModel.hiveTableName = form.value.tableName;
		}
	}

	headersList() {
		this.load = true;
		this.dataIngestionService
			.getFileHeaders(
				this.dataExploreModel.responseData,
				this.dataExploreModel.headerCheck,
				this.dataExploreModel.delimiter
			)
			.subscribe(data => {
				this.responseItem = data;
				this.dataExploreModel.headerRows = this.responseItem;
				this.dataExploreModel.dataIngestionColumns =
					this.responseItem.dataIngestionColumns;
				this.dataExploreModel.columnCount =
					this.dataExploreModel.headerRows.columnCount;
				this.dataExploreModel.fileSize =
					this.dataExploreModel.headerRows.fileSize;
				let dataIngestioncolumn = this.dataExploreModel.dataIngestionColumns;
				this.load = false;
				let columnType = "string";
				for (let param of dataIngestioncolumn) {
					this.seedDataa = {
						columnName: param.columnName.trim(),
						columnType: columnType
					};
					this.seedList.push(this.seedDataa);
				}
				this.dataExploreModel.headerRecords = this.seedList;
				this.seedData = this.dataExploreModel.headerRecords;
				if (this.dataExploreModel.updatedRecords !== undefined) {
					this.seedData2 = this.dataExploreModel.updatedRecords;
				}

				if (this.dataExploreModel.editMode !== undefined) {
					this.editMode = this.dataExploreModel.editMode;
					if (this.editMode == false) {
						this.loadForm(this.seedData);
					} else {
						if (this.dataExploreModel.updatedRecords !== undefined) {
							this.loadForm(this.seedData2);
						} else {
							this.loadForm(this.seedData);
						}
					}
				} else {
					this.loadForm(this.seedData);
				}
			});
	}

	headerChecked(h) {
		this.headerCheck = h;
		this.dataExploreModel.headerCheck = this.headerCheck;
		this.editMode = false;
		this.dataExploreModel.editMode = this.editMode;
		//  this.dataExploreModel.columnName=[];
		//  this.dataExploreModel.columnType=[];
		// this.dataExploreModel.headerRows=[];
		//this.dataExploreModel.dataIngestionColumns=[];
		//this.dataExploreModel.headerRecords=undefined;
		//this.seedData=undefined;
		//this.seedData1=undefined;
		//this.seedData2=undefined;
		this.seedDataa = {};
		//this.dataExploreModel.updatedRecords=undefined;
		this.seedList = [];

		const control = <FormArray>this.ingestTblForm.controls["columns"];
		for (let i = control.length - 1; i >= 0; i--) {
			control.removeAt(i);
		}

		// /this.seedData = this.dataExploreModel.headerRecords;
		//  this.loadForm(this.seedData);
		this.headersList();
	}

	dbList() {
		this.load = true;
		this.dataExplorerService.getConnectionsList().subscribe(
			(data: prestoData) => {
				this.dbListArr = data.prestoCatalogList;
				for (let row of this.dbListArr) {
					if (row.qubzDataWarehouse) {
						this.showSchemaList(row.connectionId, row.catalog);
					}
				}
				this.load = false;
			},
			error => {
				this.load = false;
				swal("Failed to load Data", error.error.msg, "error");
			}
		);
	}

	showSchemaList(connectionId, catalog) {
		this.dataExplorerService.getSchemaList(connectionId).subscribe(
			(data: prestoCatalogSchemaList) => {
				if (this.dbListArr)
					this.dbListArr.map(item => {
						if (item.connectionId === connectionId) {
							if (!item.schemaList)
								item.schemaList = data.prestoCatalogSchemaList;
							if (catalog == "iq_warehouse") {
								this.databaseList = item.schemaList;
							}
						}
					});
			},
			error => {
				swal("Failed to load Data", error.error.msg, "error");
			}
		);
	}

	checkDbPattern(event) {
		if (event == "") {
			this.dbRequired = false;
			this.dbRequiredError = "Database Name is required.";
		} else {
			this.dbRequired = true;
			this.dbRequiredError = "";
			const reg = new RegExp(this.unamePattern);
			let output = reg.test(event);
			if (output == false) {
				this.dbPatternErrorMsg = false;
				this.dbPatternError =
					"Only character(s) like underscore, letters and numbers are allowed.";
				//	this.clearEvent.emit();
			} else {
				this.dbPatternErrorMsg = true;
				this.dbPatternError = "";
				//	this.disableEvent.emit();
			}
		}
	}

	checkDbrequired(form: FormGroup) {
		if (form.value.databaseName == null) {
			this.dbRequired = false;
			this.dbRequiredError = "Database Name is required.";
		} else {
			this.dataExploreModel.hiveDatabaseName = form.value.databaseName;
			this.dbRequired = true;
			this.dbRequiredError = "";
		}
	}

	checktablePattern(event) {
		if (event == "") {
			this.tblRequired = false;
			this.tableRequiredError = "Table Name is required.";
		} else {
			this.tblRequired = true;
			this.tableRequiredError = "";
			const reg = new RegExp(this.unamePattern);
			let output = reg.test(event);
			if (output == false) {
				this.tablePatternErrorMsg = false;
				this.tablePatternError =
					"Only character(s) like underscore, letters and numbers are allowed.";
				//	this.clearEvent.emit();
			} else {
				this.tablePatternErrorMsg = true;
				this.tablePatternError = "";
				//this.disableEvent.emit();
			}
		}
	}

	checkButtonEnable() {
		let db = this.ingestForm.value.databaseName;
		let tbl = this.ingestForm.value.tableName;
		if (db !== "" && tbl == undefined) {
			localStorage.setItem("editClick", "");
			this.clearEvent.emit();
		} else if (db !== "" && tbl == "") {
			localStorage.setItem("editClick", "");
			this.clearEvent.emit();
		} else if (db == "" && tbl !== "") {
			localStorage.setItem("editClick", "");
			this.clearEvent.emit();
		} else if (db == "" && tbl == "") {
			localStorage.setItem("editClick", "");
			this.clearEvent.emit();
		} else if (db == null && tbl !== "") {
			localStorage.setItem("editClick", "");
			this.clearEvent.emit();
		} else if (
			this.dbPatternErrorMsg == true &&
			this.tablePatternErrorMsg == false
		) {
			localStorage.setItem("editClick", "");
			this.clearEvent.emit();
		} else if (
			this.dbPatternErrorMsg == false &&
			this.tablePatternErrorMsg == true
		) {
			localStorage.setItem("editClick", "");
			this.clearEvent.emit();
		} else if (
			this.dbPatternErrorMsg == false &&
			this.tablePatternErrorMsg == false
		) {
			localStorage.setItem("editClick", "");
			this.clearEvent.emit();
		} else if (this.dbRequired == false && this.tablePatternErrorMsg == true) {
			localStorage.setItem("editClick", "");
			this.clearEvent.emit();
		} else if (this.tblRequired == false && this.tablePatternErrorMsg == true) {
			localStorage.setItem("editClick", "");
			this.clearEvent.emit();
		} else {
			this.enableButton(this.dataExploreModel.columnName);
		}
		//  else {
		// 	this.saveHiveDtls(this.ingestForm);
		// 	this.disableEvent.emit();
		// }
	}

	checkColumnPattern(i) {
		let colName = this.ingestTblForm.get(`columns.${i}`).value.columnName;
		if (colName !== "") {
			const reg = new RegExp(this.unamePattern);
			let output = reg.test(colName);

			if (output == false) {
				//this.enableSave = true;
				this.columnPatternErrorMsg[i] = false;
				this.columnPatternError[i] =
					"Only character(s) like underscore, letters and numbers are allowed.";
			} else {
				this.columnPatternErrorMsg[i] = true;
				this.columnPatternError[i] = "";
				this.enableSave = false;
			}
		} else {
			this.columnPatternErrorMsg[i] = true;
			this.columnPatternError[i] = "";
		}

		(<FormArray>this.ingestTblForm.get("columns")).controls.forEach(
			columnName => {
				if (columnName.status == "INVALID") {
					this.enableSave = true;
				}
			}
		);
	}

	checkcolumnPatternOnLoad(d) {
		for (let [key, value] of Object.entries(d)) {
			let res4 = <any>{};
			res4 = value;
			let index = d.findIndex(el => {
				return el.columnName == res4.columnName;
			});
			if (res4.columnName !== "") {
				this.dataExploreModel.columnName = res4.columnName;
				const reg = new RegExp(this.unamePattern);
				let output = reg.test(this.dataExploreModel.columnName);

				if (output == false) {
					this.enableSave = true;
					this.columnPatternErrorMsg[index] = false;
					this.columnPatternError[index] =
						"Only character(s) like underscore, letters and numbers are allowed.";
				} else {
					this.columnPatternErrorMsg[index] = true;
					this.columnPatternError[index] = "";
					this.enableSave = false;
				}
			} else {
				this.columnPatternErrorMsg[index] = true;
				this.columnPatternError[index] = "";
			}

			this.checkButtonEnable();
		}
	}
	enableButton(event) {
		if (event !== "") {
			const reg = new RegExp(this.unamePattern);
			let output = reg.test(event);
			if (output == false) {
				//this.enableSave = true;
				this.clearEvent.emit();
			} else {
				if (!localStorage.getItem("editClick")) {
					this.enableSave = false;
					this.saveHiveDtls(this.ingestForm);
					this.disableEvent.emit();
				}
			}

			(<FormArray>this.ingestTblForm.get("columns")).controls.forEach(
				columnName => {
					if (columnName.status == "INVALID") {
						this.enableSave = true;
					}
				}
			);
		} else {
			if (!localStorage.getItem("editClick")) {
				this.enableSave = false;
				this.saveHiveDtls(this.ingestForm);
				this.disableEvent.emit();
			}
		}
	}

	checkKeyword(i) {
		let colName = this.ingestTblForm.get(`columns.${i}`).value.columnName;
		let newStatus;
		if (
			colName.toLowerCase() == keywords.BREAK ||
			colName.toLowerCase() == keywords.CASE ||
			colName.toLowerCase() == keywords.VAR ||
			colName.toLowerCase() == keywords.MODULE ||
			colName.toLowerCase() == keywords.PUBLIC ||
			colName.toLowerCase() == keywords.FINALLY ||
			colName.toLowerCase() == keywords.NULL ||
			colName.toLowerCase() == keywords.IN ||
			colName.toLowerCase() == keywords.ANY ||
			colName.toLowerCase() == keywords.PACKAGE ||
			colName.toLowerCase() == keywords.NEW ||
			colName.toLowerCase() == keywords.CONTINUE ||
			colName.toLowerCase() == keywords.AS ||
			colName.toLowerCase() == keywords.IF ||
			colName.toLowerCase() == keywords.NUMBER ||
			colName.toLowerCase() == keywords.TYPE ||
			colName.toLowerCase() == keywords.PRVATE ||
			colName.toLowerCase() == keywords.FOR ||
			colName.toLowerCase() == keywords.SUPER ||
			colName.toLowerCase() == keywords.RETURN ||
			colName.toLowerCase() == keywords.EXTENDS ||
			colName.toLowerCase() == keywords.IMPLEMENTS ||
			colName.toLowerCase() == keywords.TRY ||
			colName.toLowerCase() == keywords.DO ||
			colName.toLowerCase() == keywords.THROW ||
			colName.toLowerCase() == keywords.STRING ||
			colName.toLowerCase() == keywords.INSTANCEOF ||
			colName.toLowerCase() == keywords.ENUM ||
			colName.toLowerCase() == keywords.WHILE ||
			colName.toLowerCase() == keywords.THIS ||
			colName.toLowerCase() == keywords.TRUE ||
			colName.toLowerCase() == keywords.STATIC ||
			colName.toLowerCase() == keywords.INTERFACE ||
			colName.toLowerCase() == keywords.YIELD ||
			colName.toLowerCase() == keywords.CATCH ||
			colName.toLowerCase() == keywords.SWITCH ||
			colName.toLowerCase() == keywords.ELSE ||
			colName.toLowerCase() == keywords.GET ||
			colName.toLowerCase() == keywords.TYPEOF ||
			colName.toLowerCase() == keywords.EXPORT ||
			colName.toLowerCase() == keywords.VOID ||
			colName.toLowerCase() == keywords.FALSE ||
			colName.toLowerCase() == keywords.LET ||
			colName.toLowerCase() == keywords.FUNCTION ||
			colName.toLowerCase() == keywords.DATE ||
			colName.toLowerCase() == keywords.VARCHAR ||
			colName.toLowerCase() == keywords.INTEGER ||
			colName.toLowerCase() == keywords.INT ||
			colName.toLowerCase() == keywords.FLOAT ||
			colName.toLowerCase() == keywords.DECIMAL ||
			colName.toLowerCase() == keywords.REAL ||
			colName.toLowerCase() == keywords.BYTE ||
			colName.toLowerCase() == keywords.BIGINT ||
			colName.toLowerCase() == keywords.TIME ||
			colName.toLowerCase() == keywords.TIMESTAMP ||
			colName.toLowerCase() == keywords.ARRAY ||
			colName.toLowerCase() == keywords.BINARY ||
			colName.toLowerCase() == keywords.BIT ||
			colName.toLowerCase() == keywords.BLOB ||
			colName.toLowerCase() == keywords.BOOLEAN ||
			colName.toLowerCase() == keywords.CHAR ||
			colName.toLowerCase() == keywords.CLOB ||
			colName.toLowerCase() == keywords.DATALINK ||
			colName.toLowerCase() == keywords.DISTINCT ||
			colName.toLowerCase() == keywords.DOUBLE ||
			colName.toLowerCase() == keywords.JAVA_OBJECT ||
			colName.toLowerCase() == keywords.LONGNVARCHAR ||
			colName.toLowerCase() == keywords.LONGVARBINARY ||
			colName.toLowerCase() == keywords.LONGNVARCHAR ||
			colName.toLowerCase() == keywords.NCHAR ||
			colName.toLowerCase() == keywords.NCLOB ||
			colName.toLowerCase() == keywords.NUMERIC ||
			colName.toLowerCase() == keywords.NVARCHAR ||
			colName.toLowerCase() == keywords.SMALLINT ||
			colName.toLowerCase() == keywords.SQLXML ||
			colName.toLowerCase() == keywords.STRUCT ||
			colName.toLowerCase() == keywords.TIME_WITH_TIMEZONE ||
			colName.toLowerCase() == keywords.TIMESTAMP_WITH_TIMEZONE ||
			colName.toLowerCase() == keywords.TINYINT ||
			colName.toLowerCase() == keywords.VARBINARY ||
			colName.toLowerCase() == keywords.EQUAL ||
			colName.toLowerCase() == keywords.EQUALS ||
			colName.toLowerCase() == keywords.ABSTRACT ||
			colName.toLowerCase() == keywords.CLASS ||
			colName.toLowerCase() == keywords.DEFAULT ||
			colName.toLowerCase() == keywords.FINAL ||
			colName.toLowerCase() == keywords.IMPORT ||
			colName.toLowerCase() == keywords.LONG ||
			colName.toLowerCase() == keywords.NATIVE ||
			colName.toLowerCase() == keywords.PROTECTED ||
			colName.toLowerCase() == keywords.SHORT ||
			colName.toLowerCase() == keywords.STRICTFP ||
			colName.toLowerCase() == keywords.SYNCHRONIZED ||
			colName.toLowerCase() == keywords.THROWS ||
			colName.toLowerCase() == keywords.TRANSIENT ||
			colName.toLowerCase() == keywords.VOLATILE ||
			colName.toLowerCase() == keywords.GOTO ||
			colName.toLowerCase() == keywords.DROP ||
			colName.toLowerCase() == keywords.CREATE ||
			colName.toLowerCase() == keywords.INSERT ||
			colName.toLowerCase() == keywords.SELECT ||
			colName.toLowerCase() == keywords.FROM ||
			colName.toLowerCase() == keywords.ALTER ||
			colName.toLowerCase() == keywords.TRUNCATE ||
			colName.toLowerCase() == keywords.AND ||
			colName.toLowerCase() == keywords.OR ||
			colName.toLowerCase() == keywords.ADD ||
			colName.toLowerCase() == keywords.UPDATE ||
			colName.toLowerCase() == keywords.SET ||
			colName.toLowerCase() == keywords.DELETE ||
			colName.toLowerCase() == keywords.ORDERBY ||
			colName.toLowerCase() == keywords.ASC ||
			colName.toLowerCase() == keywords.DESC ||
			colName.toLowerCase() == keywords.BETWEEN ||
			colName.toLowerCase() == keywords.WHERE ||
			colName.toLowerCase() == keywords.NOT ||
			colName.toLowerCase() == keywords.LIMIT ||
			colName.toLowerCase() == keywords.KEY ||
			colName.toLowerCase() == keywords.HAVING ||
			colName.toLowerCase() == keywords.JOIN ||
			colName.toLowerCase() == keywords.UNION ||
			colName.toLowerCase() == keywords.LIKE ||
			colName.toLowerCase() == keywords.PROCEDURE ||
			colName.toLowerCase() == keywords.CONST
		) {
			this.keywordErrorMsg[i] = false;
			this.keywordError[i] = "Keywords are not allowed as column name";
			newStatus = "INVALID";
			this.columnList.controls[i].get("columnName").setErrors(newStatus);
			this.enableSave = true;
		} else {
			this.keywordErrorMsg[i] = true;
			this.keywordError[i] = "";
			newStatus = "VALID";
			this.enableSave = false;
		}
	}

	checkkeyWordOnLoad(d) {
		for (let [key, value] of Object.entries(d)) {
			let res4 = <any>{};
			res4 = value;
			this.dataExploreModel.columnName = res4.columnName;
			let index = d.findIndex(el => {
				return el.columnName == res4.columnName;
			});
			if (
				this.dataExploreModel.columnName.toLowerCase() == keywords.BREAK ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.CASE ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.VAR ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.MODULE ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.PUBLIC ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.FINALLY ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.NULL ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.IN ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.ANY ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.PACKAGE ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.NEW ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.CONTINUE ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.AS ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.IF ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.NUMBER ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.TYPE ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.PRVATE ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.FOR ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.SUPER ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.RETURN ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.EXTENDS ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.IMPLEMENTS ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.TRY ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.DO ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.THROW ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.STRING ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.INSTANCEOF ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.ENUM ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.WHILE ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.THIS ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.TRUE ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.STATIC ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.INTERFACE ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.YIELD ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.CATCH ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.SWITCH ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.ELSE ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.GET ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.TYPEOF ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.EXPORT ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.VOID ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.FALSE ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.LET ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.FUNCTION ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.DATE ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.VARCHAR ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.INTEGER ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.INT ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.FLOAT ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.DECIMAL ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.REAL ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.BYTE ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.BIGINT ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.TIME ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.TIMESTAMP ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.ARRAY ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.BINARY ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.BIT ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.BLOB ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.BOOLEAN ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.CHAR ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.CLOB ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.DATALINK ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.DISTINCT ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.DOUBLE ||
				this.dataExploreModel.columnName.toLowerCase() ==
					keywords.JAVA_OBJECT ||
				this.dataExploreModel.columnName.toLowerCase() ==
					keywords.LONGNVARCHAR ||
				this.dataExploreModel.columnName.toLowerCase() ==
					keywords.LONGVARBINARY ||
				this.dataExploreModel.columnName.toLowerCase() ==
					keywords.LONGNVARCHAR ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.NCHAR ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.NCLOB ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.NUMERIC ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.NVARCHAR ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.SMALLINT ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.SQLXML ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.STRUCT ||
				this.dataExploreModel.columnName.toLowerCase() ==
					keywords.TIME_WITH_TIMEZONE ||
				this.dataExploreModel.columnName.toLowerCase() ==
					keywords.TIMESTAMP_WITH_TIMEZONE ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.TINYINT ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.VARBINARY ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.EQUAL ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.EQUALS ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.ABSTRACT ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.CLASS ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.DEFAULT ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.FINAL ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.IMPORT ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.LONG ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.NATIVE ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.PROTECTED ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.SHORT ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.STRICTFP ||
				this.dataExploreModel.columnName.toLowerCase() ==
					keywords.SYNCHRONIZED ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.THROWS ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.TRANSIENT ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.VOLATILE ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.GOTO ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.DROP ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.CREATE ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.INSERT ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.SELECT ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.FROM ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.ALTER ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.TRUNCATE ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.AND ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.OR ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.ADD ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.UPDATE ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.SET ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.DELETE ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.ORDERBY ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.ASC ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.DESC ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.BETWEEN ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.WHERE ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.NOT ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.LIMIT ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.KEY ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.HAVING ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.JOIN ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.UNION ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.LIKE ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.PROCEDURE ||
				this.dataExploreModel.columnName.toLowerCase() == keywords.CONST
			) {
				this.keywordErrorMsg[index] = false;
				this.keywordError[index] = "Keywords are not allowed as column name";
				this.enableSave = true;
			} else {
				this.keywordErrorMsg[index] = true;
				this.keywordError[index] = "";
				this.enableSave = false;
			}
			this.checkButtonEnable();
		}
	}
}
