import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { HttpClient, HttpHeaders,HttpEvent, HttpRequest } from '@angular/common/http';
import { Config } from '../../config';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {
  httpOptions = {};
  static url = Config.url + '/user/';
  private _pictureChanged = new BehaviorSubject<boolean>(false);
  pictureUpdated$ = this._pictureChanged.asObservable();
  constructor(private http: HttpClient) { }
  updatePassword(userDetails){
    return this.http.post(UserSettingsService.url+'password/',userDetails);
  }
  getProfile(){
    return this.http.get(UserSettingsService.url+'profile/');
  }
  updateProfile(userDetails){
    return this.http.post(UserSettingsService.url+'profile/',userDetails).pipe(map((response: Response) => {
      this._pictureChanged.next(true);
            }));
  }
  updateProfileImage(avatar){
    // let httpOptions = {
    //   headers: new HttpHeaders({'Content-Type': "multipart/form-data"
      
		// 	})
		// };
    return this.http.post(UserSettingsService.url+'profile/avatar',avatar).pipe(map((response: Response) => {
      this._pictureChanged.next(true);
            }));
      
  }
  getProfileImage(username,timestamp){
    // let httpOptions = {
		// 	headers: new HttpHeaders({
    //     "Accept": "image/jpeg"
		// 	})
		// };
    return this.http.get(UserSettingsService.url+username+'/profile/avatar');
  }
  getUserDetails(username){
    return this.http.get(UserSettingsService.url+username);
  }
}
