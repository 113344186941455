import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ColumnEl, IMetaColumn, JoinEl } from "../data";
import { TableSortComponent } from "../table/table-sort/table-sort.component";
import { VisualQueryBuilderService } from "../visual-query-builder.service";

@Component({
	selector: "app-sort",
	templateUrl: "./sort.component.html",
	styleUrls: ["./sort.component.scss"]
})
export class SortComponent implements OnInit {
	@Input() visualQueryForm;
	@Input() metaColumns: IMetaColumn[] = [];
	@Input() metaTabels: String[] = [];
	@Input() tables: JoinEl[] = [];
	@Input() from: string = "";
	isEdit: boolean = false;
	@Input() elements: ColumnEl[];
	element: ColumnEl;
	isWarning;
	@Output() change: EventEmitter<ColumnEl[]> = new EventEmitter();
	selectedTableCols;
	invalidsortMsg: boolean = true;

	constructor(
		public modalService: NgbModal,
		private builderService: VisualQueryBuilderService
	) {
		this.elements = [];
	}

	ngOnInit() {
		if (!this.elements) {
			this.elements = [{ table: "", field: "", by: "", type: "", alias: "" }];
		}
		if (!this.isWarning) {
			this.elements && this.elements.length > 1;
		}
	}
	toggle() {
		if (!this.from) {
			this.isWarning = true;
			return;
		}
		this.isWarning = false;
		this.elements = [{ table: "", field: "", by: "", type: "", alias: "" }];
		this.change.emit(this.elements);
	}
	addEl(el) {
		this.builderService.selectedField = undefined;
		// this.builderService.selectedTableSort=undefined;
		this.builderService.selectSortCols = undefined;
		let e = this.elements.filter(f => f.field == "New item");
		if (e.length > 0) {
			return;
		}
		this.element = {
			table: this.from,
			field: "New item",
			by: "ASC",
			type: "",
			alias: ""
		};
		this.elements.push(this.element);
		this.change.emit(this.elements);
		this.colDialog(this.element);
	}
	delEl(el) {
		this.builderService.selectedField = undefined;
		//this.builderService.selectedTableSort=undefined;
		// this.builderService.selectSortCols=undefined;
		let index = this.elements.indexOf(el);
		if (index != -1) this.elements.splice(index, 1);
		this.change.emit(this.elements);
	}

	sort(el) {
		let index = this.elements.indexOf(el);
		if (index != -1) {
			if (this.elements[index].by == "ASC") this.elements[index].by = "DESC";
			else this.elements[index].by = "ASC";
			this.change.emit(this.elements);
		}
	}
	handleEl(el) {
		if (el.field == "") this.addEl(el);
	}

	colDialog(element) {
		let all = [];
		if (this.tables) {
			let fromTables = this.tables
				.map(e => e.from.table)
				.reduce((acc, value) => {
					return !acc.includes(value) ? acc.concat(value) : acc;
				}, []);

			let toTables = this.tables
				.map(e => e.to.table)
				.reduce((acc, value) => {
					return !acc.includes(value) ? acc.concat(value) : acc;
				}, []);

			all = [...fromTables, ...toTables].reduce((acc, value) => {
				return !acc.includes(value) ? acc.concat(value) : acc;
			}, []);
		} else {
			all.push(this.from);
		}
		if (all.length == 1) {
			element.table = all[0]; //= {table: all[0], by: "ASC", field:'' };
		}
		this.element = element;
		const modalRef = this.modalService.open(TableSortComponent);
		modalRef.componentInstance.titleMessage = "Select column";
		modalRef.componentInstance.bodyMessage = "";
		modalRef.componentInstance.selTable = element.table;
		modalRef.componentInstance.id = "1";
		modalRef.componentInstance.data = {
			metaColumns: this.metaColumns,
			metaTabels: all,
			type: 1,
			element: element
		};

		modalRef.componentInstance.output.subscribe(el => {
			this.element.field = el.column;
			this.element.table = el.table;
			let index = this.elements.indexOf(this.element);
			this.change.emit(this.elements);
		});
		return modalRef.result;
	}

	get(field) {
		this.builderService.selectedField = field;
	}

	removeSortfromCatalogDetails() {
		for (let i = 0; i < this.builderService.selectSortCols.length; i++) {
			for (let j = 0; j < this.elements.length; j++) {
				if (
					this.builderService.selectSortCols[i].table ===
						this.elements[j].table &&
					this.builderService.selectSortCols[i].field === this.elements[j].field
				) {
					const index = this.elements.findIndex(
						item =>
							item.table == this.builderService.selectSortCols[i].table &&
							item.field == this.builderService.selectSortCols[i].field
					);
					this.elements.splice(index, 1);
					this.change.emit(this.elements);
					this.builderService.selectSortColsArr = [];
				}
			}
		}
	}

	removeSortFromJoins() {
		for (let i = 0; i < this.builderService.selectSortColsJoin.length; i++) {
			for (let j = 0; j < this.elements.length; j++) {
				if (
					this.builderService.selectSortColsJoin[i].table ===
						this.elements[j].table &&
					this.builderService.selectSortColsJoin[i].field ===
						this.elements[j].field
				) {
					const index = this.elements.findIndex(
						item =>
							item.table == this.builderService.selectSortColsJoin[i].table &&
							item.field == this.builderService.selectSortColsJoin[i].field
					);
					this.elements.splice(index, 1);
					this.change.emit(this.elements);
					this.builderService.selectSortColsArr = [];
				}
			}
		}
	}

	clearValue() {
		if (!this.isWarning && !this.elements) {
			this.elements = [{ table: "", field: "", by: "", type: "", alias: "" }];
		}
	}

	removeCatalogAll() {
		for (let i = 0; i < this.builderService.selectSortColsAll.length; i++) {
			for (let j = 0; j < this.elements.length; j++) {
				if (
					this.builderService.selectSortColsAll[i].table ===
						this.elements[j].table &&
					this.builderService.selectSortColsAll[i].field !==
						this.elements[j].field
				) {
					const index = this.elements.findIndex(
						item =>
							item.table === this.builderService.selectSortColsAll[i].table &&
							item.field !== this.builderService.selectSortColsAll[i].field
					);
					this.elements.splice(index, 1);
					this.change.emit(this.elements);
					this.elements = [
						{
							table: this.builderService.selectSortColsAll[i].table,
							field: this.builderService.selectSortColsAll[i].field,
							by: "",
							type: "",
							alias: ""
						}
					];
					this.builderService.selectSortColArrAll = [];
				}
			}
		}
	}

	removeJoinsAll() {
		for (let [key, value] of Object.entries(
			this.builderService.selectSortColsJoinAll
		)) {
			let res2 = <any>{};
			res2 = value;
			for (let [key, value] of Object.entries(this.elements)) {
				let res3 = <any>{};
				res3 = value;
				if (res3.table == res2.table && res3.field !== res2.field) {
					let index = this.elements.findIndex(
						item => item.table == res2.table && item.field !== res2.field
					);
					if (index !== -1) {
						this.elements.splice(index, 1);
					}
				}
			}
		}
	}
}
