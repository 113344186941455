import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "catalogFilter" })

export class catalogFilter implements PipeTransform {

    /**

     * Pipe filters the list of elements based on the search text provided

     *

     * @param items list of elements to search in

     * @param searchText search string

     * @returns list of elements filtered by search text or []

     */

    transform(items: any[], searchText: string): any[] {

        if (!items) {

            return [];

        }

        if (!searchText) {

            return items;

        }

      

        return items.filter(it => {

            return (

                (it.publicName &&

                    it.publicName.toLowerCase().includes(searchText.toLowerCase())) ||

                    checkTags(it.tags,searchText)
            );

        });

    }

}
function checkTags(it,searchText){
    if(it.length<=0) return false
    for(let row of it){
        if(row.tagName.toLowerCase().includes(searchText.toLowerCase()))
        return true
    }
}