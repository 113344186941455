export const CHAR_STRING = [
	"char",
	"varchar",
	"text",
	"nchar",
	"nvarchar",
	"ntext"
];

export const NUMERIC_STRING = [
	"bigint",
	"numeric",
	"bit",
	"smallint",
	"decimal",
	"smallmoney",
	"int",
	"tinyint",
	"money",
	"float",
	"real",
	"integer",
	"double",
	"double precision"
];

export const DATE_STRING = [
	"date",
	"datetimeoffset",
	"datetime2",
	"smalldatetime",
	"datetime",
	"time",
	"timestamp"
];

export const isTextColumn = (column: string): boolean => {
	return CHAR_STRING.includes(column.toLowerCase());
};

export const isNumericColumn = (column: string): boolean => {
	return NUMERIC_STRING.includes(column.toLowerCase());
};

export const isDateColumn = (column: string): boolean => {
	return DATE_STRING.includes(column.toLowerCase());
};
