import { Component, OnInit, AfterViewInit } from '@angular/core';
import { UserManagementService } from '../../../services/users/user-management.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from "@angular/forms";
import { User } from "../../../models/users/user.model";
import { NgForm } from "@angular/forms";
import { FormsModule } from '@angular/forms';
import * as $ from 'jquery';
@Component({
  selector: 'app-user-management-view',
  templateUrl: './user-management-view.component.html',
  styleUrls: ['./user-management-view.component.scss']
})
export class UserManagementViewComponent implements OnInit,AfterViewInit {

  user: User;
  dropdownList = [];
  selectedItems = [];
  authoritiesArray=[];
  userdata: User;
  dropdownSettings = {};
  name:String;
  authorities = [
    { authority: "ROLE_ADMIN", name: "ADMIN" },
    { authority: "ROLE_MODELER", name: "MODELER" },
    { authority: "ROLE_ANALYST", name: "USER" },
    { authority: "ROLE_DATA_ADMIN", name: "DATA ADMIN" },
  ];
  constructor(private formBuilder: FormBuilder, private userManagementService: UserManagementService, private router: Router) { }
  OnCancelView(){
    this.router.navigate(["users"]);
  }

  ngAfterViewInit() {
    $(document).ready(function(){
          var contenth = $( window ).height() - 110 ;
          var sidebarh = $( window ).height() - 111 ;
          $(".pagec").css("height", contenth );
          $(".sidebar-wrapper").css("height", sidebarh );
        });
    }
    
  ngOnInit() {
    this.user = JSON.parse(sessionStorage.getItem('user'));
    for (let i = 0; i < this.user.authorities.length; i++) {
      if(this.user.authorities[i].authority=="ROLE_ADMIN"){
        this.name="ADMIN";
      }
      if(this.user.authorities[i].authority=="ROLE_MODELER"){
        this.name="MODELER";
      }
      if(this.user.authorities[i].authority=="ROLE_ANALYST"){
        this.name="USER";
      }
      if(this.user.authorities[i].authority=="ROLE_DATA_ADMIN"){
        this.name="DATA ADMIN";
      }
      this.selectedItems.push({ "authority": this.user.authorities[i].authority, "name": this.name });
    }
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'authority',
      textField: 'name',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

}
