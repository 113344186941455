import { Injectable, isDevMode  } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient,HttpParams } from '@angular/common/http';
import { Config } from '../../config';
@Injectable({
  providedIn: 'root'
})
export class LicenceService {

  static url = Config.url + '/user';
  constructor(private router: Router,private http: HttpClient) { }
  logout() {
    if(isDevMode()) {
      this.router.navigateByUrl('');
    } else {
      window.location.href=window.location.origin + "/j_spring_security_logout";
    }
  }
  acceptAggrement() {
    return this.http.post(LicenceService.url+'/eula/',{});
  }
  getLicence() {
    return this.http.get(Config.url+'/license/');
  }
}
