import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Config } from '../../config';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  static url = Config.url;
  constructor(private http: HttpClient) { }
  getCatalogsCount() {
    return this.http.get(DashboardService.url + '/dataexplorer/metadata/catalogs');
  }
  getJObsCount() {
    return this.http.get(DashboardService.url + '/dataexplorerjobs/overview');
  }
  getqdwsCount() {
    return this.http.get(DashboardService.url + '/dataexplorer/hive');
  }
  getQueryCount() {
    return this.http.get(DashboardService.url + '/dataexplorer/queycount');
  }
}
