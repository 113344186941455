import {
	AfterContentChecked,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges
} from "@angular/core";
import { Router } from "@angular/router";
import { UserSettingsService } from "../../../services/users/user-settings.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { Subscription } from "rxjs";
import { subscribeOn } from "rxjs/operators";
import { TemplateServiceService } from "../../../services/template/template-service.service";
import { DataExplorerService } from "../../../services/data-explorer/data-explorer.service";
import swal from "sweetalert2";
import { json } from "@rxweb/reactive-form-validators";
import { Jobs } from "src/app/models/jobs/jobs.model";

@Component({
	selector: "app-navigation",
	templateUrl: "./navigation.component.html",
	styleUrls: ["./navigation.component.scss"]
})
export class NavigationComponent implements OnInit, AfterContentChecked {
	@Input() data: number;
	userData;
	imgURL: any;
	isActive: boolean = false;
	subscription: Subscription;
	qubzLicenseExpire;
	expiry;
	@Input() notificationCount: number;
	catalogRequest;
	newcatalogRequest;
	pendingCount;
	qubzLicenseExpireMsg: any;
	constructor(
		private router: Router,
		private userSettingService: UserSettingsService,
		private authService: AuthService,
		private templateServiceService: TemplateServiceService,
		private dataExplorerService: DataExplorerService
	) {
		let user = sessionStorage.getItem("loggedUser");
	}
	//   ngOnChanges(inputValueChanges: SimpleChanges){
	//     if(inputValueChanges.hasOwnProperty('data') && inputValueChanges['data'].hasOwnProperty('currentValue') )
	// {
	//   this.notifyCount = inputValueChanges['data'].currentValue;
	// }
	//   }
	onEdit() {
		this.router.navigate(["/profile"]);
	}

	logout() {
		const credentials = {
			username: "",
			password: ""
		};
		sessionStorage.setItem("credentials", JSON.stringify(credentials));

		localStorage.clear();
		sessionStorage.clear();
		this.authService.logout();
	}
	ngOnInit() {
		this.qubzLicenseExpire = JSON.parse(
			sessionStorage.getItem("qubzLicenseExpire")
		);
		this.getExpiryStatus();
		this.subscription = this.userSettingService.pictureUpdated$.subscribe(
			pictureUpdated => this.profiledata()
		);
		this.getcatalogRequestList();
	}
	profiledata() {
		this.userSettingService.getProfile().subscribe(data => {
			this.userData = data;
			this.userData = this.userData.userProfileDetails;
			this.imgURL = "../../../../assets/images/";
			if (this.userData.avatarFileName == "default")
				this.userData.avatarFileName = "usericon_other.png";
			this.imgURL = this.imgURL + this.userData.avatarFileName;
		});
	}
	getExpiryStatus() {
		this.authService.ping().subscribe(data => {
			let res = <any>{};
			res = data;
			this.expiry = res.userDetails;
			this.qubzLicenseExpire = res.userDetails.qubzLicenseExpire;
		});
	}
	setColorTheme(color) {
		this.templateServiceService.saveColorTemplate(color).subscribe(data => {});
	}

	getcatalogRequestList() {
		let size;
		this.dataExplorerService.getCatalogRequest(0, 10, "").subscribe(
			data => {
				this.catalogRequest = data;
				if (this.catalogRequest.page.totalElements !== 0) {
					size = this.catalogRequest.page.totalElements;
				} else {
					size = 10;
				}
				this.dataExplorerService.getCatalogRequest(0, size, "").subscribe(
					data => {
						this.newcatalogRequest = data;

						this.pendingCount = this.newcatalogRequest.page.content.filter(
							n => n.status === "PENDING"
						).length;
					},
					error => {
						swal("Failed to load Data!", error.error.msg, "error");
					}
				);
			},
			error => {
				swal("Failed to load Data!", error.error.msg, "error");
			}
		);
	}

	hasRole(roles) {
		let user = JSON.parse(sessionStorage.getItem("loggedUser"));
		for (let role of user.authorities) {
			if (role.authority == roles) return "admin";
		}
		return "user";
	}

	ngAfterContentChecked(): void {
		if (localStorage.getItem("newObj") !== "") {
			let newcatalogReq = JSON.parse(localStorage.getItem("newObj"));
			if (newcatalogReq !== null) {
				this.pendingCount = newcatalogReq.page.content.filter(
					n => n.status === "PENDING"
				).length;
			}
		}
	}
}
