import { operators, Operation } from "./models/operator";
import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	AfterViewInit
} from "@angular/core";
import { FormBuilder, FormGroup, FormControl, FormArray } from "@angular/forms";
import { MetaColumn } from "./models/meta-columns";
import { VisualQueryBuilderService } from "../visual-query-builder.service";

import * as $ from "jquery";
import { ThrowStmt } from "@angular/compiler";

@Component({
	selector: "app-query-filter",
	templateUrl: "./query-filter.component.html",
	styleUrls: ["./query-filter.component.scss"]
})
export class QueryFilterComponent implements OnInit, AfterViewInit {
	@Input() builderForm: FormGroup;
	@Input() filterForm: FormGroup;
	@Input() index: number;
	@Input() columns;
	@Output() deleteFilter: EventEmitter<number> = new EventEmitter();
	title: string;
	table: string;
	conditionalValue;
	titleList;
	newTitle: string;
	andorCondition;
	notConditionList = [
		{ name: "", value: "" },
		{
			name: "NOT",
			value: "not"
		}
	];

	OperatorList = [
		{ name: "AND", value: "and" },
		{
			name: "OR",
			value: "or"
		}
	];
	showCondValBtn: boolean = true;
	isTitleShown: boolean = true;
	showMe: boolean = true;
	notValue;
	singleString;
	@Output() toggleFilter: EventEmitter<any> = new EventEmitter();

	constructor(
		private builderService: VisualQueryBuilderService,
		private formBuilder: FormBuilder
	) {}

	ngAfterViewInit() {
		$(document).ready(function () {
			var contenth = $(window).height() - 110;
			var sidebarh = $(window).height() - 111;
			$(".pagec").css("height", contenth);
			$(".sidebar-wrapper").css("height", sidebarh);
		});
	}

	ngOnInit(): void {
		this.updateTitle();

		if (this.columns !== undefined) {
			for (let item in this.columns) {
				this.columns[item].bindName =
					this.columns[item].table + " " + this.columns[item].label;
				this.columns.push(this.columns[item]);
			}
		}
	}

	delete() {
		if (this.index == 0) {
			this.toggleFilter.emit();
		} else {
			this.deleteFilter.emit(this.index);
		}
	}

	blurEvent(event: any) {
		this.updateTitle();
	}

	updateTitle() {
		this.isTitleShown = false;
		if (this.filterForm.valid && this.filterForm.get("column").value) {
			if (this.builderService.filters !== undefined) {
				for (let [key, value] of Object.entries(this.builderService.filters)) {
					let res1 = <any>{};
					res1 = value;
					this.filterForm.value.notCondition = res1.notCondition;
					if (this.filterForm.value.notCondition == true) {
						this.notValue = "NOT";
					} else {
						this.notValue = "";
					}
					if (
						res1.notCondition == this.filterForm.controls["notCondition"].value
					) {
						this.title = `${this.notValue} ${
							this.filterForm.get("column").value.table
						}.${this.filterForm.get("column").value.label} ${
							this.filterForm.get("operation").value.label
						} ${
							this.filterForm.get("value").value
								? this.filterForm.get("value").value
								: ""
						}`;
					}
				}
			} else {
				this.getValue();
			}

			if (this.titleList == undefined) {
				this.builderService.titleList.push(this.title);
				this.titleList = this.remove_duplicates(this.builderService.titleList);
			} else {
				if (this.titleList[0]) {
					this.singleString = this.titleList[0];
					this.showCondValBtn = true;
				}
			}

			if (this.titleList !== undefined) {
				if (this.titleList.length > 1) {
					this.getAndOR();
					this.isTitleShown = true;
					this.showCondValBtn = false;

					if (this.builderService.filters !== undefined) {
						for (let [key, value] of Object.entries(
							this.builderService.filters
						)) {
							let res1 = <any>{};
							res1 = value;

							if (this.builderService.filters.length > 1) {
								if (this.filterForm.value.andorCondition !== undefined) {
									this.conditionalValue = `${
										this.filterForm.get("andorCondition").value.name
									}`;
								} else {
									this.filterForm.value.andorCondition = res1.andorCondition;
								}
							}
						}
					} else {
						if (this.filterForm.value.andorCondition !== undefined) {
							this.conditionalValue = `${
								this.filterForm.get("andorCondition").value.name
							}`;
						} else {
							this.filterForm.value.andorCondition = this.OperatorList[0];
							this.conditionalValue = this.OperatorList[0].name;
							this.filterForm.patchValue({
								andorCondition: this.OperatorList[0]
							});
						}
					}
				}
			}
		}
	}

	isValueVisible() {
		return !(
			this.filterForm.get("operation").value == undefined ||
			this.filterForm.get("operation").value == null ||
			this.filterForm.get("operation").value.value == "IS NULL" ||
			this.filterForm.get("operation").value.value == "IS NOT NULL"
		);
	}

	get operators() {
		if (this.filterForm.get("column").value)
			return operators.filter(operator =>
				operator.acceptedTypes.includes(
					this.filterForm.get("column").value.column.type
				)
			);
	}

	compareColumn(column1: MetaColumn, column2: MetaColumn): boolean {
		if (column1 && column2)
			return column1.label == column2.label && column1.type == column2.type;
		else return column1 === undefined && column2 === undefined;
	}

	compareOperation(operation1: Operation, operation2: Operation): boolean {
		if (operation1 && operation2)
			return (
				operation1.label == operation2.label &&
				operation1.value == operation2.value
			);
		else return operation1 === undefined && operation2 === undefined;
	}

	getAndOR() {
		if (this.titleList !== undefined && this.titleList.length > 1) {
			if (this.builderService.filters) {
				for (let [key, value] of Object.entries(this.builderService.filters)) {
					let res1 = <any>{};
					res1 = value;

					if (
						res1.andorCondition ==
						this.filterForm.controls["andorCondition"].value
					) {
						if (res1.andorCondition !== undefined) {
							this.showMe = false;
						} else {
							this.showMe = true;
						}
					}
				}
			} else {
				this.showMe = false;
			}
		}
	}

	remove_duplicates(arr) {
		let obj = {};
		for (let i = 0; i < arr.length; i++) {
			obj[arr[i]] = true;
		}
		arr = [];
		for (let key in obj) {
			arr.push(key);
		}
		return arr;
	}

	getValue() {
		if (this.filterForm.value.notCondition !== undefined) {
			if (this.filterForm.get("notCondition").value == true) {
				this.notValue = "NOT";
			} else {
				this.notValue = "";
			}
		} else {
			this.filterForm.value.notCondition = false;
			this.notValue = "";
		}

		this.title = `${this.notValue} ${
			this.filterForm.get("column").value.table
		}.${this.filterForm.get("column").value.label} ${
			this.filterForm.get("operation").value.label
		} ${
			this.filterForm.get("value").value
				? this.filterForm.get("value").value
				: ""
		}`;
	}

	clearVal() {
		if (
			this.filterForm.controls["operation"].value !== "Is Empty" ||
			"Not Empty"
		) {
			this.filterForm.patchValue({
				value: ""
			});
			if (this.filterForm.value.value !== undefined) {
				this.filterForm.value.value = "";
				this.filterForm.controls["value"].reset();
				this.title = `${this.notValue} ${
					this.filterForm.get("column").value.table
				}.${this.filterForm.get("column").value.label} ${
					this.filterForm.get("operation").value.label
				} ${
					this.filterForm.get("value").value
						? this.filterForm.get("value").value
						: ""
				}`;
			}
		}
	}
}
