import {
	Component,
	Input,
	OnInit,
	AfterViewInit,
	Output,
	EventEmitter,
	OnDestroy,
	AfterContentChecked
} from "@angular/core";
import { FormBuilder, FormGroup, FormArray } from "@angular/forms";
import { DataExplorerService } from "../../../../services/data-explorer/data-explorer.service";
import * as $ from "jquery";

@Component({
	selector: "app-step-two",
	templateUrl: "./step-two.component.html",
	styleUrls: ["./step-two.component.scss"]
})
export class StepTwoComponent
	implements OnInit, AfterViewInit, OnDestroy, AfterContentChecked
{
	load;
	@Input() projectId: string;
	@Output() jsonFormData = new EventEmitter<FormGroup>();
	@Output() jsonFormDataValid = new EventEmitter<boolean>();
	@Output() jsonParseSnippet = new EventEmitter();

	@Input() jsonCodeSnippet: string;
	jsonCheck: boolean = true;
	jsonInput: boolean = true;
	jsonArray: any[];
	jsonForm: FormGroup;
	tsColumnArr: any[] = [];
	tsParserArr: any[] = [
		"com.ustglobal.qubz.stream.source.kafka.LongTimeParser",
		"com.ustglobal.qubz.stream.source.kafka.DateTimeParser"
	];
	tsPatternArr: any = [];
	pattern = "^[A-Za-z0-9_]{1,100}$";
	@Input() showDiv: any;
	isJSONValid: boolean = true;

	constructor(
		private fb: FormBuilder,
		private DataExplorerService: DataExplorerService
	) {}

	ngAfterViewInit() {
		$(document).ready(function () {
			function adjustheight() {
				//$(".phasebtn").on("click", function(){
				var puconetntheight = $(window).height() - 260;
				$(".config_table tbody").css("max-height", puconetntheight);
				//});

				$(".ace_content").keyup(function () {
					var editorheight = $(window).height() - 240;
					$("#json_editor").css("height", editorheight);

					var ace_content = $(window).height() - 236;
					$(".ace_content").css("height", ace_content);
				});

				var editorheight = $(window).height() - 240;
				$("#json_editor").css("height", editorheight);

				var ace_content = $(window).height() - 236;
				$(".ace_content").css("height", ace_content);
			}

			setTimeout(adjustheight, 2);
		});

		$(window).resize(function () {
			var editorheight = $(window).height() - 240;
			$("#json_editor").css("height", editorheight);

			var ace_content = $(window).height() - 236;
			$(".ace_content").css("height", ace_content);

			var puconetntheight = $(window).height() - 360;
			$(".config_table tbody").css("height", puconetntheight);
		});
	}
	ngOnDestroy() {
		this.DataExplorerService.setFormData({
			json: this.jsonCodeSnippet,
			data: this.jsonForm.value
		});
	}
	ngOnInit() {
		if (this.showDiv.configstreaming == true) {
			this.showDiv.stepone = false;
			this.showDiv.stepwo = false;
		}
		this.createJsonForm();
		if (this.DataExplorerService.getFormData() !== null) {
			if (
				this.DataExplorerService.getFormData() &&
				this.DataExplorerService.getFormData().json !== undefined
			) {
				this.jsonCodeSnippet = this.DataExplorerService.getFormData().json;
				this.jsonParseSnippet.emit(this.jsonCodeSnippet);
				if (this.jsonCodeSnippet !== "") {
					if (this.testJSON(this.jsonCodeSnippet)) {
						this.isJSONValid = false;
						this.jsonInput = true;
					} else {
						this.isJSONValid = true;
						this.jsonInput = false;
					}
				} else {
					this.isJSONValid = true;
					this.jsonInput = true;
				}
			}
			this.DataExplorerService.getFormData().data.tableColumns.forEach(
				element => {
					this.addColumn();
				}
			);
			this.jsonForm.patchValue(this.DataExplorerService.getFormData().data);
		}
	}
	createJsonForm() {
		this.jsonForm = this.fb.group({
			tableColumns: this.fb.array([])
		});
		this.jsonForm.valueChanges.subscribe(val => {
			this.jsonFormData.emit(this.jsonForm);
		});
	}
	checkValid() {
		if (
			this.jsonForm.controls.tableName.valid &&
			this.jsonForm.controls.catalogName.valid &&
			this.jsonForm.controls.schema.valid
		) {
			return false;
		} else {
			return true;
		}
	}

	get tableNameForm() {
		return this.jsonForm.controls;
	}
	tableColumns(): FormArray {
		return this.jsonForm.get("tableColumns") as FormArray;
	}
	newColumn(): FormGroup {
		return this.fb.group({
			columnName: "",
			columnType: "",
			mapping: "",
			comment: "",
			id: ""
		});
	}
	addColumn() {
		this.tableColumns().push(this.newColumn());
	}
	columnData(name, type, mapping, comment, id): FormGroup {
		return this.fb.group({
			columnName: name,
			columnType: type,
			mapping: mapping,
			comment: comment,
			id: id
		});
	}
	removeColumn(i: number) {
		this.tableColumns().removeAt(i);
	}

	parseJSON() {
		this.tsColumnArr = [];
		if (this.jsonArray !== []) {
			this.jsonArray = [];
		}
		if (this.jsonCodeSnippet != undefined) {
			this.jsonCheck = true;
		} else {
			this.jsonCheck = false;
		}
		let table;
		this.jsonInput = false;
		this.isJSONValid = true;
		try {
			table = JSON.parse(this.jsonCodeSnippet);
			this.jsonParseSnippet.emit(table);
			this.jsonInput = true;
			this.isJSONValid = false;
			this.jsonParseSnippet.emit(this.jsonCodeSnippet);
		} catch (e) {
			this.jsonInput = false;
			this.isJSONValid = true;
		}

		if (this.jsonInput) {
			this.flattenTables(table, "", false);
		}
	}
	flattenTables(table, sourceCol, recursiveCall) {
		let index = 0;
		for (let colName in table) {
			let dType: string = "";
			let comment = "";
			if (typeof table[colName] === "object") {
				this.flattenTables(table[colName], colName, true);
			}
			if (typeof table[colName] === "number") {
				if (
					table[colName] % 1 === 0 &&
					table[colName].toString().length <= 12
				) {
					dType = "int";
				} else if (
					table[colName] % 1 !== 0 &&
					table[colName].toString().length <= 12
				) {
					dType = "decimal";
				} else if (table[colName].toString().length > 12) {
					dType = "timestamp";
					this.tsColumnArr.push(colName);
				}
			} else if (
				typeof table[colName] === "string" &&
				table[colName].length <= 256
			) {
				//this is where we will check for date string
				let isDate = new Date(table[colName]);
				var regexp = new RegExp(
					"^(012[1-9]|3[0-1]){1}[/-]?(0[1-9]|1[0-2]){1}[/-]?((19|20)?[0-9][0-9])"
				);
				var regexp2 = new RegExp(
					"^(0[1-9]|1[0-2]){1}[/-]?(012[1-9]|3[0-1]){1}[/-]?((19|20)?[0-9][0-9])"
				);
				if (
					isDate.toString() != "Invalid Date" ||
					regexp.test(table[colName]) ||
					regexp2.test(table[colName])
				) {
					dType = "date";
				} else {
					dType = "varchar(256)";
				}
			} else if (
				typeof table[colName] === "string" &&
				table[colName].length > 256
			) {
				dType = "string";
			}
			if (dType !== "") {
				if (recursiveCall) {
					this.jsonArray.push({
						id: index,
						name: sourceCol + "_" + colName,
						comments: sourceCol + "|" + colName + " " + comment,
						datatype: dType,
						isChecked: "true"
					});
					index++;
				} else {
					this.jsonArray.push({
						id: index,
						name: colName,
						comments: comment,
						datatype: dType,
						isChecked: "true"
					});
					index++;
				}
			}
		}
		if (!recursiveCall) {
			this.jsonArray.push({
				id: ++index,
				name: "year_start",
				comments: "derived time dimension",
				datatype: "date",
				isChecked: "true"
			});
			this.jsonArray.push({
				id: ++index,
				name: "quarter_start",
				comments: "derived time dimension",
				datatype: "date",
				isChecked: "true"
			});
			this.jsonArray.push({
				id: ++index,
				name: "month_start",
				comments: "derived time dimension",
				datatype: "date",
				isChecked: "true"
			});
			this.jsonArray.push({
				id: ++index,
				name: "week_start",
				comments: "derived time dimension",
				datatype: "date",
				isChecked: "true"
			});
			this.jsonArray.push({
				id: ++index,
				name: "day_start",
				comments: "derived time dimension",
				datatype: "date",
				isChecked: "true"
			});
			this.jsonArray.push({
				id: ++index,
				name: "hour_start",
				comments: "derived time dimension",
				datatype: "timestamp",
				isChecked: "true"
			});
			this.jsonArray.push({
				id: ++index,
				name: "minute_start",
				comments: "derived time dimension",
				datatype: "timestamp",
				isChecked: "true"
			});
		}
		this.mapTableColumn();
	}
	mapTableColumn() {
		while (this.tableColumns().length !== 0) this.tableColumns().removeAt(0);
		this.jsonArray.forEach(item => {
			if (item.comments === "") {
				item.comments = item.name;
			}
			this.tableColumns().push(
				this.columnData(item.name, item.datatype, "", item.comments, item.id)
			);
		});
	}

	snippetCheck() {
		if (this.jsonCodeSnippet == "") {
			this.createJsonForm();
			this.isJSONValid = true;
			this.jsonInput = true;
			this.jsonParseSnippet.emit(this.jsonCodeSnippet);
		}
	}

	resetJsonForm() {
		this.jsonCodeSnippet = "";
		this.createJsonForm();
		this.isJSONValid = true;
		this.jsonParseSnippet.emit(this.jsonCodeSnippet);
		this.jsonForm.reset();
		localStorage.setItem("resetJsonForm", "");
	}

	ngAfterContentChecked(): void {
		if (localStorage.getItem("resetJsonForm") == "resetJson") {
			this.resetJsonForm();
		}
	}

	testJSON(text) {
		if (typeof text !== "string") {
			return false;
		}
		try {
			let json = JSON.parse(text);
			return typeof json === "object";
		} catch (error) {
			return false;
		}
	}
}
