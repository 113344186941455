import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { UserSettingsService } from '../../../services/users/user-settings.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Config } from '../../../config';
import swal from 'sweetalert2';
@Component({
  selector: 'app-profile-image',
  templateUrl: './profile-image.component.html',
  styleUrls: ['./profile-image.component.scss']
})
export class ProfileImageComponent implements OnInit {
  form: FormGroup;
  imagePreview: string | ArrayBuffer;
  public imagePath;
  imgURL: any;
  icon: any;
  public message: string;
  userData;
  HttpHeaders: Headers;
  imageData;
  image:any;
  default_image_source:any;
  constructor(private userSettingService: UserSettingsService, private http: HttpClient) {

  }

  preview(files) {
    if (files.length === 0) return;

    var mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Only images are supported.';
      return;
    }

    var reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
    this.icon = files[0];
  
  }
  imageUpload() {
    const formData = new FormData();
    formData.append("file", this.icon);
     
    this.userSettingService.updateProfileImage(formData).subscribe(
      data => {
        this.imageData=data;
        swal("Success!", "Profile image hasbeen updated successfully", "success");
        this.userSettingService.getProfile().subscribe(
          data => {
            this.userData = data;
            // this.userSettingService.getProfileImage(this.userData.username, this.imageData.imageDetails.lastModified).subscribe(
            //   data => {
          
            //     this.icon = data;
            //     this.default_image_source = "image/default_avatar.png";
            //     this.icon = UserSettingsService.url+this.userData.userProfileDetails.imageUrl;
            
            //   })

          })
        
      },
      error => {
        
      });
  }
  ngOnInit() {
    this.form = new FormGroup({
      icon: new FormControl(null, {
        validators: [Validators.required],
        //  asyncValidators: [mimeType]
      })

    });
    this.userSettingService.getProfile().subscribe(
      data => {
        this.userData = data;
        this.imgURL = UserSettingsService.url+this.userData.userProfileDetails.username+'/profile/'+this.userData.userProfileDetails.lastModified+'/image.jpg';
       
      })

  }
}
