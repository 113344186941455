import { AggregateEl, ColumnEl, JoinEl } from './data';
import { QueryFilter } from './query-filter/sql-query-filter';
import { MetaColumn } from './query-filter/models/meta-columns';

export class SqlQuery {
  catalog:string;
  schema:string;
  table: string;
  columns: MetaColumn[];
  filters: QueryFilter[];
  join: JoinEl[];
  sortBy: ColumnEl[];
  groupBy: AggregateEl;
  limits: number;
  query: string;
/*
  constructor(table?: string, columns?: MetaColumn[], filters?: QueryFilter[], join?: JoinEl[], limits?: number, query?: string) {
    this.table = table;
    this.columns = columns;
    this.filters = filters;
    this.join = join;
    this.query = query;
    this.limits = limits;
  }
*/
}
