import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
	name: "columnFilter"
})
export class ColumnFilterPipe implements PipeTransform {
	transform(items: any[], searchText: string): any[] {
		if (!items) return [];
		if (!searchText) return items;
		searchText = searchText.toLowerCase();
		return items.filter(it => {
			let searchItems;
			if (it.column) {
				searchItems = it.column.toLowerCase().includes(searchText);
			}
			return searchItems;
		});
	}
}
