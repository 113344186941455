import { Injectable } from "@angular/core";
@Injectable({
	providedIn: "root"
})
export class SidebarService {
	menuType = "home";
	constructor() {}
	adminItems = [
		{ path: "/dashboard", title: "Home" },
		{ path: "/catalog", title: "Catalog" },
		{ path: "/requests", title: "Requests" },
		{ path: "/data-source", title: "Data Sources" },
		{ path: "/data-explorer", title: "Data Explorer" },
		{ path: "/jobs", title: "Jobs" },
		{ path: "/scheduler", title: "Scheduler" },
		{ path: "/administration", title: "Administration" }
	];
	items = [
		{ path: "/dashboard", title: "Home" },
		{ path: "/catalog", title: "Catalog" },
		{ path: "/user-request", title: "Requests" },
		{ path: "/data-explorer", title: "Data Explorer" },
		{ path: "/jobs", title: "Jobs" },
		{ path: "/scheduler", title: "Scheduler" }
	];
	SetMenuType(type) {
		if (type == "admin" || type == "user") this.menuType = "home";
	}
}
