import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
	name: "catalogFilter"
})
export class DataCatalogFilterPipe implements PipeTransform {
	transform(items: any[], searchText: string): any[] {
		if (!items) return [];
		if (!searchText) return items;
		searchText = searchText.toLowerCase();
		return items.filter(it => {
			let searchItems;
			if (it.catalogPublicName) {
				searchItems = it.catalogPublicName.toLowerCase().includes(searchText);
			}
			return searchItems;
		});
	}
}
