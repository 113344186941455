import { Component, OnInit } from "@angular/core";
import * as moment from "moment";

@Component({
	selector: "app-user-data-request-details",
	templateUrl: "./user-data-request-details.component.html",
	styleUrls: ["./user-data-request-details.component.scss"]
})
export class UserDataRequestDetailsComponent implements OnInit {
	contentDetails;
	selectedCatalogObj = {
		catalogDataSourceType: "",
		catalogDescription: "",
		catalogId: "",
		catalogName: "",
		catalogPublicName: "",
		isCompleted: false,
		processedBy: "",
		processedDate: "",
		processorId: "",
		requestId: "",
		reason: "",
		resolutionReason: "",
		requestedDate: "",
		requesterId: "",
		requesterName: "",
		status: "",
		requestTracker: []
	};
	isPendingScreen: boolean;
	isApprovedorRejectedScreen: boolean;
	trackedRequestedDate;
	trackedRequestedName;
	trackedRequestedReason;
	trackedProcessDate;
	trackedProcessName;
	trackedProcessedReason;
	trackedRevokedDate;
	trackedRevokedName;
	trackedRevokedReason;

	constructor() {}

	ngOnInit() {
		if (localStorage.getItem("contentDetails") !== "") {
			this.contentDetails = JSON.parse(localStorage.getItem("contentDetails"));
			this.getRequestAccessDetails(this.contentDetails);
		}
	}

	getRequestAccessDetails(content) {
		let trackerSplit;
		let approveTrackerSplit;
		let revokedSplit;
		let trackerTimeSplit;
		let trackerReasonSPlit;
		let approvedtrackertimeSplit;
		let approvedtrackerreasonSplit;
		let revokedtrackertimesSplit;
		let revokedreasontrackerSplit;

		if (content.status !== "PENDING") {
			this.isApprovedorRejectedScreen = false;
			this.isPendingScreen = true;
		} else {
			this.isApprovedorRejectedScreen = true;
			this.isPendingScreen = false;
		}
		this.selectedCatalogObj = content;
		this.selectedCatalogObj.requestedDate = moment(
			content.requestedDate
		).format("YYYY-MM-DD: hh:mm:ssZ");

		this.selectedCatalogObj.processedDate = moment(
			content.processedDate
		).format("YYYY-MM-DD: hh:mm:ssZ");
		if (this.selectedCatalogObj.catalogDataSourceType == undefined) {
			this.selectedCatalogObj.catalogDataSourceType = "";
		}

		if (this.selectedCatalogObj.catalogDescription == undefined) {
			this.selectedCatalogObj.catalogDescription = "";
		}

		if (this.selectedCatalogObj.requestId == undefined) {
			this.selectedCatalogObj.requestId = "";
		}

		if (this.selectedCatalogObj.catalogName == undefined) {
			this.selectedCatalogObj.catalogName = "";
		}

		if (this.selectedCatalogObj.catalogPublicName == undefined) {
			this.selectedCatalogObj.catalogPublicName = "";
		}

		if (this.selectedCatalogObj.requestedDate == undefined) {
			this.selectedCatalogObj.requestedDate = "";
		}

		if (this.selectedCatalogObj.status == undefined) {
			this.selectedCatalogObj.status = "";
		}

		if (this.selectedCatalogObj.reason == undefined) {
			this.selectedCatalogObj.reason = "";
		}

		if (this.selectedCatalogObj.requesterId == undefined) {
			this.selectedCatalogObj.requesterId = "";
		}

		if (this.selectedCatalogObj.requesterName == undefined) {
			this.selectedCatalogObj.requesterName = "";
		}

		if (this.selectedCatalogObj.requestTracker.length > 1) {
			if (
				this.selectedCatalogObj.status == "APPROVED" ||
				this.selectedCatalogObj.status == "REJECTED"
			) {
				trackerSplit = this.selectedCatalogObj.requestTracker[0].split("T");
				trackerTimeSplit = trackerSplit[1].split(".");
				trackerReasonSPlit = trackerTimeSplit[1].split("'-'");

				this.trackedRequestedDate = trackerSplit[0] + " " + trackerTimeSplit[0];
				this.trackedRequestedName = trackerReasonSPlit[1];
				this.trackedRequestedReason = trackerReasonSPlit[2];

				approveTrackerSplit =
					this.selectedCatalogObj.requestTracker[1].split("T");
				approvedtrackertimeSplit = approveTrackerSplit[1].split("'-'");
				approvedtrackerreasonSplit = approvedtrackertimeSplit[0].split("Z");
				this.trackedProcessDate =
					approveTrackerSplit[0] + " " + approvedtrackerreasonSplit[0];

				this.trackedProcessName = approvedtrackertimeSplit[1];
				this.trackedProcessedReason = approvedtrackertimeSplit[2];
			} else {
				trackerSplit = this.selectedCatalogObj.requestTracker[0].split("T");
				trackerTimeSplit = trackerSplit[1].split(".");
				trackerReasonSPlit = trackerTimeSplit[1].split("'-'");

				this.trackedRequestedDate = trackerSplit[0] + " " + trackerTimeSplit[0];
				this.trackedRequestedName = trackerReasonSPlit[1];
				this.trackedRequestedReason = trackerReasonSPlit[2];

				approveTrackerSplit =
					this.selectedCatalogObj.requestTracker[1].split("T");
				approvedtrackertimeSplit = approveTrackerSplit[1].split("'-'");
				approvedtrackerreasonSplit = approvedtrackertimeSplit[0].split("Z");
				this.trackedProcessDate =
					approveTrackerSplit[0] + " " + approvedtrackerreasonSplit[0];

				this.trackedProcessName = approvedtrackertimeSplit[1];
				this.trackedProcessedReason = approvedtrackertimeSplit[2];
				revokedSplit = this.selectedCatalogObj.requestTracker[2].split("T");
				revokedtrackertimesSplit = revokedSplit[1].split("'-'");
				revokedreasontrackerSplit = revokedtrackertimesSplit[0].split("Z");
				this.trackedRevokedDate =
					revokedSplit[0] + " " + revokedreasontrackerSplit[0];
				this.trackedRevokedName = revokedtrackertimesSplit[1];
				this.trackedRevokedReason = revokedtrackertimesSplit[2];
			}
		} else {
			for (let param of this.selectedCatalogObj.requestTracker) {
				if (this.selectedCatalogObj.status == "PENDING") {
					trackerSplit = param.split("T");
					trackerTimeSplit = trackerSplit[1].split(".");
					trackerReasonSPlit = trackerTimeSplit[1].split("'-'");
					this.trackedRequestedDate =
						trackerSplit[0] + " " + trackerTimeSplit[0];
					this.trackedRequestedName = trackerReasonSPlit[1];
					this.trackedRequestedReason = trackerReasonSPlit[2];
				} else {
					this.selectedCatalogObj.requestTracker = [];
				}
			}
		}
	}
}
