import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
  
  projectId: string =this.route.snapshot.paramMap.get("projectId");

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    
  }
}
