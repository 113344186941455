import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";
import { AdminService } from "../services/admin/admin.service";
import { time } from "@rxweb/reactive-form-validators";
import * as moment from "moment";
import "moment-timezone";
@Pipe({ name: "timezone" })
export class TimezoneFilterPipe implements PipeTransform {
	config;
	constructor(private adminService: AdminService) {}

	transform(
		date: Date | string,
		format: string = "yyyy-MM-dd HH:mm:ss"
	): string {
		let timezone = localStorage.getItem("configTimezone");
		//let timezone=this.adminService.getTimeZone();
		// undefined and 0 is not necessary to show
		if (date === undefined || date === "") {
			return "";
		}

		if (timezone === undefined || timezone === "") {
			timezone = timezone == "" ? "UTC" : timezone;
		}

		if (format === undefined || format === "") {
			format = "YYYY-MM-DD HH:mm:ss z";
		}

		var time = "";
		var zone = timezone;
		if (timezone !== null && timezone.indexOf("GMT") != -1) {
			// var offset = parseInt(timezone.substr(3, 2));
			// time = moment(date).utc().zone(-offset).format("YYYY-MM-DD HH:mm:ss ") + zone;
			time = moment(date).format("YYYY-MM-DD HH:mm:ss ") + zone;
		} else if (moment.tz.zone(timezone) != null) {
			time = moment(date).tz(timezone).format("YYYY-MM-DD HH:mm:ss ") + zone;
		} else {
			time = moment(date).utc().format("YYYY-MM-DD HH:mm:ss ") + zone;
		}

		return time;
	}
}
