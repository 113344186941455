import { Injectable } from "@angular/core";
import {
	CanActivate,
	Router,
	ActivatedRouteSnapshot,
	RouterStateSnapshot
} from "@angular/router";
import { AuthService } from "../../services/auth/auth.service";
@Injectable()
export class AuthGuard implements CanActivate {
	inputDetails: any;
	constructor(private auth_service: AuthService, private router: Router) {}
	userNameDetails;

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean {
		let res = <any>{};
		this.auth_service.ping().subscribe(
			data => {
				if (data.hasOwnProperty("userDetails")) {
					res = data;

					this.inputDetails = data;
					this.auth_service
						.getDetailsbyUserName(this.inputDetails.userDetails.username)
						.subscribe(data => {
							if (data.hasOwnProperty("data")) {
								this.userNameDetails = data;
								let userDetails = this.userNameDetails.data;
								sessionStorage.setItem(
									"userNameDetails",
									JSON.stringify(userDetails)
								);
								if (!this.userNameDetails.data.eula) {
									this.router.navigate(["eula"]);
								}
							}

							sessionStorage.setItem(
								"qubzLicenseExpire",
								JSON.stringify(this.inputDetails.userDetails.qubzLicenseExpire)
							);
							sessionStorage.setItem(
								"qubzLicenseExpireMsg",
								JSON.stringify(
									this.inputDetails.userDetails.qubzLicenseExpireMsg
								)
							);
						});
				}
			},
			error => {
				this.router.navigate(["auth"]);
			}
		);
		if (this.auth_service.logged_in()) {
			const user = JSON.parse(sessionStorage.getItem("loggedUser"));

			for (
				var user_count = 0;
				user_count < user.authorities.length;
				user_count++
			) {
				for (
					var data_count = 0;
					data_count < route.data.role.length;
					data_count++
				) {
					if (
						user.authorities[user_count].authority ===
						route.data.role[data_count]
					) {
						return true;
					} else {
					}
				}
			}

			this.router.navigate(["/dashboard"]);
		} else {
			this.router.navigate([""]);
		}
	}

	canActivateChild(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean {
		this.auth_service.ping().subscribe(
			data => {},
			error => {
				this.router.navigate(["auth"]);
			}
		);
		return true;
	}
}
