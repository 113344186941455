import {
	AfterContentChecked,
	Component,
	OnInit,
	ViewChild
} from "@angular/core";
import { Router } from "@angular/router";
import { DataExplorerService } from "../../../services/data-explorer/data-explorer.service";
import swal from "sweetalert2";
import { CronOptions } from "../../common/cron-editor/CronOptions";
import { CronGenComponent } from "../../common/cron-editor/cron-editor.component";

@Component({
	selector: "app-scheduler-update",
	templateUrl: "./scheduler-update.component.html",
	styleUrls: ["./scheduler-update.component.scss"]
})
export class SchedulerUpdateComponent implements OnInit, AfterContentChecked {
	load;
	scheduleListing;
	schName;
	schCronString;
	errorCron;
	cronlistData;
	isDisabled: boolean = true;
	generateScheduledTimes;
	todayDate;
	schDbName;
	schTblName;
	public isCronDisabled = false;

	public cronOptions: CronOptions = {
		formInputClass: "form-control cron-editor-input",
		formSelectClass: "form-control cron-editor-select",
		formRadioClass: "cron-editor-radio",
		formCheckboxClass: "cron-editor-checkbox",

		defaultTime: "00:00:00",

		hideMinutesTab: false,
		hideHourlyTab: false,
		hideDailyTab: false,
		hideWeeklyTab: false,
		hideMonthlyTab: false,
		hideYearlyTab: false,
		hideAdvancedTab: false,
		hideSpecificWeekDayTab: false,
		hideSpecificMonthWeekTab: false,

		use24HourTime: true,
		hideSeconds: false,

		cronFlavor: "quartz"
	};

	@ViewChild("cronEditorDemo")
	cronEditorDemo: CronGenComponent;

	isschCronString: boolean = true;
	rRule;
	interval = 1;
	activeTab;
	state;
	day = 1;
	weekSelectedArr = [];

	constructor(
		private dataExplorerService: DataExplorerService,
		private router: Router
	) {}

	ngOnInit() {
		this.todayDate = setInterval(() => {
			this.todayDate = new Date();
		}, 1000);
		localStorage.setItem("cronValueChanged", "");
		if (JSON.parse(localStorage.getItem("schDetls"))) {
			this.scheduleListing = JSON.parse(localStorage.getItem("schDetls"));
			this.isschCronString = false;
			this.generateScheduledTimes = "";
			this.schName = localStorage.getItem("schName");
			if (localStorage.getItem("schCronString")) {
				this.schCronString = localStorage.getItem("schCronString");
			} else {
				this.schCronString = "0 0/1 * 1/1 * ? *";
			}

			this.schDbName = localStorage.getItem("schDbName");
			this.schTblName = localStorage.getItem("schTblName");
		} else {
			this.router.navigate(["/data-explorer"]);
		}
	}

	checkCronString(cronString) {
		if (cronString !== "") {
			this.isCronStringValid(cronString);
		}
	}

	clearCronMsg() {
		if (
			this.schCronString == "" ||
			this.schCronString == null ||
			this.schCronString == undefined
		) {
			this.errorCron = "";
			this.generateScheduledTimes = "";
		} else {
			this.generateScheduledTimes = "";
			this.isDisabled = true;
		}
	}

	isCronStringValid(cronString) {
		this.generateScheduledTimes = "";
		this.load = true;
		this.dataExplorerService.validateCronString(cronString).subscribe(data => {
			this.load = false;
			this.cronlistData = data;
			this.generateScheduledTimes = this.cronlistData.longList;
			if (this.cronlistData !== undefined && this.cronlistData.errorMessage) {
				this.errorCron = "Cron String is invalid";
			} else {
				this.errorCron = "";
			}

			if (this.errorCron) {
				this.isDisabled = true;
			} else {
				this.isDisabled = false;
			}
		});
	}

	checkExpressionState() {
		if (this.cronEditorDemo.activeTab == "advanced") {
			if (this.cronEditorDemo.state.advanced.expression !== "") {
				this.isschCronString = false;
			} else {
				this.isschCronString = true;
			}
		}

		if (localStorage.getItem("cronValueChanged") !== "") {
			this.isDisabled = true;
			this.generateScheduledTimes = "";
			localStorage.setItem("cronValueChanged", "");
		}
	}

	submit() {
		let mViewSchedulerDetails = {
			cronString: this.schCronString,
			database: this.scheduleListing.database,
			jobCurrentDate: this.scheduleListing.jobCurrentDate,
			name: this.scheduleListing.name,
			query: this.scheduleListing.query,
			schedulerId: this.scheduleListing.schedulerId,
			status: this.scheduleListing.status,
			submitter: this.scheduleListing.submitter,
			table: this.scheduleListing.table,
			triggerKeyGroup: this.scheduleListing.triggerKeyGroup,
			triggerKeyName: this.scheduleListing.triggerKeyName
		};

		this.dataExplorerService
			.updateJobScheduler(mViewSchedulerDetails)
			.subscribe(
				data => {
					swal("Success!", "Scheduler updated successfully", "success");
					this.router.navigate(["/scheduler"]);
				},
				error => {
					let errormsg;
					if (error.error.msg !== null) {
						errormsg = error.error.msg;
						swal("Operation failed!!", "Reason:" + "\n" + errormsg, "error");
					} else {
						errormsg = error.error.stacktrace;
						let stackTraceSliced = error.error.stacktrace.slice(0, 30);
						swal({
							type: "error",
							title: "Error:Please refer to logs ",
							html: "&nbsp;&nbsp;" + stackTraceSliced + " " + "....",
							showCancelButton: false,
							cancelButtonText: "Cancel",
							confirmButtonText: "OK"
						});
					}
				}
			);
	}

	cancel() {
		this.router.navigate(["/scheduler"]);
	}

	cronFlavorChange() {
		this.cronEditorDemo.options = this.cronOptions;
		this.cronEditorDemo.regenerateCron();
	}

	ngAfterContentChecked(): void {
		this.checkExpressionState();
		this.generateRRule();
	}

	weekSelectionChange(hr, min, sec) {
		let BYDAY = "MO";
		let weekSelected;

		if (
			this.state.weekly.MON == false &&
			this.state.weekly.TUE == false &&
			this.state.weekly.WED == false &&
			this.state.weekly.THU == false &&
			this.state.weekly.FRI == false &&
			this.state.weekly.SAT == false &&
			this.state.weekly.SUN == false
		) {
			this.rRule = `RRULE:FREQ=${this.activeTab}`;
		} else {
			if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "MO";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "MO,TU";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "MO,WE";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "MO,TH";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "MO,FR";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "MO,SA";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "MO,SU";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "MO,TU,WE";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "MO,TU,TH";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "MO,TU,FR";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "MO,TU,SA";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "MO,TU,SU";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "MO,WE,TH";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "MO,WE,FR";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "MO,WE,SA";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "MO,WE,SU";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "MO,TH,FR";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "MO,TH,SA";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "MO,TH,SU";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "MO,FR,SA";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "MO,FR,SU";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == false &&
				this.state.weekly.FRI == false &&
				this.state.weekly.THU == false &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "MO,SA,SU";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "MO,TU,WE,TH";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "MO,TU,WE,FR";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "MO,TU,WE,SA";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "MO,TU,WE,SU";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "MO,TU,TH,FR";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "MO,TU,TH,SA";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "MO,TU,TH,SU";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "MO,TU,FR,SA";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "MO,TU,FR,SU";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "MO,TU,SA,SU";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "MO,WE,TH,FR";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "MO,WE,TH,SA";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "MO,WE,TH,SU";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "MO,WE,FR,SA";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "MO,WE,FR,SU";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "MO,TH,FR,SA";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "MO,TH,FR,SU";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "MO,TH,SA,SU";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "MO,FR,SA,SU";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "MO,TU,WE,TH,FR";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "MO,TU,WE,TH,SA";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "MO,TU,WE,TH,SU";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "MO,TU,WE,FR,SA";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "MO,TU,WE,FR,SU";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "MO,TU,TH,FR,SA";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "MO,TU,TH,FR,SU";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "MO,TU,TH,SA,SU";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "MO,TU,FR,SA,SU";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "MO,TU,FR,SA,SU";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "MO,WE,TH,FR,SA";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "MO,WE,TH,FR,SU";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "MO,WE,FR,SA,SU";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "MO,TH,FR,SA,SU";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "MO,TU,WE,TH,FR,SA";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "MO,TU,WE,TH,FR,SU";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "MO,TU,WE,TH,SA,SU";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "MO,TU,WE,FR,SA,SU";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "MO,TU,TH,FR,SA,SU";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "MO,WE,TH,FR,SA,SU";
			} else if (
				this.state.weekly.MON == true &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "MO,TU,WE,TH,FR,SA,SU";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "TU";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "TU,WE";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "TU,TH";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "TU,FR";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "TU,SA";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "TU,SU";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "TU,WE,TH";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "TU,WE,FR";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "TU,WE,SA";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "TU,WE,SU";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "TU,TH,FR";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "TU,TH,SA";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "TU,TH,SU";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "TU,FR,SA";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "TU,FR,SU";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "TU,SA,SU";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "TU,WE,TH,FR";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "TU,WE,TH,SA";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "TU,WE,TH,SU";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "TU,WE,FR,SA";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "TU,WE,FR,SU";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "TU,WE,SA,SU";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "TU,TH,FR,SA";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "TU,TH,FR,SU";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "TU,TH,SA,SU";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "TU,FR,SA,SU";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "TU,WE,TH,FR,SA";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "TU,WE,TH,FR,SU";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "TU,WE,TH,SA,SU";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "TU,WE,FR,SA,SU";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "TU,TH,FR,SA,SU";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == true &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "TU,WE,TH,FR,SA,SU";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "WE";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "WE,TH";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "WE,FR";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "WE,SA";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "WE,SU";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "WE,TH,FR";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "WE,TH,SA";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "WE,TH,SU";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "WE,FR,SA";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "WE,FR,SU";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "WE,SA,SU";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "WE,TH,FR,SA";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "WE,TH,FR,SU";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "WE,TH,SA,SU";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "WE,FR,SA,SU";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == true &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "WE,TH,FR,SA,SU";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "TH";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "TH,FR";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "TH,SA";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "TH,SU";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "TH,FR,SA";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "TH,FR,SU";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "TH,SA,SU";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == true &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "TH,FR,SA,SU";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "FR";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "FR,SA";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "FR,SU";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == true &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "FR,SA,SU";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == false
			) {
				weekSelected = "SA";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == true &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "SA,SU";
			} else if (
				this.state.weekly.MON == false &&
				this.state.weekly.TUE == false &&
				this.state.weekly.WED == false &&
				this.state.weekly.THU == false &&
				this.state.weekly.FRI == false &&
				this.state.weekly.SAT == false &&
				this.state.weekly.SUN == true
			) {
				weekSelected = "SU";
			}

			this.weekSelectedArr.push(weekSelected);
			for (let param of this.weekSelectedArr) {
				BYDAY = param;
				if (hr !== 0 && min !== 0 && sec !== 0) {
					this.rRule = `RRULE:FREQ=${this.activeTab};BYWEEKDAY=${BYDAY};BYHOUR=${hr};BYMINUTE=${min};BYSECOND=${sec}`;
				} else {
					if (hr !== 0 && min !== 0) {
						this.rRule = `RRULE:FREQ=${this.activeTab};BYWEEKDAY=${BYDAY};BYHOUR=${hr};BYMINUTE=${min}`;
					} else if (hr !== 0 && sec !== 0) {
						this.rRule = `RRULE:FREQ=${this.activeTab};BYWEEKDAY=${BYDAY};BYHOUR=${hr};BYSECOND=${sec}`;
					} else if (min !== 0 && sec !== 0) {
						this.rRule = `RRULE:FREQ=${this.activeTab};BYWEEKDAY=${BYDAY};BYMINUTE=${min};BYSECOND=${sec}`;
					} else if (hr !== 0) {
						this.rRule = `RRULE:FREQ=${this.activeTab};BYWEEKDAY=${BYDAY};BYHOUR=${hr}`;
					} else if (min !== 0) {
						this.rRule = `RRULE:FREQ=${this.activeTab};BYWEEKDAY=${BYDAY};BYMINUTE=${min}`;
					} else if (sec !== 0) {
						this.rRule = `RRULE:FREQ=${this.activeTab};BYWEEKDAY=${BYDAY};BYSECOND=${sec}`;
					} else {
						this.rRule = `RRULE:FREQ=${this.activeTab};BYWEEKDAY=${BYDAY}`;
					}
				}
			}
		}
	}

	generateRRule() {
		let bysetpos = "1";
		let BYDAY = "+1MO";
		let day;
		let minute;
		let hour;
		let seconds;
		if (
			localStorage.getItem("state") !== null &&
			localStorage.getItem("state") !== ""
		) {
			this.state = JSON.parse(localStorage.getItem("state"));

			if (this.activeTab == "yearly") {
				this.activeTab = "YEARLY";
				if (this.state.yearly.subTab == "specificMonthDay") {
					this.interval = this.state.yearly.specificMonthDay.month;
					this.day = this.state.yearly.specificMonthDay.day;
					hour = this.state.yearly.specificMonthDay.hours;
					minute = this.state.yearly.specificMonthDay.minutes;
					seconds = this.state.yearly.specificMonthDay.seconds;

					if (hour !== 0 && minute !== 0 && seconds !== 0) {
						this.rRule = `RRULE:FREQ=${this.activeTab};BYMONTH=${this.interval};BYMONTHDAY=${this.day};BYHOUR=${hour};BYMINUTE=${minute};BYSECOND=${seconds}`;
					} else {
						if (hour !== 0 && minute !== 0) {
							this.rRule = `RRULE:FREQ=${this.activeTab};BYMONTH=${this.interval};BYMONTHDAY=${this.day};BYHOUR=${hour};BYMINUTE=${minute}`;
						} else if (hour !== 0 && seconds !== 0) {
							this.rRule = `RRULE:FREQ=${this.activeTab};BYMONTH=${this.interval};BYMONTHDAY=${this.day};BYHOUR=${hour};BYSECOND=${seconds}`;
						} else if (minute !== 0 && seconds !== 0) {
							this.rRule = `RRULE:FREQ=${this.activeTab};BYMONTH=${this.interval};BYMONTHDAY=${this.day};BYMINUTE=${minute};BYSECOND=${seconds}`;
						} else if (hour !== 0) {
							this.rRule = `RRULE:FREQ=${this.activeTab};BYMONTH=${this.interval};BYMONTHDAY=${this.day};BYHOUR=${hour}`;
						} else if (minute !== 0) {
							this.rRule = `RRULE:FREQ=${this.activeTab};BYMONTH=${this.interval};BYMONTHDAY=${this.day};BYMINUTE=${minute}`;
						} else if (seconds !== 0) {
							this.rRule = `RRULE:FREQ=${this.activeTab};BYMONTH=${this.interval};BYMONTHDAY=${this.day};BYSECOND=${seconds}`;
						} else {
							this.rRule = `RRULE:FREQ=${this.activeTab};BYMONTH=${this.interval};BYMONTHDAY=${this.day}`;
						}
					}
				} else {
					this.interval = this.state.yearly.specificMonthWeek.month;
					bysetpos = this.state.yearly.specificMonthWeek.monthWeek;
					day = this.state.yearly.specificMonthWeek.day;
					hour = this.state.yearly.specificMonthWeek.hours;
					minute = this.state.yearly.specificMonthWeek.minutes;
					seconds = this.state.yearly.specificMonthWeek.seconds;
					let newDay = day.slice(0, 2);
					let newsetPos = bysetpos.split("#");
					let sign;
					let createsetPos;
					if (bysetpos == "L") {
						sign = "-";
						createsetPos = sign + 1;
						BYDAY = createsetPos + newDay;
						if (hour !== 0 && minute !== 0 && seconds !== 0) {
							this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYSETPOS=${createsetPos};BYYEARDAY=${BYDAY};BYMONTH=${this.interval};BYHOUR=${hour};BYMINUTE=${minute};BYSECOND=${seconds}`;
						} else {
							if (hour !== 0 && minute !== 0) {
								this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYSETPOS=${createsetPos};BYYEARDAY=${BYDAY};BYMONTH=${this.interval};BYHOUR=${hour};BYMINUTE=${minute}`;
							} else if (hour !== 0 && seconds !== 0) {
								this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYSETPOS=${createsetPos};BYYEARDAY=${BYDAY};BYMONTH=${this.interval};BYHOUR=${hour};BYSECOND=${seconds}`;
							} else if (minute !== 0 && seconds !== 0) {
								this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYSETPOS=${createsetPos};BYYEARDAY=${BYDAY};BYMONTH=${this.interval};BYMINUTE=${minute};BYSECOND=${seconds}`;
							} else if (hour !== 0) {
								this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYSETPOS=${createsetPos};BYYEARDAY=${BYDAY};BYMONTH=${this.interval};BYHOUR=${hour}`;
							} else if (minute !== 0) {
								this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYSETPOS=${createsetPos};BYYEARDAY=${BYDAY};BYMONTH=${this.interval};BYMINUTE=${minute}`;
							} else if (seconds !== 0) {
								this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYSETPOS=${createsetPos};BYYEARDAY=${BYDAY};BYMONTH=${this.interval};BYSECOND=${seconds}`;
							} else {
								this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYSETPOS=${createsetPos};BYYEARDAY=${BYDAY};BYMONTH=${this.interval};`;
							}
						}
					} else {
						sign = "+";
						createsetPos = sign + newsetPos[1];
						BYDAY = createsetPos + newDay;
						if (hour !== 0 && minute !== 0 && seconds !== 0) {
							this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYSETPOS=${createsetPos};BYYEARDAY=${BYDAY};BYMONTH=${this.interval};BYHOUR=${hour};BYMINUTE=${minute};BYSECOND=${seconds}`;
						} else {
							if (hour !== 0 && minute !== 0) {
								this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYSETPOS=${createsetPos};BYYEARDAY=${BYDAY};BYMONTH=${this.interval};BYHOUR=${hour};BYMINUTE=${minute}`;
							} else if (hour !== 0 && seconds !== 0) {
								this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYSETPOS=${createsetPos};BYYEARDAY=${BYDAY};BYMONTH=${this.interval};BYHOUR=${hour};BYSECOND=${seconds}`;
							} else if (minute !== 0 && seconds !== 0) {
								this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYSETPOS=${createsetPos};BYYEARDAY=${BYDAY};BYMONTH=${this.interval};BYMINUTE=${minute};BYSECOND=${seconds}`;
							} else if (hour !== 0) {
								this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYSETPOS=${createsetPos};BYYEARDAY=${BYDAY};BYMONTH=${this.interval};BYHOUR=${hour}`;
							} else if (minute !== 0) {
								this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYSETPOS=${createsetPos};BYYEARDAY=${BYDAY};BYMONTH=${this.interval};BYMINUTE=${minute}`;
							} else if (seconds !== 0) {
								this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYSETPOS=${createsetPos};BYYEARDAY=${BYDAY};BYMONTH=${this.interval};BYSECOND=${seconds}`;
							} else {
								this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYSETPOS=${createsetPos};BYYEARDAY=${BYDAY};BYMONTH=${this.interval};`;
							}
						}
					}
				}
			} else if (this.activeTab == "monthly") {
				this.activeTab = "MONTHLY";
				if (this.state.monthly.subTab == "specificWeekDay") {
					this.interval = this.state.monthly.specificWeekDay.months;
					bysetpos = this.state.monthly.specificWeekDay.monthWeek;
					day = this.state.monthly.specificWeekDay.day;
					hour = this.state.monthly.specificWeekDay.hours;
					minute = this.state.monthly.specificWeekDay.minutes;
					seconds = this.state.monthly.specificWeekDay.seconds;
					let newDay = day.slice(0, 2);
					let newsetPos = bysetpos.split("#");
					let sign;
					let createsetPos;
					if (bysetpos == "L") {
						sign = "-";
						createsetPos = sign + 1;
						BYDAY = createsetPos + newDay;
						if (hour !== 0 && minute !== 0 && seconds !== 0) {
							this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYSETPOS=${createsetPos};BYMONTHDAY=${BYDAY};BYHOUR=${hour};BYMINUTE=${minute};BYSECOND=${seconds}`;
						} else {
							if (hour !== 0 && minute !== 0) {
								this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYSETPOS=${createsetPos};BYMONTHDAY=${BYDAY};BYHOUR=${hour};BYMINUTE=${minute}`;
							} else if (hour !== 0 && seconds !== 0) {
								this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYSETPOS=${createsetPos};BYMONTHDAY=${BYDAY};BYHOUR=${hour};BYSECOND=${seconds}`;
							} else if (minute !== 0 && seconds !== 0) {
								this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYSETPOS=${createsetPos};BYMONTHDAY=${BYDAY};BYMINUTE=${minute};BYSECOND=${seconds}`;
							} else if (hour !== 0) {
								this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYSETPOS=${createsetPos};BYMONTHDAY=${BYDAY};BYHOUR=${hour}`;
							} else if (minute !== 0) {
								this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYSETPOS=${createsetPos};BYMONTHDAY=${BYDAY};BYMINUTE=${minute}`;
							} else if (seconds !== 0) {
								this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYSETPOS=${createsetPos};BYMONTHDAY=${BYDAY};BYSECOND=${seconds}`;
							} else {
								this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYSETPOS=${createsetPos};BYMONTHDAY=${BYDAY}`;
							}
						}
					} else {
						sign = "+";
						createsetPos = sign + newsetPos[1];
						BYDAY = createsetPos + newDay;
						if (hour !== 0 && minute !== 0 && seconds !== 0) {
							this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYSETPOS=${createsetPos};BYMONTHDAY=${BYDAY};BYHOUR=${hour};BYMINUTE=${minute};BYSECOND=${seconds}`;
						} else {
							if (hour !== 0 && minute !== 0) {
								this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYSETPOS=${createsetPos};BYMONTHDAY=${BYDAY};BYHOUR=${hour};BYMINUTE=${minute}`;
							} else if (hour !== 0 && seconds !== 0) {
								this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYSETPOS=${createsetPos};BYMONTHDAY=${BYDAY};BYHOUR=${hour};BYSECOND=${seconds}`;
							} else if (minute !== 0 && seconds !== 0) {
								this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYSETPOS=${createsetPos};BYMONTHDAY=${BYDAY};BYMINUTE=${minute};BYSECOND=${seconds}`;
							} else if (hour !== 0) {
								this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYSETPOS=${createsetPos};BYMONTHDAY=${BYDAY};BYHOUR=${hour}`;
							} else if (minute !== 0) {
								this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYSETPOS=${createsetPos};BYMONTHDAY=${BYDAY};BYMINUTE=${minute}`;
							} else if (seconds !== 0) {
								this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYSETPOS=${createsetPos};BYMONTHDAY=${BYDAY};BYSECOND=${seconds}`;
							} else {
								this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYSETPOS=${createsetPos};BYMONTHDAY=${BYDAY}`;
							}
						}
					}
				} else {
					this.day = this.state.monthly.specificDay.day;
					this.interval = this.state.monthly.specificDay.months;
					hour = this.state.monthly.specificDay.hours;
					minute = this.state.monthly.specificDay.minutes;
					seconds = this.state.monthly.specificDay.seconds;
					if (hour !== 0 && minute !== 0 && seconds !== 0) {
						this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYMONTHDAY=${this.day};BYHOUR=${hour};BYMINUTE=${minute};BYSECOND=${seconds}`;
					} else {
						if (hour !== 0 && minute !== 0) {
							this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYMONTHDAY=${this.day};BYHOUR=${hour};BYMINUTE=${minute}`;
						} else if (hour !== 0 && seconds !== 0) {
							this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYMONTHDAY=${this.day};BYHOUR=${hour};BYSECOND=${seconds}`;
						} else if (minute !== 0 && seconds !== 0) {
							this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYMONTHDAY=${this.day};BYMINUTE=${minute};BYSECOND=${seconds}`;
						} else if (hour !== 0) {
							this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYMONTHDAY=${this.day};BYHOUR=${hour}`;
						} else if (minute !== 0) {
							this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYMONTHDAY=${this.day};BYMINUTE=${minute}`;
						} else if (seconds !== 0) {
							this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYMONTHDAY=${this.day};BYSECOND=${seconds}`;
						} else {
							this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYMONTHDAY=${this.day}`;
						}
					}
				}
			} else if (this.activeTab == "weekly") {
				this.activeTab = "WEEKLY";
				hour = this.state.weekly.hours;
				minute = this.state.weekly.minutes;
				seconds = this.state.weekly.seconds;
				this.weekSelectionChange(hour, minute, seconds);
			} else if (this.activeTab == "daily") {
				this.activeTab = "DAILY";
				if (this.state.daily.subTab == "everyDays") {
					this.interval = this.state.daily.everyDays.days;
					hour = this.state.daily.everyDays.hours;
					minute = this.state.daily.everyDays.minutes;
					seconds = this.state.daily.everyDays.seconds;
					if (hour !== 0 && minute !== 0 && seconds !== 0) {
						this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYHOUR=${hour};BYMINUTE=${minute};BYSECOND=${seconds}`;
					} else {
						if (hour !== 0 && minute !== 0) {
							this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYHOUR=${hour};BYMINUTE=${minute}`;
						} else if (hour !== 0 && seconds !== 0) {
							this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYHOUR=${hour};BYSECOND=${seconds}`;
						} else if (minute !== 0 && seconds !== 0) {
							this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYMINUTE=${minute};BYSECOND=${seconds}`;
						} else if (hour !== 0) {
							this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYHOUR=${hour}`;
						} else if (minute !== 0) {
							this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYMINUTE=${minute}`;
						} else if (seconds !== 0) {
							this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYSECOND=${seconds}`;
						} else {
							this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval}`;
						}
					}
				} else {
					hour = this.state.daily.everyWeekDay.hours;
					minute = this.state.daily.everyWeekDay.minutes;
					seconds = this.state.daily.everyWeekDay.seconds;
					if (hour !== 0 && minute !== 0 && seconds !== 0) {
						this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYHOUR=${hour};BYMINUTE=${minute};BYSECOND=${seconds}`;
					} else {
						if (hour !== 0 && minute !== 0) {
							this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYHOUR=${hour};BYMINUTE=${minute}`;
						} else if (hour !== 0 && seconds !== 0) {
							this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYHOUR=${hour};BYSECOND=${seconds}`;
						} else if (minute !== 0 && seconds !== 0) {
							this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYMINUTE=${minute};BYSECOND=${seconds}`;
						} else if (hour !== 0) {
							this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYHOUR=${hour}`;
						} else if (minute !== 0) {
							this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYMINUTE=${minute}`;
						} else if (seconds !== 0) {
							this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYSECOND=${seconds}`;
						} else {
							this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval}`;
						}
					}
				}
			} else if (this.activeTab == "hourly") {
				this.activeTab = "HOURLY";
				this.interval = this.state.hourly.hours;
				minute = this.state.hourly.minutes;
				seconds = this.state.hourly.seconds;
				if (minute !== 0 && seconds !== 0) {
					this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYMINUTE=${minute};BYSECOND=${seconds}`;
				} else {
					if (minute !== 0) {
						this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYMINUTE=${minute}`;
					} else if (seconds !== 0) {
						this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYSECOND=${seconds}`;
					} else {
						this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval}`;
					}
				}
			} else {
				this.activeTab = "MINUTES";
				this.interval = this.state.minutes.minutes;
				seconds = this.state.minutes.seconds;
				if (seconds !== 0) {
					this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval};BYSECOND=${seconds}`;
				} else {
					this.rRule = `RRULE:FREQ=${this.activeTab};INTERVAL=${this.interval}`;
				}
			}
		}

		if (
			localStorage.getItem("selectedTab") !== null &&
			localStorage.getItem("selectedTab") !== ""
		) {
			this.activeTab = JSON.parse(localStorage.getItem("selectedTab"));
		}
	}
}
