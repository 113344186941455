import {
	Component,
	Input,
	OnInit,
	Output,
	EventEmitter,
	AfterContentChecked
} from "@angular/core";
import {
	FormGroup,
	FormArray,
	FormBuilder,
	Validators,
	FormControl
} from "@angular/forms";

@Component({
	selector: "app-kafka-step-one",
	templateUrl: "./kafka-step-one.component.html",
	styleUrls: ["./kafka-step-one.component.scss"]
})
export class KafkaStepOneComponent implements OnInit, AfterContentChecked {
	@Input() projectId: string;
	@Input() kafkaFormValue: any;
	@Output() kafkaFormData = new EventEmitter<any>();
	@Output() kafkaFormValid = new EventEmitter<any>();
	kafkaForm: FormGroup;
	@Input() configFormValue: any;
	configForm: FormGroup;
	@Input() showDiv: any;

	load;
	public serverList: FormArray;
	pattern = "^[A-Za-z0-9_]{1,100}$";
	catalogpublicSame: boolean = false;
	@Output() checkcatalogpublicSame = new EventEmitter<any>();
	isPattern: boolean = false;
	isCatalogRequired: boolean = false;
	@Output() configFormValid = new EventEmitter<any>();
	@Output() configFormData = new EventEmitter<any>();
	publishName = false;
	dbImage = [
		{ type: "mysql", value: "../../../assets/images/mysql.svg" },
		{ type: "postgresql", value: "../../../assets/images/postgresql_logo.svg" },
		{ type: "redshift", value: "../../../assets/images/redshift.svg" },
		{ type: "sqlserver", value: "../../../assets/images/sqlserver.svg" },
		{ type: "oracle", value: "../../../assets/images/oracle-icon.svg" },
		{ type: "hive2", value: "../../../assets/images/hive2.png" },
		{ type: "iq_warehouse", value: "../../../assets/images/warehouse.svg" },
		{ type: "memsql", value: "../../../assets/images/memsql.svg" },
		{ type: "mongo", value: "../../../assets/images/mongodb.svg" },
		{ type: "bigquery", value: "../../../assets/images/bigquery.svg" },
		{ type: "kafka", value: "../../../assets/images/catalog_kafka.svg" },
		{ type: "snowflake", value: "../../../assets/images/snowflake-icon.svg" }
	];
	@Input() sourceType: any;
	isTagEnabled: boolean = true;
	showCustomTag: boolean = true;
	tagsData = [];
	selectedValue: any = [];
	indexposition: any;
	newTagData;
	searchText = null;
	tagName;
	@Input() publicNamekeywordcheck: boolean = false;
	@Output() publicNamekeywordCheck = new EventEmitter<any>();
	@Input() catalogNamekeywordcheck: boolean = false;
	@Output() catalogNamekeywordCheck = new EventEmitter<any>();
	publicNamekeyworderrormsg;
	catalogNamekeyworderrormsg;

	constructor(private fb: FormBuilder) {
		this.configForm = fb.group({
			publicName: ["", [Validators.required, Validators.pattern("^[a-z0-9_]*$")]],
			catalogName: [
				"",
				[Validators.required, Validators.pattern("^[a-z0-9_]*$")]
			],
			schema: ["", [Validators.required, Validators.pattern("^[a-z0-9_]*$")]],
			tableName: [
				"",
				[Validators.required, Validators.pattern("^[a-z0-9_]*$")]
			],
			description: [""],
			publishName: new FormControl(false, [])
		});

		this.kafkaForm = fb.group({
			topic: ["", [Validators.required]],
			servers: this.fb.array([], [Validators.required])
		});
		this.serverList = this.kafkaForm.get("servers") as FormArray;
	}
	ngOnInit() {
		if (this.showDiv.stepone == true) {
			this.showDiv.stepTwo = false;
			this.showDiv.configstreaming = false;
			this.configForm.reset();
			if (this.configFormValue !== undefined) {
				this.configForm.patchValue(this.configFormValue);
			}

			this.configForm.valueChanges.subscribe(val => {
				this.configFormValid.emit(this.configForm.valid);

				this.configFormData.emit(this.configForm.value);
			});
			if (
				localStorage.getItem("tag") !== "" &&
				localStorage.getItem("tag") != null
			) {
				this.newTagData = JSON.parse(localStorage.getItem("tag"));
				if (this.newTagData.length > 0) {
					this.showCustomTag = false;
				} else {
					this.showCustomTag = true;
				}
			}
			this.checkKeyword();
			this.checkCatlogPublicSame();
		} else if (this.showDiv.stepTwo == true) {
			this.showDiv.stepone = false;
			this.showDiv.configstreaming = false;
			this.kafkaForm.reset();
			if (this.kafkaFormValue !== undefined) {
				this.kafkaFormValue.servers.forEach(element => {
					this.addServer();
				});
				this.kafkaForm.patchValue(this.kafkaFormValue);
			} else {
				this.addServer();
			}
			this.kafkaForm.valueChanges.subscribe(val => {
				this.kafkaFormValid.emit(this.kafkaForm.valid);
				this.kafkaFormData.emit(this.kafkaForm.value);
			});
		}
	}

	get server() {
		return this.kafkaForm.get("servers") as FormArray;
	}
	newServer(): FormGroup {
		return this.fb.group({
			host: ["", [Validators.required]],
			port: ["", [Validators.required, Validators.pattern("^[0-9]*$")]]
		});
	}

	addServer() {
		this.serverList.push(this.newServer());
	}

	removeServer(i: number) {
		this.serverList.removeAt(i);
	}
	resetKafkaForm() {
		this.kafkaForm.reset();
		localStorage.setItem("resetKafkaForm", "");
		localStorage.setItem("resetJsonForm", "resetJson");
	}

	ngAfterContentChecked(): void {
		if (localStorage.getItem("clearVal") == "clearValues") {
			if (this.showDiv.stepone == true) {
				this.resetConfigForm();
			}
		} else if (localStorage.getItem("resetKafkaForm") == "resetkafka") {
			if (this.showDiv.stepTwo == true) {
				this.resetKafkaForm();
			}
		}
	}

	checkCatlogPublicSame() {
		if (
			this.configForm.value.catalogName !== "" &&
			this.configForm.value.catalogName !== null
		) {
			if (
				this.configForm.value.catalogName == this.configForm.value.publicName
			) {
				this.catalogpublicSame = true;
				this.isPattern = false;
				this.isCatalogRequired = false;
			} else {
				this.catalogpublicSame = false;
				this.isPattern = true;
				this.isCatalogRequired = false;
			}
		} else {
			this.catalogpublicSame = false;
			this.isPattern = true;
			this.isCatalogRequired = false;
		}
		this.checkcatalogpublicSame.emit(this.catalogpublicSame);
	}

	resetConfigForm() {
		this.configForm.reset();
		localStorage.setItem("clearVal", "");
		localStorage.setItem("resetKafkaForm", "resetkafka");
	}

	checkFieldValid() {
		if (
			this.configForm.value.publicName != "" &&
			this.configForm.value.catalogName !== "" &&
			this.configForm.value.schema !== "" &&
			this.configForm.value.tableName !== ""
		) {
		}
	}

	loadDbImage(type) {
		let img;
		this.dbImage.filter(item => {
			if (item.type === type) img = item.value;
		});

		return img;
	}

	enableAddBtn() {
		if (this.tagName !== "") {
			this.isTagEnabled = false;
		} else {
			this.isTagEnabled = true;
		}
	}

	addTag() {
		if (
			this.selectedValue.length != 0 &&
			this.selectedValue != undefined &&
			this.selectedValue.tagName !== ""
		) {
			if (this.newTagData) {
				this.tagsData = this.newTagData;
			}
			this.tagsData.splice(this.indexposition + 1, 0, this.selectedValue);
			this.tagsData.sort((a, b) => (a.tagName < b.tagName ? -1 : 1));
			this.reduce();
			this.selectedValue = [];
			this.tagName = undefined;
			this.isTagEnabled = true;
			this.showCustomTag = false;
			this.searchText = null;
		} else {
			this.isTagEnabled = true;
			this.tagName = undefined;
			this.searchText = null;
		}
	}

	onSelect(event) {
		let tagObj = {
			tagName: event
		};
		if (
			tagObj.tagName == " " ||
			tagObj.tagName == "  " ||
			tagObj.tagName == "   " ||
			tagObj.tagName == "    " ||
			tagObj.tagName == "    " ||
			tagObj.tagName == "     " ||
			tagObj.tagName == "      " ||
			tagObj.tagName == "       " ||
			tagObj.tagName == "        " ||
			tagObj.tagName == "         " ||
			tagObj.tagName == "          " ||
			tagObj.tagName == "           " ||
			tagObj.tagName == "            " ||
			tagObj.tagName == "             " ||
			tagObj.tagName == "              " ||
			tagObj.tagName == "                " ||
			tagObj.tagName == "                 " ||
			tagObj.tagName == "                  " ||
			tagObj.tagName == "                   " ||
			tagObj.tagName == "                     " ||
			tagObj.tagName == "                     " ||
			tagObj.tagName == "                     " ||
			tagObj.tagName == "               " ||
			tagObj.tagName == "                    " ||
			tagObj.tagName == "                      " ||
			tagObj.tagName == "                       " ||
			tagObj.tagName == "                        " ||
			tagObj.tagName == "                         " ||
			tagObj.tagName == "                          " ||
			tagObj.tagName == "                           " ||
			tagObj.tagName == "                             " ||
			(tagObj.tagName == "                              " &&
				tagObj.tagName.length == 30)
		) {
			tagObj.tagName = "";
		}
		this.selectedValue = tagObj;
	}

	removeTag(index) {
		if (this.newTagData) {
			this.tagsData = this.newTagData;
		}
		if (this.tagsData.length == 1) {
			this.isTagEnabled = true;
			this.showCustomTag = true;
			this.searchText = null;
		}
		this.tagsData.splice(index, 1);
		localStorage.setItem("tag", JSON.stringify(this.tagsData));
	}
	selectedTag(index) {
		this.indexposition = index;
	}

	reduce() {
		let res = [];
		if (this.tagsData.length > 0) {
			this.tagsData.map(function (item) {
				var existItem = res.find(x => x.tagName === item.tagName);
				if (!existItem) {
					res.push(item);
				}
			});
			this.newTagData = res;
			localStorage.setItem("tag", JSON.stringify(this.newTagData));
		}
	}

	checkKeyword() {
		if (
			this.configForm.value.publicName !== null &&
			this.configForm.value.publicName
				.toLowerCase()
				.split(/[\s,\?\,\.!]+/)
				.some(f => f === "iq_warehouse" || f === "iqwarehouse")
		) {
			this.publicNamekeywordcheck = true;
			this.publicNamekeyworderrormsg =
				this.configForm.value.publicName.toLowerCase() +
				" " +
				"is reserved" +
				" " +
				"please try something else";
			this.publicNamekeywordCheck.emit(this.publicNamekeywordcheck);
		} else {
			this.publicNamekeywordcheck = false;
			this.publicNamekeyworderrormsg = "";
			this.publicNamekeywordCheck.emit(this.publicNamekeywordcheck);
		}
		if (
			this.configForm.value.catalogName !== null &&
			this.configForm.value.catalogName
				.toLowerCase()
				.split(/[\s,\?\,\.!]+/)
				.some(f => f === "iq_warehouse" || f === "iqwarehouse")
		) {
			this.catalogNamekeywordcheck = true;
			this.catalogNamekeyworderrormsg =
				this.configForm.value.catalogName.toLowerCase() +
				" " +
				"is reserved" +
				" " +
				"please try something else";
			this.catalogNamekeywordCheck.emit(this.catalogNamekeywordcheck);
		} else {
			this.catalogNamekeywordcheck = false;
			this.catalogNamekeyworderrormsg = "";
			this.catalogNamekeywordCheck.emit(this.catalogNamekeywordcheck);
		}
	}
}
