import {
	Component,
	OnInit,
	AfterViewInit,
	AfterContentChecked,
	forwardRef,
	ViewChild,
	ElementRef
} from "@angular/core";

import { ModalService } from "./modal.service";
import * as moment from "moment";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => CustomDateComponent),
	multi: true
};

const noop = () => {};

@Component({
	selector: "app-custom-date",
	templateUrl: "./custom-date.component.html",
	styleUrls: ["./custom-date.component.scss"],
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class CustomDateComponent
	implements OnInit, AfterViewInit, AfterContentChecked, ControlValueAccessor
{
	private params: any;
	dateString;
	isDateFormat: boolean = true;
	selectedDate;
	dateFormat = "YYYY-MM-DD";
	showModal: boolean = false;
	dtFormat;
	invalidDateErrMsg: boolean = true;
	@ViewChild("datepickerInput") dateField: ElementRef;

	public mask = {
		guide: true,
		showMask: true,
		mask: [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]
	};

	dateFormats = [
		{ name: "DD-MM-YYYY", value: "DD-MM-YYYY" },
		{ name: "MM/DD/YYYY", value: "MM/DD/YYYY" },
		{ name: "YYYY-MM-DD", value: "YYYY-MM-DD" },
		// { name: "YY.MM.DD", value: "YY.MM.DD" },
		// { name: "MM-DD-YY", value: "MM-DD-YY" },
		{ name: "MM-DD-YYYY", value: "MM-DD-YYYY" },
		{ name: "DD/MM/YYYY", value: "DD/MM/YYYY" },
		{ name: "DD.MM.YYYY", value: "DD.MM.YYYY" },
		{ name: "MM.DD.YYYY", value: "MM.DD.YYYY" },
		{ name: "YYYY/MM/DD", value: "YYYY/MM/DD" },
		{ name: "YYYY.MM.DD", value: "YYYY.MM.DD" },
		// { name: "YYYYMMDD", value: "YYYYMMDD" },
		// { name: "DDMMYYYY", value: "DDMMYYYY" },
		// { name: "MMDDYYYY", value: "MMDDYYYY" },
		{ name: "YYYY MM DD", value: "YYYY MM DD" },
		{ name: "MM DD YYYY", value: "MM DD YYYY" },
		{ name: "DD MM YYYY", value: "DD MM YYYY" },
		//with timepicker
		{ name: "YYYY-MM-DD HH:mm:ss", value: "YYYY-MM-DD HH:mm:ss" },
		{ name: "MM-DD-YYYY HH:mm:ss", value: "MM-DD-YYYY HH:mm:ss" },
		{ name: "DD-MM-YYYY HH:mm:ss", value: "DD-MM-YYYY HH:mm:ss" },
		{ name: "YYYY/MM/DD HH:mm:ss", value: "YYYY/MM/DD HH:mm:ss" },
		{ name: "MM/DD/YYYY HH:mm:ss", value: "MM/DD/YYYY HH:mm:ss" },
		{ name: "DD/MM/YYYY HH:mm:ss", value: "DD/MM/YYYY HH:mm:ss" },
		{ name: "YYYY.MM.DD HH:mm:ss", value: "YYYY.MM.DD HH:mm:ss" },
		{ name: "MM.DD.YYYY HH:mm:ss", value: "MM.DD.YYYY HH:mm:ss" },
		{ name: "DD.MM.YYYY HH:mm:ss", value: "DD.MM.YYYY HH:mm:ss" },
		{ name: "YYYYMMDD HH:mm:ss", value: "YYYYMMDD HH:mm:ss" },
		{ name: "MMDDYYYY HH:mm:ss", value: "MMDDYYYY HH:mm:ss" },
		{ name: "DDMMYYYY HH:mm:ss", value: "DDMMYYYY HH:mm:ss" },
		{ name: "YYYY MM DD HH:mm:ss", value: "YYYY MM DD HH:mm:ss" },
		{ name: "MM DD YYYY HH:mm:ss", value: "MM DD YYYY HH:mm:ss" },
		{ name: "DD MM YYYY HH:mm:ss", value: "DD MM YYYY HH:mm:ss" },
		{ name: "YYYY-MM-DDTHH:mm:ss", value: "YYYY-MM-DDTHH:mm:ss" },
		{ name: "MM-DD-YYYYTHH:mm:ss", value: "MM-DD-YYYYTHH:mm:ss" },
		{ name: "DD-MM-YYYYTHH:mm:ss", value: "DD-MM-YYYYTHH:mm:ss" },
		{ name: "YYYY/MM/DDTHH:mm:ss", value: "YYYY/MM/DDTHH:mm:ss" },
		{ name: "MM/DD/YYYYTHH:mm:ss", value: "MM/DD/YYYYTHH:mm:ss" },
		{ name: "DD/MM/YYYYTHH:mm:ss", value: "DD/MM/YYYYTHH:mm:ss" },
		{ name: "YYYY.MM.DDTHH:mm:ss", value: "YYYY.MM.DDTHH:mm:ss" },
		{ name: "MM.DD.YYYYTHH:mm:ss", value: "MM.DD.YYYYTHH:mm:ss" },
		{ name: "DD.MM.YYYYTHH:mm:ss", value: "DD.MM.YYYYTHH:mm:ss" },
		// { name: "YYYYMMDDTHH:mm:ss", value: "YYYYMMDDTHH:mm:ss" },
		// { name: "MMDDYYYYTHH:mm:ss", value: "MMDDYYYYTHH:mm:ss" },
		// { name: "DDMMYYYYTHH:mm:ss", value: "DDMMYYYYTHH:mm:ss" },
		{ name: "YYYY MM DDTHH:mm:ss", value: "YYYY MM DDTHH:mm:ss" },
		{ name: "MM DD YYYYTHH:mm:ss", value: "MM DD YYYYTHH:mm:ss" },
		{ name: "DD MM YYYYTHH:mm:ss", value: "DD MM YYYYTHH:mm:ss" },
		{ name: "yyyy-MM-dd HH:mm:ss.S", value: "yyyy-MM-dd HH:mm:ss.S" }
	];

	//Placeholders for the callbacks which are later provided
	//by the Control Value Accessor
	private onTouchedCallback: () => void = noop;
	private onChangeCallback: (_: any) => void = noop;

	constructor(private modal: ModalService) {}

	ngOnInit(): void {}

	ngAfterViewInit(): void {}

	agInit(params: any): void {
		this.params = params;
	}

	selectDtFormat() {
		this.showModal = true;
	}

	onDateChanged(event) {
		if (event !== undefined && event._d) {
			this.selectedDate = event._d;
		} else if (event.value) {
			this.selectedDate = event.value._d;
		} else if (event) {
			this.selectedDate = event;
		} else {
			this.selectedDate = null;
		}

		this.params.onDateChanged();
	}

	getDate(): Date {
		return this.selectedDate;
	}

	setDate(date: Date): void {
		this.selectedDate = date || null;
	}

	clearDate(dateTime: HTMLInputElement) {
		dateTime.value = "";
		this.dateFormat = "YYYY-MM-DD";
		localStorage.setItem("df", "");
		this.selectedDate = null;
		this.dateField.nativeElement.focus();
		this.dtFormat = "yyyy-MM-dd";
		this.invalidDateErrMsg = true;
		this.params.onDateChanged();
	}

	close() {
		this.showModal = false;
	}

	openModal(content) {
		this.modal.open("", content);
	}

	ngAfterContentChecked(): void {
		if (localStorage.getItem("df")) {
			this.dateFormat = localStorage.getItem("df");
		}
		this.setDateFormat(this.dateFormat);

		this.setMask(this.dateFormat);
	}

	getVal(val) {
		let date = moment(val, this.dateFormat);
		this.onDateChanged(date);
	}

	setMask(df) {
		let maskItem;
		if (df === "YYYY-MM-DD") {
			maskItem = [/\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/];
		} else if (df === "MM/DD/YYYY") {
			maskItem = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];
		} else if (df === "DD-MM-YYYY") {
			maskItem = [/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
			// } else if (df === "YY.MM.DD") {
			// 	maskItem = [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/];
			// } else if (df === "MM-DD-YY") {
			// 	maskItem = [/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/];
		} else if (df === "MM-DD-YYYY") {
			maskItem = [/\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
		} else if (df === "DD/MM/YYYY") {
			maskItem = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];
		} else if (df === "DD.MM.YYYY") {
			maskItem = [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/];
		} else if (df === "MM.DD.YYYY") {
			maskItem = [/\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/, /\d/, /\d/];
		} else if (df === "YYYY/MM/DD") {
			maskItem = [/\d/, /\d/, /\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/];
		} else if (df === "YYYY.MM.DD") {
			maskItem = [/\d/, /\d/, /\d/, /\d/, ".", /\d/, /\d/, ".", /\d/, /\d/];
		}
		// else if (df === "YYYYMMDD") {
		// 	maskItem = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
		// } else if (df === "DDMMYYYY") {
		// 	maskItem = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
		// } else if (df === "MMDDYYYY") {
		// 	maskItem = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
		// }
		else if (df === "YYYY MM DD") {
			maskItem = [/\d/, /\d/, /\d/, /\d/, " ", /\d/, /\d/, " ", /\d/, /\d/];
		} else if (df === "MM DD YYYY") {
			maskItem = [/\d/, /\d/, " ", /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/];
		} else if (df === "DD MM YYYY") {
			maskItem = [/\d/, /\d/, " ", /\d/, /\d/, " ", /\d/, /\d/, /\d/, /\d/];
			//with time
		} else if (df === "YYYY-MM-DD HH:mm:ss") {
			maskItem = [
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				"-",
				/\d/,
				/\d/,
				"-",
				/\d/,
				/\d/,
				" ",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/
			];
		} else if (df === "MM-DD-YYYY HH:mm:ss") {
			maskItem = [
				/\d/,
				/\d/,
				"-",
				/\d/,
				/\d/,
				"-",
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				" ",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/
			];
		} else if (df === "DD-MM-YYYY HH:mm:ss") {
			maskItem = [
				/\d/,
				/\d/,
				"-",
				/\d/,
				/\d/,
				"-",
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				" ",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/
			];
		} else if (df === "YYYY/MM/DD HH:mm:ss") {
			maskItem = [
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				"/",
				/\d/,
				/\d/,
				"/",
				/\d/,
				/\d/,
				" ",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/
			];
		} else if (df === "MM/DD/YYYY HH:mm:ss") {
			maskItem = [
				/\d/,
				/\d/,
				"/",
				/\d/,
				/\d/,
				"/",
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				" ",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/
			];
		} else if (df === "DD/MM/YYYY HH:mm:ss") {
			maskItem = [
				/\d/,
				/\d/,
				"/",
				/\d/,
				/\d/,
				"/",
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				" ",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/
			];
		} else if (df === "YYYY.MM.DD HH:mm:ss") {
			maskItem = [
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				".",
				/\d/,
				/\d/,
				".",
				/\d/,
				/\d/,
				" ",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/
			];
		} else if (df === "MM.DD.YYYY HH:mm:ss") {
			maskItem = [
				/\d/,
				/\d/,
				".",
				/\d/,
				/\d/,
				".",
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				" ",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/
			];
		} else if (df === "DD.MM.YYYY HH:mm:ss") {
			maskItem = [
				/\d/,
				/\d/,
				".",
				/\d/,
				/\d/,
				".",
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				" ",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/
			];
		}
		// else if (df === "YYYYMMDD HH:mm:ss") {
		// 	maskItem = [
		// 		/\d/,
		// 		/\d/,
		// 		/\d/,
		// 		/\d/,
		// 		/\d/,
		// 		/\d/,
		// 		/\d/,
		// 		/\d/,
		// 		" ",
		// 		/\d/,
		// 		/\d/,
		// 		":",
		// 		/\d/,
		// 		/\d/,
		// 		":",
		// 		/\d/,
		// 		/\d/
		// 	];
		// } else if (df === "DDMMYYYY HH:mm:ss") {
		// 	maskItem = [
		// 		/\d/,
		// 		/\d/,
		// 		/\d/,
		// 		/\d/,
		// 		/\d/,
		// 		/\d/,
		// 		/\d/,
		// 		/\d/,
		// 		" ",
		// 		/\d/,
		// 		/\d/,
		// 		":",
		// 		/\d/,
		// 		/\d/,
		// 		":",
		// 		/\d/,
		// 		/\d/
		// 	];
		// } else if (df === "MMDDYYYY HH:mm:ss") {
		// 	maskItem = [
		// 		/\d/,
		// 		/\d/,
		// 		/\d/,
		// 		/\d/,
		// 		/\d/,
		// 		/\d/,
		// 		/\d/,
		// 		/\d/,
		// 		" ",
		// 		/\d/,
		// 		/\d/,
		// 		":",
		// 		/\d/,
		// 		/\d/,
		// 		":",
		// 		/\d/,
		// 		/\d/
		// 	];
		// }
		else if (df === "YYYY MM DD HH:mm:ss") {
			maskItem = [
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				" ",
				/\d/,
				/\d/,
				" ",
				/\d/,
				/\d/,
				" ",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/
			];
		} else if (df === "MM DD YYYY HH:mm:ss") {
			maskItem = [
				/\d/,
				/\d/,
				" ",
				/\d/,
				/\d/,
				" ",
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				" ",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/
			];
		} else if (df === "DD MM YYYY HH:mm:ss") {
			maskItem = [
				/\d/,
				/\d/,
				" ",
				/\d/,
				/\d/,
				" ",
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				" ",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/
			];
		} else if (df === "YYYY-MM-DDTHH:mm:ss") {
			maskItem = [
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				"-",
				/\d/,
				/\d/,
				"-",
				/\d/,
				/\d/,
				"T",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/
			];
		} else if (df === "MM-DD-YYYYTHH:mm:ss") {
			maskItem = [
				/\d/,
				/\d/,
				"-",
				/\d/,
				/\d/,
				"-",
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				"T",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/
			];
		} else if (df === "DD-MM-YYYYTHH:mm:ss") {
			maskItem = [
				/\d/,
				/\d/,
				"-",
				/\d/,
				/\d/,
				"-",
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				"T",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/
			];
		} else if (df === "YYYY/MM/DDTHH:mm:ss") {
			maskItem = [
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				"/",
				/\d/,
				/\d/,
				"/",
				/\d/,
				/\d/,
				"T",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/
			];
		} else if (df === "MM/DD/YYYYTHH:mm:ss") {
			maskItem = [
				/\d/,
				/\d/,
				"/",
				/\d/,
				/\d/,
				"/",
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				"T",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/
			];
		} else if (df === "DD/MM/YYYYTHH:mm:ss") {
			maskItem = [
				/\d/,
				/\d/,
				"/",
				/\d/,
				/\d/,
				"/",
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				"T",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/
			];
		} else if (df === "YYYY.MM.DDTHH:mm:ss") {
			maskItem = [
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				".",
				/\d/,
				/\d/,
				".",
				/\d/,
				/\d/,
				"T",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/
			];
		} else if (df === "MM.DD.YYYYTHH:mm:ss") {
			maskItem = [
				/\d/,
				/\d/,
				".",
				/\d/,
				/\d/,
				".",
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				"T",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/
			];
		} else if (df === "DD.MM.YYYYTHH:mm:ss") {
			maskItem = [
				/\d/,
				/\d/,
				".",
				/\d/,
				/\d/,
				".",
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				"T",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/
			];
		}
		//  else if (df === "YYYYMMDDTHH:mm:ss") {
		// 	maskItem = [
		// 		/\d/,
		// 		/\d/,
		// 		/\d/,
		// 		/\d/,
		// 		/\d/,
		// 		/\d/,
		// 		/\d/,
		// 		/\d/,
		// 		"T",
		// 		/\d/,
		// 		/\d/,
		// 		":",
		// 		/\d/,
		// 		/\d/,
		// 		":",
		// 		/\d/,
		// 		/\d/
		// 	];
		// } else if (df === "DDMMYYYYTHH:mm:ss") {
		// 	maskItem = [
		// 		/\d/,
		// 		/\d/,
		// 		/\d/,
		// 		/\d/,
		// 		/\d/,
		// 		/\d/,
		// 		/\d/,
		// 		/\d/,
		// 		"T",
		// 		/\d/,
		// 		/\d/,
		// 		":",
		// 		/\d/,
		// 		/\d/,
		// 		":",
		// 		/\d/,
		// 		/\d/
		// 	];
		// } else if (df === "MMDDYYYYTHH:mm:ss") {
		// 	maskItem = [
		// 		/\d/,
		// 		/\d/,
		// 		/\d/,
		// 		/\d/,
		// 		/\d/,
		// 		/\d/,
		// 		/\d/,
		// 		/\d/,
		// 		"T",
		// 		/\d/,
		// 		/\d/,
		// 		":",
		// 		/\d/,
		// 		/\d/,
		// 		":",
		// 		/\d/,
		// 		/\d/
		// 	];
		// }
		else if (df === "YYYY MM DDTHH:mm:ss") {
			maskItem = [
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				" ",
				/\d/,
				/\d/,
				" ",
				/\d/,
				/\d/,
				"T",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/
			];
		} else if (df === "MM DD YYYYTHH:mm:ss") {
			maskItem = [
				/\d/,
				/\d/,
				" ",
				/\d/,
				/\d/,
				" ",
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				"T",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/
			];
		} else if (df === "DD MM YYYYTHH:mm:ss") {
			maskItem = [
				/\d/,
				/\d/,
				" ",
				/\d/,
				/\d/,
				" ",
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				"T",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/
			];
		}else if (df === "yyyy-MM-dd HH:mm:ss.S"){
			maskItem = [
				/\d/,
				/\d/,
				/\d/,
				/\d/,
				"-",
				/\d/,
				/\d/,
				"-",
				/\d/,
				/\d/,
				" ",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/,
				":",
				/\d/,
				/\d/,
				".",
				/\d/,
			];
		}

		this.mask = {
			guide: true,
			showMask: true,
			mask: maskItem
		};

		return this.mask;
	}

	//get accessor
	get value(): Date {
		return this.selectedDate;
	}

	//set accessor including call the onchange callback
	set value(v: Date) {
		this.selectedDate = v;
	}
	//Occured value changed from module
	writeValue(value: any): void {
		this.selectedDate = value;
	}

	registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	onChange(event) {
		this.value = event;
		this.onBlur();
	}

	todate(value) {
		this.value = new Date(value);
	}

	onBlur() {
		if (this.selectedDate == "Invalid Date") {
			this.invalidDateErrMsg = false;
		} else {
			this.invalidDateErrMsg = true;
			this.onChangeCallback(this.selectedDate);
		}
	}

	setDateFormat(v) {
		if (v == "YYYY-MM-DD") {
			this.dtFormat = "yyyy-MM-dd";
		} else if (v == "MM/DD/YYYY") {
			this.dtFormat = "MM/dd/yyyy";
		} else if (v == "DD-MM-YYYY") {
			this.dtFormat = "dd-MM-yyyy";
		}
		// else if (v == "YY.MM.DD") {
		// 	this.dtFormat = "yy.MM.dd";
		// } else if (v == "MM-DD-YY") {
		// 	this.dtFormat = "MM-dd-yy";
		// }
		else if (v == "MM-DD-YYYY") {
			this.dtFormat = "MM-dd-yyyy";
		} else if (v == "DD/MM/YYYY") {
			this.dtFormat = "dd/MM/yyyy";
		} else if (v == "DD.MM.YYYY") {
			this.dtFormat = "dd.MM.yyyy";
		} else if (v == "MM.DD.YYYY") {
			this.dtFormat = "MM.dd.yyyy";
		} else if (v == "YYYY/MM/DD") {
			this.dtFormat = "yyyy/MM/dd";
		} else if (v == "YYYY.MM.DD") {
			this.dtFormat = "yyyy.MM.dd";
		}
		// else if (v == "YYYYMMDD") {
		// 	this.dtFormat = "yyyyMMdd";
		// } else if (v == "DDMMYYYY") {
		// 	this.dtFormat = "ddMMyyyy";
		// } else if (v == "MMDDYYYY") {
		// 	this.dtFormat = "MMddyyyy";
		// }
		else if (v == "YYYY MM DD") {
			this.dtFormat = "yyyy" + " " + "MM" + " " + "dd";
		} else if (v == "MM DD YYYY") {
			this.dtFormat = "MM" + " " + "dd" + " " + "yyyy";
		} else if (v == "DD MM YYYY") {
			this.dtFormat = "dd" + " " + "MM" + " " + "yyyy";
		} else if (v == "YYYY-MM-DD HH:mm:ss") {
			this.dtFormat = "yyyy-MM-dd HH:mm:ss";
		} else if (v == "MM-DD-YYYY HH:mm:ss") {
			this.dtFormat = "MM-dd-yyyy HH:mm:ss";
		} else if (v == "DD-MM-YYYY HH:mm:ss") {
			this.dtFormat = "dd-MM-yyyy HH:mm:ss";
		} else if (v == "YYYY/MM/DD HH:mm:ss") {
			this.dtFormat = "yyyy/MM/dd HH:mm:ss";
		} else if (v == "MM/DD/YYYY HH:mm:ss") {
			this.dtFormat = "MM/dd/yyyy HH:mm:ss";
		} else if (v == "DD/MM/YYYY HH:mm:ss") {
			this.dtFormat = "dd/MM/yyyy HH:mm:ss";
		} else if (v == "YYYY.MM.DD HH:mm:ss") {
			this.dtFormat = "yyyy.MM.dd HH:mm:ss";
		} else if (v == "MM.DD.YYYY HH:mm:ss") {
			this.dtFormat = "MM.dd.yyyy HH:mm:ss";
		} else if (v == "DD.MM.YYYY HH:mm:ss") {
			this.dtFormat = "dd.MM.yyyy HH:mm:ss";
		}
		// else if (v == "YYYYMMDD HH:mm:ss") {
		// 	this.dtFormat = "yyyyMMdd HH:mm:ss";
		// } else if (v == "DDMMYYYY HH:mm:ss") {
		// 	this.dtFormat = "ddMMyyyy HH:mm:ss";
		// } else if (v == "MMDDYYYY HH:mm:ss") {
		// 	this.dtFormat = "MMddyyyy HH:mm:ss";
		// }
		else if (v == "YYYY MM DD HH:mm:ss") {
			this.dtFormat = "yyyy MM dd HH:mm:ss";
		} else if (v == "DD MM YYYY HH:mm:ss") {
			this.dtFormat = "dd MM yyyy HH:mm:ss";
		} else if (v == "MM DD YYYY HH:mm:ss") {
			this.dtFormat = "MM dd yyyy HH:mm:ss";
		} else if (v == "YYYY-MM-DDTHH:mm:ss") {
			this.dtFormat = "yyyy-MM-ddTHH:mm:ss";
		} else if (v == "MM-DD-YYYYTHH:mm:ss") {
			this.dtFormat = "MM-dd-yyyyTHH:mm:ss";
		} else if (v == "DD-MM-YYYYTHH:mm:ss") {
			this.dtFormat = "dd-MM-yyyyTHH:mm:ss";
		} else if (v == "YYYY/MM/DDTHH:mm:ss") {
			this.dtFormat = "yyyy/MM/ddTHH:mm:ss";
		} else if (v == "MM/DD/YYYYTHH:mm:ss") {
			this.dtFormat = "MM/dd/yyyyTHH:mm:ss";
		} else if (v == "DD/MM/YYYYTHH:mm:ss") {
			this.dtFormat = "dd/MM/yyyyTHH:mm:ss";
		} else if (v == "YYYY.MM.DDTHH:mm:ss") {
			this.dtFormat = "yyyy.MM.ddTHH:mm:ss";
		} else if (v == "MM.DD.YYYYTHH:mm:ss") {
			this.dtFormat = "MM.dd.yyyyTHH:mm:ss";
		} else if (v == "DD.MM.YYYYTHH:mm:ss") {
			this.dtFormat = "dd.MM.yyyyTHH:mm:ss";
		}
		// else if (v == "YYYYMMDDTHH:mm:ss") {
		// 	this.dtFormat = "yyyyMMddTHH:mm:ss";
		// } else if (v == "DDMMYYYYTH:MM:SS") {
		// 	this.dtFormat = "ddMMyyyyTHH:mm:ss";
		// } else if (v == "MMDDYYYYTHH:mm:ss") {
		// 	this.dtFormat = "MMddyyyyTHH:mm:ss";
		// }
		else if (v == "YYYY MM DDTHH:mm:ss") {
			this.dtFormat = "yyyy MM ddTHH:mm:ss";
		} else if (v == "DD MM YYYYTHH:mm:ss") {
			this.dtFormat = "dd MM yyyyTHH:mm:ss";
		} else if (v == "MM DD YYYYTHH:mm:ss") {
			this.dtFormat = "MM dd yyyyTHH:mm:ss";
		}else if (v == "yyyy-MM-dd HH:mm:ss.S"){
			this.dtFormat = "yyyy-MM-dd HH:mm:ss.S";
		}

		return this.dtFormat;
	}

	toggle(ref) {
		if (ref.opened == false) {
			this.onDateChanged(this.selectedDate);
		}
	}
}
