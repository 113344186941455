import { Component, OnInit,Output,EventEmitter } from '@angular/core';
import { FilePreviewModel } from 'ngx-awesome-uploader';
import { HttpRequest, HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { FilePickerAdapter } from 'ngx-awesome-uploader';
import {DataExploreModel} from '../../../../models/data-explore/data-explore.model';
import swal from 'sweetalert2';
import { file } from '@rxweb/reactive-form-validators';
import {DataIngestionService} from '../../../../services/data-ingestion/data-ingestion.service';
import { Config } from '../../../../config';

@Component({
  selector: 'app-file-progress',
  templateUrl: './file-progress.component.html',
  styleUrls: ['./file-progress.component.scss']
})
export class FileProgressComponent implements OnInit {
  selectedCSVFileName:any;
  headerRows:any;
  seedList:any=[];
  responseData:any=[];
  responseItem:any=[];
  load;
  @Output() disableEvent = new EventEmitter<string>();

  constructor(private http: HttpClient, public dataExploreModel: DataExploreModel, public dataIngestionService: DataIngestionService) { }

  ngOnInit() {
  }

  public uploadFile(fileItem: FilePreviewModel) {
    localStorage.setItem('msgToLoad',"");
    this.dataExploreModel.fileDetails = fileItem;
    const formData = new FormData();
    formData.append('csvfile', fileItem.file);
    const api = Config.url + '/dataingestion/csvfile';   
    const req = new HttpRequest('POST', api,formData, {reportProgress: true});
    return this.http.request(req)
    .pipe(
      map( (res: HttpEvent<any>) => {
          if (res.type === HttpEventType.Response) {
            this.responseData = res.body.toString();
            this.dataExploreModel.responseData = this.responseData;
          return this.responseData;
        } else if (res.type ===  HttpEventType.UploadProgress) {
            // Compute and show the % done:
           let result ="Uploaded " + this.formatFileSize(res.loaded,2,'loaded') +"  "+ "of " + this.formatFileSize(res.total,2,'result');
            this.dataExploreModel.totalFiles = result;
            const UploadProgress = +Math.round((100 * res.loaded) / res.total);
            return UploadProgress;
        }
      }),catchError((error) =>{
        this.load=false;
        this.disableEvent.emit();
        swal('Ooops!',`Something went wrong, Please Try after sometime`,'warning');
        return throwError(error);
      })
      );
  }


  public removeFile(fileItem: FilePreviewModel): Observable<any>{
    //  const removeApi = Config.url + '/dataingestion/csvfile';
    //  return this.http.post(removeApi, fileItem);
    swal('Ooops!',`Removal of file cannot be done. Once the file is uploaded to HDFS it can't be removed`,'warning');

    // const removeApi = Config.url + '/dataingestion/csvfile';
    // this.dataIngestionModel.totalFiles = undefined;  
    // return this.http.post(removeApi, {});

    return;
    }

    // headersList(){
    //   this.dataIngestionService.getFileHeaders(this.responseData).subscribe(data=>{
    //     this.responseItem = data;
    //     this.dataExploreModel.headerRows =this.responseItem;
    //     for(let param of this.dataExploreModel.headerRows){
    //       let seedData =  {
            
    //             "columnName": param,
    //             "columnType": "string",
    //       }
    //       this.seedList.push(seedData);
    //       this.dataExploreModel.headerRecords = this.seedList;
    //     }
    //   })
    // }

    getHeaderArray(csvRecordsArr: any) {
    
      let headers = (<string>csvRecordsArr[0]).split(',');
      let headerArray = [];
      for (let j = 0; j < headers.length; j++) {
        headerArray.push(headers[j]);
      }
      return headerArray;
      
    }

    formatFileSize(bytes,decimalPoint, type) {
      if(bytes == 0){
        return '0 Bytes';
      } 
      let k = 1024;
      let dm:any;
      let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      let i = Math.floor(Math.log(bytes) / Math.log(k));
      if(type == 'loaded'){
        dm= decimalPoint || 2; 
      }else{
        dm= decimalPoint || 0; 
      }
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
   }

}
