import { Operation } from './models/operator';
import { MetaColumn } from './models/meta-columns';

export class QueryFilter
{
  notCondition:boolean;
  column: MetaColumn;
  operation: Operation;
  value: string;
  table: string;
  andorCondition:string;

  constructor(notCondition?:boolean, column?: MetaColumn, operation?: Operation, value?: string, andorCondition?: string) {
    this.notCondition=notCondition;
    this.column = column;
    this.operation = operation; 
    this.value = value;
    this.andorCondition = andorCondition;
  }
}
