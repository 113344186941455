import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { HttpClient,HttpHeaders,HttpParams } from '@angular/common/http';
import { Config } from '../../config';
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class DataCatalogService {
  public cartCount:number=0;
  public cart=[];
  public project;
  public justification;
  static url = Config.url + '/datacatalogs';
  private _pictureChanged = new BehaviorSubject<boolean>(false);
  constructor(private http: HttpClient) { }
   getDashboardCount(){
    return this.http.get(DataCatalogService.url);
  }
  getCount(param){
    let params;
    params = new HttpParams()
      .set('page', param.page)
      .set('status', param.status)
    return this.http.get(DataCatalogService.url);
  }
  list(params) {
    
      return this.http.get(DataCatalogService.url+'/'+params);
  }
  listAll(page,count,search_key,searchType){
    let params;
    params = new HttpParams()
    // .set('page', page)
    // .set('size', count)
    
    if(search_key==null){
     
       params = new HttpParams()
      .set('page', page)
      .set('size', count)
    }else{
      if(searchType==null || searchType=='name'){
         params = new HttpParams()
        .set('page', page)
        .set('size', count)
        .set('name', search_key)
      }
      else if(searchType=='sourceType'){
         params = new HttpParams()
        .set('page', page)
        .set('size', count)
        .set('sourceType', search_key)
      }
      else if(searchType=='status'){
         params = new HttpParams()
        .set('page', page)
        .set('size', count)
        .set('status', search_key)
      }
     
    }
    // if(search_key!='' && )
    return this.http.get(DataCatalogService.url,{params});
  }
  updateStatus(dataCatalogId, dataCatalog) {
      return this.http.put(DataCatalogService.url + '/' + dataCatalogId + '/status' ,dataCatalog);
  }

   updateDescription(dataCatalogId, dataCatalog) {
      return this.http.put(DataCatalogService.url + '/' + dataCatalogId + '/description',dataCatalog);
  }

   synchronize(body) {
      return this.http.post(DataCatalogService.url + '/sync' ,body);
  }

  clean(dataCatalogId) {
      return this.http.delete(DataCatalogService.url + '/' + dataCatalogId + '/clean');
  }
  getProject(){
    return this.http.get(Config.url +'/projects');
  }
  
  updateDataCatalogImage(formdata,dataCatalogId){
    let httpOptions = {
      headers: new HttpHeaders({'Content-Type': "multipart/form-data"
      
			})
		};
    return this.http.post(DataCatalogService.url+'/icon?dataCatalogId='+ dataCatalogId,formdata);

  }
  getDataCatalogImage(imageName){
    let httpOptions = {
			headers: new HttpHeaders({
        "Accept": "image/jpeg"
			})
		};
    return this.http.get(DataCatalogService.url +'/icon?imageName='+ imageName,httpOptions);
  }
}
