import { BrowserModule } from "@angular/platform-browser";
import { SlimLoadingBarModule } from "ng2-slim-loading-bar";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import {
	NgbModule,
	NgbPopoverModule,
	NgbTooltipModule,
	NgbDatepickerModule,
	NgbTabsetModule
} from "@ng-bootstrap/ng-bootstrap";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { NgSelectModule } from "@ng-select/ng-select";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { MenuComponent } from "./components/menu/menu.component";
import { AuthComponent } from "./components/auth/auth.component";
import { HttpConfigInterceptor } from "./interceptor/httpconfig.interceptor";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { UsersComponent } from "./components/users/users.component";
import { SettingsComponent } from "./components/settings/settings.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { UserManagementViewComponent } from "./components/users/view/user-management-view.component";
import { AuthGuard } from "./guard/auth/auth.guard";
import { SweetAlert2Module } from "@toverux/ngx-sweetalert2";
import { NgxPaginationModule } from "ngx-pagination";
import { FooterComponent } from "./components/common/footer/footer.component";
import { BasicLayoutComponent } from "./components/common/layouts/basic-layout/basic-layout.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { AceEditorModule } from "ng2-ace-editor";
import { EditUserComponent } from "./components/users/edit-user/edit-user.component";
import { EditComponent } from "./components/profile/edit/edit.component";
import { BlankLayoutComponent } from "./components/common/layouts/blank-layout/blank-layout.component";
import { NavigationComponent } from "./components/common/navigation/navigation.component";
import { ProfileImageComponent } from "./components/profile/profile-image/profile-image.component";
import { SideNavComponent } from "./components/common/side-nav/side-nav.component";
import { ProjectLayoutComponent } from "./components/common/layouts/project-layout/project-layout.component";
import { ProjectSideNavComponent } from "./components/common/project-side-nav/project-side-nav.component";
import { NvD3Module } from "ngx-nvd3";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { OrderModule } from "ngx-order-pipe";
import { CalendarModule } from "primeng/calendar";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
// import { AngularDateTimePickerModule } from 'angular2-datetimepicker';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { PasswordResetComponent } from "./components/auth/password-reset/password-reset.component";
import { PasswordResetPageComponent } from "./components/auth/password-reset-page/password-reset-page.component";
import { FileUploadModule } from "ng2-file-upload";
import { FilePickerModule } from "ngx-awesome-uploader";
import { CustomPipe } from "./pipes/custom-pipe";
import { OWL_DATE_TIME_FORMATS } from "ng-pick-datetime";
import { OwlMomentDateTimeModule } from "ng-pick-datetime-moment";
import { AdministrationComponent } from "./components/administration/administration.component";
import { DataSourceComponent } from "./components/administration/data-source/data-source.component";
import { AngularDraggableModule } from "angular2-draggable";
import { LicenceComponent } from "./components/licences/licence/licence.component";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { ToastrModule } from "ngx-toastr";

import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { library as fontLibrary } from "@fortawesome/fontawesome-svg-core";

import { faCalendar, faClock } from "@fortawesome/free-regular-svg-icons";
import { DateTimePickerComponent } from "./components/common/date-time-picker/date-time-picker.component";
import { NgxTrimDirectiveModule } from "ngx-trim-directive";
import { FocusDirective } from "./components/common/focus.directive";
import { from } from "rxjs";
import { DataExploreComponent } from "./components/data-explore/data-explore.component";
import { CoreServicesComponent } from "./components/core-services/core-services.component";
import { DataJobsComponent } from "./components/data-jobs/data-jobs.component";
import { UploadCsvComponent } from "./components/data-explore/upload-csv/upload-csv.component";
import { UploadStep1Component } from "./components/data-explore/upload-csv/upload-step1/upload-step1.component";
import { UploadStep2Component } from "./components/data-explore/upload-csv/upload-step2/upload-step2.component";
import { UploadStep3Component } from "./components/data-explore/upload-csv/upload-step3/upload-step3.component";
import { FileProgressComponent } from "./components/data-explore/upload-csv/file-progress/file-progress.component";
import { MetadataService } from "./services/ace-editor/metadata.service";
import { EditorComponent } from "./components/editor/editor.component";
import { CompleterService } from "./services/ace-editor/completer.service";
import { StepTwoComponent } from "./components/data-explore/kafka/step-two/step-two.component";
import { KafkaStepOneComponent } from "./components/data-explore/kafka/step-one/kafka-step-one.component";
import { SchedulerListingComponent } from "./components/scheduler/scheduler-listing/scheduler-listing.component";
import { SchedulerUpdateComponent } from "./components/scheduler/scheduler-update/scheduler-update.component";
import { LicenceDetailsComponent } from "./components/licences/licence-details/licence-details.component";
import { TimezoneFilterPipe } from "./pipes/timezone-filter.pipe";
import { GrdFilterPipe } from "./pipes/grd-filter.pipe";
import { FilterPipe } from "./pipes/filter.pipe";
import { DataSourcesComponent } from "./components/data-explore/data-sources/data-sources.component";
import { VisualQueryBuilderComponent } from "./components/data-explore/visual-query-builder/visual-query-builder.component";
import { GroupbyComponent } from "./components/data-explore/visual-query-builder/groupby/groupby.component";
import { JoinComponent } from "./components/data-explore/visual-query-builder/join/join.component";
import { LimitsComponent } from "./components/data-explore/visual-query-builder/limits/limits.component";
import { QueryFilterComponent } from "./components/data-explore/visual-query-builder/query-filter/query-filter.component";
import { SortComponent } from "./components/data-explore/visual-query-builder/sort/sort.component";
import { TableComponent } from "./components/data-explore/visual-query-builder/table/table.component";
import { ColumnsComponent } from "./components/data-explore/visual-query-builder/columns/columns.component";
import { ItemComponent } from "./components/data-explore/visual-query-builder/join/item/item.component";
import { VisualQueryBuilderService } from "./components/data-explore/visual-query-builder/visual-query-builder.service";
import { TableSortComponent } from "./components/data-explore/visual-query-builder/table/table-sort/table-sort.component";
import { AnalyzerComponent } from "./components/analyzer/analyzer.component";
import { AgGridModule } from "ag-grid-angular";
import { LicenseManager } from "ag-grid-enterprise";
import { CustomDateComponent } from "./components/common/custom-date/custom-date.component";
import {
	MatDatepickerModule,
	MatInputModule,
	MatNativeDateModule,
	MatDialogModule
} from "@angular/material";

import { ModalService } from "./components/common/custom-date/modal.service";
import { ModalWindowComponent } from "./components/common/custom-date/modal-window.component";
import { DatePickerFormatDirective } from "./components/common/custom-date/date-picker-format.directive";
import { MatIconModule } from "@angular/material/icon";
import { TextMaskModule } from "angular2-text-mask";
import { NewFilterPipe } from "./pipes/newfilter.pipe";
import { ColumnFilterPipe } from "./components/data-explore/columnfilter.pipe";
import { DataCatalogComponent } from "./components/data-catalog/data-catalog.component";
import { DataRequestComponent } from "./components/data-request/data-request.component";
import { UserDataRequestComponent } from "./components/data-request/user-data-request/user-data-request.component";
import { DataCatalogFilterPipe } from "./components/data-catalog/datacatalogFilter.pipe";
import { DataRequestFilterPipe } from "./components/data-request/datarequestFilter.pipe";
import { UserDataRequestDetailsComponent } from "./components/data-request/user-data-request/user-data-request-details/user-data-request-details.component";
import { DataRequestDetailsComponent } from "./components/data-request/data-request-details/data-request-details.component";
import { DataSourceSteponeComponent } from "./components/data-explore/data-sources/data-source-stepone/data-source-stepone.component";
import { DataSourceSteptwoComponent } from "./components/data-explore/data-sources/data-source-steptwo/data-source-steptwo.component";
import { CreateDataSourceComponent } from "./components/data-explore/data-sources/create-data-source/create-data-source.component";
import { CreateDataSourceFilterPipe } from "./components/data-explore/data-sources/create-data-source/create-data-sourceFilter.pipe";
import { CreateDataSourceFilterCasePipe } from "./components/data-explore/data-sources/create-data-source/create-data-sourceFiltercase.pipe";
import { DataSourceFilterPipe } from "./components/data-explore/data-sources/data-sourceFilter.pipe";
import { DataSourceStepOneFilterPipe } from "./components/data-explore/data-sources/data-source-stepone/data-source-steponeFilter.pipe";
import { KafkaStepOneFilterPipe } from "./components/data-explore/kafka/step-one/kafka-step-oneFilter.pipe";
import { CronEditorModule } from "./components/common/cron-editor/cron-editor.module";
import { CreateSchedulerComponent } from "./components/scheduler/create-scheduler/create-scheduler.component";
import { CustomDatePipe } from "./pipes/custom-date-pipe";
import { catalogFilter } from "./pipes/catalog-filter";
LicenseManager.setLicenseKey(
	"CompanyName=UST Global Inc,LicensedApplication=insightsquotient.com (UST Insights Studio),LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=4,LicensedProductionInstancesCount=1,AssetReference=AG-022790,ExpiryDate=29_November_2022_[v2]_MTY2OTY4MDAwMDAwMA==9e6453573df08c41c865bcc2a4da753d"
);

fontLibrary.add(faCalendar, faClock);

export const MY_MOMENT_FORMATS = {
	parseInput: "LL LT",
	fullPickerInput:
		"YYYY-MM-DD HH:mm:ss" /* <---- Here i've rewrited the format */,
	datePickerInput: "LL",
	timePickerInput: "LT",
	monthYearLabel: "MMM YYYY",
	dateA11yLabel: "LL",
	monthYearA11yLabel: "MM YYYY"
};
@NgModule({
	declarations: [
		AppComponent,
		MenuComponent,
		AuthComponent,
		DashboardComponent,
		UsersComponent,
		SettingsComponent,
		ProfileComponent,
		UserManagementViewComponent,
		FooterComponent,
		SideNavComponent,
		BasicLayoutComponent,
		BlankLayoutComponent,
		NavigationComponent,
		ProfileImageComponent,
		EditUserComponent,
		EditComponent,
		LicenceDetailsComponent,
		TimezoneFilterPipe,
		ProjectLayoutComponent,
		ProjectSideNavComponent,
		PasswordResetComponent,
		PasswordResetPageComponent,
		GrdFilterPipe,
		FilterPipe,
		CustomPipe,
		AdministrationComponent,
		DataSourceComponent,
		LicenceComponent,
		PageNotFoundComponent,
		DateTimePickerComponent,
		FocusDirective,
		DataExploreComponent,
		CoreServicesComponent,
		DataJobsComponent,
		UploadCsvComponent,
		UploadStep1Component,
		UploadStep2Component,
		UploadStep3Component,
		FileProgressComponent,
		EditorComponent,
		StepTwoComponent,
		KafkaStepOneComponent,
		SchedulerListingComponent,
		SchedulerUpdateComponent,
		DataSourcesComponent,
		VisualQueryBuilderComponent,
		GroupbyComponent,
		JoinComponent,
		LimitsComponent,
		QueryFilterComponent,
		SortComponent,
		TableComponent,
		ColumnsComponent,
		ItemComponent,
		TableSortComponent,
		AnalyzerComponent,
		CustomDateComponent,
		ModalWindowComponent,
		DatePickerFormatDirective,
		NewFilterPipe,
		ColumnFilterPipe,
		DataCatalogComponent,
		DataRequestComponent,
		UserDataRequestComponent,
		DataCatalogFilterPipe,
		DataRequestFilterPipe,
		UserDataRequestDetailsComponent,
		DataRequestDetailsComponent,
		DataSourceSteponeComponent,
		DataSourceSteptwoComponent,
		CreateDataSourceComponent,
		CreateDataSourceFilterPipe,
		CreateDataSourceFilterCasePipe,
		DataSourceFilterPipe,
		DataSourceStepOneFilterPipe,
		KafkaStepOneFilterPipe,
		CreateSchedulerComponent,
		CustomDatePipe,
		catalogFilter
	],
	imports: [
		NgbModule,
		BrowserModule,
		SlimLoadingBarModule,
		NgbPopoverModule,
		NgbTooltipModule,
		NgbDatepickerModule,
		NgbTabsetModule,
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		ReactiveFormsModule,
		FormsModule,
		SweetAlert2Module.forRoot(),
		NgMultiSelectDropDownModule.forRoot(),
		NgxPaginationModule,
		AceEditorModule,
		DragDropModule,
		NgSelectModule,
		NvD3Module,
		Ng2SearchPipeModule,
		OrderModule,
		CalendarModule,
		BrowserAnimationsModule,
		// AngularDateTimePickerModule,
		OwlDateTimeModule,
		OwlNativeDateTimeModule,
		FileUploadModule,
		FilePickerModule,
		OwlDateTimeModule,
		OwlMomentDateTimeModule,
		AngularDraggableModule,
		FontAwesomeModule,
		NgxTrimDirectiveModule,
		AgGridModule.withComponents([CustomDateComponent]),
		ToastrModule.forRoot({
			// timeOut: 2000,
			// preventDuplicates: true
		}),
		MatDatepickerModule,
		MatInputModule,
		MatNativeDateModule,
		MatDialogModule,
		MatIconModule,
		TextMaskModule,
		CronEditorModule
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpConfigInterceptor,
			multi: true
		},
		{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
		AuthGuard,
		MetadataService,
		CompleterService,
		VisualQueryBuilderService,
		ModalService
	],

	entryComponents: [
		ColumnsComponent,
		TableComponent,
		TableSortComponent,
		ModalWindowComponent,
		CustomDateComponent
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
