export class User {
    constructor(public authorities: any,
                public qubzLDAPUser?: boolean,
                public defaultPassword?: boolean,
                public disabled?: boolean,
                public displayName?: string,
                public emailId?:string,
                public firstName?:string,
                public image?:string,
                public lastName?:string,
                public last_modified?: 0,
                public locked?: boolean,
                public lockedTime?:0,
                public password?:string,
                public userId?: string,
                public username?: string,
                public uuid?: string,
                public version?: string,
                public wrongTime?: 0,
                public colorTheme?: string,
                public qubzLicenseExpire?: boolean,
                public qubzLicenseExpireMsg?: string,
                public eula?: boolean,
            ) {}
}

