import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../../config';
@Injectable({
  providedIn: 'root'
})
export class CoreServicesService {

  static url = Config.url + '/coreservice';
  constructor(private http: HttpClient) { }

  getServiceStatus() {
    return this.http.get(CoreServicesService.url);
  }
  startService(param){
    return this.http.put(CoreServicesService.url+'/startservice',param);
  }
  stopService(param){
    return this.http.put(CoreServicesService.url+'/stopservice',param);
  }
}
