import { Pipe, PipeTransform } from "@angular/core";
@Pipe({
	name: "tagFilter"
})
export class DataSourceStepOneFilterPipe implements PipeTransform {
	transform(items: any[], searchText: string): any[] {
		if (!items) return [];
		if (!searchText) return items;
		searchText = searchText.toLowerCase();
		return items.filter(it => {
			let searchItems;
			if (it.tagName) {
				searchItems = it.tagName.toLowerCase().includes(searchText);
			}
			return searchItems;
		});
	}
}
