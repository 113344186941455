import { FormArray, FormBuilder, FormControl, Validators} from '@angular/forms'
import { SqlQuery } from './sql-query';
import { FilterForm } from './query-filter/models/filter-form';

export class VisualQueryForm {
    catalog = new FormControl();
    schema = new FormControl();
    table = new FormControl();
    columns = new FormControl();
    filters = new FormArray([])

    constructor(private fb: FormBuilder, sqlQuery?: SqlQuery) {
      if (sqlQuery.catalog) {
        this.catalog.setValue(sqlQuery.catalog)
      }
      if (sqlQuery.schema) {
        this.schema.setValue(sqlQuery.schema)
      }


      if (sqlQuery.table) {
          this.table.setValue(sqlQuery.table)
      }

      if (sqlQuery.columns) {
        this.columns.setValue(sqlQuery.columns)
      }

      if (sqlQuery && sqlQuery.filters) {
        sqlQuery.filters.forEach(filter => {
          this.filters.push(this.fb.group(
            new FilterForm(filter)
          ))
        })
      }

    }
}
