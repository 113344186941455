interface dbList {
	connectionId?: any;
	catalog?: any;
	dbType?: any;
	uuid?: any;
	version?: any;
	last_modified?: any;
	numberOfSchema?: any;
	qubzDataWarehouse?: any;
}

interface prestoData {
	prestoCatalogList: dbList[];
}

import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	AfterViewInit
} from "@angular/core";
import { DashboardService } from "../../services/dashboard/dashboard.service";
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import * as $ from "jquery";
import { VisualQueryBuilderService } from "../../components/data-explore/visual-query-builder/visual-query-builder.service";
import { DataExplorerService } from "../../services/data-explorer/data-explorer.service";

@Component({
	selector: "app-dashboard",
	templateUrl: "./dashboard.component.html",
	styleUrls: ["./dashboard.component.scss"],
	providers: [DatePipe],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardComponent implements OnInit, AfterViewInit {
	catalogsCount = 0;
	jobsCount = {
		ERROR: 0,
		FAILED: 0,
		FINISHED: 0,
		PENDING: 0,
		RUNNING: 0
	};
	qdwsCount = 0;
	queryCount = {
		success: true,
		totalQuery: 0,
		successQuery: 0,
		failedQuery: 0
	};
	dbListArr: dbList[];

	constructor(
		private dashboardService: DashboardService,
		private cd: ChangeDetectorRef,
		public datePipe: DatePipe,
		private router: Router,
		private builderService: VisualQueryBuilderService,
		private dataExplorerService: DataExplorerService
	) {}

	ngAfterViewInit() {
		$(document).ready(function () {
			var contenth = $(window).height() - 110;
			var sidebarh = $(window).height() - 111;
			$(".pagec").css("height", contenth);
			$(".sidebar-wrapper").css("height", sidebarh);
		});
	}

	ngOnInit() {
		this.getCatalogs();
		this.getJobs();
		this.getIQSandboxCount();
		this.getQueryCount();
		this.clearVal();
	}
	public getCatalogs() {
		this.dashboardService.getCatalogsCount().subscribe(data => {
			let res: any = data;
			if (res.prestoCatalogList) {
				let count = 0;
				for (let param of res.prestoCatalogList) {
					if (!param.qubzDataWarehouse) count++;
				}
				this.catalogsCount = count;
				this.cd.detectChanges();
			}
		});
	}
	public getJobs() {
		this.dashboardService.getJObsCount().subscribe(data => {
			let res: any = data;
			this.jobsCount = res;
			this.cd.detectChanges();
		});
	}
	public getIQSandboxCount() {
		this.dataExplorerService
			.getConnectionsList()
			.subscribe((data: prestoData) => {
				let res: any = data;
				if (res.prestoCatalogList) {
					this.dbListArr = res.prestoCatalogList;
					this.cd.detectChanges();
				}
			});
	}
	public getQueryCount() {
		this.dashboardService.getQueryCount().subscribe(data => {
			let res: any = data;
			if (res.prestoQuery) {
				this.queryCount = res.prestoQuery;
				this.cd.detectChanges();
			}
		});
	}

	clearVal() {
		this.builderService.getCatalog = undefined;
		this.builderService.getCol = undefined;
		this.builderService.getSchema = undefined;
		this.builderService.getTable = undefined;
		this.builderService.getmainQuery = undefined;
		this.builderService.join = undefined;
		this.builderService.result = undefined;
		this.builderService.tableList = undefined;
		this.builderService.filters = undefined;
		this.builderService.groupBy = undefined;
		this.builderService.sortBy = undefined;
		this.builderService.limits = undefined;
		this.builderService.multiColumns = undefined;
		this.builderService.colListing = undefined;
		this.builderService.tables = [];
		this.builderService.fromTable = [];
		this.builderService.fromField = [];
		this.builderService.fromselectedTable = undefined;
		this.builderService.toTable = undefined;
		this.builderService.toField = undefined;
		this.builderService.toselectedTable = undefined;
		this.builderService.selectedField = undefined;
		this.builderService.titleList = [];
		this.builderService.selectedTableCols = [];
		this.builderService.newselectedTableCols = undefined;
		this.builderService.castArray = [];
		this.builderService.selectedElementDtls = [];
		this.builderService.selectedTableArr = [];
		this.builderService.selectedTableSort = undefined;
		this.builderService.colListingArray = [];
		this.builderService.selectedJoinCols = [];
		this.builderService.newSelectedJoinCols;
		this.builderService.comboArray = [];
		this.builderService.noColumnerrorMsg = undefined;
		this.builderService.selectSortCols = undefined;
		this.builderService.selectSortColsArr = [];
		this.builderService.selectSortColsJoin = undefined;
		this.builderService.selectSortColsJoinArr = [];
		this.builderService.selectSortColsAll = undefined;
		this.builderService.selectSortColArrAll = [];
		this.builderService.selectSortColsJoinAll = undefined;
		this.builderService.selectSortColsJoinArrAll = [];
		this.builderService.countAll = undefined;
		this.builderService.analyzerQueryObj = undefined;
		this.builderService.removeerrMsg = undefined;
	}

	hasRole(roles) {
		let user = JSON.parse(sessionStorage.getItem("loggedUser"));
		for (let role of user.authorities) {
			if (role.authority == roles) return "admin";
		}
		return "user";
	}
}
