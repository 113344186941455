interface column {
	SchemaId?: any;
	catalog?: any;
	column?: any;
	last_modified?: any;
	schema?: any;
	table?: any;
	tableId?: any;
	type?: any;
	uuid?: any;
	version?: any;
}
interface prestoTableColumnList {
	prestoTableColumnList: column[];
}
interface dbTable {
	catalog?: any;
	last_modified?: any;
	schema?: any;
	schemaId?: any;
	table?: any;
	uuid?: any;
	version?: any;
	columns?: column[];
}
interface prestoSchemaTableList {
	prestoSchemaTableList: dbTable[];
}

import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	ViewChild
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { model } from "@rxweb/reactive-form-validators";
import { ColumnsComponent } from "../../../../../components/data-explore/visual-query-builder/columns/columns.component";
import { IMetaColumn, JoinEl, ColumnEl } from "../../data";
import { VisualQueryBuilderService } from "../../visual-query-builder.service";
import { DataExplorerService } from "../../../../../services/data-explorer/data-explorer.service";
import swal from "sweetalert2";

@Component({
	selector: "app-item",
	templateUrl: "./item.component.html",
	styleUrls: ["./item.component.scss"]
})
export class ItemComponent implements OnInit {
	dropdownSettings: {
		singleSelection: boolean;
		idField: string;
		textField: string;
		selectAllText: string;
		unSelectAllText: string;
		allowSearchFilter: boolean;
	};
	@Input() visualQueryForm;
	@Input() metaColumns: IMetaColumn[] = [];
	@Input() metaTabels: String[] = [];
	@Input() el: JoinEl;
	@Output() changeItem: EventEmitter<JoinEl> = new EventEmitter();
	@Input() elements: ColumnEl[];
	@Input() noJoinErrMsg: boolean = true;
	columnListing;
	casObj = {
		frmtable: "",
		frmfield: "",
		frmType: "",
		totable: "",
		tofield: "",
		toType: "",
		stmt: "",
		isCast: false
	};
	frmType;
	toType;
	@Input() SortComponent;
	@Input() invalidJoinMsg: boolean = true;

	constructor(
		public modalService: NgbModal,
		private builderService: VisualQueryBuilderService,
		private dataExplorerService: DataExplorerService
	) {
		this.dropdownSettings = {
			singleSelection: false,
			idField: "uuid",
			textField: "column",
			selectAllText: "Select All",
			unSelectAllText: "Select All",
			allowSearchFilter: true
		};
	}

	colDialog(selElement, type) {
		const modalRef = this.modalService.open(ColumnsComponent);
		modalRef.componentInstance.titleMessage = "Select column";
		modalRef.componentInstance.bodyMessage = "";
		modalRef.componentInstance.selTable = selElement.table;
		modalRef.componentInstance.data = {
			metaColumns: this.metaColumns,
			metaTabels: this.metaTabels,
			type: type,
			element: selElement
		};

		modalRef.componentInstance.output.subscribe(el => {
			selElement.field = el.column;
			selElement.table = el.table;
			selElement.type = el.type;
			this.elements = selElement;
			this.builderService.selectedElementDtls.push(selElement);
			this.changeItem.emit(selElement);
			this.ngOnChanges();
		});
		return modalRef.result;
	}
	getcolumns(tables) {
		for (let param of this.builderService.tables) {
			for (let com of param) {
				if (tables.table == com.catalog + "." + com.schema + "." + com.table) {
					this.dataExplorerService.getColumnListOnTable(com.uuid).subscribe(
						(data: prestoTableColumnList) => {
							this.columnListing = data.prestoTableColumnList;
							this.builderService.colListing = this.columnListing;
						},
						error => {
							swal("Failed to load Data", error.error.msg, "error");
						}
					);
				}
			}
		}
	}
	ngOnChanges() {
		this.builderService.fromField = [];
		this.builderService.toField = "";
		if (this.el.from.table == "") {
			this.colDialog(this.el.from, 1);
			return;
		} else {
			this.builderService.fromTable.push(this.el.from.table);
			if (this.el.from.field == "") {
				this.colDialog(this.el.from, 1);
				return;
			} else {
				this.builderService.fromField.push(this.el.from.field);
			}
		}

		if (this.el.to.table == "") {
			//this.builderService.fromField=[];
			if (!this.builderService.toField) {
				this.colDialog(this.el.to, 0);
				return;
			}
		} else {
			// this.builderService.toTable=this.el.to.table;
			if (this.el.to.field !== "") {
				this.builderService.toField = this.el.to.field;
			}
		}

		if (this.elements) {
			this.frmType = this.el.from.type.slice(0, 7);
			this.toType = this.el.to.type.slice(0, 7);

			if (this.frmType !== this.toType) {
				this.checkSameDataType();
			} else {
				this.casObj.frmtable = this.el.from.table;
				this.casObj.frmfield = this.el.from.field;
				this.casObj.frmType = this.el.from.type;
				this.casObj.totable = this.el.to.table;
				this.casObj.tofield = this.el.to.field;
				this.casObj.toType = this.el.to.type;
				this.casObj.isCast = false;
				this.casObj.stmt = "";
				if (this.casObj !== undefined) {
					this.builderService.castArray.push(this.casObj);
				}
			}
		}
	}

	ngOnInit() {
		if (!this.elements) {
			this.elements = [{ table: "", field: "", by: "", type: "", alias: "" }];
		}
	}

	getTableDtl(selElement, type) {
		if (selElement == this.el.from && type == 1) {
			this.builderService.toselectedTable = "";
			this.builderService.fromselectedTable = selElement.table;
		} else {
			this.builderService.fromselectedTable = "";
			this.builderService.toselectedTable = selElement.table;
		}
	}

	resetVal(selElement, type, condType) {
		if (selElement == this.el.from && type == 1) {
			if (condType == "table") {
				this.builderService.fromField = [];
			} else {
				this.builderService.fromField.push(this.el.from.field);
			}
		} else {
			if (condType == "table") {
				this.builderService.toField = undefined;
			} else {
				this.builderService.toField = this.el.to.field;
			}
		}
	}

	getVal(cols) {
		this.builderService.joinComboArr = [];
		for (let [key, value] of Object.entries(cols)) {
			let res2 = <any>{};
			res2 = value;

			let tableDtl = {
				table: this.el.to.table,
				field: res2.column,
				by: "",
				type: ""
			};
			this.builderService.selectedJoinCols.push(tableDtl);
			this.reduce();
		}
	}

	reduce() {
		let res = [];
		this.builderService.selectedJoinCols.map(function (item) {
			var existItem = res.find(
				x => x.table === item.table && x.field === item.field
			);
			if (!existItem) {
				res.push(item);
			}
		});
		this.builderService.newSelectedJoinCols = res;
	}

	checkSameDataType() {
		let text;

		if (
			["integer", "bigint", "double"].includes(this.frmType) &&
			this.toType == "varchar"
		) {
			text =
				"Do you want to cast" +
				" " +
				this.el.from.table +
				"." +
				this.el.from.field +
				" " +
				"as varchar";
		} else if (
			this.frmType == "varchar" &&
			["integer", "bigint", "double"].includes(this.toType)
		) {
			text =
				"Do you want to cast" +
				" " +
				this.el.to.table +
				"." +
				this.el.to.field +
				" " +
				"as varchar";
		} else if (
			["integer", "bigint", "double"].includes(this.frmType) &&
			["integer", "bigint", "double"].includes(this.toType)
		) {
			text =
				"Do you want to cast" +
				" " +
				"both" +
				" " +
				this.el.from.table +
				"." +
				this.el.from.field +
				"," +
				" " +
				"\n" +
				this.el.to.table +
				"." +
				this.el.to.field +
				" " +
				"as varchar";
		}

		swal({
			type: "warning",
			title:
				"Data types" +
				" " +
				this.frmType +
				" " +
				"and" +
				"\n" +
				" " +
				this.toType +
				" " +
				"are not compatible!",
			text: text,
			showCancelButton: true,
			confirmButtonText: "Yes",
			cancelButtonText: "No"
		}).then(
			status => {
				if (status.value === true) {
					let stmt;

					if (
						["integer", "bigint", "double"].includes(this.frmType) &&
						this.toType == "varchar"
					) {
						stmt =
							"CAST" +
							"(" +
							this.el.from.table +
							"." +
							this.el.from.field +
							" " +
							"as varchar(255)" +
							")";
					} else if (
						this.frmType == "varchar" &&
						["integer", "bigint", "double"].includes(this.toType)
					) {
						stmt =
							"CAST" +
							"(" +
							this.el.to.table +
							"." +
							this.el.to.field +
							" " +
							"as varchar(255)" +
							")";
					} else if (
						["integer", "bigint", "double"].includes(this.frmType) &&
						["integer", "bigint", "double"].includes(this.toType)
					) {
						stmt =
							"CAST" +
							"(" +
							this.el.from.table +
							"." +
							this.el.from.field +
							" " +
							"as varchar(255)" +
							")" +
							" " +
							"=" +
							" " +
							"CAST" +
							"(" +
							this.el.to.table +
							"." +
							this.el.to.field +
							" " +
							"as varchar(255)" +
							")";
					}

					this.casObj.frmtable = this.el.from.table;
					this.casObj.frmfield = this.el.from.field;
					this.casObj.frmType = this.frmType;
					this.casObj.totable = this.el.to.table;
					this.casObj.tofield = this.el.to.field;
					this.casObj.toType = this.toType;
					this.casObj.isCast = true;
					this.casObj.stmt = stmt;
					this.builderService.castArray.push(this.casObj);
				}

				if (status.dismiss) {
					this.casObj.frmtable = this.el.from.table;
					this.casObj.frmfield = this.el.from.field;
					this.casObj.frmType = this.el.from.type;
					this.casObj.totable = this.el.to.table;
					this.casObj.tofield = this.el.to.field;
					this.casObj.toType = this.el.to.type;
					this.casObj.isCast = false;

					this.casObj.stmt = "";
					this.builderService.castArray.push(this.casObj);
				}
			},
			dismiss => {
				if (dismiss === "cancel") {
				}
			}
		);
	}

	getSelectedTable(cols) {
		if (this.builderService.colListing) {
			for (let [key, value] of Object.entries(this.builderService.colListing)) {
				let res2 = <any>{};
				res2 = value;
				for (let param of cols) {
					if (param.uuid == res2.uuid && res2.column == param.column) {
						let tableDtl = {
							catalog: res2.catalog,
							schema: res2.schema,
							table: res2.table,
							column: res2.column,
							type: res2.type
						};
						this.builderService.selectedTableArr.push(tableDtl);
						this.tablereduce();
					}
				}
			}
		}
	}

	tablereduce() {
		let res = [];
		this.builderService.selectedTableArr.map(function (item) {
			var existItem = res.find(
				x =>
					x.catalog == item.catalog &&
					x.schema == item.schema &&
					x.table == item.table &&
					x.column == item.column &&
					x.type == item.type
			);
			if (!existItem) {
				res.push(item);
			}
		});
		this.builderService.selectedTableSort = res;
	}

	OnItemDeSelect(item: any) {
		this.builderService.selectedJoinCols = [];
		let field;
		if (item && item.column) {
			field = item.column;
		} else if (item) {
			field = item;
		}
		let tableDtl = {
			table: this.el.to.table,
			field: field,
			by: "",
			type: ""
		};
		this.builderService.joinComboArr.push(tableDtl);
		this.creduce();
	}

	onDeSelectAll(items: any) {
		this.builderService.selectedJoinCols = [];
		if (items.length == 0) {
			let tableDtl = {
				table: this.el.to.table,
				field: "",
				by: "",
				type: ""
			};
			this.builderService.selectedJoinCols.push(tableDtl);
			this.reduce();
		}
	}

	creduce() {
		let res = [];
		this.builderService.joinComboArr.map(function (item) {
			var existItem = res.find(
				x => x.table === item.table && x.field === item.field
			);
			if (!existItem) {
				res.push(item);
			}
		});
		this.builderService.joinComboList = res;
	}

	notifySort(item) {
		this.builderService.selectSortColsJoinArr = [];
		let field;
		if (item && item.column) {
			field = item.column;
		} else if (item) {
			field = item;
		}
		let tableDtl = {
			table: this.el.to.table,
			field: field,
			by: "",
			type: ""
		};
		this.builderService.selectSortColsJoinArr.push(tableDtl);
		this.sortreduce();
	}

	sortreduce() {
		let res = [];
		this.builderService.selectSortColsJoinArr.map(function (item) {
			var existItem = res.find(
				x => x.table === item.table && x.field === item.field
			);
			if (!existItem) {
				res.push(item);
			}
		});
		this.builderService.selectSortColsJoin = res;
		if (this.builderService.selectSortColsJoin) {
			this.SortComponent.removeSortFromJoins();
		}
	}

	notifySortAll(item: any) {
		// this.elements = [{'table': '', 'field': '', 'by': '', 'type':'' }];
		this.builderService.selectSortColsJoinArr = [];
		if (item.length == 0) {
			let tableDtl = {
				table: this.el.to.table,
				field: "",
				by: "",
				type: ""
			};
			this.builderService.selectSortColsJoinArr.push(tableDtl);
			this.allreduce();
			// this.builderService.selectSortColsJoinAll=this.elements;
			//this.SortComponent.removeJoinsAll();
		}
	}

	allreduce() {
		let res = [];
		this.builderService.selectSortColsJoinArr.map(function (item) {
			var existItem = res.find(
				x => x.table === item.table && x.field === item.field
			);
			if (!existItem) {
				res.push(item);
			}
		});
		this.builderService.selectSortColsJoinAll = res;
		this.SortComponent.removeJoinsAll();
	}
}
