import { Component, OnInit } from "@angular/core";
import { DataExplorerService } from "../../../services/data-explorer/data-explorer.service";
import swal from "sweetalert2";
import { Router } from "@angular/router";

@Component({
	selector: "app-user-data-request",
	templateUrl: "./user-data-request.component.html",
	styleUrls: ["./user-data-request.component.scss"]
})
export class UserDataRequestComponent implements OnInit {
	constructor(
		private dataExplorerService: DataExplorerService,
		private router: Router
	) {}
	catalogRequest;
	catalogRequestContent = [];
	page: number = 0;
	order: string = "requestId";
	reverse: boolean = true;
	pendingCount;
	approvedCount;
	rejectedCount;
	searchText = null;
	pendingStatus;
	approvedStatus;
	rejectedStatus;
	userNameDetails;
	newCatalogRequest;
	isExpand: boolean = true;
	revokedCount;
	revokedStatus;
	load;

	ngOnInit() {
		this.userNameDetails = JSON.parse(
			sessionStorage.getItem("userNameDetails")
		);
		this.getcatlalogRequestList();
		localStorage.setItem("contentDetails", "");
	}

	getcatlalogRequestList() {
		let size;
		this.load = true;
		this.dataExplorerService
			.getCatalogRequest(this.page, 10, "")
			.subscribe(data => {
				this.load = false;
				this.catalogRequest = data;
				if (this.catalogRequest.page.totalElements !== 0) {
					size = this.catalogRequest.page.totalElements;
				} else {
					size = 10;
				}
				for (let param of this.catalogRequest.page.content) {
					if (this.userNameDetails.uuid == param.requesterId) {
						this.dataExplorerService
							.getUserCatalogRequest(
								this.page,
								size,
								"",
								this.userNameDetails.uuid
							)
							.subscribe(
								data => {
									this.load = false;
									this.searchText = null;
									this.newCatalogRequest = data;
									this.getstatusCount(this.newCatalogRequest);
								},
								error => {
									swal("Failed to load Data!", error.error.msg, "error");
								}
							);
					}
				}
			});
	}

	setOrder(value: string) {
		if (this.order === value) {
			this.reverse = !this.reverse;
		}
		this.order = value;
	}

	getstatusCount(req) {
		this.pendingCount = req.page.content.filter(
			n => n.status === "PENDING"
		).length;
		this.approvedCount = req.page.content.filter(
			n => n.status === "APPROVED"
		).length;
		this.rejectedCount = req.page.content.filter(
			n => n.status === "REJECTED"
		).length;

		this.revokedCount = req.page.content.filter(
			n => n.status === "REVOKED"
		).length;
	}

	refreshStatus(st) {
		let size;
		let status;
		let isComplete;
		this.searchText = null;
		if (st !== 5) {
			if (this.pendingStatus == true) {
				status = "PENDING";
				isComplete = false;
				for (let param of this.catalogRequest.page.content) {
					if (this.userNameDetails.uuid == param.requesterId) {
						if (this.pendingCount !== 0) {
							this.dataExplorerService
								.getUserCatalogRequest(
									this.page,
									this.pendingCount,
									status,
									this.userNameDetails.uuid
								)
								.subscribe(
									data => {
										this.searchText = null;
										this.newCatalogRequest = data;
									},
									error => {
										swal("Failed to load Data!", error.error.msg, "error");
									}
								);
						} else {
							this.newCatalogRequest = undefined;
						}
					}
				}
			} else if (this.approvedStatus == true) {
				isComplete = true;
				status = "APPROVED";
				for (let param of this.catalogRequest.page.content) {
					if (this.userNameDetails.uuid == param.requesterId) {
						if (this.approvedCount !== 0) {
							this.dataExplorerService
								.getUserCatalogRequest(
									this.page,
									this.approvedCount,
									status,
									this.userNameDetails.uuid
								)
								.subscribe(
									data => {
										this.searchText = null;
										this.newCatalogRequest = data;
									},
									error => {
										swal("Failed to load Data!", error.error.msg, "error");
									}
								);
						} else {
							this.newCatalogRequest = undefined;
						}
					}
				}
			} else if (this.rejectedStatus == true) {
				isComplete = true;
				status = "REJECTED";
				for (let param of this.catalogRequest.page.content) {
					if (this.userNameDetails.uuid == param.requesterId) {
						if (this.rejectedCount !== 0) {
							this.dataExplorerService
								.getUserCatalogRequest(
									this.page,
									this.rejectedCount,
									status,
									this.userNameDetails.uuid
								)
								.subscribe(
									data => {
										this.searchText = null;
										this.newCatalogRequest = data;
									},
									error => {
										swal("Failed to load Data!", error.error.msg, "error");
									}
								);
						} else {
							this.newCatalogRequest = undefined;
						}
					}
				}
			} else if (this.revokedStatus == true) {
				isComplete = true;
				status = "REVOKED";
				for (let param of this.catalogRequest.page.content) {
					if (this.userNameDetails.uuid == param.requesterId) {
						if (this.revokedCount !== 0) {
							this.dataExplorerService
								.getUserCatalogRequest(
									this.page,
									this.revokedCount,
									status,
									this.userNameDetails.uuid
								)
								.subscribe(
									data => {
										this.searchText = null;
										this.newCatalogRequest = data;
									},
									error => {
										swal("Failed to load Data!", error.error.msg, "error");
									}
								);
						} else {
							this.newCatalogRequest = undefined;
						}
					}
				}
			} else {
				this.page = 0;
				status = "";
				isComplete = "";
				this.pendingStatus = false;
				this.approvedStatus = false;
				this.rejectedStatus = false;
				this.revokedStatus = false;
				if (this.catalogRequest.page.totalElements !== 0) {
					size = this.catalogRequest.page.totalElements;
				} else {
					size = 10;
				}
				for (let param of this.catalogRequest.page.content) {
					if (this.userNameDetails.uuid == param.requesterId) {
						if (
							this.pendingCount !== 0 ||
							this.approvedCount !== 0 ||
							(this.rejectedCount !== 0 && this.revokedCount !== 0)
						) {
							this.dataExplorerService
								.getUserCatalogRequest(
									this.page,
									size,
									"",
									this.userNameDetails.uuid
								)
								.subscribe(
									data => {
										this.searchText = null;
										this.newCatalogRequest = data;
										this.getstatusCount(this.newCatalogRequest);
									},
									error => {
										swal("Failed to load Data!", error.error.msg, "error");
									}
								);
						} else {
							this.newCatalogRequest = undefined;
						}
					}
				}
			}
		} else {
			this.page = 0;
			status = "";
			isComplete = "";
			this.pendingStatus = false;
			this.approvedStatus = false;
			this.rejectedStatus = false;
			this.revokedStatus = false;
			if (this.catalogRequest.page.totalElements !== 0) {
				size = this.catalogRequest.page.totalElements;
			} else {
				size = 10;
			}
			for (let param of this.catalogRequest.page.content) {
				if (this.userNameDetails.uuid == param.requesterId) {
					this.dataExplorerService
						.getUserCatalogRequest(
							this.page,
							size,
							"",
							this.userNameDetails.uuid
						)
						.subscribe(
							data => {
								this.searchText = null;
								this.newCatalogRequest = data;
								this.getstatusCount(this.newCatalogRequest);
							},
							error => {
								swal("Failed to load Data!", error.error.msg, "error");
							}
						);
				}
			}
		}
	}

	isExpandToggle() {
		this.isExpand = !this.isExpand;
	}

	getuserReqDetails(content) {
		this.router.navigate(["/user-request/requestAccessDetails"]);
		localStorage.setItem("contentDetails", JSON.stringify(content));
	}
}
