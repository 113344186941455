import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

//App Components
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { DataExploreComponent } from "./components/data-explore/data-explore.component";
import { UsersComponent } from "./components/users/users.component";
import { SettingsComponent } from "./components/settings/settings.component";
import { ProfileComponent } from "./components/profile/profile.component";
import { UserManagementViewComponent } from "./components/users/view/user-management-view.component";
import { AuthComponent } from "./components/auth/auth.component";
import { AuthGuard } from "./guard/auth/auth.guard";
import { MenuComponent } from "./components/menu/menu.component";
import { BlankLayoutComponent } from "./components/common/layouts/blank-layout/blank-layout.component";
import { BasicLayoutComponent } from "./components/common/layouts/basic-layout/basic-layout.component";
import { EditUserComponent } from "./components/users/edit-user/edit-user.component";
import { PasswordResetComponent } from "./components/auth/password-reset/password-reset.component";
import { PasswordResetPageComponent } from "./components/auth/password-reset-page/password-reset-page.component";
import { AdministrationComponent } from "./components/administration/administration.component";
import { LicenceComponent } from "./components/licences/licence/licence.component";
import { LicenceDetailsComponent } from "./components/licences/licence-details/licence-details.component";
import { CoreServicesComponent } from "./components/core-services/core-services.component";
import { DataJobsComponent } from "./components/data-jobs/data-jobs.component";
import { UploadCsvComponent } from "./components/data-explore/upload-csv/upload-csv.component";
import { UploadStep1Component } from "./components/data-explore/upload-csv/upload-step1/upload-step1.component";
import { UploadStep2Component } from "./components/data-explore/upload-csv/upload-step2/upload-step2.component";
import { UploadStep3Component } from "./components/data-explore/upload-csv/upload-step3/upload-step3.component";
import { PageNotFoundComponent } from "./components/page-not-found/page-not-found.component";
import { SchedulerListingComponent } from "./components/scheduler/scheduler-listing/scheduler-listing.component";
import { SchedulerUpdateComponent } from "./components/scheduler/scheduler-update/scheduler-update.component";
import { DataSourcesComponent } from "./components/data-explore/data-sources/data-sources.component";
import { VisualQueryBuilderComponent } from "./components/data-explore/visual-query-builder/visual-query-builder.component";
import { AnalyzerComponent } from "./components/analyzer/analyzer.component";
import { DataCatalogComponent } from "./components/data-catalog/data-catalog.component";
import { DataRequestComponent } from "./components/data-request/data-request.component";
import { UserDataRequestComponent } from "./components/data-request/user-data-request/user-data-request.component";
import { UserDataRequestDetailsComponent } from "./components/data-request/user-data-request/user-data-request-details/user-data-request-details.component";
import { DataRequestDetailsComponent } from "./components/data-request/data-request-details/data-request-details.component";
import { CreateDataSourceComponent } from "./components/data-explore/data-sources/create-data-source/create-data-source.component";
import { CreateSchedulerComponent } from "./components/scheduler/create-scheduler/create-scheduler.component";

const routes: Routes = [
	{ path: "", redirectTo: "auth", pathMatch: "full" },
	{
		path: "",
		component: BlankLayoutComponent,
		children: [
			{
				path: "auth",
				children: [
					{ path: "", component: AuthComponent },
					{ path: "password-reset", component: PasswordResetComponent }
				]
			}
		]
	},
	{ path: "password-reset-page", component: PasswordResetPageComponent },
	// { path: 'j_spring_security_logout',
	//resolve: {
	//url:"localhost:4200/j_spring_security_logout"
	//}
	//},
	{
		path: "menu",
		component: MenuComponent,
		canActivate: [AuthGuard],
		data: { role: ["ROLE_ADMIN", "ROLE_MODELER", "ROLE_ANALYST"] }
	},
	{
		path: "eula",
		component: LicenceComponent,
		canActivate: [AuthGuard],
		data: { role: ["ROLE_ADMIN", "ROLE_MODELER", "ROLE_ANALYST"] }
	},
	{
		path: "license",
		component: LicenceDetailsComponent,
		canActivate: [AuthGuard],
		data: { role: ["ROLE_ADMIN", "ROLE_MODELER", "ROLE_ANALYST"] }
	},
	{
		path: "",
		component: BasicLayoutComponent,
		children: [
			{
				path: "dashboard",
				component: DashboardComponent,
				canActivate: [AuthGuard],
				data: { role: ["ROLE_ADMIN", "ROLE_MODELER", "ROLE_ANALYST"] }
			},
			{
				path: "catalog",
				canActivate: [AuthGuard],
				data: { role: ["ROLE_ADMIN", "ROLE_MODELER", "ROLE_ANALYST"] },
				children: [{ path: "", component: DataCatalogComponent }]
			},
			{
				path: "data-source",
				canActivate: [AuthGuard],
				data: { role: ["ROLE_ADMIN", "ROLE_MODELER", "ROLE_ANALYST"] },
				children: [
					{ path: "", component: DataSourcesComponent },
					{
						path: "create-data-source",
						component: CreateDataSourceComponent,
						canActivate: [AuthGuard],
						data: { role: ["ROLE_ADMIN"] }
					},

					{
						path: "edit-data-source",
						component: CreateDataSourceComponent,
						canActivate: [AuthGuard],
						data: { role: ["ROLE_ADMIN"] }
					},

					{
						path: "upload-csv",
						component: UploadCsvComponent,
						canActivate: [AuthGuard],
						data: { role: ["ROLE_ADMIN", "ROLE_MODELER", "ROLE_ANALYST"] }
					},
					{
						path: "",
						component: UploadStep1Component,
						canActivate: [AuthGuard],
						data: { role: ["ROLE_ADMIN"] }
					},
					{
						path: "",
						component: UploadStep2Component,
						canActivate: [AuthGuard],
						data: { role: ["ROLE_ADMIN"] }
					},
					{
						path: "",
						component: UploadStep3Component,
						canActivate: [AuthGuard],
						data: { role: ["ROLE_ADMIN"] }
					}
				]
			},
			{
				path: "data-explorer",
				children: [
					{ path: "", component: DataExploreComponent },
					{
						path: "visual-query-builder",
						component: VisualQueryBuilderComponent
					}
				],
				canActivate: [AuthGuard],
				data: { role: ["ROLE_ADMIN", "ROLE_MODELER", "ROLE_ANALYST"] }
			},

			{
				path: "scheduler",
				children: [
					{ path: "", component: SchedulerListingComponent },
					{ path: "create", component: CreateSchedulerComponent },
					{
						path: ":schedulerId/edit",
						component: SchedulerUpdateComponent
					}
				],
				canActivate: [AuthGuard],
				data: { role: ["ROLE_ADMIN", "ROLE_MODELER", "ROLE_ANALYST"] }
			},
			{
				path: "requests",
				canActivate: [AuthGuard],
				data: { role: ["ROLE_ADMIN"] },
				children: [
					{ path: "", component: DataRequestComponent },
					{
						path: "requestAccessDetails",
						component: DataRequestDetailsComponent
					}
				]
			},
			{
				path: "user-request",
				canActivate: [AuthGuard],
				data: { role: ["ROLE_MODELER", "ROLE_ANALYST"] },
				children: [
					{ path: "", component: UserDataRequestComponent },
					{
						path: "requestAccessDetails",
						component: UserDataRequestDetailsComponent
					}
				]
			},
			{
				path: "analyzer",
				children: [{ path: "", component: AnalyzerComponent }],
				canActivate: [AuthGuard],
				data: { role: ["ROLE_ADMIN", "ROLE_MODELER", "ROLE_ANALYST"] }
			},
			{
				path: "jobs",
				component: DataJobsComponent,
				canActivate: [AuthGuard],
				data: { role: ["ROLE_ADMIN", "ROLE_MODELER", "ROLE_ANALYST"] }
			},
			{
				path: "users",
				component: UsersComponent,
				canActivate: [AuthGuard],
				data: { role: ["ROLE_ADMIN", "ROLE_MODELER", "ROLE_ANALYST"] },
				children: [
					{ path: "users/view", component: UserManagementViewComponent }
				]
			},
			{
				path: "users/edit",
				component: EditUserComponent,
				canActivate: [AuthGuard],
				data: { role: ["ROLE_ADMIN"] }
			},
			{
				path: "users/view",
				component: UserManagementViewComponent,
				canActivate: [AuthGuard],
				data: { role: ["ROLE_ADMIN"] }
			},

			{
				path: "administration",
				canActivate: [AuthGuard],
				data: { role: ["ROLE_ADMIN"] },
				children: [
					{ path: "", component: AdministrationComponent },
					{
						path: "settings",
						component: SettingsComponent
						//canActivate: [AuthGuard],
						//data: { role: ['ROLE_ADMIN'] }
					},
					{
						path: "core-services",
						component: CoreServicesComponent
						//canActivate: [AuthGuard],
						//data: { role: ['ROLE_ADMIN'] }
					},
					{
						path: "users",
						component: UsersComponent,
						canActivate: [AuthGuard],
						data: { role: ["ROLE_ADMIN", "ROLE_MODELER", "ROLE_ANALYST"] },
						children: [
							{ path: "users/view", component: UserManagementViewComponent }
						]
					}
				]
			},
			{
				path: "profile",
				component: ProfileComponent,
				canActivate: [AuthGuard],
				data: { role: ["ROLE_ADMIN", "ROLE_MODELER", "ROLE_ANALYST"] }
			}
		]
	},
	{ path: "404", component: PageNotFoundComponent },
	{ path: "**", redirectTo: "404" }
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			enableTracing: false
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule {}
