import { Component, OnInit } from '@angular/core';
import { UserSettingsService} from '../../../services/users/user-settings.service';
import { NgForm } from "@angular/forms";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Users } from '../../../models/users/users.model';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
  public user: Users;
  emailPattern = "^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+[.][a-z]{2,4}$";
  alphabetonly = "^[A-Za-z]*$";
  AlphabetSpaceOnly = "^[a-zA-Z ]*$";
  imgURL: any;
  userData;
  constructor(private userSettingService: UserSettingsService,private router: Router) { }
  profileUpdate(form: NgForm){
    this.userSettingService.updateProfile(form.value).subscribe(
      data=>{
        swal("Success!", "Profile details hasbeen updated successfully", "success");
        this.router.navigate(["profile"]);
      },
      error=>{
        
      });
  }
  OnCancelEdit(){
    this.router.navigate(["profile"]);
  }
  ngOnInit() {
    this.user = JSON.parse(sessionStorage.getItem('user'));
    this.userSettingService.getProfile().subscribe(
      data=>{
         this.userData=data;
         this.userData=this.userData.userProfileDetails;
         this.userData.username;
         this.imgURL = UserSettingsService.url+this.userData.username+'/profile/'+this.userData.lastModified+'/image.jpg';
      })
  }

}
