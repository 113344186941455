import { Component, Input, AfterContentChecked } from "@angular/core";
import * as moment from "moment";

@Component({
	selector: "modal-window",
	template: `
		<div
			class="modal"
			id="myModal"
			[style.display]="showModal ? 'block' : 'none'"
		>
			<div class="modal-dialog" role="document">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">Date Format</h5>
						<button
							type="button"
							class="close"
							data-dismiss="modal"
							(click)="close()"
							aria-label="Close"
						>
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="cmodal-body">
						<ng-content> </ng-content>
						<ng-select
							#select
							[items]="dateFormats"
							bindLabel="name"
							bindValue="value"
							[(ngModel)]="dateFormat"
							[clearable]="false"
							[searchable]="true"
							placeholder=""
							(change)="getFormat($event)"
						>
						</ng-select>
					</div>
				</div>
			</div>
		</div>
	`,
	styles: [
		`
			.cmodal-body {
				position: relative;
				padding: 15px;
				min-height: 50px;
				max-height: calc(100vh - 200px);
			}
		`
	]
})
export class ModalWindowComponent implements AfterContentChecked {
	dateFormat = "YYYY-MM-DD";

	dateFormats = [
		{ name: "DD-MM-YYYY", value: "DD-MM-YYYY" },
		{ name: "MM/DD/YYYY", value: "MM/DD/YYYY" },
		{ name: "YYYY-MM-DD", value: "YYYY-MM-DD" },
		// { name: "YY.MM.DD", value: "YY.MM.DD" },
		// { name: "MM-DD-YY", value: "MM-DD-YY" },
		{ name: "MM-DD-YYYY", value: "MM-DD-YYYY" },
		{ name: "DD/MM/YYYY", value: "DD/MM/YYYY" },
		{ name: "DD.MM.YYYY", value: "DD.MM.YYYY" },
		{ name: "MM.DD.YYYY", value: "MM.DD.YYYY" },
		{ name: "YYYY/MM/DD", value: "YYYY/MM/DD" },
		{ name: "YYYY.MM.DD", value: "YYYY.MM.DD" },
		// { name: "YYYYMMDD", value: "YYYYMMDD" },
		// { name: "DDMMYYYY", value: "DDMMYYYY" },
		// { name: "MMDDYYYY", value: "MMDDYYYY" },
		{ name: "YYYY MM DD", value: "YYYY MM DD" },
		{ name: "MM DD YYYY", value: "MM DD YYYY" },
		{ name: "DD MM YYYY", value: "DD MM YYYY" },
		//with timepicker
		{ name: "YYYY-MM-DD HH:mm:ss", value: "YYYY-MM-DD HH:mm:ss" },
		{ name: "MM-DD-YYYY HH:mm:ss", value: "MM-DD-YYYY HH:mm:ss" },
		{ name: "DD-MM-YYYY HH:mm:ss", value: "DD-MM-YYYY HH:mm:ss" },
		{ name: "YYYY/MM/DD HH:mm:ss", value: "YYYY/MM/DD HH:mm:ss" },
		{ name: "MM/DD/YYYY HH:mm:ss", value: "MM/DD/YYYY HH:mm:ss" },
		{ name: "DD/MM/YYYY HH:mm:ss", value: "DD/MM/YYYY HH:mm:ss" },
		{ name: "YYYY.MM.DD HH:mm:ss", value: "YYYY.MM.DD HH:mm:ss" },
		{ name: "MM.DD.YYYY HH:mm:ss", value: "MM.DD.YYYY HH:mm:ss" },
		{ name: "DD.MM.YYYY HH:mm:ss", value: "DD.MM.YYYY HH:mm:ss" },
		// { name: "YYYYMMDD HH:mm:ss", value: "YYYYMMDD HH:mm:ss" },
		// { name: "MMDDYYYY HH:mm:ss", value: "MMDDYYYY HH:mm:ss" },
		// { name: "DDMMYYYY HH:mm:ss", value: "DDMMYYYY HH:mm:ss" },
		{ name: "YYYY MM DD HH:mm:ss", value: "YYYY MM DD HH:mm:ss" },
		{ name: "MM DD YYYY HH:mm:ss", value: "MM DD YYYY HH:mm:ss" },
		{ name: "DD MM YYYY HH:mm:ss", value: "DD MM YYYY HH:mm:ss" },
		{ name: "YYYY-MM-DDTHH:mm:ss", value: "YYYY-MM-DDTHH:mm:ss" },
		{ name: "MM-DD-YYYYTHH:mm:ss", value: "MM-DD-YYYYTHH:mm:ss" },
		{ name: "DD-MM-YYYYTHH:mm:ss", value: "DD-MM-YYYYTHH:mm:ss" },
		{ name: "YYYY/MM/DDTHH:mm:ss", value: "YYYY/MM/DDTHH:mm:ss" },
		{ name: "MM/DD/YYYYTHH:mm:ss", value: "MM/DD/YYYYTHH:mm:ss" },
		{ name: "DD/MM/YYYYTHH:mm:ss", value: "DD/MM/YYYYTHH:mm:ss" },
		{ name: "YYYY.MM.DDTHH:mm:ss", value: "YYYY.MM.DDTHH:mm:ss" },
		{ name: "MM.DD.YYYYTHH:mm:ss", value: "MM.DD.YYYYTHH:mm:ss" },
		{ name: "DD.MM.YYYYTHH:mm:ss", value: "DD.MM.YYYYTHH:mm:ss" },
		// { name: "YYYYMMDDTHH:mm:ss", value: "YYYYMMDDTHH:mm:ss" },
		// { name: "MMDDYYYYTHH:mm:ss", value: "MMDDYYYYTHH:mm:ss" },
		// { name: "DDMMYYYYTHH:mm:ss", value: "DDMMYYYYTHH:mm:ss" },
		{ name: "YYYY MM DDTHH:mm:ss", value: "YYYY MM DDTHH:mm:ss" },
		{ name: "MM DD YYYYTHH:mm:ss", value: "MM DD YYYYTHH:mm:ss" },
		{ name: "DD MM YYYYTHH:mm:ss", value: "DD MM YYYYTHH:mm:ss" },
		{ name: "yyyy-MM-dd HH:mm:ss.S", value: "yyyy-MM-dd HH:mm:ss.S" }
	];
	showModal: boolean = true;

	close() {
		this.showModal = false;
	}

	getFormat(event) {
		if (event) {
			this.dateFormat = event.value;
			localStorage.setItem("df", this.dateFormat);
		}
		this.showModal = false;
	}

	ngAfterContentChecked(): void {
		// if (localStorage.getItem("newDate")) {
		// 	if (
		// 		moment(localStorage.getItem("newDate"), "DD-MM-YYYY", true).isValid()
		// 	) {
		// 		this.dateFormat = "DD-MM-YYYY";
		// 	} else if (
		// 		moment(localStorage.getItem("newDate"), "MM/DD/YYYY", true).isValid()
		// 	) {
		// 		this.dateFormat = "MM/DD/YYYY";
		// 	} else if (
		// 		moment(localStorage.getItem("newDate"), "YYYY-MM-DD", true).isValid()
		// 	) {
		// 		this.dateFormat = "YYYY-MM-DD";
		// 	}
		// 	localStorage.setItem("DfFormat", this.dateFormat);
		// }

		if (localStorage.getItem("df")) {
			this.dateFormat = localStorage.getItem("df");
		}
	}
}
