import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DataExplorerService } from "../../../services/data-explorer/data-explorer.service";
import { CoreServicesService } from "src/app/services/core-services/core-services.service";
import swal from "sweetalert2";
import {
	FormGroup,
	FormBuilder,
	Validators,
	FormArray,
	NgForm
} from "@angular/forms";
import { SqlQuery } from "./sql-query";
import { VisualQueryBuilderService } from "./visual-query-builder.service";
import { QueryFilter } from "./query-filter/sql-Query-Filter";
import { TableColumn } from "./table/table-columns";
import { AggregateEl, ColumnEl, JoinEl } from "./data";
import { formatCurrency, Location } from "@angular/common";
import { JoinComponent } from "../../../components/data-explore/visual-query-builder/join/join.component";
import { format } from "sql-formatter";
import { SortComponent } from "../../../components/data-explore/visual-query-builder/sort/sort.component";
import { GroupbyComponent } from "../../,,/../../components/data-explore/visual-query-builder/groupby/groupby.component";
import { LimitsComponent } from "../../../components/data-explore/visual-query-builder/limits/limits.component";

class MetaColumn {
	constructor(name: string, columns: TableColumn[]) {
		this.name = name;
		this.columns = columns;
	}

	name: string;
	columns: TableColumn[];
}
interface column {
	SchemaId?: any;
	catalog?: any;
	column?: any;
	last_modified?: any;
	schema?: any;
	table?: any;
	tableId?: any;
	type?: any;
	uuid?: any;
	version?: any;
}
interface prestoTableColumnList {
	prestoTableColumnList: column[];
}
interface dbTable {
	catalog?: any;
	last_modified?: any;
	schema?: any;
	schemaId?: any;
	table?: any;
	uuid?: any;
	version?: any;
	columns?: column[];
}
interface prestoSchemaTableList {
	prestoSchemaTableList: dbTable[];
}
interface schema {
	schema?: any;
	catalog?: any;
	uuid?: any;
	version?: any;
	last_modified?: any;
	connectionId?: any;
	tableList?: dbTable[];
}
interface prestoCatalogSchemaList {
	prestoCatalogSchemaList: schema[];
}

interface dbList {
	connectionId?: any;
	catalog?: any;
	dbType?: any;
	uuid?: any;
	version?: any;
	last_modified?: any;
	numberOfSchema?: any;
	schemaList?: schema[];
	qubzDataWarehouse?: any;
}

interface prestoData {
	prestoCatalogList: dbList[];
}

@Component({
	selector: "app-visual-query-builder",
	templateUrl: "./visual-query-builder.component.html",
	styleUrls: ["./visual-query-builder.component.scss"]
})
export class VisualQueryBuilderComponent implements OnInit {
	datasourceId: any;
	projectId: string;
	metaColumns: MetaColumn[] = [];
	metaTabels: String[] = [];
	public visualQueryForm: FormGroup;
	dropdownSettings: {
		singleSelection: boolean;
		idField: string;
		textField: string;
		selectAllText: string;
		unSelectAllText: string;
		allowSearchFilter: boolean;
	};
	query: string;
	vfromCols;
	isVisible: boolean = false;
	showFilter: boolean = false;
	builderFormSub: any;
	filters: FormArray;
	multiSelectColumns: any;
	source: string;
	name: any;
	sqlQuery: SqlQuery;
	queryId: any;
	limits: number;
	isWarning;
	catlogListArr;
	catlogschemListing;
	tableListing;
	columnListing;
	@ViewChild(JoinComponent) JoinComponent: JoinComponent;
	noColumnErrMsg: boolean = true;
	emptyMsg: boolean = true;
	noJoinErrMsg: boolean = true;
	@ViewChild(SortComponent) SortComponent: SortComponent;
	@ViewChild(GroupbyComponent) GroupbyComponent: GroupbyComponent;
	@ViewChild(LimitsComponent) LimitsComponent: LimitsComponent;

	invalidBtnMsg: boolean = true;
	invalidJoinMsg: boolean = true;
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private coreService: CoreServicesService,
		private fb: FormBuilder,
		private builderService: VisualQueryBuilderService,
		private dataExplorerService: DataExplorerService,
		private location: Location
	) {
		this.dropdownSettings = {
			singleSelection: false,
			idField: "uuid",
			textField: "column",
			selectAllText: "Select All",
			unSelectAllText: "Select All",
			allowSearchFilter: true
		};
		if (this.router.getCurrentNavigation().extras.state) {
			let state = this.router.getCurrentNavigation().extras.state;
			this.name = state.name;
			this.source = state.source;
			this.sqlQuery = state.value;
			this.queryId = state.queryId;
		}
	}

	ngOnInit(): void {
		//this.builderService.noColumnerrorMsg=undefined;
		this.projectId = this.route.snapshot.paramMap.get("projectId");
		this.datasourceId = this.route.snapshot.paramMap.get("id");
		this.catalogList();
		if (this.builderService.filters) {
			if (this.builderService.result) {
				this.sqlQuery = this.builderService.result;
			} else {
				this.sqlQuery = this.builderService.analyzerQueryObj;
			}
			if (this.sqlQuery !== undefined && this.sqlQuery.filters !== undefined) {
				this.showFilter =
					this.sqlQuery && this.isFilterValid(this.sqlQuery.filters[0])
						? true
						: false;
			}
		} else {
			this.showFilter =
				this.sqlQuery && this.isFilterValid(this.sqlQuery.filters[0])
					? true
					: false;
		}
		if (this.sqlQuery) this.limits = this.sqlQuery.limits;

		this.setQuery(this.sqlQuery ? this.sqlQuery : new SqlQuery());

		if (
			this.builderService.getCatalog &&
			this.builderService.getSchema &&
			this.builderService.getTable
		) {
			if (this.builderService.getCol) {
				let cols = this.builderService.getCol;
				this.visualQueryForm.patchValue({
					catalog: this.builderService.getCatalog,
					schema: this.builderService.getSchema,
					table: this.builderService.getTable,
					columns: cols
				});
			} else {
				this.visualQueryForm.patchValue({
					catalog: this.builderService.getCatalog,
					schema: this.builderService.getSchema,
					table: this.builderService.getTable
				});
			}

			if (this.builderService.join) {
				this.builderService.sqlQuery.join = this.builderService.join;
			}

			if (this.builderService.groupBy) {
				this.builderService.sqlQuery.groupBy = this.builderService.groupBy;
			}

			if (this.builderService.sortBy) {
				this.builderService.sqlQuery.sortBy = this.builderService.sortBy;
			}

			this.dataExplorerService.getConnectionsList().subscribe(
				(data: prestoData) => {
					this.catlogListArr = data.prestoCatalogList;
					for (let catArr of this.catlogListArr) {
						if (
							catArr.catalog == this.visualQueryForm.controls["catalog"].value
						) {
							this.dataExplorerService
								.getSchemaList(catArr.connectionId)
								.subscribe(
									(data: prestoCatalogSchemaList) => {
										this.catlogschemListing = data.prestoCatalogSchemaList;
										for (let catlogSchem of this.catlogschemListing) {
											if (
												catlogSchem.schema ==
												this.visualQueryForm.controls["schema"].value
											) {
												this.dataExplorerService
													.getTableListOnSchema(catlogSchem.uuid)
													.subscribe(
														(data: prestoSchemaTableList) => {
															this.tableListing = data.prestoSchemaTableList;
															for (let table of this.tableListing) {
																if (
																	table.table ==
																	this.visualQueryForm.controls["table"].value
																) {
																	this.dataExplorerService
																		.getColumnListOnTable(table.uuid)
																		.subscribe(
																			(data: prestoTableColumnList) => {
																				this.columnListing =
																					data.prestoTableColumnList;
																			},
																			error => {
																				swal(
																					"Failed to load Data",
																					error.error.msg,
																					"error"
																				);
																			}
																		);
																}
															}
														},
														error => {
															swal(
																"Failed to load Data",
																error.error.msg,
																"error"
															);
														}
													);
											}
										}
									},
									error => {
										swal("Failed to load Data", error.error.msg, "error");
									}
								);
						}
					}
				},
				error => {
					swal("Failed to load Data", error.error.msg, "error");
				}
			);
		}
		//this.builderService.castArray=undefined;
		//this.builderService.selectedElementDtls=[];
	}

	catalogList() {
		this.dataExplorerService.getConnectionsList().subscribe(
			(data: prestoData) => {
				this.catlogListArr = data.prestoCatalogList;
			},
			error => {
				swal("Failed to load Data", error.error.msg, "error");
			}
		);
	}

	schemaList(event, f: FormGroup) {
		f.controls["schema"].reset();
		f.controls["table"].reset();
		f.controls["columns"].reset();
		this.tableListing = undefined;
		this.columnListing = undefined;
		this.builderService.sqlQuery.join = undefined;
		this.JoinComponent.clearVal();
		this.builderService.fromTable = [];
		this.builderService.selectedTableCols = [];
		this.builderService.sqlQuery.groupBy = undefined;
		this.GroupbyComponent.clearAll();
		this.builderService.sqlQuery.sortBy = undefined;
		this.SortComponent.clearValue();
		this.isWarning = false;
		this.showFilter = false;
		this.builderService.sqlQuery.filters = undefined;
		this.builderService.titleList = [];
		this.removeAllFilters();
		this.LimitsComponent.clear();

		//this.isSaveEnable=true;

		if (event) {
			this.dataExplorerService.getSchemaList(event.connectionId).subscribe(
				(data: prestoCatalogSchemaList) => {
					this.catlogschemListing = data.prestoCatalogSchemaList;
				},
				error => {
					swal("Failed to load Data", error.error.msg, "error");
				}
			);
		} else {
			this.catlogschemListing = undefined;
			f.controls["schema"].reset();
			f.controls["table"].reset();
			//this.tableListing=undefined;
			f.controls["columns"].reset();
			//this.tableListing=undefined;
			// this.columnListing=undefined;
			// this.isSaveEnable=true;
		}
	}

	tableList(event, f: FormGroup) {
		f.controls["table"].reset();
		f.controls["columns"].reset();
		this.columnListing = undefined;
		this.builderService.sqlQuery.join = undefined;
		this.JoinComponent.clearVal();
		this.builderService.fromTable = [];
		this.builderService.sqlQuery.groupBy = undefined;
		this.GroupbyComponent.clearAll();
		this.builderService.sqlQuery.sortBy = undefined;
		this.SortComponent.clearValue();
		this.isWarning = false;
		this.showFilter = false;
		this.builderService.sqlQuery.filters = undefined;
		this.builderService.titleList = [];
		this.removeAllFilters();
		this.LimitsComponent.clear();
		// this.isSaveEnable=true;
		if (event) {
			this.dataExplorerService.getTableListOnSchema(event.uuid).subscribe(
				(data: prestoSchemaTableList) => {
					this.tableListing = data.prestoSchemaTableList;
					this.builderService.tableList = this.tableListing;
					this.builderService.tables.push(this.tableListing);
					this.metaTabels = this.tableListing;
				},
				error => {
					swal("Failed to load Data", error.error.msg, "error");
				}
			);
		} else {
			//this.tableListing=undefined;
			f.controls["table"].reset();
			f.controls["columns"].reset();
			//this.tableListing=undefined;
			//this.columnListing=undefined;
			//this.isSaveEnable=true;
		}
	}

	get columns() {
		if (this.multiSelectColumns)
			return this.multiSelectColumns[
				this.visualQueryForm.controls["table"].value
			];
	}

	onTableChange() {
		this.visualQueryForm.get("columns").reset();
	}

	showPreview() {
		this.visualQueryForm.markAsTouched();
		if (this.visualQueryForm.valid) {
			this.buildQuery();
			this.isVisible = true;
		}
	}
	buildQuery(back?: boolean) {
		let catalog = this.visualQueryForm.get("catalog").value;
		let schema = this.visualQueryForm.get("schema").value;
		let columns = this.visualQueryForm.get("columns").value
			? this.visualQueryForm
					.get("columns")
					.value.map(column => (column.column ? column.column : column))
			: null;
		let table = this.visualQueryForm.get("table").value;
		let filters: QueryFilter[] = this.visualQueryForm.get("filters").value;
		let join: JoinEl[] = this.builderService.sqlQuery.join;
		let groupBy: AggregateEl = this.builderService.sqlQuery.groupBy;
		let sortBy: ColumnEl[] = this.builderService.sqlQuery.sortBy;
		let limits: number = this.builderService.sqlQuery.limits;
		//filters = filters.map(el=> {el.table = el.column.table; el.column = el.column.column ? el.column.column : el.column; return el;});
		let query = {
			catalog,
			schema,
			table,
			columns,
			filters,
			join,
			sortBy,
			groupBy,
			limits,
			query: ""
		};
	}

	getColumnValues(): string {
		let columns = this.visualQueryForm
			.get("columns")
			.value.map(column => column.label);
		let str = "";

		if (columns) {
			for (let i = 0; i < columns.length; i++) {
				if (i == 0) str += columns[i];
				else str += `, ${columns[i]}`;
			}
		}

		return str;
	}

	getTable(): string {
		return this.visualQueryForm.get("table").value;
	}

	hidePreview() {
		this.isVisible = false;
	}

	toggleFilter() {
		if (!this.visualQueryForm.get("table").value) {
			this.isWarning = true;
			return;
		}
		this.isWarning = true;
		this.showFilter = !this.showFilter;

		if (this.showFilter == false) this.removeAllFilters();
	}

	setQuery(query: SqlQuery) {
		this.builderService.setForm(query);
		this.builderFormSub = this.builderService.builderForm$.subscribe(form => {
			this.visualQueryForm = form;
			this.filters = this.visualQueryForm.get("filters") as FormArray;
		});
	}

	addFilter() {
		this.builderService.filters = undefined;
		this.builderService.addFilter();
	}

	deleteFilter(index: number) {
		this.builderService.deleteFilter(index);
	}

	removeAllFilters() {
		let length = this.filters.controls.length;

		for (let i = 0; i < length; i++) {
			this.builderService.deleteFilter(0);
		}

		this.builderService.addFilter();
	}

	getColumns() {
		let catalog = this.visualQueryForm.controls["catalog"].value;
		let schema = this.visualQueryForm.controls["schema"].value;
		let table = this.visualQueryForm.controls["table"].value;

		let combination = catalog + "." + schema + "." + table;
		//let columns= this.visualQueryForm.controls["columns"].value

		let cols = [];
		if (this.builderService.multiColumns) {
			this.multiSelectColumns = this.builderService.multiColumns;
			cols.push(...this.multiSelectColumns); //copy
			cols = cols.map(el => {
				el.table = combination;
				return el;
			});
		} else {
			cols.push(...this.multiSelectColumns); //copy
			cols = cols.map(el => {
				el.table = combination;
				return el;
			});
		}

		if (this.builderService.sqlQuery.join) {
			for (var i = 0; i < this.builderService.sqlQuery.join.length; i++) {
				let to = this.builderService.sqlQuery.join[i].to;
				if (to.table != "") {
					let newTable = to.table.split(".", 3);
					let newComb = combination.split(",", 3);
					if (this.builderService.colListing) {
						let bbb = [];

						for (let cols of this.builderService.colListing) {
							// if(newTable[2] == cols.table){
							let bb = {
								column: {
									label: cols.column,
									type: cols.type.toUpperCase()
								},
								label: cols.column,
								table: cols.table
							};
							bbb.push(bb);
						}
						this.multiSelectColumns = bbb;
					}
					let copyTo = [];
					copyTo.push(...this.multiSelectColumns); //copy
					copyTo = copyTo.map(el => {
						el.table = to.table;
						return el;
					});
					cols.push(...copyTo);
				}
			}
		}
		return cols;
	}

	isFilterValid(filter: QueryFilter): boolean {
		return filter.column && filter.operation ? true : false;
	}

	ngOnDestroy() {
		this.builderFormSub.unsubscribe();
	}

	changeJoin(join: JoinEl[]) {
		this.builderService.sqlQuery.join = join;
	}

	get groupBy(): AggregateEl {
		return this.builderService.sqlQuery.groupBy;
	}
	get join(): JoinEl[] {
		return this.builderService.sqlQuery.join;
	}

	get sortBy(): ColumnEl[] {
		return this.builderService.sqlQuery.sortBy;
	}
	get from(): string {
		if (
			this.visualQueryForm.controls["catalog"].value !== null &&
			this.visualQueryForm.controls["schema"].value !== null &&
			this.visualQueryForm.controls["table"].value !== null
		) {
			return (
				this.visualQueryForm.controls["catalog"].value +
				"." +
				this.visualQueryForm.controls["schema"].value +
				"." +
				this.visualQueryForm.controls["table"].value
			);
		}
	}

	get catalog(): string {
		return this.visualQueryForm.controls["catalog"].value;
	}
	changeSort(sortBy: ColumnEl[]) {
		this.builderService.sqlQuery.sortBy = sortBy;
	}

	changeLimits(limits: any) {
		if (limits.srcElement) {
			this.builderService.sqlQuery.limits = limits.srcElement.value;
		} else {
			if (limits == 0) {
				this.LimitsComponent.clearVal(limits);
			}
		}
	}

	changeGroupBy(groupBy: AggregateEl) {
		this.builderService.sqlQuery.groupBy = groupBy;
	}

	save() {
		this.buildQuery(true);
	}

	back() {
		this.builderService.selectedTableCols = [];
		this.location.back();
	}

	columnList(event) {
		this.visualQueryForm.controls["columns"].reset();
		this.builderService.getCol = undefined;
		this.builderService.sqlQuery.join = undefined;
		this.JoinComponent.clearVal();
		this.builderService.fromTable = [];
		this.builderService.selectedTableCols = [];
		this.builderService.newselectedTableCols = undefined;
		this.builderService.selectedJoinCols = [];
		this.builderService.newSelectedJoinCols = undefined;
		this.builderService.joinComboArr = [];
		this.builderService.joinComboList = undefined;
		this.builderService.comboArray = [];
		this.builderService.comboList = undefined;
		this.builderService.sqlQuery.groupBy = undefined;
		this.builderService.sqlQuery.sortBy = undefined;
		this.SortComponent.clearValue();
		this.GroupbyComponent.clearAll();
		this.isWarning = false;
		this.showFilter = false;
		this.builderService.sqlQuery.filters = undefined;
		this.builderService.titleList = [];
		this.removeAllFilters();
		//this.LimitsComponent.clearVal();
		// this.builderService.selectedTableCols=[];
		// this.builderService.newselectedTableCols=[];
		//this.builderService.selectedJoinCols=[];
		//this.builderService.newSelectedJoinCols=[];
		//this.builderService.comboList=[];
		// this.builderService.joinComboList=[];
		// this.builderService.selectSortColsArr=[];
		// this.builderService.selectSortCols=[];
		//this.builderService.selectSortColsJoinArr=[];
		//  this.builderService.selectSortColsJoin=[];
		//  this.builderService.sqlQuery.sortBy=undefined;
		//
		// this.GroupbyComponent.clearAll();

		//this.isSaveEnable=true;
		if (event) {
			this.dataExplorerService.getColumnListOnTable(event.uuid).subscribe(
				(data: prestoTableColumnList) => {
					this.columnListing = data.prestoTableColumnList;
					this.metaColumns = this.columnListing;
					let aas = [];
					for (let cols of this.columnListing) {
						let aa = {
							column: {
								label: cols.column,
								type: cols.type.toUpperCase()
							},
							label: cols.column,
							table: cols.table
						};
						aas.push(aa);
					}

					this.multiSelectColumns = aas;
					this.builderService.multiColumns = this.multiSelectColumns;
				},
				error => {
					swal("Failed to load Data", error.error.msg, "error");
				}
			);
		}
	}

	saveQuery(btn) {
		this.builderService.getCol = undefined;
		this.builderService.join = undefined;
		this.builderService.titleList = [];
		if (this.visualQueryForm.controls["catalog"].value !== null) {
			this.builderService.getCatalog =
				this.visualQueryForm.controls["catalog"].value;
			this.builderService.getSchema =
				this.visualQueryForm.controls["schema"].value;
			this.builderService.getTable =
				this.visualQueryForm.controls["table"].value;
			if (this.visualQueryForm.value.columns !== null) {
				if (this.visualQueryForm.value.columns.length > 0) {
					this.builderService.getCol = this.visualQueryForm.value.columns;
				} else {
					this.visualQueryForm.value.columns = null;
				}
			}

			let columns;
			let colLisitng = [];
			let newArray;
			if (this.visualQueryForm.value.columns !== null) {
				if (this.visualQueryForm.value.columns.length > 0) {
					for (let col of this.visualQueryForm.value.columns) {
						columns = col.column;
						let tabCol =
							this.visualQueryForm.controls["catalog"].value +
							"." +
							this.visualQueryForm.controls["schema"].value +
							"." +
							this.visualQueryForm.controls["table"].value +
							"." +
							columns;
						colLisitng.push(tabCol);
						newArray = colLisitng.filter(value => !Number.isNaN(value));
					}
				} else {
					this.visualQueryForm.value.column = null;
				}
			}

			let query = "";
			let join: JoinEl[];
			let firsttable;
			let secondtable;
			let firsttablecols;
			let toTablecols;
			let newCols;
			let newColListing = [];
			let newJoin = "";
			let newJoinListing = [];
			let filters: QueryFilter[];
			let filtersinglequeryString;
			let filtermutiplequeryString;
			let typeSymbol;
			let signSymbol;
			let appendedArray = [];
			let filterCol;
			let groupBy: AggregateEl;
			let grpAggColumns;
			let aggCol;
			let grpByMultiStatement;
			let aggColArray = [];
			let grpCombo;
			let grpBy;
			let grpByArray = [];
			let aggColBy;
			let sortBy: ColumnEl[];
			let sorts;
			let sortByMutli;
			let sortByArray = [];
			let limits: number;
			let appendedCode;
			let filterMultArray = [];
			let column;
			let notValue;
			let filterSingleString;
			let castObj;
			let castJoin;
			let castArray = [];
			let selectedElementDetails;
			let frmtableCol;
			let totableCol;
			let aggCountArr = [];
			let aggCount;

			if (this.builderService.selectedElementDtls) {
				selectedElementDetails = this.builderService.selectedElementDtls;
			}

			if (this.builderService.castArray) {
				castObj = this.builderService.castArray;
			}
			if (this.builderService.sqlQuery.limits) {
				limits = this.builderService.sqlQuery.limits;
				this.builderService.limits = limits;
			}
			if (this.builderService.sqlQuery.sortBy) {
				sortBy = this.builderService.sqlQuery.sortBy;
				this.builderService.sortBy = sortBy;

				if (sortBy.length > 1) {
					for (let [key, value] of Object.entries(sortBy)) {
						sorts = <any>{};
						sorts = value;
						if (sorts.table !== "" && sorts.field !== "") {
							if (sorts.by !== "") {
								sortByMutli = sorts.table + "." + sorts.field + " " + sorts.by;
							} else {
								sortByMutli = sorts.table + "." + sorts.field + " " + "ASC";
							}

							sortByArray.push(sortByMutli);
						}
					}
				}
			}

			if (this.builderService.sqlQuery.groupBy) {
				groupBy = this.builderService.sqlQuery.groupBy;
				this.builderService.groupBy = this.builderService.sqlQuery.groupBy;
				if (groupBy.aggColumns.length > 1) {
					for (let [key, value] of Object.entries(groupBy.aggColumns)) {
						aggCol = <any>{};
						aggCol = value;
						if (aggCol.table !== "" && aggCol.field !== "") {
							aggColBy =
								aggCol.by + "(" + aggCol.table + "." + aggCol.field + ")";
							aggColArray.push(aggColBy);
							grpAggColumns = aggColArray;

							// if(aggCol.by=='COUNT'){
							//   aggCount= aggCol.by+'('+aggCol.table+ '.' +aggCol.field+')' +' ' + 'AS'+' '+aggCol.alias;
							//   aggCountArr.push(aggCount);
							// }else{
							//   aggColBy=aggCol.by+'('+aggCol.table+ '.' +aggCol.field+')';
							//   aggColArray.push(aggColBy);
							//   grpAggColumns = aggColArray;
							// }
						} else if (aggCol.table == "" && aggCol.field == "*") {
							aggCount = aggCol.by + "(" + aggCol.field + ")";
							aggCountArr.push(aggCount);
						}
					}
				}

				if (groupBy.groupBy.length > 1) {
					for (let [key, value] of Object.entries(groupBy.groupBy)) {
						grpBy = <any>{};
						grpBy = value;

						if (grpBy.table !== "" && grpBy.field !== "") {
							grpCombo = grpBy.table + "." + "*";
							if (grpBy.field !== undefined) {
								grpByMultiStatement = " " + grpBy.table + "." + grpBy.field;
							} else {
								grpByMultiStatement = " " + grpBy.table + "." + grpBy;
							}
							grpByArray.push(grpByMultiStatement);
						}
					}
				}
			}

			if (this.builderService.sqlQuery.join) {
				join = this.builderService.sqlQuery.join;
				this.builderService.join = this.builderService.sqlQuery.join;
				for (let [key, value] of Object.entries(join)) {
					let res1 = <any>{};
					res1 = value;
					firsttable = res1.from.table;
					secondtable = res1.to.table;
					firsttablecols = res1.from.table + "." + res1.from.field;
					toTablecols = res1.to.table + "." + res1.to.field;
					for (let [key, value] of Object.entries(res1.cols)) {
						let res2 = <any>{};
						res2 = value;
						if (res2.column !== undefined) {
							newCols = secondtable + "." + res2.column;
						} else {
							newCols = secondtable + "." + res2;
						}
						newColListing.push(newCols);
					}

					for (let [key, value] of Object.entries(castObj)) {
						let res3 = <any>{};
						res3 = value;
						frmtableCol = res3.frmtable + "." + res3.frmfield;
						totableCol = res3.totable + "." + res3.tofield;

						if (
							["integer", "bigint", "double"].includes(res3.frmType) &&
							res3.toType == "varchar"
						) {
							if (res3.isCast == true) {
								if (totableCol == toTablecols && res3.stmt !== "") {
									newJoin =
										" " +
										res1.join +
										" " +
										secondtable +
										" " +
										"ON" +
										" " +
										res3.stmt +
										"" +
										"=" +
										" " +
										totableCol;
								}
							} else {
								if (
									frmtableCol == firsttablecols &&
									totableCol == toTablecols &&
									res3.stmt == ""
								) {
									newJoin =
										" " +
										res1.join +
										" " +
										secondtable +
										" " +
										"ON" +
										" " +
										frmtableCol +
										"" +
										"=" +
										" " +
										totableCol;
								}
							}
						} else {
							if (
								frmtableCol == firsttablecols &&
								totableCol == toTablecols &&
								res3.stmt == ""
							) {
								newJoin =
									" " +
									res1.join +
									" " +
									secondtable +
									" " +
									"ON" +
									" " +
									frmtableCol +
									"" +
									"=" +
									" " +
									totableCol;
							}
						}
						if (
							res3.frmType == "varchar" &&
							["integer", "bigint", "double"].includes(res3.toType)
						) {
							if (res3.isCast == true) {
								if (frmtableCol == firsttablecols && res3.stmt !== "") {
									newJoin =
										" " +
										res1.join +
										" " +
										secondtable +
										" " +
										"ON" +
										" " +
										res3.frmtable +
										"." +
										res3.frmfield +
										"" +
										"=" +
										" " +
										res3.stmt;
								}
							} else {
								if (
									frmtableCol == firsttablecols &&
									totableCol == toTablecols &&
									res3.stmt == ""
								) {
									newJoin =
										" " +
										res1.join +
										" " +
										secondtable +
										" " +
										"ON" +
										" " +
										frmtableCol +
										"" +
										"=" +
										" " +
										totableCol;
								}
							}
						} else {
							if (
								frmtableCol == firsttablecols &&
								totableCol == toTablecols &&
								res3.stmt == ""
							) {
								newJoin =
									" " +
									res1.join +
									" " +
									secondtable +
									" " +
									"ON" +
									" " +
									frmtableCol +
									"" +
									"=" +
									" " +
									totableCol;
							}
						}
						if (
							["integer", "bigint", "double"].includes(res3.frmType) &&
							["integer", "bigint", "double"].includes(res3.toType)
						) {
							if (res3.isCast == true) {
								if (frmtableCol == firsttablecols && res3.stmt !== "") {
									newJoin =
										" " +
										res1.join +
										" " +
										secondtable +
										" " +
										"ON" +
										" " +
										res3.stmt;
								}
							} else {
								if (
									frmtableCol == firsttablecols &&
									totableCol == toTablecols &&
									res3.stmt == ""
								) {
									newJoin =
										" " +
										res1.join +
										" " +
										secondtable +
										" " +
										"ON" +
										" " +
										frmtableCol +
										"" +
										"=" +
										" " +
										totableCol;
								}
							}
						} else {
							if (
								frmtableCol == firsttablecols &&
								totableCol == toTablecols &&
								res3.stmt == ""
							) {
								newJoin =
									" " +
									res1.join +
									" " +
									secondtable +
									" " +
									"ON" +
									" " +
									frmtableCol +
									"" +
									"=" +
									" " +
									totableCol;
							}
						}
					}

					newJoinListing.push(newJoin);
				}
			}

			if (this.visualQueryForm.get("filters").value.length > 0) {
				filters = this.visualQueryForm.get("filters").value;
				this.builderService.filters = this.visualQueryForm.get("filters").value;

				for (let [key, value] of Object.entries(filters)) {
					let res1 = <any>{};
					res1 = value;
					if (res1.notCondition !== undefined) {
						if (res1.notCondition == true) {
							notValue = "NOT";
						} else {
							notValue = "";
						}
					} else {
						notValue = "";
					}
					//filterSingleString=res1.column.table + '.' + res1.column.label;

					if (res1.column) {
						for (let [key, value] of Object.entries(res1.column)) {
							let res2 = <any>{};
							res2 = value;
							column = res1.column.table + "." + res2.label;
							if (res2.label && res2.type) {
								if (
									[
										"INTEGER",
										"DOUBLE",
										"NUMERIC",
										"REAL",
										"DECIMAL(10,0)",
										"DECIMAL(10,2)",
										"BIGINT",
										"CHAR(4)",
										"CHAR(1)",
										"CHAR(2)",
										"CHAR(6)",
										"CHAR(5)",
										"DECIMAL(10,3)",
										"DECIMAL(3,2)",
										"DECIMAL(6,2)",
										"BOOLEAN",
										"DATE",
										"TIMESTAMP(3)",
										"TIMESTAMP(3) WITH TIME ZONE",
										"TIMESTAMP(6)"
									].includes(res2.type)
								) {
									typeSymbol = res1.value;
								} else if (
									[
										"VARCHAR",
										"VARCHAR(500)",
										"VARCHAR(90)",
										"VARCHAR(45)",
										"VARCHAR(50)",
										"VARCHAR(40)",
										"VARCHAR(14)",
										"VARCHAR(16)",
										"VARCHAR(33)",
										"VARCHAR(39)",
										"VARCHAR(7)",
										"VARCHAR(5)",
										"VARCHAR(17)",
										"VARCHAR(19)",
										"VARCHAR(1)",
										"VARCHAR(10)",
										"VARCHAR(52)",
										"VARCHAR(13)",
										"VARCHAR(36)",
										"VARCHAR(32)",
										"VARCHAR(130)",
										"VARCHAR(30)",
										"VARCHAR(3)",
										"VARCHAR(18)",
										"VARCHAR(22)",
										"VARCHAR(25)",
										"VARCHAR(24)",
										"VARCHAR(20)",
										"VARCHAR(23)",
										"VARCHAR(29)",
										"VARCHAR(26)",
										"VARCHAR(2)",
										"VARCHAR(12)",
										"VARCHAR(6)",
										"VARCHAR(31)",
										"VARCHAR(9)",
										"VARCHAR(200)",
										"VARCHAR(100)",
										"VARCHAR(255)",
										"VARCHAR(300)",
										"VARCHAR(1024)",
										"VARCHAR(99)",
										"VARCHAR(125)",
										"VARCHAR(256)",
										"VARCHAR(2000)",
										"VARCHAR(250)",
										"VARCHAR(20000)"
									].includes(res2.type)
								) {
									typeSymbol = "'" + res1.value + "'";
								}

								filterCol = column;
							}
						}
					}
					if (res1.operation) {
						if (
							[
								"Is",
								"Is Not",
								"Greater than",
								"Less than",
								"Greater than or equal to",
								"Less than or equal to",
								"Starts with",
								"Ends with"
							].includes(res1.operation.label)
						) {
							signSymbol = res1.operation.value;
							filterSingleString =
								notValue +
								" " +
								res1.column.table +
								"." +
								res1.column.label +
								" " +
								signSymbol +
								" " +
								typeSymbol;
							appendedCode =
								notValue +
								" " +
								filterCol +
								" " +
								signSymbol +
								" " +
								typeSymbol;
						}

						if (["Is Empty", "Not Empty"].includes(res1.operation.label)) {
							signSymbol = res1.operation.value;
							filterSingleString =
								notValue +
								" " +
								res1.column.table +
								"." +
								res1.column.label +
								" " +
								signSymbol;
							appendedCode = notValue + " " + filterCol + " " + signSymbol;
						}
						appendedArray.push(appendedCode);
					}

					for (let param of appendedArray) {
						if (param == appendedArray[0]) {
							filtersinglequeryString = param;
						} else {
							if (param == filterSingleString) {
								filtermutiplequeryString =
									" " + res1.andorCondition.name + " " + param;
								filterMultArray.push(filtermutiplequeryString);
							}
						}
					}
				}
			}

			if (this.builderService.sqlQuery.join) {
				firsttable =
					this.visualQueryForm.controls["catalog"].value +
					"." +
					this.visualQueryForm.controls["schema"].value +
					"." +
					this.visualQueryForm.controls["table"].value;
				if (
					this.visualQueryForm.get("filters").value !== undefined &&
					this.visualQueryForm.get("filters").value.length > 0
				) {
					if (newArray !== undefined) {
						if (newColListing.length > 0) {
							if (filterMultArray.length > 0) {
								query =
									"SELECT" +
									" " +
									newArray +
									"," +
									" " +
									newColListing +
									" " +
									"\n" +
									"FROM" +
									"  " +
									firsttable +
									" " +
									newJoinListing.join("") +
									"\n" +
									"WHERE" +
									" " +
									filtersinglequeryString +
									" " +
									filterMultArray.join("");
							} else {
								if (filtersinglequeryString !== undefined) {
									query =
										"SELECT" +
										" " +
										newArray +
										"," +
										" " +
										newColListing +
										" " +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										" " +
										newJoinListing.join("") +
										"\n" +
										"WHERE" +
										" " +
										filtersinglequeryString;
								} else {
									query =
										"SELECT" +
										" " +
										newArray +
										"," +
										" " +
										newColListing +
										" " +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										" " +
										newJoinListing.join("");
								}
							}
						} else {
							if (filterMultArray.length > 0) {
								query =
									"SELECT" +
									" " +
									newArray +
									" " +
									"\n" +
									"FROM" +
									"  " +
									firsttable +
									" " +
									newJoinListing.join("") +
									"\n" +
									"WHERE" +
									" " +
									filtersinglequeryString +
									" " +
									filterMultArray.join("");
							} else {
								if (filtersinglequeryString !== undefined) {
									query =
										"SELECT" +
										" " +
										newArray +
										" " +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										" " +
										newJoinListing.join("") +
										"\n" +
										"WHERE" +
										" " +
										filtersinglequeryString;
								} else {
									query =
										"SELECT" +
										" " +
										newArray +
										" " +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										" " +
										newJoinListing.join("");
								}
							}
						}
					} else {
						if (newColListing.length > 0) {
							if (filterMultArray.length > 0) {
								query =
									"SELECT" +
									" " +
									newColListing +
									" " +
									"\n" +
									"FROM" +
									"  " +
									firsttable +
									" " +
									newJoinListing.join("") +
									"\n" +
									"WHERE" +
									" " +
									filtersinglequeryString +
									" " +
									filterMultArray.join("");
							} else {
								if (filtersinglequeryString !== undefined) {
									query =
										"SELECT" +
										" " +
										newColListing +
										" " +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										" " +
										newJoinListing.join("") +
										"\n" +
										"WHERE" +
										" " +
										filtersinglequeryString;
								} else {
									query =
										"SELECT" +
										" " +
										newColListing +
										" " +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										" " +
										newJoinListing.join("");
								}
							}
						} else {
							firsttable =
								this.visualQueryForm.controls["catalog"].value +
								"." +
								this.visualQueryForm.controls["schema"].value +
								"." +
								this.visualQueryForm.controls["table"].value;
							if (filterMultArray.length > 0) {
								query =
									"SELECT" +
									" " +
									firsttable +
									"." +
									" " +
									"*" +
									"\n" +
									"FROM" +
									"  " +
									firsttable +
									" " +
									newJoinListing.join("") +
									"\n" +
									"WHERE" +
									" " +
									filtersinglequeryString +
									" " +
									filterMultArray.join("");
							} else {
								if (filterSingleString !== undefined) {
									query =
										"SELECT" +
										" " +
										firsttable +
										"." +
										" " +
										"*" +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										" " +
										newJoinListing.join("") +
										"\n" +
										"WHERE" +
										" " +
										filtersinglequeryString;
								} else {
									query =
										"SELECT" +
										" " +
										firsttable +
										"." +
										" " +
										"*" +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										" " +
										newJoinListing.join("");
								}
							}
						}
					}
				} else {
					query =
						"SELECT" +
						" " +
						firsttable +
						"." +
						" " +
						"*" +
						"\n" +
						"FROM" +
						"  " +
						firsttable +
						" " +
						newJoinListing.join("");
				}

				if (groupBy) {
					if (groupBy.aggColumns.length > 1) {
						if (filterMultArray.length > 0) {
							if (newArray !== undefined) {
								if (newColListing.length > 0) {
									if (
										grpAggColumns &&
										grpAggColumns.length > 0 &&
										aggCountArr.length > 0
									) {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											newColListing +
											"," +
											" " +
											grpAggColumns +
											"," +
											aggCountArr +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("");
									} else if (aggCountArr.length > 0) {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											newColListing +
											"," +
											" " +
											aggCountArr +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("");
									} else {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											newColListing +
											"," +
											" " +
											grpAggColumns +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("");
									}
								} else {
									if (
										grpAggColumns &&
										grpAggColumns.length > 0 &&
										aggCountArr.length > 0
									) {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											grpAggColumns +
											"," +
											aggCountArr +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("");
									} else if (aggCountArr.length > 0) {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											aggCountArr +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("");
									} else {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											grpAggColumns +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("");
									}
								}
							} else {
								if (newColListing.length > 0) {
									if (
										grpAggColumns &&
										grpAggColumns.length > 0 &&
										aggCountArr.length > 0
									) {
										query =
											"SELECT" +
											" " +
											newColListing +
											"," +
											" " +
											grpAggColumns +
											"," +
											aggCountArr +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("");
									} else if (aggCountArr.length > 0) {
										query =
											"SELECT" +
											" " +
											newColListing +
											"," +
											" " +
											aggCountArr +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("");
									} else {
										query =
											"SELECT" +
											" " +
											newColListing +
											"," +
											" " +
											grpAggColumns +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("");
									}
								} else {
									if (
										grpAggColumns &&
										grpAggColumns.length > 0 &&
										aggCountArr.length > 0
									) {
										query =
											"SELECT" +
											" " +
											grpAggColumns +
											"," +
											aggCountArr +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("");
									} else if (aggCountArr.length > 0) {
										query =
											"SELECT" +
											" " +
											aggCountArr +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("");
									} else {
										query =
											"SELECT" +
											" " +
											grpAggColumns +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("");
									}
								}
							}
						} else {
							if (filtersinglequeryString !== undefined) {
								if (newArray !== undefined) {
									if (newColListing.length > 0) {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												newColListing +
												"," +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString;
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												newColListing +
												"," +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString;
										} else {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												newColListing +
												"," +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString;
										}
									} else {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString;
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString;
										} else {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString;
										}
									}
								} else {
									if (newColListing.length > 0) {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												newColListing +
												"," +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString;
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												newColListing +
												"," +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString;
										} else {
											query =
												"SELECT" +
												" " +
												newColListing +
												"," +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString;
										}
									} else {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString;
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString;
										} else {
											query =
												"SELECT" +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString;
										}
									}
								}
							} else {
								if (newArray !== undefined) {
									if (newColListing.length > 0) {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												newColListing +
												"," +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("");
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												newColListing +
												"," +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("");
										} else {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												newColListing +
												"," +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("");
										}
									} else {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												newArray +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("");
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												newArray +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("");
										} else {
											query =
												"SELECT" +
												" " +
												newArray +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("");
										}
									}
								} else {
									if (newColListing.length > 0) {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												newColListing +
												"," +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("");
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												newColListing +
												"," +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("");
										} else {
											query =
												"SELECT" +
												" " +
												newColListing +
												"," +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("");
										}
									} else {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("");
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("");
										} else {
											query =
												"SELECT" +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("");
										}
									}
								}
							}
						}
					}

					if (groupBy.groupBy.length > 1) {
						if (newArray !== undefined) {
							if (newColListing.length > 0) {
								if (filterMultArray.length > 0) {
									query =
										"SELECT" +
										" " +
										newArray +
										"," +
										" " +
										newColListing +
										" " +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										" " +
										newJoinListing.join("") +
										"\n" +
										"WHERE" +
										" " +
										filtersinglequeryString +
										" " +
										filterMultArray.join("") +
										"\n" +
										"GROUP" +
										" " +
										"BY" +
										" " +
										grpByArray;
								} else {
									if (filtersinglequeryString !== undefined) {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											newColListing +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									} else {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											newColListing +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									}
								}
							} else {
								if (filterMultArray.length > 0) {
									query =
										"SELECT" +
										" " +
										newArray +
										" " +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										" " +
										newJoinListing.join("") +
										"\n" +
										"WHERE" +
										" " +
										filtersinglequeryString +
										" " +
										filterMultArray.join("") +
										"\n" +
										"GROUP" +
										" " +
										"BY" +
										" " +
										grpByArray;
								} else {
									if (filtersinglequeryString !== undefined) {
										query =
											"SELECT" +
											" " +
											newArray +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									} else {
										query =
											"SELECT" +
											" " +
											newArray +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									}
								}
							}
						} else {
							firsttable =
								this.visualQueryForm.controls["catalog"].value +
								"." +
								this.visualQueryForm.controls["schema"].value +
								"." +
								this.visualQueryForm.controls["table"].value;
							if (newColListing.length > 0) {
								if (filterMultArray.length > 0) {
									query =
										"SELECT" +
										" " +
										newColListing +
										" " +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										" " +
										newJoinListing.join("") +
										"\n" +
										"WHERE" +
										" " +
										filtersinglequeryString +
										" " +
										filterMultArray.join("") +
										"\n" +
										"GROUP" +
										" " +
										"BY" +
										" " +
										grpByArray;
								} else {
									if (filtersinglequeryString !== undefined) {
										query =
											"SELECT" +
											" " +
											newColListing +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									} else {
										query =
											"SELECT" +
											" " +
											newColListing +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									}
								}
							} else {
								if (filterMultArray.length > 0) {
									query =
										"SELECT" +
										" " +
										firsttable +
										"." +
										" " +
										"*" +
										" " +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										" " +
										newJoinListing.join("") +
										"\n" +
										"WHERE" +
										" " +
										filtersinglequeryString +
										" " +
										filterMultArray.join("") +
										"\n" +
										"GROUP" +
										" " +
										"BY" +
										" " +
										grpByArray;
								} else {
									if (filtersinglequeryString !== undefined) {
										query =
											"SELECT" +
											" " +
											firsttable +
											"." +
											" " +
											"*" +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									} else {
										query =
											"SELECT" +
											" " +
											firsttable +
											"." +
											" " +
											"*" +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									}
								}
							}
						}
					}

					if (groupBy.aggColumns.length > 1 && groupBy.groupBy.length > 1) {
						if (newArray !== undefined) {
							if (filterMultArray.length > 0) {
								if (newColListing.length > 0) {
									if (
										grpAggColumns &&
										grpAggColumns.length > 0 &&
										aggCountArr.length > 0
									) {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											newColListing +
											"," +
											" " +
											grpAggColumns +
											"," +
											aggCountArr +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("") +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									} else if (aggCountArr.length > 0) {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											newColListing +
											"," +
											" " +
											aggCountArr +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("") +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									} else {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											newColListing +
											"," +
											" " +
											grpAggColumns +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("") +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									}
								} else {
									if (
										grpAggColumns &&
										grpAggColumns.length > 0 &&
										aggCountArr.length > 0
									) {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											grpAggColumns +
											"," +
											aggCountArr +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("") +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									} else if (aggCountArr.length > 0) {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											aggCountArr +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("") +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									} else {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											grpAggColumns +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("") +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									}
								}
							} else {
								if (filtersinglequeryString !== undefined) {
									if (newColListing.length > 0) {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												newColListing +
												"," +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												newColListing +
												"," +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										} else {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												newColListing +
												"," +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										}
									} else {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										} else {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										}
									}
								} else {
									if (newColListing.length > 0) {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												newColListing +
												"," +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												newColListing +
												"," +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										} else {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												newColListing +
												"," +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										}
									} else {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										} else {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										}
									}
								}
							}
						} else {
							if (filterMultArray.length > 0) {
								if (newColListing.length > 0) {
									if (
										grpAggColumns &&
										grpAggColumns.length > 0 &&
										aggCountArr.length > 0
									) {
										query =
											"SELECT" +
											" " +
											newColListing +
											"," +
											" " +
											grpAggColumns +
											"," +
											aggCountArr +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("") +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									} else if (aggCountArr.length > 0) {
										query =
											"SELECT" +
											" " +
											newColListing +
											"," +
											" " +
											aggCountArr +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("") +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									} else {
										query =
											"SELECT" +
											" " +
											newColListing +
											"," +
											" " +
											grpAggColumns +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("") +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									}
								} else {
									if (
										grpAggColumns &&
										grpAggColumns.length > 0 &&
										aggCountArr.length > 0
									) {
										query =
											"SELECT" +
											" " +
											grpAggColumns +
											"," +
											aggCountArr +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("") +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									} else if (aggCountArr.length > 0) {
										query =
											"SELECT" +
											" " +
											aggCountArr +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("") +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									} else {
										query =
											"SELECT" +
											" " +
											grpAggColumns +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("") +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									}
								}
							} else {
								if (filtersinglequeryString !== undefined) {
									if (newColListing.length > 0) {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												newColListing +
												"," +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												newColListing +
												"," +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										} else {
											query =
												"SELECT" +
												" " +
												newColListing +
												"," +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										}
									} else {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										} else {
											query =
												"SELECT" +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										}
									}
								} else {
									if (newColListing.length > 0) {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												newColListing +
												"," +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												newColListing +
												"," +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										} else {
											query =
												"SELECT" +
												" " +
												newColListing +
												"," +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										}
									} else {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										} else {
											query =
												"SELECT" +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										}
									}
								}
							}
						}
					}
				}

				if (sortBy) {
					if (newArray !== undefined) {
						if (newColListing.length > 0) {
							if (filterMultArray.length > 0) {
								if (sortByArray.length > 0) {
									query =
										"SELECT" +
										" " +
										newArray +
										"," +
										" " +
										newColListing +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										" " +
										newJoinListing.join("") +
										"\n" +
										"WHERE" +
										" " +
										filtersinglequeryString +
										" " +
										filterMultArray.join("") +
										"\n" +
										"ORDER BY" +
										" " +
										sortByArray;
								} else {
									query =
										"SELECT" +
										" " +
										newArray +
										"," +
										" " +
										newColListing +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										" " +
										newJoinListing.join("") +
										"\n" +
										"WHERE" +
										" " +
										filtersinglequeryString +
										" " +
										filterMultArray.join("") +
										"\n";
								}
							} else {
								if (filtersinglequeryString !== undefined) {
									if (sortByArray.length > 0) {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											newColListing +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											"\n" +
											"ORDER BY" +
											" " +
											sortByArray;
									} else {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											newColListing +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString;
									}
								} else {
									if (sortByArray.length > 0) {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											newColListing +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"ORDER BY" +
											" " +
											sortByArray;
									} else {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											newColListing +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("");
									}
								}
							}
						} else {
							if (filterMultArray.length > 0) {
								if (sortByArray.length > 0) {
									query =
										"SELECT" +
										" " +
										newArray +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										" " +
										newJoinListing.join("") +
										"\n" +
										"WHERE" +
										" " +
										filtersinglequeryString +
										" " +
										filterMultArray.join("") +
										"\n" +
										"ORDER BY" +
										" " +
										sortByArray;
								} else {
									query =
										"SELECT" +
										" " +
										newArray +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										" " +
										newJoinListing.join("") +
										"\n" +
										"WHERE" +
										" " +
										filtersinglequeryString +
										" " +
										filterMultArray.join("");
								}
							} else {
								if (filtersinglequeryString !== undefined) {
									if (sortByArray.length > 0) {
										query =
											"SELECT" +
											" " +
											newArray +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											"\n" +
											"ORDER BY" +
											" " +
											sortByArray;
									} else {
										query =
											"SELECT" +
											" " +
											newArray +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											"\n";
									}
								} else {
									if (sortByArray.length > 0) {
										query =
											"SELECT" +
											" " +
											newArray +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"ORDER BY" +
											" " +
											sortByArray;
									} else {
										query =
											"SELECT" +
											" " +
											newArray +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("");
									}
								}
							}
						}
					} else {
						if (newColListing.length > 0) {
							if (filterMultArray.length > 0) {
								if (sortByArray.length > 0) {
									query =
										"SELECT" +
										" " +
										newColListing +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										" " +
										newJoinListing.join("") +
										"\n" +
										"WHERE" +
										" " +
										filtersinglequeryString +
										" " +
										filterMultArray.join("") +
										"\n" +
										"ORDER BY" +
										" " +
										sortByArray;
								} else {
									query =
										"SELECT" +
										" " +
										newColListing +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										" " +
										newJoinListing.join("") +
										"\n" +
										"WHERE" +
										" " +
										filtersinglequeryString +
										" " +
										filterMultArray.join("");
								}
							} else {
								if (filtersinglequeryString !== undefined) {
									if (sortByArray.length > 0) {
										query =
											"SELECT" +
											" " +
											newColListing +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											"\n" +
											"ORDER BY" +
											" " +
											sortByArray;
									} else {
										query =
											"SELECT" +
											" " +
											newColListing +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString;
									}
								} else {
									if (sortByArray.length > 0) {
										query =
											"SELECT" +
											" " +
											newColListing +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"ORDER BY" +
											" " +
											sortByArray;
									} else {
										query =
											"SELECT" +
											" " +
											newColListing +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("");
									}
								}
							}
						} else {
							if (filterMultArray.length > 0) {
								if (sortByArray.length > 0) {
									query =
										"SELECT" +
										" " +
										firsttable +
										"." +
										" " +
										"*" +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										" " +
										newJoinListing.join("") +
										"\n" +
										"WHERE" +
										" " +
										filtersinglequeryString +
										" " +
										filterMultArray.join("") +
										"\n" +
										"ORDER BY" +
										" " +
										sortByArray;
								} else {
									query =
										"SELECT" +
										" " +
										firsttable +
										"." +
										" " +
										"*" +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										" " +
										newJoinListing.join("") +
										"\n" +
										"WHERE" +
										" " +
										filtersinglequeryString +
										" " +
										filterMultArray.join("");
								}
							} else {
								if (filtersinglequeryString !== undefined) {
									if (sortByArray.length > 0) {
										query =
											"SELECT" +
											" " +
											firsttable +
											"." +
											" " +
											"*" +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											"\n" +
											"ORDER BY" +
											" " +
											sortByArray;
									} else {
										query =
											"SELECT" +
											" " +
											firsttable +
											"." +
											" " +
											"*" +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString;
									}
								} else {
									if (sortByArray.length > 0) {
										query =
											"SELECT" +
											" " +
											firsttable +
											"." +
											" " +
											"*" +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"ORDER BY" +
											" " +
											sortByArray;
									} else {
										query =
											"SELECT" +
											" " +
											firsttable +
											"." +
											" " +
											"*" +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("");
									}
								}
							}
						}
					}
				}
				if (groupBy && sortBy) {
					if (groupBy.aggColumns.length > 1) {
						if (newArray !== undefined) {
							if (newColListing.length > 0) {
								if (filterMultArray.length > 0) {
									if (sortByArray.length > 0) {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												newColListing +
												"," +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												" " +
												filterMultArray.join("") +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												newColListing +
												"," +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												" " +
												filterMultArray.join("") +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										} else {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												newColListing +
												"," +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												" " +
												filterMultArray.join("") +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										}
									} else {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												newColListing +
												"," +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												" " +
												filterMultArray.join("");
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												newColListing +
												"," +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												" " +
												filterMultArray.join("");
										} else {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												newColListing +
												"," +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												" " +
												filterMultArray.join("");
										}
									}
								} else {
									if (filtersinglequeryString !== undefined) {
										if (sortByArray.length > 0) {
											if (
												grpAggColumns &&
												grpAggColumns.length > 0 &&
												aggCountArr.length > 0
											) {
												query =
													"SELECT" +
													" " +
													newArray +
													"," +
													" " +
													newColListing +
													"," +
													" " +
													grpAggColumns +
													"," +
													aggCountArr +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("") +
													"\n" +
													"WHERE" +
													" " +
													filtersinglequeryString +
													"\n" +
													"ORDER BY" +
													" " +
													sortByArray;
											} else if (aggCountArr.length > 0) {
												query =
													"SELECT" +
													" " +
													newArray +
													"," +
													" " +
													newColListing +
													"," +
													" " +
													aggCountArr +
													" " +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("") +
													"\n" +
													"WHERE" +
													" " +
													filtersinglequeryString +
													"\n" +
													"ORDER BY" +
													" " +
													sortByArray;
											} else {
												query =
													"SELECT" +
													" " +
													newArray +
													"," +
													" " +
													newColListing +
													"," +
													" " +
													grpAggColumns +
													" " +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("") +
													"\n" +
													"WHERE" +
													" " +
													filtersinglequeryString +
													"\n" +
													"ORDER BY" +
													" " +
													sortByArray;
											}
										} else {
											if (
												grpAggColumns &&
												grpAggColumns.length > 0 &&
												aggCountArr.length > 0
											) {
												query =
													"SELECT" +
													" " +
													newArray +
													"," +
													" " +
													newColListing +
													"," +
													" " +
													grpAggColumns +
													"," +
													aggCountArr +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("") +
													"\n" +
													"WHERE" +
													" " +
													filtersinglequeryString +
													"\n";
											} else if (aggCountArr.length > 0) {
												query =
													"SELECT" +
													" " +
													newArray +
													"," +
													" " +
													newColListing +
													"," +
													" " +
													aggCountArr +
													" " +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("") +
													"\n" +
													"WHERE" +
													" " +
													filtersinglequeryString +
													"\n";
											} else {
												query =
													"SELECT" +
													" " +
													newArray +
													"," +
													" " +
													newColListing +
													"," +
													" " +
													grpAggColumns +
													" " +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("") +
													"\n" +
													"WHERE" +
													" " +
													filtersinglequeryString +
													"\n";
											}
										}
									} else {
										if (sortByArray.length > 0) {
											if (
												grpAggColumns &&
												grpAggColumns.length > 0 &&
												aggCountArr.length > 0
											) {
												query =
													"SELECT" +
													" " +
													newArray +
													"," +
													" " +
													newColListing +
													"," +
													" " +
													grpAggColumns +
													"," +
													aggCountArr +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("") +
													"\n" +
													"ORDER BY" +
													" " +
													sortByArray;
											} else if (aggCountArr.length > 0) {
												query =
													"SELECT" +
													" " +
													newArray +
													"," +
													" " +
													newColListing +
													"," +
													" " +
													aggCountArr +
													" " +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("") +
													"\n" +
													"ORDER BY" +
													" " +
													sortByArray;
											} else {
												query =
													"SELECT" +
													" " +
													newArray +
													"," +
													" " +
													newColListing +
													"," +
													" " +
													grpAggColumns +
													" " +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("") +
													"\n" +
													"ORDER BY" +
													" " +
													sortByArray;
											}
										} else {
											if (
												grpAggColumns &&
												grpAggColumns.length > 0 &&
												aggCountArr.length > 0
											) {
												query =
													"SELECT" +
													" " +
													newArray +
													"," +
													" " +
													newColListing +
													"," +
													" " +
													grpAggColumns +
													"," +
													aggCountArr +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("");
											} else if (aggCountArr.length > 0) {
												query =
													"SELECT" +
													" " +
													newArray +
													"," +
													" " +
													newColListing +
													"," +
													" " +
													aggCountArr +
													" " +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("");
											} else {
												query =
													"SELECT" +
													" " +
													newArray +
													"," +
													" " +
													newColListing +
													"," +
													" " +
													grpAggColumns +
													" " +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("");
											}
										}
									}
								}
							}
						} else {
							if (newColListing.length > 0) {
								if (filterMultArray.length > 0) {
									if (sortByArray.length > 0) {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												newColListing +
												"," +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												" " +
												filterMultArray.join("") +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												newColListing +
												"," +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												" " +
												filterMultArray.join("") +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										} else {
											query =
												"SELECT" +
												" " +
												newColListing +
												"," +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												" " +
												filterMultArray.join("") +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										}
									} else {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												newColListing +
												"," +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												" " +
												filterMultArray.join("");
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												newColListing +
												"," +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												" " +
												filterMultArray.join("");
										} else {
											query =
												"SELECT" +
												" " +
												newColListing +
												"," +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												" " +
												filterMultArray.join("");
										}
									}
								} else {
									if (filtersinglequeryString !== undefined) {
										if (sortByArray.length > 0) {
											if (
												grpAggColumns &&
												grpAggColumns.length > 0 &&
												aggCountArr.length > 0
											) {
												query =
													"SELECT" +
													" " +
													newColListing +
													"," +
													" " +
													grpAggColumns +
													"," +
													aggCountArr +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("") +
													"\n" +
													"WHERE" +
													" " +
													filtersinglequeryString +
													"\n" +
													"ORDER BY" +
													" " +
													sortByArray;
											} else if (aggCountArr.length > 0) {
												query =
													"SELECT" +
													" " +
													newColListing +
													"," +
													" " +
													aggCountArr +
													" " +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("") +
													"\n" +
													"WHERE" +
													" " +
													filtersinglequeryString +
													"\n" +
													"ORDER BY" +
													" " +
													sortByArray;
											} else {
												query =
													"SELECT" +
													" " +
													newColListing +
													"," +
													" " +
													grpAggColumns +
													" " +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("") +
													"\n" +
													"WHERE" +
													" " +
													filtersinglequeryString +
													"\n" +
													"ORDER BY" +
													" " +
													sortByArray;
											}
										} else {
											if (
												grpAggColumns &&
												grpAggColumns.length > 0 &&
												aggCountArr.length > 0
											) {
												query =
													"SELECT" +
													" " +
													newColListing +
													"," +
													" " +
													grpAggColumns +
													"," +
													aggCountArr +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("") +
													"\n" +
													"WHERE" +
													" " +
													filtersinglequeryString;
											} else if (aggCountArr.length > 0) {
												query =
													"SELECT" +
													" " +
													newColListing +
													"," +
													" " +
													aggCountArr +
													" " +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("") +
													"\n" +
													"WHERE" +
													" " +
													filtersinglequeryString;
											} else {
												query =
													"SELECT" +
													" " +
													newColListing +
													"," +
													" " +
													grpAggColumns +
													" " +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("") +
													"\n" +
													"WHERE" +
													" " +
													filtersinglequeryString;
											}
										}
									} else {
										if (sortByArray.length > 0) {
											if (
												grpAggColumns &&
												grpAggColumns.length > 0 &&
												aggCountArr.length > 0
											) {
												query =
													"SELECT" +
													" " +
													newColListing +
													"," +
													" " +
													grpAggColumns +
													"," +
													aggCountArr +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("") +
													"\n" +
													"ORDER BY" +
													" " +
													sortByArray;
											} else if (aggCountArr.length > 0) {
												query =
													"SELECT" +
													" " +
													newColListing +
													"," +
													" " +
													aggCountArr +
													" " +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("") +
													"\n" +
													"ORDER BY" +
													" " +
													sortByArray;
											} else {
												query =
													"SELECT" +
													" " +
													newColListing +
													"," +
													" " +
													grpAggColumns +
													" " +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("") +
													"\n" +
													"ORDER BY" +
													" " +
													sortByArray;
											}
										} else {
											if (
												grpAggColumns &&
												grpAggColumns.length > 0 &&
												aggCountArr.length > 0
											) {
												query =
													"SELECT" +
													" " +
													newColListing +
													"," +
													" " +
													grpAggColumns +
													"," +
													aggCountArr +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("");
											} else if (aggCountArr.length > 0) {
												query =
													"SELECT" +
													" " +
													newColListing +
													"," +
													" " +
													aggCountArr +
													" " +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("");
											} else {
												query =
													"SELECT" +
													" " +
													newColListing +
													"," +
													" " +
													grpAggColumns +
													" " +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("");
											}
										}
									}
								}
							} else {
								if (filterMultArray.length > 0) {
									if (sortByArray.length > 0) {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												" " +
												filterMultArray.join("") +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												" " +
												filterMultArray.join("") +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										} else {
											query =
												"SELECT" +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												" " +
												filterMultArray.join("") +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										}
									} else {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												" " +
												filterMultArray.join("");
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												" " +
												filterMultArray.join("");
										} else {
											query =
												"SELECT" +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												" " +
												filterMultArray.join("");
										}
									}
								} else {
									if (filtersinglequeryString !== undefined) {
										if (sortByArray.length > 0) {
											if (
												grpAggColumns &&
												grpAggColumns.length > 0 &&
												aggCountArr.length > 0
											) {
												query =
													"SELECT" +
													" " +
													grpAggColumns +
													"," +
													aggCountArr +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("") +
													"\n" +
													"WHERE" +
													" " +
													filtersinglequeryString +
													"\n" +
													"ORDER BY" +
													" " +
													sortByArray;
											} else if (aggCountArr.length > 0) {
												query =
													"SELECT" +
													" " +
													aggCountArr +
													" " +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("") +
													"\n" +
													"WHERE" +
													" " +
													filtersinglequeryString +
													"\n" +
													"ORDER BY" +
													" " +
													sortByArray;
											} else {
												query =
													"SELECT" +
													" " +
													grpAggColumns +
													" " +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("") +
													"\n" +
													"WHERE" +
													" " +
													filtersinglequeryString +
													"\n" +
													"ORDER BY" +
													" " +
													sortByArray;
											}
										} else {
											if (
												grpAggColumns &&
												grpAggColumns.length > 0 &&
												aggCountArr.length > 0
											) {
												query =
													"SELECT" +
													" " +
													grpAggColumns +
													"," +
													aggCountArr +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("") +
													"\n" +
													"WHERE" +
													" " +
													filtersinglequeryString;
											} else if (aggCountArr.length > 0) {
												query =
													"SELECT" +
													" " +
													aggCountArr +
													" " +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("") +
													"\n" +
													"WHERE" +
													" " +
													filtersinglequeryString;
											} else {
												query =
													"SELECT" +
													" " +
													grpAggColumns +
													" " +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("") +
													"\n" +
													"WHERE" +
													" " +
													filtersinglequeryString;
											}
										}
									} else {
										if (sortByArray.length > 0) {
											if (
												grpAggColumns &&
												grpAggColumns.length > 0 &&
												aggCountArr.length > 0
											) {
												query =
													"SELECT" +
													" " +
													grpAggColumns +
													"," +
													aggCountArr +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("") +
													"\n" +
													"ORDER BY" +
													" " +
													sortByArray;
											} else if (aggCountArr.length > 0) {
												query =
													"SELECT" +
													" " +
													aggCountArr +
													" " +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("") +
													"\n" +
													"ORDER BY" +
													" " +
													sortByArray;
											} else {
												query =
													"SELECT" +
													" " +
													grpAggColumns +
													" " +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("") +
													"\n" +
													"ORDER BY" +
													" " +
													sortByArray;
											}
										} else {
											if (
												grpAggColumns &&
												grpAggColumns.length > 0 &&
												aggCountArr.length > 0
											) {
												query =
													"SELECT" +
													" " +
													grpAggColumns +
													"," +
													aggCountArr +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("");
											} else if (aggCountArr.length > 0) {
												query =
													"SELECT" +
													" " +
													aggCountArr +
													" " +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("");
											} else {
												query =
													"SELECT" +
													" " +
													grpAggColumns +
													" " +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("");
											}
										}
									}
								}
							}
						}
					}
					if (groupBy.groupBy.length > 1) {
						if (newArray !== undefined) {
							if (newColListing.length > 0) {
								if (filterMultArray.length > 0) {
									if (sortByArray.length > 0) {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											newColListing +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("") +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray +
											"\n" +
											"ORDER BY" +
											" " +
											sortByArray;
									} else {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											newColListing +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("") +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									}
								} else {
									if (filtersinglequeryString !== undefined) {
										if (sortByArray.length > 0) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												newColListing +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										} else {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												newColListing +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										}
									} else {
										if (sortByArray.length > 0) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												newColListing +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										} else {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												newColListing +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										}
									}
								}
							} else {
								if (filterMultArray.length > 0) {
									if (sortByArray.length > 0) {
										query =
											"SELECT" +
											" " +
											newArray +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("") +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray +
											"\n" +
											"ORDER BY" +
											" " +
											sortByArray;
									} else {
										query =
											"SELECT" +
											" " +
											newArray +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("") +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									}
								} else {
									if (filtersinglequeryString !== undefined) {
										if (sortByArray.length > 0) {
											query =
												"SELECT" +
												" " +
												newArray +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										} else {
											query =
												"SELECT" +
												" " +
												newArray +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										}
									} else {
										if (sortByArray.length > 0) {
											query =
												"SELECT" +
												" " +
												newArray +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										} else {
											query =
												"SELECT" +
												" " +
												newArray +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										}
									}
								}
							}
						} else {
							if (newColListing.length > 0) {
								if (filterMultArray.length > 0) {
									if (sortByArray.length > 0) {
										query =
											"SELECT" +
											" " +
											newColListing +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("") +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray +
											"\n" +
											"ORDER BY" +
											" " +
											sortByArray;
									} else {
										query =
											"SELECT" +
											" " +
											newColListing +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("") +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									}
								} else {
									if (filtersinglequeryString !== undefined) {
										if (sortByArray.length > 0) {
											query =
												"SELECT" +
												" " +
												newColListing +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										} else {
											query =
												"SELECT" +
												" " +
												newColListing +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										}
									} else {
										if (sortByArray.length > 0) {
											query =
												"SELECT" +
												" " +
												newColListing +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										} else {
											query =
												"SELECT" +
												" " +
												newColListing +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										}
									}
								}
							} else {
								if (filterMultArray.length > 0) {
									if (sortByArray.length > 0) {
										query =
											"SELECT" +
											" " +
											firsttable +
											"." +
											" " +
											"*" +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("") +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray +
											"\n" +
											"ORDER BY" +
											" " +
											sortByArray;
									} else {
										query =
											"SELECT" +
											" " +
											firsttable +
											"." +
											" " +
											"*" +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											" " +
											newJoinListing.join("") +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("") +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									}
								} else {
									if (filtersinglequeryString !== undefined) {
										if (sortByArray.length > 0) {
											query =
												"SELECT" +
												" " +
												firsttable +
												"." +
												" " +
												"*" +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										} else {
											query =
												"SELECT" +
												" " +
												firsttable +
												"." +
												" " +
												"*" +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										}
									} else {
										if (sortByArray.length > 0) {
											query =
												"SELECT" +
												" " +
												firsttable +
												"." +
												" " +
												"*" +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										} else {
											query =
												"SELECT" +
												" " +
												firsttable +
												"." +
												" " +
												"*" +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										}
									}
								}
							}
						}
					}

					if (groupBy.aggColumns.length > 1 && groupBy.groupBy.length > 1) {
						if (newArray !== undefined) {
							if (filterMultArray.length > 0) {
								if (newColListing.length > 0) {
									if (sortByArray.length > 0) {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												newColListing +
												"," +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												" " +
												filterMultArray.join("") +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												newColListing +
												"," +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												" " +
												filterMultArray.join("") +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										} else {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												newColListing +
												"," +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												" " +
												filterMultArray.join("") +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										}
									} else {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												newColListing +
												"," +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												" " +
												filterMultArray.join("") +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												newColListing +
												"," +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												" " +
												filterMultArray.join("") +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										} else {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												newColListing +
												"," +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												" " +
												filterMultArray.join("") +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										}
									}
								} else {
									if (sortByArray.length > 0) {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												" " +
												filterMultArray.join("") +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												" " +
												filterMultArray.join("") +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										} else {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												" " +
												filterMultArray.join("") +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										}
									} else {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												" " +
												filterMultArray.join("") +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												" " +
												filterMultArray.join("") +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										} else {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												" " +
												filterMultArray.join("") +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										}
									}
								}
							} else {
								if (filtersinglequeryString !== undefined) {
									if (newColListing.length > 0) {
										if (sortByArray.length > 0) {
											if (
												grpAggColumns &&
												grpAggColumns.length > 0 &&
												aggCountArr.length > 0
											) {
												query =
													"SELECT" +
													" " +
													newArray +
													"," +
													" " +
													newColListing +
													"," +
													" " +
													grpAggColumns +
													"," +
													aggCountArr +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("") +
													"\n" +
													"WHERE" +
													" " +
													filtersinglequeryString +
													"\n" +
													"GROUP" +
													" " +
													"BY" +
													" " +
													grpByArray +
													"\n" +
													"ORDER BY" +
													" " +
													sortByArray;
											} else if (aggCountArr.length > 0) {
												query =
													"SELECT" +
													" " +
													newArray +
													"," +
													" " +
													newColListing +
													"," +
													" " +
													aggCountArr +
													" " +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("") +
													"\n" +
													"WHERE" +
													" " +
													filtersinglequeryString +
													"\n" +
													"GROUP" +
													" " +
													"BY" +
													" " +
													grpByArray +
													"\n" +
													"ORDER BY" +
													" " +
													sortByArray;
											} else {
												query =
													"SELECT" +
													" " +
													newArray +
													"," +
													" " +
													newColListing +
													"," +
													" " +
													grpAggColumns +
													" " +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("") +
													"\n" +
													"WHERE" +
													" " +
													filtersinglequeryString +
													"\n" +
													"GROUP" +
													" " +
													"BY" +
													" " +
													grpByArray +
													"\n" +
													"ORDER BY" +
													" " +
													sortByArray;
											}
										} else {
											if (
												grpAggColumns &&
												grpAggColumns.length > 0 &&
												aggCountArr.length > 0
											) {
												query =
													"SELECT" +
													" " +
													newArray +
													"," +
													" " +
													newColListing +
													"," +
													" " +
													grpAggColumns +
													"," +
													aggCountArr +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("") +
													"\n" +
													"WHERE" +
													" " +
													filtersinglequeryString +
													"\n" +
													"GROUP" +
													" " +
													"BY" +
													" " +
													grpByArray;
											} else if (aggCountArr.length > 0) {
												query =
													"SELECT" +
													" " +
													newArray +
													"," +
													" " +
													newColListing +
													"," +
													" " +
													aggCountArr +
													" " +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("") +
													"\n" +
													"WHERE" +
													" " +
													filtersinglequeryString +
													"\n" +
													"GROUP" +
													" " +
													"BY" +
													" " +
													grpByArray;
											} else {
												query =
													"SELECT" +
													" " +
													newArray +
													"," +
													" " +
													newColListing +
													"," +
													" " +
													grpAggColumns +
													" " +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("") +
													"\n" +
													"WHERE" +
													" " +
													filtersinglequeryString +
													"\n" +
													"GROUP" +
													" " +
													"BY" +
													" " +
													grpByArray;
											}
										}
									} else {
										if (sortByArray.length > 0) {
											if (
												grpAggColumns &&
												grpAggColumns.length > 0 &&
												aggCountArr.length > 0
											) {
												query =
													"SELECT" +
													" " +
													newArray +
													"," +
													" " +
													grpAggColumns +
													"," +
													aggCountArr +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("") +
													"\n" +
													"WHERE" +
													" " +
													filtersinglequeryString +
													"\n" +
													"GROUP" +
													" " +
													"BY" +
													" " +
													grpByArray +
													"\n" +
													"ORDER BY" +
													" " +
													sortByArray;
											} else if (aggCountArr.length > 0) {
												query =
													"SELECT" +
													" " +
													newArray +
													"," +
													" " +
													aggCountArr +
													" " +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("") +
													"\n" +
													"WHERE" +
													" " +
													filtersinglequeryString +
													"\n" +
													"GROUP" +
													" " +
													"BY" +
													" " +
													grpByArray +
													"\n" +
													"ORDER BY" +
													" " +
													sortByArray;
											} else {
												query =
													"SELECT" +
													" " +
													newArray +
													"," +
													" " +
													grpAggColumns +
													" " +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("") +
													"\n" +
													"WHERE" +
													" " +
													filtersinglequeryString +
													"\n" +
													"GROUP" +
													" " +
													"BY" +
													" " +
													grpByArray +
													"\n" +
													"ORDER BY" +
													" " +
													sortByArray;
											}
										} else {
											if (
												grpAggColumns &&
												grpAggColumns.length > 0 &&
												aggCountArr.length > 0
											) {
												query =
													"SELECT" +
													" " +
													newArray +
													"," +
													" " +
													grpAggColumns +
													"," +
													aggCountArr +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("") +
													"\n" +
													"WHERE" +
													" " +
													filtersinglequeryString +
													"\n" +
													"GROUP" +
													" " +
													"BY" +
													" " +
													grpByArray;
											} else if (aggCountArr.length > 0) {
												query =
													"SELECT" +
													" " +
													newArray +
													"," +
													" " +
													aggCountArr +
													" " +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("") +
													"\n" +
													"WHERE" +
													" " +
													filtersinglequeryString +
													"\n" +
													"GROUP" +
													" " +
													"BY" +
													" " +
													grpByArray;
											} else {
												query =
													"SELECT" +
													" " +
													newArray +
													"," +
													" " +
													grpAggColumns +
													" " +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													" " +
													newJoinListing.join("") +
													"\n" +
													"WHERE" +
													" " +
													filtersinglequeryString +
													"\n" +
													"GROUP" +
													" " +
													"BY" +
													" " +
													grpByArray;
											}
										}
									}
								} else {
									if (sortByArray.length > 0) {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										} else {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										}
									} else {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										} else {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												" " +
												newJoinListing.join("") +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										}
									}
								}
							}
						}
					}
				}
			} else {
				firsttable =
					this.visualQueryForm.controls["catalog"].value +
					"." +
					this.visualQueryForm.controls["schema"].value +
					"." +
					this.visualQueryForm.controls["table"].value;

				if (
					this.visualQueryForm.get("filters").value !== undefined &&
					this.visualQueryForm.get("filters").value.length > 0
				) {
					if (newArray !== undefined) {
						if (filterMultArray.length > 0) {
							query =
								"SELECT" +
								" " +
								newArray +
								"\n" +
								"FROM" +
								" " +
								firsttable +
								" " +
								"\n" +
								"WHERE" +
								" " +
								filtersinglequeryString +
								" " +
								filterMultArray.join("");
						} else {
							if (filtersinglequeryString !== undefined) {
								query =
									"SELECT" +
									" " +
									newArray +
									"\n" +
									"FROM" +
									" " +
									firsttable +
									" " +
									"\n" +
									"WHERE" +
									" " +
									filtersinglequeryString;
							} else {
								query =
									"SELECT" + " " + newArray + "\n" + "FROM" + " " + firsttable;
							}
						}
					} else {
						if (filterMultArray.length > 0) {
							query =
								"SELECT" +
								" " +
								firsttable +
								"." +
								" " +
								"*" +
								"\n" +
								"FROM" +
								" " +
								firsttable +
								" " +
								"\n" +
								"WHERE" +
								" " +
								filtersinglequeryString +
								" " +
								filterMultArray.join();
						} else {
							if (filtersinglequeryString !== undefined) {
								query =
									"SELECT" +
									" " +
									firsttable +
									"." +
									" " +
									"*" +
									"\n" +
									"FROM" +
									" " +
									firsttable +
									" " +
									"\n" +
									"WHERE" +
									" " +
									filtersinglequeryString;
							} else {
								query =
									"SELECT" +
									" " +
									firsttable +
									"." +
									" " +
									"*" +
									"\n" +
									"FROM" +
									" " +
									firsttable;
							}
						}
					}
				} else {
					query =
						"SELECT" +
						" " +
						firsttable +
						"." +
						" " +
						"*" +
						"\n" +
						"FROM" +
						" " +
						firsttable;
				}
				if (groupBy) {
					if (groupBy.aggColumns.length > 1) {
						if (filterMultArray.length > 0) {
							if (newArray !== undefined) {
								if (
									grpAggColumns &&
									grpAggColumns.length > 0 &&
									aggCountArr.length > 0
								) {
									query =
										"SELECT" +
										" " +
										newArray +
										"," +
										" " +
										grpAggColumns +
										"," +
										aggCountArr +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										"\n" +
										"WHERE" +
										" " +
										filtersinglequeryString +
										" " +
										filterMultArray.join("");
								} else if (aggCountArr.length > 0) {
									query =
										"SELECT" +
										" " +
										newArray +
										"," +
										" " +
										aggCountArr +
										" " +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										"\n" +
										"WHERE" +
										" " +
										filtersinglequeryString +
										" " +
										filterMultArray.join("");
								} else {
									query =
										"SELECT" +
										" " +
										newArray +
										"," +
										" " +
										grpAggColumns +
										" " +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										"\n" +
										"WHERE" +
										" " +
										filtersinglequeryString +
										" " +
										filterMultArray.join("");
								}
							} else {
								if (
									grpAggColumns &&
									grpAggColumns.length > 0 &&
									aggCountArr.length > 0
								) {
									query =
										"SELECT" +
										" " +
										grpAggColumns +
										"," +
										aggCountArr +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										"\n" +
										"WHERE" +
										" " +
										filtersinglequeryString +
										" " +
										filterMultArray.join("");
								} else if (aggCountArr.length > 0) {
									query =
										"SELECT" +
										" " +
										aggCountArr +
										" " +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										"\n" +
										"WHERE" +
										" " +
										filtersinglequeryString +
										" " +
										filterMultArray.join("");
								} else {
									query =
										"SELECT" +
										" " +
										grpAggColumns +
										" " +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										"\n" +
										"WHERE" +
										" " +
										filtersinglequeryString +
										" " +
										filterMultArray.join("");
								}
							}
						} else {
							if (filtersinglequeryString !== undefined) {
								if (newArray !== undefined) {
									if (
										grpAggColumns &&
										grpAggColumns.length > 0 &&
										aggCountArr.length > 0
									) {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											grpAggColumns +
											"," +
											aggCountArr +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString;
									} else if (aggCountArr.length > 0) {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											aggCountArr +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString;
									} else {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											grpAggColumns +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString;
									}
								} else {
									if (
										grpAggColumns &&
										grpAggColumns.length > 0 &&
										aggCountArr.length > 0
									) {
										query =
											"SELECT" +
											" " +
											grpAggColumns +
											"," +
											aggCountArr +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString;
									} else if (aggCountArr.length > 0) {
										query =
											"SELECT" +
											" " +
											aggCountArr +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString;
									} else {
										query =
											"SELECT" +
											" " +
											grpAggColumns +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString;
									}
								}
							} else {
								if (newArray !== undefined) {
									if (
										grpAggColumns &&
										grpAggColumns.length > 0 &&
										aggCountArr.length > 0
									) {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											grpAggColumns +
											"," +
											aggCountArr +
											"\n" +
											"FROM" +
											"  " +
											firsttable;
									} else if (aggCountArr.length > 0) {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											aggCountArr +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable;
									} else {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											grpAggColumns +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable;
									}
								} else {
									if (
										grpAggColumns &&
										grpAggColumns.length > 0 &&
										aggCountArr.length > 0
									) {
										query =
											"SELECT" +
											" " +
											grpAggColumns +
											"," +
											aggCountArr +
											"\n" +
											"FROM" +
											"  " +
											firsttable;
									} else if (aggCountArr.length > 0) {
										query =
											"SELECT" +
											" " +
											aggCountArr +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable;
									} else {
										query =
											"SELECT" +
											" " +
											grpAggColumns +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable;
									}
								}
							}
						}
					}

					if (groupBy.groupBy.length > 1) {
						if (newArray !== undefined) {
							if (filterMultArray.length > 0) {
								query =
									"SELECT" +
									" " +
									newArray +
									" " +
									"\n" +
									"FROM" +
									"  " +
									firsttable +
									"\n" +
									"WHERE" +
									" " +
									filtersinglequeryString +
									" " +
									filterMultArray.join("") +
									"\n" +
									"GROUP" +
									" " +
									"BY" +
									" " +
									grpByArray;
							} else {
								if (filtersinglequeryString !== undefined) {
									query =
										"SELECT" +
										" " +
										newArray +
										" " +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										"\n" +
										"WHERE" +
										" " +
										filtersinglequeryString +
										"\n" +
										"GROUP" +
										" " +
										"BY" +
										" " +
										grpByArray;
								} else {
									query =
										"SELECT" +
										" " +
										newArray +
										" " +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										"\n" +
										"GROUP" +
										" " +
										"BY" +
										" " +
										grpByArray;
								}
							}
						} else {
							if (filterMultArray.length > 0) {
								query =
									"SELECT" +
									" " +
									grpCombo +
									" " +
									"\n" +
									"FROM" +
									"  " +
									firsttable +
									"\n" +
									"WHERE" +
									" " +
									filtersinglequeryString +
									" " +
									filterMultArray.join("") +
									"\n" +
									"GROUP" +
									" " +
									"BY" +
									" " +
									grpByArray;
							} else {
								if (filtersinglequeryString !== undefined) {
									query =
										"SELECT" +
										" " +
										grpCombo +
										" " +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										"\n" +
										"WHERE" +
										" " +
										filtersinglequeryString +
										"\n" +
										"GROUP" +
										" " +
										"BY" +
										" " +
										grpByArray;
								} else {
									query =
										"SELECT" +
										" " +
										grpCombo +
										" " +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										"\n" +
										"GROUP" +
										" " +
										"BY" +
										" " +
										grpByArray;
								}
							}
						}
					}

					if (groupBy.aggColumns.length > 1 && groupBy.groupBy.length > 1) {
						if (newArray !== undefined) {
							if (filterMultArray.length > 0) {
								if (
									grpAggColumns &&
									grpAggColumns.length > 0 &&
									aggCountArr.length > 0
								) {
									query =
										"SELECT" +
										" " +
										newArray +
										"," +
										" " +
										grpAggColumns +
										"," +
										aggCountArr +
										" " +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										"\n" +
										"WHERE" +
										" " +
										filtersinglequeryString +
										" " +
										filterMultArray.join("") +
										"\n" +
										"GROUP" +
										" " +
										"BY" +
										" " +
										grpByArray;
								} else if (aggCountArr.length > 0) {
									query =
										"SELECT" +
										" " +
										newArray +
										"," +
										" " +
										aggCountArr +
										" " +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										"\n" +
										"WHERE" +
										" " +
										filtersinglequeryString +
										" " +
										filterMultArray.join("") +
										"\n" +
										"GROUP" +
										" " +
										"BY" +
										" " +
										grpByArray;
								} else {
									query =
										"SELECT" +
										" " +
										newArray +
										"," +
										" " +
										grpAggColumns +
										" " +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										"\n" +
										"WHERE" +
										" " +
										filtersinglequeryString +
										" " +
										filterMultArray.join("") +
										"\n" +
										"GROUP" +
										" " +
										"BY" +
										" " +
										grpByArray;
								}
							} else {
								if (filtersinglequeryString !== undefined) {
									if (
										grpAggColumns &&
										grpAggColumns.length > 0 &&
										aggCountArr.length > 0
									) {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											grpAggColumns +
											"," +
											aggCountArr +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									} else if (aggCountArr.length > 0) {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											aggCountArr +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									} else {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											grpAggColumns +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									}
								} else {
									if (
										grpAggColumns &&
										grpAggColumns.length > 0 &&
										aggCountArr.length > 0
									) {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											grpAggColumns +
											"," +
											aggCountArr +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									} else if (aggCountArr.length > 0) {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											aggCountArr +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									} else {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											grpAggColumns +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									}
								}
							}
						} else {
							if (filterMultArray.length > 0) {
								if (
									grpAggColumns &&
									grpAggColumns.length > 0 &&
									aggCountArr.length > 0
								) {
									query =
										"SELECT" +
										" " +
										grpAggColumns +
										"," +
										aggCountArr +
										" " +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										"\n" +
										"WHERE" +
										" " +
										filtersinglequeryString +
										" " +
										filterMultArray.join("") +
										"\n" +
										"GROUP" +
										" " +
										"BY" +
										" " +
										grpByArray;
								} else if (aggCountArr.length > 0) {
									query =
										"SELECT" +
										" " +
										aggCountArr +
										" " +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										"\n" +
										"WHERE" +
										" " +
										filtersinglequeryString +
										" " +
										filterMultArray.join("") +
										"\n" +
										"GROUP" +
										" " +
										"BY" +
										" " +
										grpByArray;
								} else {
									query =
										"SELECT" +
										" " +
										grpAggColumns +
										" " +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										"\n" +
										"WHERE" +
										" " +
										filtersinglequeryString +
										" " +
										filterMultArray.join("") +
										"\n" +
										"GROUP" +
										" " +
										"BY" +
										" " +
										grpByArray;
								}
							} else {
								if (filtersinglequeryString !== undefined) {
									if (
										grpAggColumns &&
										grpAggColumns.length > 0 &&
										aggCountArr.length > 0
									) {
										query =
											"SELECT" +
											" " +
											grpAggColumns +
											"," +
											aggCountArr +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									} else if (aggCountArr.length > 0) {
										query =
											"SELECT" +
											" " +
											aggCountArr +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									} else {
										query =
											"SELECT" +
											" " +
											grpAggColumns +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									}
								} else {
									if (
										grpAggColumns &&
										grpAggColumns.length > 0 &&
										aggCountArr.length > 0
									) {
										query =
											"SELECT" +
											" " +
											grpAggColumns +
											"," +
											aggCountArr +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									} else if (aggCountArr.length > 0) {
										query =
											"SELECT" +
											" " +
											aggCountArr +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									} else {
										query =
											"SELECT" +
											" " +
											grpAggColumns +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									}
								}
							}
						}
					}
				}

				if (sortBy) {
					if (newArray !== undefined) {
						if (filterMultArray.length > 0) {
							if (sortByArray.length > 0) {
								query =
									"SELECT" +
									" " +
									newArray +
									"\n" +
									"FROM" +
									"  " +
									firsttable +
									"\n" +
									"WHERE" +
									" " +
									filtersinglequeryString +
									" " +
									filterMultArray.join("") +
									"\n" +
									"ORDER BY" +
									" " +
									sortByArray;
							} else {
								query =
									"SELECT" +
									" " +
									newArray +
									"\n" +
									"FROM" +
									"  " +
									firsttable +
									"\n" +
									"WHERE" +
									" " +
									filtersinglequeryString +
									" " +
									filterMultArray.join("");
							}
						} else {
							if (filtersinglequeryString !== undefined) {
								if (sortByArray.length > 0) {
									query =
										"SELECT" +
										" " +
										newArray +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										"\n" +
										"WHERE" +
										" " +
										filtersinglequeryString +
										"\n" +
										"ORDER BY" +
										" " +
										sortByArray;
								} else {
									query =
										"SELECT" +
										" " +
										newArray +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										"\n" +
										"WHERE" +
										" " +
										filtersinglequeryString;
								}
							} else {
								if (sortByArray.length > 0) {
									query =
										"SELECT" +
										" " +
										newArray +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										"\n" +
										"ORDER BY" +
										" " +
										sortByArray;
								} else {
									query =
										"SELECT" +
										" " +
										newArray +
										"\n" +
										"FROM" +
										"  " +
										firsttable;
								}
							}
						}
					} else {
						if (filterMultArray.length > 0) {
							if (sortByArray.length > 0) {
								query =
									"SELECT" +
									" " +
									firsttable +
									"." +
									" " +
									"*" +
									"\n" +
									"FROM" +
									"  " +
									firsttable +
									"\n" +
									"WHERE" +
									" " +
									filtersinglequeryString +
									" " +
									filterMultArray.join("") +
									"\n" +
									"ORDER BY" +
									" " +
									sortByArray;
							} else {
								query =
									"SELECT" +
									" " +
									firsttable +
									"." +
									" " +
									"*" +
									"\n" +
									"FROM" +
									"  " +
									firsttable +
									"\n" +
									"WHERE" +
									" " +
									filtersinglequeryString +
									" " +
									filterMultArray.join("");
							}
						} else {
							if (filtersinglequeryString !== undefined) {
								if (sortByArray.length > 0) {
									query =
										"SELECT" +
										" " +
										firsttable +
										"." +
										" " +
										"*" +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										"\n" +
										"WHERE" +
										" " +
										filtersinglequeryString +
										"\n" +
										"ORDER BY" +
										" " +
										sortByArray;
								} else {
									query =
										"SELECT" +
										" " +
										firsttable +
										"." +
										" " +
										"*" +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										"\n" +
										"WHERE" +
										" " +
										filtersinglequeryString;
								}
							} else {
								if (sortByArray.length > 0) {
									query =
										"SELECT" +
										" " +
										firsttable +
										"." +
										" " +
										"*" +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										"\n" +
										"ORDER BY" +
										" " +
										sortByArray;
								} else {
									query =
										"SELECT" +
										" " +
										firsttable +
										"." +
										" " +
										"*" +
										"\n" +
										"FROM" +
										"  " +
										firsttable;
								}
							}
						}
					}
				}

				if (sortBy && groupBy) {
					if (groupBy.aggColumns.length > 1) {
						if (newArray !== undefined) {
							if (filterMultArray.length > 0) {
								if (sortByArray.length > 0) {
									if (
										grpAggColumns &&
										grpAggColumns.length > 0 &&
										aggCountArr.length > 0
									) {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											grpAggColumns +
											"," +
											aggCountArr +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("") +
											"\n" +
											"ORDER BY" +
											" " +
											sortByArray;
									} else if (aggCountArr.length > 0) {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											aggCountArr +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("") +
											"\n" +
											"ORDER BY" +
											" " +
											sortByArray;
									} else {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											grpAggColumns +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("") +
											"\n" +
											"ORDER BY" +
											" " +
											sortByArray;
									}
								} else {
									if (
										grpAggColumns &&
										grpAggColumns.length > 0 &&
										aggCountArr.length > 0
									) {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											grpAggColumns +
											"," +
											aggCountArr +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("");
									} else if (aggCountArr.length > 0) {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											aggCountArr +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("");
									} else {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											grpAggColumns +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("");
									}
								}
							} else {
								if (filtersinglequeryString !== undefined) {
									if (sortByArray.length > 0) {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												"ORDER BY" +
												" " +
												sortByArray;
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												"ORDER BY" +
												" " +
												sortByArray;
										} else {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												"ORDER BY" +
												" " +
												sortByArray;
										}
									} else {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString;
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString;
										} else {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString;
										}
									}
								} else {
									if (sortByArray.length > 0) {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"ORDER BY" +
												" " +
												sortByArray;
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"ORDER BY" +
												" " +
												sortByArray;
										} else {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"ORDER BY" +
												" " +
												sortByArray;
										}
									} else {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable;
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable;
										} else {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable;
										}
									}
								}
							}
						} else {
							if (filterMultArray.length > 0) {
								if (sortByArray.length > 0) {
									if (
										grpAggColumns &&
										grpAggColumns.length > 0 &&
										aggCountArr.length > 0
									) {
										query =
											"SELECT" +
											" " +
											grpAggColumns +
											"," +
											aggCountArr +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("") +
											"\n" +
											"ORDER BY" +
											" " +
											sortByArray;
									} else if (aggCountArr.length > 0) {
										query =
											"SELECT" +
											" " +
											aggCountArr +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("") +
											"\n" +
											"ORDER BY" +
											" " +
											sortByArray;
									} else {
										query =
											"SELECT" +
											" " +
											grpAggColumns +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("") +
											"\n" +
											"ORDER BY" +
											" " +
											sortByArray;
									}
								} else {
									if (
										grpAggColumns &&
										grpAggColumns.length > 0 &&
										aggCountArr.length > 0
									) {
										query =
											"SELECT" +
											" " +
											grpAggColumns +
											"," +
											aggCountArr +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("");
									} else if (aggCountArr.length > 0) {
										query =
											"SELECT" +
											" " +
											aggCountArr +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("");
									} else {
										query =
											"SELECT" +
											" " +
											grpAggColumns +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("");
									}
								}
							} else {
								if (filtersinglequeryString !== undefined) {
									if (sortByArray.length > 0) {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										} else {
											query =
												"SELECT" +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										}
									} else {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString;
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString;
										} else {
											query =
												"SELECT" +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString;
										}
									}
								} else {
									if (sortByArray.length > 0) {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										} else {
											query =
												"SELECT" +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										}
									} else {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable;
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable;
										} else {
											query =
												"SELECT" +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable;
										}
									}
								}
							}
						}
					}

					if (groupBy.groupBy.length > 1) {
						if (newArray !== undefined) {
							if (filterMultArray.length > 0) {
								if (sortByArray.length > 0) {
									query =
										"SELECT" +
										" " +
										newArray +
										" " +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										"\n" +
										"WHERE" +
										" " +
										filtersinglequeryString +
										" " +
										filterMultArray.join("") +
										"\n" +
										"GROUP" +
										" " +
										"BY" +
										" " +
										grpByArray +
										"\n" +
										"ORDER BY" +
										" " +
										sortByArray;
								} else {
									query =
										"SELECT" +
										" " +
										newArray +
										" " +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										"\n" +
										"WHERE" +
										" " +
										filtersinglequeryString +
										" " +
										filterMultArray.join("") +
										"\n" +
										"GROUP" +
										" " +
										"BY" +
										" " +
										grpByArray;
								}
							} else {
								if (filtersinglequeryString !== undefined) {
									if (sortByArray.length > 0) {
										query =
											"SELECT" +
											" " +
											newArray +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray +
											"\n" +
											"ORDER BY" +
											" " +
											sortByArray;
									} else {
										query =
											"SELECT" +
											" " +
											newArray +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									}
								} else {
									if (sortByArray.length > 0) {
										query =
											"SELECT" +
											" " +
											newArray +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray +
											"\n" +
											"ORDER BY" +
											" " +
											sortByArray;
									} else {
										query =
											"SELECT" +
											" " +
											newArray +
											" " +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									}
								}
							}
						} else {
							if (filterMultArray.length > 0) {
								if (sortByArray.length > 0) {
									query =
										"SELECT" +
										" " +
										grpCombo +
										" " +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										"\n" +
										"WHERE" +
										" " +
										filtersinglequeryString +
										" " +
										filterMultArray.join("") +
										"\n" +
										"GROUP" +
										" " +
										"BY" +
										" " +
										grpByArray +
										"\n" +
										"ORDER BY" +
										" " +
										sortByArray;
								} else {
									query =
										"SELECT" +
										" " +
										grpCombo +
										" " +
										"\n" +
										"FROM" +
										"  " +
										firsttable +
										"\n" +
										"WHERE" +
										" " +
										filtersinglequeryString +
										" " +
										filterMultArray.join("") +
										"\n" +
										"GROUP" +
										" " +
										"BY" +
										" " +
										grpByArray;
								}
							} else {
								if (filtersinglequeryString !== undefined) {
									if (sortByArray.length > 0) {
										query =
											"SELECT" +
											" " +
											grpCombo +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray +
											"\n" +
											"ORDER BY" +
											" " +
											sortByArray;
									} else {
										query =
											"SELECT" +
											" " +
											grpCombo +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									}
								} else {
									if (sortByArray.length > 0) {
										query =
											"SELECT" +
											" " +
											grpCombo +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray +
											"\n" +
											"ORDER BY" +
											" " +
											sortByArray;
									} else {
										query =
											"SELECT" +
											" " +
											grpCombo +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									}
								}
							}
						}
					}

					if (groupBy.aggColumns.length > 1 && groupBy.groupBy.length > 1) {
						if (newArray !== undefined) {
							if (filterMultArray.length > 0) {
								if (sortByArray.length > 0) {
									if (
										grpAggColumns &&
										grpAggColumns.length > 0 &&
										aggCountArr.length > 0
									) {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											grpAggColumns +
											"," +
											aggCountArr +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("") +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray +
											"\n" +
											"ORDER BY" +
											" " +
											sortByArray;
									} else if (aggCountArr.length > 0) {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											aggCountArr +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("") +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray +
											"\n" +
											"ORDER BY" +
											" " +
											sortByArray;
									} else {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											grpAggColumns +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("") +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray +
											"\n" +
											"ORDER BY" +
											" " +
											sortByArray;
									}
								} else {
									if (
										grpAggColumns &&
										grpAggColumns.length > 0 &&
										aggCountArr.length > 0
									) {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											grpAggColumns +
											"," +
											aggCountArr +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("") +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									} else if (aggCountArr.length > 0) {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											aggCountArr +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("") +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									} else {
										query =
											"SELECT" +
											" " +
											newArray +
											"," +
											" " +
											grpAggColumns +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("") +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									}
								}
							} else {
								if (filtersinglequeryString !== undefined) {
									if (sortByArray.length > 0) {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										} else {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												grpAggColumns +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										}
									} else {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										} else {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												grpAggColumns +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										}
									}
								} else {
									if (sortByArray.length > 0) {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										} else {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												grpAggColumns +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										}
									} else {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										} else {
											query =
												"SELECT" +
												" " +
												newArray +
												"," +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										}
									}
								}
							}
						} else {
							if (filterMultArray.length > 0) {
								if (sortByArray.length > 0) {
									if (
										grpAggColumns &&
										grpAggColumns.length > 0 &&
										aggCountArr.length > 0
									) {
										query =
											"SELECT" +
											" " +
											grpAggColumns +
											"," +
											aggCountArr +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("") +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray +
											"\n" +
											"ORDER BY" +
											" " +
											sortByArray;
									} else if (aggCountArr.length > 0) {
										query =
											"SELECT" +
											" " +
											aggCountArr +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("") +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray +
											"\n" +
											"ORDER BY" +
											" " +
											sortByArray;
									} else {
										query =
											"SELECT" +
											" " +
											grpAggColumns +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("") +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray +
											"\n" +
											"ORDER BY" +
											" " +
											sortByArray;
									}
								} else {
									if (
										grpAggColumns &&
										grpAggColumns.length > 0 &&
										aggCountArr.length > 0
									) {
										query =
											"SELECT" +
											" " +
											grpAggColumns +
											"," +
											aggCountArr +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("") +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									} else if (aggCountArr.length > 0) {
										query =
											"SELECT" +
											" " +
											aggCountArr +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("") +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									} else {
										query =
											"SELECT" +
											" " +
											grpAggColumns +
											" " +
											"\n" +
											"FROM" +
											"  " +
											firsttable +
											"\n" +
											"WHERE" +
											" " +
											filtersinglequeryString +
											" " +
											filterMultArray.join("") +
											"\n" +
											"GROUP" +
											" " +
											"BY" +
											" " +
											grpByArray;
									}
								}
							} else {
								if (filtersinglequeryString !== undefined) {
									if (sortByArray.length > 0) {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										} else {
											if (
												grpAggColumns &&
												grpAggColumns.length > 0 &&
												aggCountArr.length > 0
											) {
												query =
													"SELECT" +
													" " +
													grpAggColumns +
													"," +
													aggCountArr +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													"\n" +
													"WHERE" +
													" " +
													filtersinglequeryString +
													"\n" +
													"GROUP" +
													" " +
													"BY" +
													" " +
													grpByArray +
													"\n" +
													"ORDER BY" +
													" " +
													sortByArray;
											} else if (aggCountArr.length > 0) {
												query =
													"SELECT" +
													" " +
													aggCountArr +
													" " +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													"\n" +
													"WHERE" +
													" " +
													filtersinglequeryString +
													"\n" +
													"GROUP" +
													" " +
													"BY" +
													" " +
													grpByArray +
													"\n" +
													"ORDER BY" +
													" " +
													sortByArray;
											} else {
												query =
													"SELECT" +
													" " +
													grpAggColumns +
													" " +
													"\n" +
													"FROM" +
													"  " +
													firsttable +
													"\n" +
													"WHERE" +
													" " +
													filtersinglequeryString +
													"\n" +
													"GROUP" +
													" " +
													"BY" +
													" " +
													grpByArray +
													"\n" +
													"ORDER BY" +
													" " +
													sortByArray;
											}
										}
									} else {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										} else {
											query =
												"SELECT" +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"\n" +
												"WHERE" +
												" " +
												filtersinglequeryString +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										}
									}
								} else {
									if (sortByArray.length > 0) {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										} else {
											query =
												"SELECT" +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray +
												"\n" +
												"ORDER BY" +
												" " +
												sortByArray;
										}
									} else {
										if (
											grpAggColumns &&
											grpAggColumns.length > 0 &&
											aggCountArr.length > 0
										) {
											query =
												"SELECT" +
												" " +
												grpAggColumns +
												"," +
												aggCountArr +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										} else if (aggCountArr.length > 0) {
											query =
												"SELECT" +
												" " +
												aggCountArr +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										} else {
											query =
												"SELECT" +
												" " +
												grpAggColumns +
												" " +
												"\n" +
												"FROM" +
												"  " +
												firsttable +
												"\n" +
												"GROUP" +
												" " +
												"BY" +
												" " +
												grpByArray;
										}
									}
								}
							}
						}
					}
				}
			}

			let queryObj = {
				catalog: this.visualQueryForm.controls["catalog"].value,
				schema: this.visualQueryForm.controls["schema"].value,
				table: this.visualQueryForm.controls["table"].value,
				columns: this.visualQueryForm.controls["columns"].value,
				filters: filters,
				join: join,
				sortBy: sortBy,
				groupBy: groupBy,
				limits: limits,
				query: format(query)
			};

			if (btn == 1) {
				this.builderService.result = queryObj;
				this.router.navigate(["/data-explorer"]);
			} else {
				this.builderService.analyzerQueryObj = queryObj;
				this.router.navigate(["/analyzer"]);
			}
		}
	}

	remove_duplicates(arr) {
		let obj = {};
		for (let i = 0; i < arr.length; i++) {
			obj[arr[i]] = true;
		}
		arr = [];
		for (let key in obj) {
			arr.push(key);
		}
		return arr;
	}

	getVal() {
		this.builderService.comboArray = [];
		let schemaDtl =
			this.visualQueryForm.controls["catalog"].value +
			"." +
			this.visualQueryForm.controls["schema"].value +
			"." +
			this.visualQueryForm.controls["table"].value;
		if (this.visualQueryForm.value.columns !== null) {
			if (this.visualQueryForm.value.columns.length > 0) {
				for (let [key, value] of Object.entries(
					this.visualQueryForm.value.columns
				)) {
					let res2 = <any>{};
					res2 = value;

					let tableDtl = {
						table: schemaDtl,
						field: res2.column,
						by: "",
						type: ""
					};
					this.builderService.selectedTableCols.push(tableDtl);
					this.reduce();
				}
			} else {
				this.visualQueryForm.value.columns = null;
			}
		}
	}

	reduce() {
		let res = [];
		if (this.builderService.selectedTableCols.length > 0) {
			this.builderService.selectedTableCols.map(function (item) {
				var existItem = res.find(
					x => x.table === item.table && x.field === item.field
				);
				if (!existItem) {
					res.push(item);
				}
			});
			this.builderService.newselectedTableCols = res;
		}
	}

	getSelectedTable() {
		this.builderService.selectedTableArr = [];
		let schemaDtl =
			this.visualQueryForm.controls["catalog"].value +
			"." +
			this.visualQueryForm.controls["schema"].value +
			"." +
			this.visualQueryForm.controls["table"].value;
		if (this.visualQueryForm.value.columns !== null) {
			for (let [key, value] of Object.entries(
				this.visualQueryForm.value.columns
			)) {
				let res2 = <any>{};
				res2 = value;
				if (this.columnListing) {
					for (let [key, value] of Object.entries(this.columnListing)) {
						let colsList = <any>{};
						colsList = value;
						if (
							res2.uuid == colsList.uuid &&
							schemaDtl ==
								colsList.catalog + "." + colsList.schema + "." + colsList.table
						) {
							let tableDtl = {
								catalog: colsList.catalog,
								schema: colsList.schema,
								table: colsList.table,
								column: colsList.column,
								type: colsList.type
							};
							this.builderService.selectedTableArr.push(tableDtl);
							this.tablereduce();
						}
					}
				}
			}
		}
	}

	tablereduce() {
		let res = [];
		this.builderService.selectedTableArr.map(function (item) {
			var existItem = res.find(
				x =>
					x.catalog == item.catalog &&
					x.schema == item.schema &&
					x.table == item.table &&
					x.column == item.column &&
					x.type == item.type
			);
			if (!existItem) {
				res.push(item);
			}
		});
		this.builderService.selectedTableSort = res;
	}

	showButton(btn) {
		if (this.visualQueryForm.value.columns !== null) {
			if (this.visualQueryForm.value.columns.length == 0) {
				this.visualQueryForm.value.columns = null;
			}
		}
		if (this.builderService.sqlQuery.join !== undefined) {
			if (this.builderService.sqlQuery.join.length == 0) {
				this.builderService.sqlQuery.join = undefined;
			}
		}
		if (
			this.visualQueryForm.value.columns == null &&
			this.builderService.sqlQuery.join == undefined
		) {
			this.emptyMsg = false;
			this.noColumnErrMsg = false;
			return;
		}
		if (
			this.visualQueryForm.value.columns == null &&
			this.builderService.sqlQuery.join !== undefined
		) {
			for (let [key, value] of Object.entries(
				this.builderService.sqlQuery.join
			)) {
				let res2 = <any>{};
				res2 = value;
				if (res2.cols.length > 0) {
					this.emptyMsg = true;
					this.noColumnErrMsg = true;
					let joinFrmTableIndex = this.builderService.sqlQuery.join.findIndex(
						x => x.from.field === ""
					);

					let joinFrmFieldIndex = this.builderService.sqlQuery.join.findIndex(
						x => x.from.field === ""
					);

					let joinToTableIndex = this.builderService.sqlQuery.join.findIndex(
						x => x.to.table === ""
					);

					let joinToFieldIndex = this.builderService.sqlQuery.join.findIndex(
						x => x.to.field === ""
					);

					if (joinFrmTableIndex !== -1) {
						this.invalidJoinMsg = false;
						return;
					}

					if (joinFrmFieldIndex !== -1) {
						this.invalidJoinMsg = false;
						return;
					}
					if (joinToTableIndex !== -1) {
						this.invalidJoinMsg = false;
						return;
					}

					if (joinToFieldIndex !== -1) {
						this.invalidJoinMsg = false;
						return;
					}

					let aggColindex =
						this.builderService.sqlQuery.groupBy.aggColumns.findIndex(
							x => x.field === "New item"
						);

					let grpByIndex =
						this.builderService.sqlQuery.groupBy.groupBy.findIndex(
							x => x.field === "New item"
						);

					let sortIndex;

					if (aggColindex !== -1 && grpByIndex == -1) {
						if (this.builderService.sqlQuery.sortBy !== undefined) {
							sortIndex = this.builderService.sqlQuery.sortBy.findIndex(
								x => x.field === "New item"
							);

							if (sortIndex == -1) {
								this.GroupbyComponent.invalidBtnMsg = false;
								this.GroupbyComponent.invalidgrpMsg = true;
								this.SortComponent.invalidsortMsg = true;
							} else {
								this.GroupbyComponent.invalidBtnMsg = false;
								this.GroupbyComponent.invalidgrpMsg = true;
								this.SortComponent.invalidsortMsg = false;
							}
						} else {
							this.GroupbyComponent.invalidBtnMsg = false;
							this.GroupbyComponent.invalidgrpMsg = true;
						}
					} else if (aggColindex !== -1 && grpByIndex !== -1) {
						if (this.builderService.sqlQuery.sortBy !== undefined) {
							sortIndex = this.builderService.sqlQuery.sortBy.findIndex(
								x => x.field === "New item"
							);

							if (sortIndex == -1) {
								this.GroupbyComponent.invalidBtnMsg = false;
								this.GroupbyComponent.invalidgrpMsg = false;
								this.SortComponent.invalidsortMsg = true;
							} else {
								this.GroupbyComponent.invalidBtnMsg = false;
								this.GroupbyComponent.invalidgrpMsg = false;
								this.SortComponent.invalidsortMsg = false;
							}
						} else {
							this.GroupbyComponent.invalidBtnMsg = false;
							this.GroupbyComponent.invalidgrpMsg = false;
						}
					} else if (aggColindex == -1 && grpByIndex == -1) {
						if (this.builderService.sqlQuery.sortBy !== undefined) {
							sortIndex = this.builderService.sqlQuery.sortBy.findIndex(
								x => x.field === "New item"
							);

							if (sortIndex == -1) {
								this.GroupbyComponent.invalidBtnMsg = true;
								this.GroupbyComponent.invalidgrpMsg = true;
								this.SortComponent.invalidsortMsg = true;
								this.saveQuery(btn);
							} else {
								this.GroupbyComponent.invalidBtnMsg = true;
								this.GroupbyComponent.invalidgrpMsg = true;
								this.SortComponent.invalidsortMsg = false;
							}
						} else {
							this.saveQuery(btn);
						}
					} else if (grpByIndex !== -1 && aggColindex == -1) {
						if (this.builderService.sqlQuery.sortBy !== undefined) {
							sortIndex = this.builderService.sqlQuery.sortBy.findIndex(
								x => x.field === "New item"
							);
							if (sortIndex == -1) {
								this.GroupbyComponent.invalidBtnMsg = true;
								this.GroupbyComponent.invalidgrpMsg = false;
								this.SortComponent.invalidsortMsg = true;
							} else {
								this.GroupbyComponent.invalidBtnMsg = true;
								this.GroupbyComponent.invalidgrpMsg = false;
								this.SortComponent.invalidsortMsg = false;
							}
						} else {
							this.GroupbyComponent.invalidBtnMsg = true;
							this.GroupbyComponent.invalidgrpMsg = false;
						}
					}
				} else {
					this.emptyMsg = false;
					this.noColumnErrMsg = false;
					this.JoinComponent.showMessage();
				}
			}
		}
		if (
			this.visualQueryForm.value.columns !== null &&
			this.builderService.sqlQuery.join == undefined
		) {
			this.emptyMsg = true;
			this.noColumnErrMsg = true;

			let aggColindex =
				this.builderService.sqlQuery.groupBy.aggColumns.findIndex(
					x => x.field === "New item"
				);

			let grpByIndex = this.builderService.sqlQuery.groupBy.groupBy.findIndex(
				x => x.field === "New item"
			);

			let sortIndex;

			if (aggColindex !== -1 && grpByIndex == -1) {
				if (this.builderService.sqlQuery.sortBy !== undefined) {
					sortIndex = this.builderService.sqlQuery.sortBy.findIndex(
						x => x.field === "New item"
					);

					if (sortIndex == -1) {
						this.GroupbyComponent.invalidBtnMsg = false;
						this.GroupbyComponent.invalidgrpMsg = true;
						this.SortComponent.invalidsortMsg = true;
					} else {
						this.GroupbyComponent.invalidBtnMsg = false;
						this.GroupbyComponent.invalidgrpMsg = true;
						this.SortComponent.invalidsortMsg = false;
					}
				} else {
					this.GroupbyComponent.invalidBtnMsg = false;
					this.GroupbyComponent.invalidgrpMsg = true;
				}
			} else if (aggColindex !== -1 && grpByIndex !== -1) {
				if (this.builderService.sqlQuery.sortBy !== undefined) {
					sortIndex = this.builderService.sqlQuery.sortBy.findIndex(
						x => x.field === "New item"
					);

					if (sortIndex == -1) {
						this.GroupbyComponent.invalidBtnMsg = false;
						this.GroupbyComponent.invalidgrpMsg = false;
						this.SortComponent.invalidsortMsg = true;
					} else {
						this.GroupbyComponent.invalidBtnMsg = false;
						this.GroupbyComponent.invalidgrpMsg = false;
						this.SortComponent.invalidsortMsg = false;
					}
				} else {
					this.GroupbyComponent.invalidBtnMsg = false;
					this.GroupbyComponent.invalidgrpMsg = false;
				}
			} else if (aggColindex == -1 && grpByIndex == -1) {
				if (this.builderService.sqlQuery.sortBy !== undefined) {
					sortIndex = this.builderService.sqlQuery.sortBy.findIndex(
						x => x.field === "New item"
					);

					if (sortIndex == -1) {
						this.GroupbyComponent.invalidBtnMsg = true;
						this.GroupbyComponent.invalidgrpMsg = true;
						this.SortComponent.invalidsortMsg = true;
						this.saveQuery(btn);
					} else {
						this.GroupbyComponent.invalidBtnMsg = true;
						this.GroupbyComponent.invalidgrpMsg = true;
						this.SortComponent.invalidsortMsg = false;
					}
				} else {
					this.saveQuery(btn);
				}
			} else if (grpByIndex !== -1 && aggColindex == -1) {
				if (this.builderService.sqlQuery.sortBy !== undefined) {
					sortIndex = this.builderService.sqlQuery.sortBy.findIndex(
						x => x.field === "New item"
					);
					if (sortIndex == -1) {
						this.GroupbyComponent.invalidBtnMsg = true;
						this.GroupbyComponent.invalidgrpMsg = false;
						this.SortComponent.invalidsortMsg = true;
					} else {
						this.GroupbyComponent.invalidBtnMsg = true;
						this.GroupbyComponent.invalidgrpMsg = false;
						this.SortComponent.invalidsortMsg = false;
					}
				} else {
					this.GroupbyComponent.invalidBtnMsg = true;
					this.GroupbyComponent.invalidgrpMsg = false;
				}
			}
		}
		if (
			this.visualQueryForm.value.columns !== null &&
			this.builderService.sqlQuery.join !== undefined
		) {
			this.emptyMsg = true;
			this.noColumnErrMsg = true;
			let joinFrmTableIndex = this.builderService.sqlQuery.join.findIndex(
				x => x.from.field === ""
			);

			let joinFrmFieldIndex = this.builderService.sqlQuery.join.findIndex(
				x => x.from.field === ""
			);

			let joinToTableIndex = this.builderService.sqlQuery.join.findIndex(
				x => x.to.table === ""
			);

			let joinToFieldIndex = this.builderService.sqlQuery.join.findIndex(
				x => x.to.field === ""
			);

			if (joinFrmTableIndex !== -1) {
				this.invalidJoinMsg = false;
				return;
			}

			if (joinFrmFieldIndex !== -1) {
				this.invalidJoinMsg = false;
				return;
			}
			if (joinToTableIndex !== -1) {
				this.invalidJoinMsg = false;
				return;
			}

			if (joinToFieldIndex !== -1) {
				this.invalidJoinMsg = false;
				return;
			}
			let aggColindex =
				this.builderService.sqlQuery.groupBy.aggColumns.findIndex(
					x => x.field === "New item"
				);

			let grpByIndex = this.builderService.sqlQuery.groupBy.groupBy.findIndex(
				x => x.field === "New item"
			);

			let sortIndex;

			if (aggColindex !== -1 && grpByIndex == -1) {
				if (this.builderService.sqlQuery.sortBy !== undefined) {
					sortIndex = this.builderService.sqlQuery.sortBy.findIndex(
						x => x.field === "New item"
					);

					if (sortIndex == -1) {
						this.GroupbyComponent.invalidBtnMsg = false;
						this.GroupbyComponent.invalidgrpMsg = true;
						this.SortComponent.invalidsortMsg = true;
					} else {
						this.GroupbyComponent.invalidBtnMsg = false;
						this.GroupbyComponent.invalidgrpMsg = true;
						this.SortComponent.invalidsortMsg = false;
					}
				} else {
					this.GroupbyComponent.invalidBtnMsg = false;
					this.GroupbyComponent.invalidgrpMsg = true;
				}
			} else if (aggColindex !== -1 && grpByIndex !== -1) {
				if (this.builderService.sqlQuery.sortBy !== undefined) {
					sortIndex = this.builderService.sqlQuery.sortBy.findIndex(
						x => x.field === "New item"
					);

					if (sortIndex == -1) {
						this.GroupbyComponent.invalidBtnMsg = false;
						this.GroupbyComponent.invalidgrpMsg = false;
						this.SortComponent.invalidsortMsg = true;
					} else {
						this.GroupbyComponent.invalidBtnMsg = false;
						this.GroupbyComponent.invalidgrpMsg = false;
						this.SortComponent.invalidsortMsg = false;
					}
				} else {
					this.GroupbyComponent.invalidBtnMsg = false;
					this.GroupbyComponent.invalidgrpMsg = false;
				}
			} else if (aggColindex == -1 && grpByIndex == -1) {
				if (this.builderService.sqlQuery.sortBy !== undefined) {
					sortIndex = this.builderService.sqlQuery.sortBy.findIndex(
						x => x.field === "New item"
					);

					if (sortIndex == -1) {
						this.GroupbyComponent.invalidBtnMsg = true;
						this.GroupbyComponent.invalidgrpMsg = true;
						this.SortComponent.invalidsortMsg = true;
						this.saveQuery(btn);
					} else {
						this.GroupbyComponent.invalidBtnMsg = true;
						this.GroupbyComponent.invalidgrpMsg = true;
						this.SortComponent.invalidsortMsg = false;
					}
				} else {
					this.saveQuery(btn);
				}
			} else if (grpByIndex !== -1 && aggColindex == -1) {
				if (this.builderService.sqlQuery.sortBy !== undefined) {
					sortIndex = this.builderService.sqlQuery.sortBy.findIndex(
						x => x.field === "New item"
					);
					if (sortIndex == -1) {
						this.GroupbyComponent.invalidBtnMsg = true;
						this.GroupbyComponent.invalidgrpMsg = false;
						this.SortComponent.invalidsortMsg = true;
					} else {
						this.GroupbyComponent.invalidBtnMsg = true;
						this.GroupbyComponent.invalidgrpMsg = false;
						this.SortComponent.invalidsortMsg = false;
					}
				} else {
					this.GroupbyComponent.invalidBtnMsg = true;
					this.GroupbyComponent.invalidgrpMsg = false;
				}
			}
		}
	}
	onDeSelectAll(item: any) {
		this.builderService.selectedTableCols = [];
		if (item.length == 0) {
			let schemaDtl =
				this.visualQueryForm.controls["catalog"].value +
				"." +
				this.visualQueryForm.controls["schema"].value +
				"." +
				this.visualQueryForm.controls["table"].value;
			let tableDtl = {
				table: schemaDtl,
				field: "",
				by: "",
				type: ""
			};
			this.builderService.selectedTableCols.push(tableDtl);
			this.reduce();
		}
	}

	OnItemDeSelect(item: any) {
		this.builderService.selectedTableCols = [];
		let field;
		if (item && item.column) {
			field = item.column;
		} else if (item) {
			field = item;
		}
		let schemaDtl =
			this.visualQueryForm.controls["catalog"].value +
			"." +
			this.visualQueryForm.controls["schema"].value +
			"." +
			this.visualQueryForm.controls["table"].value;
		let tableDtl = {
			table: schemaDtl,
			field: field,
			by: "",
			type: ""
		};
		this.builderService.comboArray.push(tableDtl);
		this.creduce();
	}

	creduce() {
		let res = [];
		this.builderService.comboArray.map(function (item) {
			var existItem = res.find(
				x => x.table === item.table && x.field === item.field
			);
			if (!existItem) {
				res.push(item);
			}
		});
		this.builderService.comboList = res;
	}

	notifySort(item) {
		this.builderService.selectSortColsArr = [];
		let field;
		if (item && item.column) {
			field = item.column;
		} else if (item) {
			field = item;
		}
		let schemaDtl =
			this.visualQueryForm.controls["catalog"].value +
			"." +
			this.visualQueryForm.controls["schema"].value +
			"." +
			this.visualQueryForm.controls["table"].value;
		let tableDtl = {
			table: schemaDtl,
			field: field,
			by: "",
			type: ""
		};
		this.builderService.selectSortColsArr.push(tableDtl);
		this.sortreduce();
	}

	sortreduce() {
		let res = [];
		this.builderService.selectSortColsArr.map(function (item) {
			var existItem = res.find(
				x => x.table === item.table && x.field === item.field
			);
			if (!existItem) {
				res.push(item);
			}
		});
		this.builderService.selectSortCols = res;
		if (this.builderService.selectSortCols) {
			this.SortComponent.removeSortfromCatalogDetails();
		}
	}

	notifySortAll(item: any) {
		this.builderService.selectSortColArrAll = [];
		if (item.length == 0) {
			let schemaDtl =
				this.visualQueryForm.controls["catalog"].value +
				"." +
				this.visualQueryForm.controls["schema"].value +
				"." +
				this.visualQueryForm.controls["table"].value;
			let tableDtl = {
				table: schemaDtl,
				field: "",
				by: "",
				type: ""
			};
			this.builderService.selectSortColArrAll.push(tableDtl);
			this.allreduce();
		}
	}

	allreduce() {
		let res = [];
		this.builderService.selectSortColArrAll.map(function (item) {
			var existItem = res.find(
				x =>
					x.table === item.table && x.field === item.field && item.by === x.by
			);
			if (!existItem) {
				res.push(item);
			}
		});
		this.builderService.selectSortColsAll = res;
		if (this.builderService.selectSortColsAll) {
			this.SortComponent.removeCatalogAll();
		}
	}

	clearAll() {
		if (this.visualQueryForm.controls["catalog"].value !== null) {
			swal({
				type: "warning",
				title: "Are you sure you want to  clear query?",
				text: "All information associated to this query will be cleared",
				showCancelButton: true,
				confirmButtonText: "Yes",
				cancelButtonText: "No"
			}).then(
				status => {
					if (status.value === true) {
						this.visualQueryForm.reset();
						this.tableListing = undefined;
						this.columnListing = undefined;
						this.builderService.sqlQuery.join = undefined;
						this.JoinComponent.clearVal();
						this.builderService.fromTable = [];
						this.builderService.selectedTableCols = [];
						this.builderService.sqlQuery.groupBy = undefined;
						this.GroupbyComponent.clearValue();
						this.GroupbyComponent.clearAll();
						this.builderService.sqlQuery.sortBy = undefined;
						this.SortComponent.clearValue();
						this.isWarning = false;
						this.showFilter = false;
						this.builderService.sqlQuery.filters = undefined;
						this.builderService.titleList = [];
						this.removeAllFilters();
						//this.LimitsComponent.clearVal();
						this.builderService.result = undefined;
						this.builderService.getmainQuery = undefined;
						this.builderService.getCatalog = undefined;
						this.builderService.getCol = undefined;
						this.builderService.getSchema = undefined;
						this.builderService.getTable = undefined;
						this.builderService.join = undefined;
						this.builderService.tableList = undefined;
						this.builderService.filters = undefined;
						this.builderService.groupBy = undefined;
						this.builderService.sortBy = undefined;
						this.builderService.limits = undefined;
						this.builderService.multiColumns = undefined;
						this.builderService.colListing = undefined;
						this.builderService.tables = [];
						this.builderService.fromField = [];
						this.builderService.fromselectedTable = undefined;
						this.builderService.toTable = undefined;
						this.builderService.toField = undefined;
						this.builderService.toselectedTable = undefined;
						this.builderService.selectedField = undefined;
						this.builderService.newselectedTableCols = undefined;
						this.builderService.castArray = [];
						this.builderService.selectedElementDtls = [];
						this.builderService.selectedTableArr = [];
						this.builderService.selectedTableSort = undefined;
						this.builderService.colListingArray = [];
						this.builderService.selectedJoinCols = [];
						this.builderService.newSelectedJoinCols;
						this.builderService.comboArray = [];
						this.builderService.noColumnerrorMsg = undefined;
						this.builderService.selectSortCols = undefined;
						this.builderService.selectSortColsArr = [];
						this.builderService.selectSortColsJoin = undefined;
						this.builderService.selectSortColsJoinArr = [];
						this.builderService.selectSortColsAll = undefined;
						this.builderService.selectSortColArrAll = [];
						this.builderService.selectSortColsJoinAll = undefined;
						this.builderService.selectSortColsJoinArrAll = [];
						this.builderService.countAll = undefined;
						this.builderService.analyzerQueryObj = undefined;
						this.builderService.removeerrMsg = undefined;
						this.noColumnErrMsg = true;
						this.emptyMsg = true;
						this.GroupbyComponent.invalidBtnMsg = true;
						this.GroupbyComponent.invalidgrpMsg = true;
						this.SortComponent.invalidsortMsg = true;
						if (this.limits !== 0) {
							this.LimitsComponent.clear();
						} else {
							this.changeLimits(0);
						}
					}
				},
				dismiss => {
					if (dismiss === "cancel") {
					}
				}
			);
		} else {
			this.isWarning = false;
			this.JoinComponent.isWarning = false;
			this.GroupbyComponent.isWarning = false;
			this.GroupbyComponent.isWarningFun = false;
			this.SortComponent.isWarning = false;
		}
	}
}
