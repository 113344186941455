export const MaxBlocksInCache = 2;
export const CacheBlockSize = 1000;

export const getCellClassRules = {
	greaterThan: params => {
		return {
			"cell-pass": data => data.value >= params.value,
			"cell-fail": data => data.value < params.value
		};
	},
	lessThan: params => {
		return {
			"cell-pass": data => data.value <= params.value,
			"cell-fail": data => data.value > params.value
		};
	},
	equalTo: params => {
		return {
			"cell-pass": data => data.value === params.value,
			"cell-fail": data => data.value === params.value
		};
	}
};

export const defaultGridOptions: GridOptions = {
	sideBar: {
		toolPanels: [
			{
				id: "columns",
				labelDefault: "Columns",
				labelKey: "columns",
				iconKey: "columns",
				toolPanel: "agColumnsToolPanel"
			},
			{
				id: "filters",
				labelDefault: "Filters",
				labelKey: "filters",
				iconKey: "filter",
				toolPanel: "agFiltersToolPanel"
			}
		],
		defaultToolPanel: ""
	},
	rowBuffer: 0,
	enableCharts: true,
	enableRangeSelection: true,
	animateRows: true,
	rowGroupPanelShow: "onlyWhenGrouping",
	rowSelection: "multiple",
	rowDragManaged: true,
	rowDragMultiRow: true,
	suppressRowDrag: true,
	overlayLoadingTemplate: '<span class="page_loader"></span>',
	// tell grid we want virtual row model type
	// how big each page in our page cache will be, default is 100
	// how many extra blank rows to display to the user at the end of the dataset,
	// which sets the vertical scroll and then allows the grid to request viewing more rows of data.
	// default is 1, ie show 1 row.
	cacheOverflowSize: 2,
	// how many server side requests to send at a time. if user is scrolling lots, then the requests
	// are throttled down
	maxConcurrentDatasourceRequests: 1,
	// how many rows to initially show in the grid. having 1 shows a blank row, so it looks like
	// the grid is loading from the users perspective (as we have a spinner in the first col)
	infiniteInitialRowCount: 1000,
	// how many pages to store in cache. default is undefined, which allows an infinite sized cache,
	// pages are never purged. this should be set for large data to stop your browser from getting
	// full of data
	maxBlocksInCache: 10,
	cacheBlockSize: CacheBlockSize,
	// groupSelectsChildren: true,

	// maxBlocksInCache: MaxBlocksInCache,

	defaultColDef: {
		// flex: 1,
		resizable: true,
		// minWidth: 100,
		sortable: true,
		filter: true,
		floatingFilter: true,
		enableRowGroup: true
	},

	// rowData: [],
	statusBar: {
		statusPanels: [
			{ statusPanel: "agSelectedRowCountComponent" },
			{ statusPanel: "agAggregationComponent" }
		]
	},

	getContextMenuItems: params => {
		const replaceArrayItemProp = curry((value, colId, arr) =>
			map(when(propEq("colId", colId), assoc("cellClassRules", value)), arr)
		);
		const menuItems = [
			{
				// custom item
				name: "Highlight Cells Rule",
				subMenu: [
					{
						name: "Greater Than...  ",
						action: function () {
							// window.alert("Alerting about " + params.value);
							const cellClassRules = getCellClassRules.greaterThan(params);

							const colDefs = replaceArrayItemProp(
								cellClassRules,
								params.column.getColId(),
								params.api.getColumnDefs()
							);

							params.api.setColumnDefs(colDefs);
						}
					},
					{
						name: "Less Than...",
						action: function () {
							// window.alert("Alerting about " + params.value);
							const cellClassRules = getCellClassRules.lessThan(params);

							const colDefs = replaceArrayItemProp(
								cellClassRules,
								params.column.getColId(),
								params.api.getColumnDefs()
							);
							params.api.setColumnDefs(colDefs);
						}
					},
					{
						name: "Equal To...",
						action: function () {
							// window.alert("Alerting about " + params.value);
							const cellClassRules = getCellClassRules.equalTo(params);

							const colDefs = replaceArrayItemProp(
								cellClassRules,
								params.column.getColId(),
								params.api.getColumnDefs()
							);
							params.api.setColumnDefs(colDefs);
						}
					}
				]
			},
			{
				name: "Clear Rules",
				subMenu: [
					{
						name: "Clear Rules from Selected Column",
						action: function () {
							const colDefs = replaceArrayItemProp(
								null,
								params.column.getColId(),
								params.api.getColumnDefs()
							);
							params.api.setColumnDefs(colDefs);
							params.api.redrawRows();
						}
					},
					{
						name: "Clear Rules from Entire Sheet",
						action: function () {
							const xLens = lensProp("cellClassRules");
							const colDefs = params.api.getColumnDefs();
							const updatedColDefs = map(set(xLens, null), colDefs);
							params.api.setColumnDefs(updatedColDefs);
							params.api.redrawRows();
						}
					}
				]
			},
			"separator",
			"copy",
			"copyWithHeaders",
			"paste",
			"separator",
			"chartRange",
			"export"
		];

		return menuItems;
	}
};

export const getContextMenuItems =
	(
		onHighlightAdded?: ({ colId: string, cellClassRules: any }) => void,
		onHighlightRemoved?: ({ colId: string }) => void
	) =>
	params => {
		const replaceArrayItemProp = curry((value, colId, arr) =>
			map(when(propEq("colId", colId), assoc("cellClassRules", value)), arr)
		);
		const menuItems = [
			{
				// custom item
				name: "Highlight Cells Rule",
				subMenu: [
					{
						name: "Greater Than...  ",
						action: function () {
							const cellClassRules = getCellClassRules.greaterThan(params);

							const colDefs = replaceArrayItemProp(
								cellClassRules,
								params.column.getColId(),
								params.api.getColumnDefs()
							);

							onHighlightAdded({
								colId: params.column.getColId(),
								cellClassRules
							});
							params.api.setColumnDefs(colDefs);
						}
					},
					{
						name: "Less Than...",
						action: function () {
							const cellClassRules = getCellClassRules.lessThan(params);

							const colDefs = replaceArrayItemProp(
								cellClassRules,
								params.column.getColId(),
								params.api.getColumnDefs()
							);
							onHighlightAdded({
								colId: params.column.getColId(),
								cellClassRules
							});
							params.api.setColumnDefs(colDefs);
						}
					},
					{
						name: "Equal To...",
						action: function () {
							const cellClassRules = getCellClassRules.equalTo(params);

							const colDefs = replaceArrayItemProp(
								cellClassRules,
								params.column.getColId(),
								params.api.getColumnDefs()
							);
							onHighlightAdded({
								colId: params.column.getColId(),
								cellClassRules
							});
							params.api.setColumnDefs(colDefs);
						}
					}
				]
			},
			{
				name: "Clear Rules",
				subMenu: [
					{
						name: "Clear Rules from Selected Column",
						action: function () {
							const colDefs = replaceArrayItemProp(
								null,
								params.column.getColId(),
								params.api.getColumnDefs()
							);
							onHighlightRemoved({ colId: params.column.getColId() });
							params.api.setColumnDefs(colDefs);
							params.api.redrawRows();
						}
					},
					{
						name: "Clear Rules from Entire Sheet",
						action: function () {
							const xLens = lensProp("cellClassRules");
							const colDefs = params.api.getColumnDefs();
							const updatedColDefs = map(set(xLens, null), colDefs);
							onHighlightRemoved({ colId: params.column.getColId() });
							params.api.setColumnDefs(updatedColDefs);
							params.api.redrawRows();
						}
					}
				]
			},
			"separator",
			"copy",
			"copyWithHeaders",
			"paste",
			"separator",
			"chartRange",
			"export"
		];

		return menuItems;
	};
export const partialGridOptions: GridOptions = Object.assign(
	{},
	defaultGridOptions,
	{
		rowModelType: "serverSide",
		serverSideStoreType: "partial" as ServerSideStoreType
	}
);

export const getColDefs = columns => {
	if (isEmpty(columns)) return [];
	const mapIndexed = addIndex(map);
	if (columns) {
		const colDefs: Array<ColDef> = mapIndexed((column: ColumnType) => {
			const col: ColDef = {
				field: column.label,
				sortable: true
			};

			if (isNumericColumn(column.type)) {
				col.enableValue = true;
				col.allowedAggFuncs = ["avg", "count", "sum", "min", "max"];
				col.filter = "agNumberColumnFilter";
			} else if (isTextColumn(column.type)) {
				col.filter = "agTextColumnFilter";
			} else if (isDateColumn(column.type)) {
				col.filter = "agDateColumnFilter";
				//col.filterParams = filterParams;
				col.cellRenderer = data => {
					//let newDate = data.value;
					let newDate;
					if (column.type.toLowerCase() == "timestamp") {
						newDate = moment(data.value).format("YYYY-MM-DD HH:mm:ss.S");
					} else {
						newDate = moment(data.value).format("YYYY-MM-DD");
					}
					localStorage.setItem("newDate", newDate);
					//localStorage.setItem("DfFormat", "YYYY-MM-DD");
					return newDate;
				};
			}

			col.filterParams = {
				// can be 'windows' or 'mac'
				buttons: ["reset"],
				closeOnApply: true,
				debounceMs: 500,
				excelMode: "windows"
			};
			return col;
		}, columns);
		return colDefs;
	}
	return [];
};

export const getRows = res => {
	if (res.columns && res.rows) {
		const fields: string[] = map(prop("label"), res.columns);
		const rowData = map(zipObj(fields), res.rows);
		return rowData;
	}
	return [];
};

export type GridSortModel = {
	colId: string | undefined;
	sort: string | null | undefined;
};

export type GridStateChangeEvent =
	| ColumnVisibleEvent
	| ColumnPinnedEvent
	| ColumnResizedEvent
	| ColumnMovedEvent
	| ColumnRowGroupChangedEvent
	| ColumnPivotChangedEvent
	| ColumnPivotModeChangedEvent;

export const getColumnState = columnState => {
	if (!columnState) return [];
	const state = columnState.map(col => {
		const def = pick(
			[
				"colId",
				"hide",
				"pivot",
				"width",
				"rowGroup",
				"sort",
				"aggFunc",
				"pinned"
			],
			col
		);

		def.rowGroupIndex = col.rowGroup ? col.rowGroupIndex : null;
		def.sortIndex = col.sort ? col.sortIndex : null;
		def.pivotIndex = col.pivot ? col.pivotIndex : null;

		return def;
	});
	return state;
};

export function getLastRowIndex(request, results) {
	if (!results) return undefined;
	var currentLastRow = request.startRow + results.length;

	// if on or after the last block, work out the last row, otherwise return 'undefined'
	return currentLastRow < request.endRow ? currentLastRow : undefined;
}

export function removeDuplicates(array) {
	return Array.from(new Set(array.map(JSON.stringify))).map((val: string) =>
		JSON.parse(val)
	);
}
export function findCommonColumns(arr1: Array<Column>, arr2: Array<Column>) {
	const intersection = arr1.filter(item1 =>
		arr2.some(item2 => item1.name === item2.name)
	);
	return intersection;
}

export function findColumnDifference(
	list1: Array<Column>,
	list2: Array<Column>
) {
	const cmp = (x: Column, y: Column) => x.name === y.name;
	differenceWith(cmp, list1, list2);
}

export const addCellClassRuleForColId = curry((value, colId, arr) =>
	map(when(propEq("colId", colId), assoc("cellClassRules", value)), arr)
);

export const defaultSSRRequestParams: IServerSideGetRowsRequest = {
	startRow: 0,
	endRow: 1000,
	rowGroupCols: [],
	valueCols: [],
	pivotCols: [],
	pivotMode: false,
	groupKeys: [],
	filterModel: {},
	sortModel: []
};

export type AnalyzerRequestOntableClick = {
	tableName: string;
	itemsPerPage: number;
	queryId?: any;
	sqlText?: any;
} & IServerSideGetRowsRequest;

export type AnalyzerRequestonSqlEditor = {
	tableName: string;
	itemsPerPage: number;
	queryId?: any;
	sqlText?: any;
	limit: number;
} & IServerSideGetRowsRequest;

export interface GridColumnGroupState {
	groupId: string;
	open: boolean;
}
export interface Analyzer {
	id: string;
	name: string;
	description?: any;
	meta: AnalyzerMeta;
	columnState: ColumnState[];
	columnGroupState: GridColumnGroupState[];
	selectedTable: string;
	sqlText: string;
	mode: string;
	dataSourceId: string;
	projectId: string;
}

export interface AnalyzerMeta {
	startRow: number;
	endRow: number;
	rowGroupCols: any[];
	valueCols: any[];
	pivotCols: any[];
	pivotMode: boolean;
	groupKeys: any[];
	filterModel?: any;
	sortModel?: any;
}

import { AfterViewInit, Component, Input, OnInit } from "@angular/core";
import {
	ColumnState,
	GridOptions,
	GridReadyEvent,
	FilterChangedEvent,
	IServerSideGetRowsParams,
	SideBarDef,
	StatusPanelDef,
	ColDef,
	ColumnMovedEvent,
	ColumnPinnedEvent,
	ColumnPivotChangedEvent,
	ColumnPivotModeChangedEvent,
	ColumnResizedEvent,
	ColumnRowGroupChangedEvent,
	ColumnVisibleEvent,
	IServerSideGetRowsRequest,
	ServerSideStoreType,
	GridApi,
	ColumnApi
} from "ag-grid-community";

import {
	isDateColumn,
	isNumericColumn,
	isTextColumn
} from "src/app/components/analyzer/column.type";
import {
	head,
	addIndex,
	assoc,
	curry,
	differenceWith,
	isEmpty,
	lensProp,
	map,
	pick,
	prop,
	propEq,
	set,
	when,
	zipObj
} from "ramda";
import {
	Column,
	ColumnType
} from "src/app/components/analyzer/tableexp.response.type";
import { DataExplorerService } from "src/app/services/data-explorer/data-explorer.service";
declare var $: any;
import { VisualQueryBuilderService } from "src/app/components/data-explore/visual-query-builder/visual-query-builder.service";
import { CustomDateComponent } from "src/app/components/common/custom-date/custom-date.component";
import * as moment from "moment";
import { BasicLayoutComponent } from "src/app/components/common/layouts/basic-layout/basic-layout.component";

@Component({
	selector: "app-analyzer",
	templateUrl: "./analyzer.component.html",
	styleUrls: ["./analyzer.component.scss"]
})
export class AnalyzerComponent implements OnInit, AfterViewInit {
	result;
	selectedTable;
	sideMenu: boolean = false;
	// ag-grid configs
	api: GridApi;
	columnApi: ColumnApi;

	defaultColDef;
	sideBar: SideBarDef;
	statusBar: { statusPanels: StatusPanelDef[] };

	columnDefs: ColDef[];
	rowData: any[];

	rowSelection: string;
	rowGroupPanelShow;
	rowModelType;
	serverSideStoreType;
	cacheBlockSize;
	maxBlocksInCache;
	totalRows;
	loadOnTable;
	showAnalyzer: boolean = false;
	pagination;
	paginationPageSize;
	showResultinDataExplorer: boolean = true;
	showInAnalyzer: boolean = false;
	frameworkComponents;
	public infiniteInitialRowCount = 1000;
	gridOptions: GridOptions = {};
	columnState: ColumnState[] = [];
	columnGroupState: GridColumnGroupState[] = [];
	filterModel: { [key: string]: any };
	cellClassRules: Record<string, { colId: string; cellClassRules: any }> = {};
	sqlError: string;
	dataSource;
	AnalysertotalRows: any;
	constructor(
		private dataExplorerService: DataExplorerService,
		private builderService: VisualQueryBuilderService,
		private basicLayComp: BasicLayoutComponent
	) {
		const gridOptions = partialGridOptions;
		gridOptions.getContextMenuItems = getContextMenuItems(
			({ colId, cellClassRules }) => {
				this.cellClassRules[colId] = cellClassRules;
			},
			({ colId }) => {
				delete this.cellClassRules[colId];
			}
		);
		this.gridOptions = gridOptions;
		this.defaultColDef = {
			resizable: true,
			sortable: true,
			filter: true,
			floatingFilter: true,
			enableValue: true,
			enableRowGroup: true,
			enablePivot: true
		};
		this.frameworkComponents = {
			agDateInput: CustomDateComponent
		};
	}

	ngOnInit() {
		if (this.builderService.analyzerQueryObj) {
			this.basicLayComp.scrollTop();
			this.onTableSelected(
				this.builderService.analyzerQueryObj.catalog,
				this.builderService.analyzerQueryObj.schema,
				this.builderService.analyzerQueryObj.table
			);
		}

		localStorage.setItem("newDate", "");
		localStorage.setItem("DfFormat", "");
		localStorage.setItem("df", "");
	}

	ngAfterViewInit() {
		$(document).ready(function () {
			var contenth = $(window).height() - 110;
			var sidebarh = $(window).height() - 111;
			var treeviewHeight = $(window).height() - 101;
			$(".pagec").css("height", contenth);
			$(".sidebar-wrapper").css("height", sidebarh);
			$(".data_treec").css("max-height", treeviewHeight);

			function treeHeight() {
				var treeviewHeight = $(window).height() - 101;
				$("body").on("click", ".datatree_ico, .tabletree_parent", function () {
					$(".data_treec").css("max-height", treeviewHeight);
				});
			}
			setTimeout(treeHeight, 1000);
		});

		$(window).resize(function () {
			var contenth = $(window).height() - 110;
			var sidebarh = $(window).height() - 111;
			var treeviewHeight = $(window).height() - 101;
			$(".pagec").css("height", contenth);
			$(".sidebar-wrapper").css("height", sidebarh);
			$(".data_treec").css("max-height", treeviewHeight);
		});
	}

	resetGridState() {
		this.columnGroupState = null;
		this.columnState = null;
		this.filterModel = null;
	}

	createServersideDataSourceOntableClick() {
		const datasource = {
			getRows: (params: IServerSideGetRowsParams) => {
				let ssrRequest = params.request;
				let sortModel;
				const { sortModel: sortOrder, ...request } = ssrRequest;

				sortModel = isEmpty(sortOrder)
					? !isEmpty(request.rowGroupCols)
						? [
								{
									colId: `${head(request.rowGroupCols).field}`,
									sort: "asc"
								}
						  ]
						: []
					: sortOrder; // use the sort order from the grid

				let query: AnalyzerRequestOntableClick = {
					tableName: this.selectedTable,
					itemsPerPage: CacheBlockSize,
					sqlText: null,
					sortModel,
					...request
				};

				try {
					this.sqlError = null;
					this.dataExplorerService.serverSideAnalyzer(query).subscribe(
						data => {
							this.result = data;
							this.handleApiResponse(params, this.result);
							this.dataExplorerService.getResultCount(query).subscribe((res : any) =>{
								this.AnalysertotalRows = res.totalRowCount;
							})
						},
						error => {
							params.fail();
							this.sqlError = error;
						}
					);
				} catch (error) {
					params.fail();
					this.sqlError = error;
				}
			},
			destroy: () => {}
		};
		this.dataSource = datasource;
		return this.dataSource;
	}

	onViewTableSelected(viewDataCatalog, viewDataSchema, viewDataTable) {
		if (this.selectedTable !== undefined) {
			if (
				this.selectedTable ==
				viewDataCatalog + "." + viewDataSchema + "." + viewDataTable
			) {
				return;
			} else {
				this.result = {};
				this.sqlError = null;
				this.api.setColumnDefs([]);
				this.api.setServerSideDatasource(this.dataSource);
			}
		}
		this.showResultinDataExplorer = false;
		this.selectedTable =
			viewDataCatalog + "." + viewDataSchema + "." + viewDataTable;
		this.resetGridState();
		this.createServersideDataSourceOntableClick();
	}

	handleApiResponse(params: IServerSideGetRowsParams, res) {
		if (res.error) {
			params.fail();
			this.sqlError = res.error;
		} else {
			const rowData = getRows(res);
			const rowCount = getLastRowIndex(params.request, res.rows);
			this.totalRows = res.totalRowCount;
			let defs = params.api.getColumnDefs();
			if (isEmpty(defs)) {
				defs = getColDefs(res.columns);
			}

			Object.keys(this.cellClassRules).forEach(colId => {
				const cellClassRules = this.cellClassRules[colId];
				if (!isEmpty(cellClassRules)) {
					defs = addCellClassRuleForColId(cellClassRules, colId, defs);
				}
			});
			params.api.setColumnDefs(defs);
			params.success({ rowData, rowCount });
		}
	}

	onGridColumnStateChange(_event: GridStateChangeEvent) {
		this.columnState = this.columnApi.getColumnState();
		this.columnGroupState = this.columnApi.getColumnGroupState();
	}

	onFilterChanged($event: FilterChangedEvent) {
		this.filterModel = $event.api.getFilterModel();
	}

	//===========  Get a table from back end=============

	onTableSelected(catalog, schema, table) {
		if (this.selectedTable !== undefined) {
			if (this.selectedTable == catalog + "." + schema + "." + table) {
				return;
			} else {
				this.result = {};
				this.sqlError = null;
				this.api.setColumnDefs([]);
				this.api.setServerSideDatasource(this.dataSource);
			}
		}
		this.showResultinDataExplorer = false;
		this.selectedTable = catalog + "." + schema + "." + table;
		this.resetGridState();
		this.createServersideDataSourceOnsqlEditor();
	}

	public onGridReady(params: GridReadyEvent) {
		this.api = params.api;
		this.columnApi = params.columnApi;
		this.api.setServerSideDatasource(this.dataSource);
		this.api.setColumnDefs([]);
	}

	createServersideDataSourceOnsqlEditor() {
		const datasource = {
			getRows: (params: IServerSideGetRowsParams) => {
				const query: any = {};
				let limit;
				if (this.builderService.analyzerQueryObj) {
					query.sqlText = this.builderService.analyzerQueryObj.query;
					if (this.builderService.analyzerQueryObj.limits) {
						limit = this.builderService.analyzerQueryObj.limits;
					}
				}
				let ssrRequest = params.request;
				let sortModel;
				const { sortModel: sortOrder, ...request } = ssrRequest;

				sortModel = isEmpty(sortOrder)
					? !isEmpty(request.rowGroupCols)
						? [
								{
									colId: `${head(request.rowGroupCols).field}`,
									sort: "asc"
								}
						  ]
						: []
					: sortOrder; // use the sort order from the grid

				let param: AnalyzerRequestonSqlEditor = {
					tableName: null,
					itemsPerPage: CacheBlockSize,
					sqlText: query.sqlText,
					limit: limit,
					sortModel,
					...request
				};

				try {
					this.sqlError = null;
					this.dataExplorerService.serverSideAnalyzer(param).subscribe(
						data => {
							this.result = data;
							this.handleApiResponse(params, this.result);
							this.dataExplorerService.getResultCount(param).subscribe((res : any) =>{
								this.AnalysertotalRows = res.totalRowCount;
							})
						},
						error => {
							params.fail();
							this.sqlError = error;
						}
					);
				} catch (error) {
					params.fail();
					this.sqlError = error;
				}
			},
			destroy: () => {}
		};
		this.dataSource = datasource;
		return this.dataSource;
	}

	filterParams = {
		filterOptions: [
			"equals",
			"notEqual",
			"lessThan",
			"lessThanOrEqual",
			"greaterThan",
			"greaterThanOrEqual",
			"inRange"
		],
		// use inRangeInclusive: true for the range filter to include the selected
		// from and to dates. Setting it false would fetch only the inbetween dates
		inRangeInclusive: true,
		comparator: function (filterLocalDateAtMidnight, cellValue) {
			let defVal = "YYYY-MM-DD";
			let dateFormat = localStorage.getItem("df");
			let dateAsString;

			if (!dateFormat) {
				dateAsString = moment(cellValue).format(defVal);
			} else {
				dateAsString = moment(cellValue).format(dateFormat);
			}
			//var dateParts = dateAsString.split("/");
			let dateParts;
			let cellDate;
			let datetimeSplit;
			let secondsSplit;
			if (!dateFormat) {
				dateParts = dateAsString.split("-");
				cellDate = new Date(
					Number(dateParts[0]),
					Number(dateParts[1] - 1),
					Number(dateParts[2])
				);
			}

			if (dateFormat == "DD-MM-YYYY") {
				dateParts = dateAsString.split("-");
				cellDate = new Date(
					Number(dateParts[2]),
					Number(dateParts[1]) - 1,
					Number(dateParts[0])
				);
			} else if (dateFormat == "DD.MM.YYYY") {
				dateParts = dateAsString.split(".");
				cellDate = new Date(
					Number(dateParts[2]),
					Number(dateParts[1]) - 1,
					Number(dateParts[0])
				);
			} else if (dateFormat == "DD/MM/YYYY") {
				dateParts = dateAsString.split("/");
				cellDate = new Date(
					Number(dateParts[2]),
					Number(dateParts[1]) - 1,
					Number(dateParts[0])
				);
			} else if (dateFormat == "MM-DD-YYYY") {
				dateParts = dateAsString.split("-");
				cellDate = new Date(
					Number(dateParts[2]),
					Number(dateParts[0] - 1),
					Number(dateParts[1])
				);
			} else if (dateFormat == "MM/DD/YYYY") {
				cellDate = new Date(
					Number(dateParts[2]),
					Number(dateParts[0] - 1),
					Number(dateParts[1])
				);
			} else if (dateFormat == "MM.DD.YYYY") {
				dateParts = dateAsString.split(".");
				cellDate = new Date(
					Number(dateParts[2]),
					Number(dateParts[0] - 1),
					Number(dateParts[1])
				);
			} else if (dateFormat == "YYYY/MM/DD") {
				dateParts = dateAsString.split("/");
				cellDate = new Date(
					Number(dateParts[0]),
					Number(dateParts[1] - 1),
					Number(dateParts[2])
				);
			} else if (dateFormat == "YYYY-MM-DD") {
				dateParts = dateAsString.split("-");
				cellDate = new Date(
					Number(dateParts[0]),
					Number(dateParts[1] - 1),
					Number(dateParts[2])
				);
			} else if (dateFormat == "YYYY.MM.DD") {
				dateParts = dateAsString.split(".");
				cellDate = new Date(
					Number(dateParts[0]),
					Number(dateParts[1] - 1),
					Number(dateParts[2])
				);
			} else if (dateFormat == "YYYY-MM-DD HH:mm:ss") {
				datetimeSplit = dateAsString.split(" ");
				dateParts = datetimeSplit[0].split("-");
				secondsSplit = datetimeSplit[1].split(":");
				cellDate = new Date(
					Number(dateParts[0]),
					Number(dateParts[1] - 1),
					Number(dateParts[2]),
					Number(secondsSplit[0]),
					Number(secondsSplit[1]),
					Number(secondsSplit[2])
				);
			} else if (dateFormat == "YYYY/MM/DD HH:mm:ss") {
				datetimeSplit = dateAsString.split(" ");
				dateParts = datetimeSplit[0].split("/");
				secondsSplit = datetimeSplit[1].split(":");
				cellDate = new Date(
					Number(dateParts[0]),
					Number(dateParts[1] - 1),
					Number(dateParts[2]),
					Number(secondsSplit[0]),
					Number(secondsSplit[1]),
					Number(secondsSplit[2])
				);
			} else if (dateFormat == "YYYY.MM.DD HH:mm:ss") {
				datetimeSplit = dateAsString.split(" ");
				dateParts = datetimeSplit[0].split(".");
				secondsSplit = datetimeSplit[1].split(":");
				cellDate = new Date(
					Number(dateParts[0]),
					Number(dateParts[1] - 1),
					Number(dateParts[2]),
					Number(secondsSplit[0]),
					Number(secondsSplit[1]),
					Number(secondsSplit[2])
				);
			} else if (dateFormat == "MM-DD-YYYY HH:mm:ss") {
				datetimeSplit = dateAsString.split(" ");
				dateParts = datetimeSplit[0].split("-");
				secondsSplit = datetimeSplit[1].split(":");
				cellDate = new Date(
					Number(dateParts[2]),
					Number(dateParts[0] - 1),
					Number(dateParts[1]),
					Number(secondsSplit[0]),
					Number(secondsSplit[1]),
					Number(secondsSplit[2])
				);
			} else if (dateFormat == "MM/DD/YYYY HH:mm:ss") {
				datetimeSplit = dateAsString.split(" ");
				dateParts = datetimeSplit[0].split("/");
				secondsSplit = datetimeSplit[1].split(":");
				cellDate = new Date(
					Number(dateParts[2]),
					Number(dateParts[0] - 1),
					Number(dateParts[1]),
					Number(secondsSplit[0]),
					Number(secondsSplit[1]),
					Number(secondsSplit[2])
				);
			} else if (dateFormat == "MM.DD.YYYY HH:mm:ss") {
				datetimeSplit = dateAsString.split(" ");
				dateParts = datetimeSplit[0].split(".");
				secondsSplit = datetimeSplit[1].split(":");
				cellDate = new Date(
					Number(dateParts[2]),
					Number(dateParts[0] - 1),
					Number(dateParts[1]),
					Number(secondsSplit[0]),
					Number(secondsSplit[1]),
					Number(secondsSplit[2])
				);
			} else if (dateFormat == "DD-MM-YYYY HH:mm:ss") {
				datetimeSplit = dateAsString.split(" ");
				dateParts = datetimeSplit[0].split("-");
				secondsSplit = datetimeSplit[1].split(":");
				cellDate = new Date(
					Number(dateParts[2]),
					Number(dateParts[1] - 1),
					Number(dateParts[0]),
					Number(secondsSplit[0]),
					Number(secondsSplit[1]),
					Number(secondsSplit[2])
				);
			} else if (dateFormat == "DD/MM/YYYY HH:mm:ss") {
				datetimeSplit = dateAsString.split(" ");
				dateParts = datetimeSplit[0].split("/");
				secondsSplit = datetimeSplit[1].split(":");
				cellDate = new Date(
					Number(dateParts[2]),
					Number(dateParts[1] - 1),
					Number(dateParts[0]),
					Number(secondsSplit[0]),
					Number(secondsSplit[1]),
					Number(secondsSplit[2])
				);
			} else if (dateFormat == "DD.MM.YYYY HH:mm:ss") {
				datetimeSplit = dateAsString.split(" ");
				dateParts = datetimeSplit[0].split(".");
				secondsSplit = datetimeSplit[1].split(":");
				cellDate = new Date(
					Number(dateParts[2]),
					Number(dateParts[1] - 1),
					Number(dateParts[0]),
					Number(secondsSplit[0]),
					Number(secondsSplit[1]),
					Number(secondsSplit[2])
				);
			} else if (dateFormat == "YYYY MM DD") {
				dateParts = dateAsString.split(" ");
				cellDate = new Date(
					Number(dateParts[0]),
					Number(dateParts[1] - 1),
					Number(dateParts[2])
				);
			} else if (dateFormat == "MM DD YYYY") {
				dateParts = dateAsString.split(" ");
				cellDate = new Date(
					Number(dateParts[2]),
					Number(dateParts[0] - 1),
					Number(dateParts[1])
				);
			} else if (dateFormat == "DD MM YYYY") {
				dateParts = dateAsString.split(" ");
				cellDate = new Date(
					Number(dateParts[2]),
					Number(dateParts[1] - 1),
					Number(dateParts[0])
				);
			} else if (dateFormat == "YYYY MM DD HH:mm:ss") {
				dateParts = dateAsString.split(" ");
				secondsSplit = dateParts[3].split(":");

				cellDate = new Date(
					Number(dateParts[0]),
					Number(dateParts[1] - 1),
					Number(dateParts[2]),
					Number(secondsSplit[0]),
					Number(secondsSplit[1]),
					Number(secondsSplit[2])
				);
			} else if (dateFormat == "MM DD YYYY HH:mm:ss") {
				dateParts = dateAsString.split(" ");
				secondsSplit = dateParts[3].split(":");

				cellDate = new Date(
					Number(dateParts[2]),
					Number(dateParts[0] - 1),
					Number(dateParts[1]),
					Number(secondsSplit[0]),
					Number(secondsSplit[1]),
					Number(secondsSplit[2])
				);
			} else if (dateFormat == "DD MM YYYY HH:mm:ss") {
				dateParts = dateAsString.split(" ");
				secondsSplit = dateParts[3].split(":");

				cellDate = new Date(
					Number(dateParts[2]),
					Number(dateParts[1] - 1),
					Number(dateParts[0]),
					Number(secondsSplit[0]),
					Number(secondsSplit[1]),
					Number(secondsSplit[2])
				);
			} else if (dateFormat == "YYYY-MM-DDTHH:mm:ss") {
				dateParts = dateAsString.split("-");
				let tSplit = dateParts[2].split("T");
				secondsSplit = tSplit[1].split(":");

				cellDate = new Date(
					Number(dateParts[0]),
					Number(dateParts[1] - 1),
					Number(tSplit[0]),
					Number(secondsSplit[0]),
					Number(secondsSplit[1]),
					Number(secondsSplit[2])
				);
			} else if (dateFormat == "YYYY/MM/DDTHH:mm:ss") {
				dateParts = dateAsString.split("/");
				let tSplit = dateParts[2].split("T");
				secondsSplit = tSplit[1].split(":");

				cellDate = new Date(
					Number(dateParts[0]),
					Number(dateParts[1] - 1),
					Number(tSplit[0]),
					Number(secondsSplit[0]),
					Number(secondsSplit[1]),
					Number(secondsSplit[2])
				);
			} else if (dateFormat == "YYYY.MM.DDTHH:mm:ss") {
				dateParts = dateAsString.split(".");
				let tSplit = dateParts[2].split("T");
				secondsSplit = tSplit[1].split(":");

				cellDate = new Date(
					Number(dateParts[0]),
					Number(dateParts[1] - 1),
					Number(tSplit[0]),
					Number(secondsSplit[0]),
					Number(secondsSplit[1]),
					Number(secondsSplit[2])
				);
			} else if (dateFormat == "MM-DD-YYYYTHH:mm:ss") {
				dateParts = dateAsString.split("-");
				let tSplit = dateParts[2].split("T");
				secondsSplit = tSplit[1].split(":");
				cellDate = new Date(
					Number(tSplit[0]),
					Number(dateParts[0] - 1),
					Number(dateParts[1]),
					Number(secondsSplit[0]),
					Number(secondsSplit[1]),
					Number(secondsSplit[2])
				);
			} else if (dateFormat == "MM/DD/YYYYTHH:mm:ss") {
				dateParts = dateAsString.split("/");
				let tSplit = dateParts[2].split("T");
				secondsSplit = tSplit[1].split(":");
				cellDate = new Date(
					Number(tSplit[0]),
					Number(dateParts[0] - 1),
					Number(dateParts[1]),
					Number(secondsSplit[0]),
					Number(secondsSplit[1]),
					Number(secondsSplit[2])
				);
			} else if (dateFormat == "MM.DD.YYYYTHH:mm:ss") {
				dateParts = dateAsString.split(".");
				let tSplit = dateParts[2].split("T");
				secondsSplit = tSplit[1].split(":");
				cellDate = new Date(
					Number(tSplit[0]),
					Number(dateParts[0] - 1),
					Number(dateParts[1]),
					Number(secondsSplit[0]),
					Number(secondsSplit[1]),
					Number(secondsSplit[2])
				);
			} else if (dateFormat == "DD-MM-YYYYTHH:mm:ss") {
				dateParts = dateAsString.split("-");
				let tSplit = dateParts[2].split("T");
				secondsSplit = tSplit[1].split(":");
				cellDate = new Date(
					Number(tSplit[0]),
					Number(dateParts[1] - 1),
					Number(dateParts[0]),
					Number(secondsSplit[0]),
					Number(secondsSplit[1]),
					Number(secondsSplit[2])
				);
			} else if (dateFormat == "DD/MM/YYYYTHH:mm:ss") {
				dateParts = dateAsString.split("/");
				let tSplit = dateParts[2].split("T");
				secondsSplit = tSplit[1].split(":");
				cellDate = new Date(
					Number(tSplit[0]),
					Number(dateParts[1] - 1),
					Number(dateParts[0]),
					Number(secondsSplit[0]),
					Number(secondsSplit[1]),
					Number(secondsSplit[2])
				);
			} else if (dateFormat == "DD.MM.YYYYTHH:mm:ss") {
				dateParts = dateAsString.split(".");
				let tSplit = dateParts[2].split("T");
				secondsSplit = tSplit[1].split(":");
				cellDate = new Date(
					Number(tSplit[0]),
					Number(dateParts[1] - 1),
					Number(dateParts[0]),
					Number(secondsSplit[0]),
					Number(secondsSplit[1]),
					Number(secondsSplit[2])
				);
			} else if (dateFormat == "YYYY MM DDTHH:mm:ss") {
				dateParts = dateAsString.split(" ");
				let tSplit = dateParts[2].split("T");
				secondsSplit = tSplit[1].split(":");

				cellDate = new Date(
					Number(dateParts[0]),
					Number(dateParts[1] - 1),
					Number(tSplit[0]),
					Number(secondsSplit[0]),
					Number(secondsSplit[1]),
					Number(secondsSplit[2])
				);
			} else if (dateFormat == "MM DD YYYYTHH:mm:ss") {
				dateParts = dateAsString.split(" ");
				let tSplit = dateParts[2].split("T");
				secondsSplit = tSplit[1].split(":");
				cellDate = new Date(
					Number(tSplit[0]),
					Number(dateParts[0] - 1),
					Number(dateParts[1]),
					Number(secondsSplit[0]),
					Number(secondsSplit[1]),
					Number(secondsSplit[2])
				);
			} else if (dateFormat == "DD MM YYYYTHH:mm:ss") {
				dateParts = dateAsString.split(" ");
				let tSplit = dateParts[2].split("T");
				secondsSplit = tSplit[1].split(":");
				cellDate = new Date(
					Number(tSplit[0]),
					Number(dateParts[1] - 1),
					Number(dateParts[0]),
					Number(secondsSplit[0]),
					Number(secondsSplit[1]),
					Number(secondsSplit[2])
				);
			}

			if (filterLocalDateAtMidnight.getTime() == cellDate.getTime()) {
				return 0;
			}

			if (cellDate < filterLocalDateAtMidnight) {
				return -1;
			}

			if (cellDate > filterLocalDateAtMidnight) {
				return 1;
			}
		}
	};

	//----------------------------------------------------------------------------------------------
}
