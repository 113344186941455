import {
	ColDef,
	ColumnMovedEvent,
	ColumnPinnedEvent,
	ColumnPivotChangedEvent,
	ColumnPivotModeChangedEvent,
	ColumnResizedEvent,
	ColumnRowGroupChangedEvent,
	ColumnVisibleEvent,
	GridOptions,
	IServerSideGetRowsRequest,
	ServerSideStoreType
} from "ag-grid-community";
import {
	isDateColumn,
	isNumericColumn,
	isTextColumn
} from "../analyzer/column.type";
import {
	addIndex,
	assoc,
	curry,
	differenceWith,
	isEmpty,
	lensProp,
	map,
	pick,
	prop,
	propEq,
	set,
	when,
	zipObj
} from "ramda";
import { Column, ColumnType } from "../analyzer/tableexp.response.type";
export const MaxBlocksInCache = 2;
export const CacheBlockSize = 1000;
import * as moment from "moment";

export const getCellClassRules = {
	greaterThan: params => {
		return {
			"cell-pass": data => data.value >= params.value,
			"cell-fail": data => data.value < params.value
		};
	},
	lessThan: params => {
		return {
			"cell-pass": data => data.value <= params.value,
			"cell-fail": data => data.value > params.value
		};
	},
	equalTo: params => {
		return {
			"cell-pass": data => data.value === params.value,
			"cell-fail": data => data.value === params.value
		};
	}
};

export const defaultGridOptions: GridOptions = {
	// components: {
	// 	loadingRenderer: function (params) {
	// 		if (params.value !== undefined) {
	// 			return params.value;
	// 		} else {
	// 			return '<img src="https://www.ag-grid.com/example-assets/loading.gif">';
	// 		}
	// 	}
	// },
	sideBar: {
		toolPanels: [
			{
				id: "columns",
				labelDefault: "Columns",
				labelKey: "columns",
				iconKey: "columns",
				toolPanel: "agColumnsToolPanel"
			},
			{
				id: "filters",
				labelDefault: "Filters",
				labelKey: "filters",
				iconKey: "filter",
				toolPanel: "agFiltersToolPanel"
			}
		],
		defaultToolPanel: ""
	},
	rowBuffer: 0,
	enableCharts: true,
	enableRangeSelection: true,
	animateRows: true,
	rowGroupPanelShow: "onlyWhenGrouping",
	rowSelection: "multiple",
	rowDragManaged: true,
	rowDragMultiRow: true,
	suppressRowDrag: true,
	overlayLoadingTemplate: '<span class="page_loader"></span>',
	// tell grid we want virtual row model type
	// how big each page in our page cache will be, default is 100
	// how many extra blank rows to display to the user at the end of the dataset,
	// which sets the vertical scroll and then allows the grid to request viewing more rows of data.
	// default is 1, ie show 1 row.
	cacheOverflowSize: 2,
	// how many server side requests to send at a time. if user is scrolling lots, then the requests
	// are throttled down
	maxConcurrentDatasourceRequests: 1,
	// how many rows to initially show in the grid. having 1 shows a blank row, so it looks like
	// the grid is loading from the users perspective (as we have a spinner in the first col)
	infiniteInitialRowCount: 1000,
	// how many pages to store in cache. default is undefined, which allows an infinite sized cache,
	// pages are never purged. this should be set for large data to stop your browser from getting
	// full of data
	maxBlocksInCache: 10,
	cacheBlockSize: CacheBlockSize,
	// groupSelectsChildren: true,

	// maxBlocksInCache: MaxBlocksInCache,

	defaultColDef: {
		// flex: 1,
		resizable: true,
		// minWidth: 100,
		sortable: true,
		filter: true,
		floatingFilter: true,
		enableRowGroup: true
	},

	// rowData: [],
	statusBar: {
		statusPanels: [
			{ statusPanel: "agSelectedRowCountComponent" },
			{ statusPanel: "agAggregationComponent" }
		]
	},

	getContextMenuItems: params => {
		const replaceArrayItemProp = curry((value, colId, arr) =>
			map(when(propEq("colId", colId), assoc("cellClassRules", value)), arr)
		);
		const menuItems = [
			{
				// custom item
				name: "Highlight Cells Rule",
				subMenu: [
					{
						name: "Greater Than...  ",
						action: function () {
							// window.alert("Alerting about " + params.value);
							const cellClassRules = getCellClassRules.greaterThan(params);

							const colDefs = replaceArrayItemProp(
								cellClassRules,
								params.column.getColId(),
								params.api.getColumnDefs()
							);

							params.api.setColumnDefs(colDefs);
						}
					},
					{
						name: "Less Than...",
						action: function () {
							// window.alert("Alerting about " + params.value);
							const cellClassRules = getCellClassRules.lessThan(params);

							const colDefs = replaceArrayItemProp(
								cellClassRules,
								params.column.getColId(),
								params.api.getColumnDefs()
							);
							params.api.setColumnDefs(colDefs);
						}
					},
					{
						name: "Equal To...",
						action: function () {
							// window.alert("Alerting about " + params.value);
							const cellClassRules = getCellClassRules.equalTo(params);

							const colDefs = replaceArrayItemProp(
								cellClassRules,
								params.column.getColId(),
								params.api.getColumnDefs()
							);
							params.api.setColumnDefs(colDefs);
						}
					}
					// {
					//   name: "Text that Contains...",
					//   action: function () {
					//     // window.alert("Alerting about " + params.value);
					//     const cellClassRules = {
					//       "cell-pass": (data) =>
					//         data.value && data.value.includes(params.value),
					//       "cell-fail": (data) =>
					//         !data.value ||
					//         (data.value && !data.value.includes(params.value)),
					//     };

					//     const colDefs = replaceArrayItemProp(
					//       cellClassRules,
					//       params.column.colId,
					//       params.api.getColumnDefs()
					//     );
					//     params.api.setColumnDefs(colDefs);
					//   },
					// },
				]

				// cssClasses: ["redFont", "bold"],
			},
			{
				name: "Clear Rules",
				subMenu: [
					{
						name: "Clear Rules from Selected Column",
						action: function () {
							const colDefs = replaceArrayItemProp(
								null,
								params.column.getColId(),
								params.api.getColumnDefs()
							);
							params.api.setColumnDefs(colDefs);
							params.api.redrawRows();
						}
					},
					{
						name: "Clear Rules from Entire Sheet",
						action: function () {
							const xLens = lensProp("cellClassRules");
							const colDefs = params.api.getColumnDefs();
							const updatedColDefs = map(set(xLens, null), colDefs);
							params.api.setColumnDefs(updatedColDefs);
							params.api.redrawRows();
						}
					}
				]
			},
			"separator",
			"copy",
			"copyWithHeaders",
			"paste",
			"separator",
			"chartRange",
			"export"
		];

		return menuItems;
	}
};

export const getContextMenuItems =
	(
		onHighlightAdded?: ({ colId: string, cellClassRules: any }) => void,
		onHighlightRemoved?: ({ colId: string }) => void
	) =>
	params => {
		const replaceArrayItemProp = curry((value, colId, arr) =>
			map(when(propEq("colId", colId), assoc("cellClassRules", value)), arr)
		);
		const menuItems = [
			{
				// custom item
				name: "Highlight Cells Rule",
				subMenu: [
					{
						name: "Greater Than...  ",
						action: function () {
							// window.alert("Alerting about " + params.value);
							const cellClassRules = getCellClassRules.greaterThan(params);

							const colDefs = replaceArrayItemProp(
								cellClassRules,
								params.column.getColId(),
								params.api.getColumnDefs()
							);

							onHighlightAdded({
								colId: params.column.getColId(),
								cellClassRules
							});
							params.api.setColumnDefs(colDefs);
						}
					},
					{
						name: "Less Than...",
						action: function () {
							// window.alert("Alerting about " + params.value);
							const cellClassRules = getCellClassRules.lessThan(params);

							const colDefs = replaceArrayItemProp(
								cellClassRules,
								params.column.getColId(),
								params.api.getColumnDefs()
							);
							onHighlightAdded({
								colId: params.column.getColId(),
								cellClassRules
							});
							params.api.setColumnDefs(colDefs);
						}
					},
					{
						name: "Equal To...",
						action: function () {
							// window.alert("Alerting about " + params.value);
							const cellClassRules = getCellClassRules.equalTo(params);

							const colDefs = replaceArrayItemProp(
								cellClassRules,
								params.column.getColId(),
								params.api.getColumnDefs()
							);
							onHighlightAdded({
								colId: params.column.getColId(),
								cellClassRules
							});
							params.api.setColumnDefs(colDefs);
						}
					}
					// {
					//   name: "Text that Contains...",
					//   action: function () {
					//     // window.alert("Alerting about " + params.value);
					//     const cellClassRules = {
					//       "cell-pass": (data) =>
					//         data.value && data.value.includes(params.value),
					//       "cell-fail": (data) =>
					//         !data.value ||
					//         (data.value && !data.value.includes(params.value)),
					//     };

					//     const colDefs = replaceArrayItemProp(
					//       cellClassRules,
					//       params.column.colId,
					//       params.api.getColumnDefs()
					//     );
					//     params.api.setColumnDefs(colDefs);
					//   },
					// },
				]

				// cssClasses: ["redFont", "bold"],
			},
			{
				name: "Clear Rules",
				subMenu: [
					{
						name: "Clear Rules from Selected Column",
						action: function () {
							const colDefs = replaceArrayItemProp(
								null,
								params.column.getColId(),
								params.api.getColumnDefs()
							);
							onHighlightRemoved({ colId: params.column.getColId() });
							params.api.setColumnDefs(colDefs);
							params.api.redrawRows();
						}
					},
					{
						name: "Clear Rules from Entire Sheet",
						action: function () {
							const xLens = lensProp("cellClassRules");
							const colDefs = params.api.getColumnDefs();
							const updatedColDefs = map(set(xLens, null), colDefs);
							onHighlightRemoved({ colId: params.column.getColId() });
							params.api.setColumnDefs(updatedColDefs);
							params.api.redrawRows();
						}
					}
				]
			},
			"separator",
			"copy",
			"copyWithHeaders",
			"paste",
			"separator",
			"chartRange",
			"export"
		];

		return menuItems;
	};
export const partialGridOptions: GridOptions = Object.assign(
	{},
	defaultGridOptions,
	{
		rowModelType: "serverSide",
		serverSideStoreType: "partial" as ServerSideStoreType
	}
);

export const getColDefs = columns => {
	if (isEmpty(columns)) return [];
	const mapIndexed = addIndex(map);
	if (columns) {
		const colDefs: Array<ColDef> = mapIndexed((column: ColumnType) => {
			const col: ColDef = {
				field: column.label,
				sortable: true
			};
			// if (index === 0) {
			// 	col.checkboxSelection = true;
			// 	// col.rowDrag = true;
			// }

			if (isNumericColumn(column.type)) {
				col.enableValue = true;
				col.allowedAggFuncs = ["avg", "count", "sum", "min", "max"];
				col.filter = "agNumberColumnFilter";
			} else if (isTextColumn(column.type)) {
				col.filter = "agTextColumnFilter";
			} else if (isDateColumn(column.type)) {
				col.filter = "agDateColumnFilter";
				//col.filterParams = filterParams;
				col.cellRenderer = data => {
					//let newDate = data.value;
					let newDate;
					if(column.type.toLowerCase() =='timestamp'){
						newDate = moment(data.value).format("YYYY-MM-DD HH:mm:ss.S");
					}else{
						newDate = moment(data.value).format("YYYY-MM-DD");
					}
					localStorage.setItem("newDate", newDate);
					//localStorage.setItem("DfFormat", "YYYY-MM-DD");
					return newDate;
				};
			}

			col.filterParams = {
				// can be 'windows' or 'mac'
				buttons: ["reset"],
				closeOnApply: true,
				debounceMs: 500,
				excelMode: "windows"
			};
			return col;
		}, columns);
		return colDefs;
	}
	return [];
};

export const getRows = res => {
	if (res.columns && res.rows) {
		const fields: string[] = map(prop("label"), res.columns);
		const rowData = map(zipObj(fields), res.rows);
		return rowData;
	}
	return [];
};

export type GridSortModel = {
	colId: string | undefined;
	sort: string | null | undefined;
};

export type GridStateChangeEvent =
	| ColumnVisibleEvent
	| ColumnPinnedEvent
	| ColumnResizedEvent
	| ColumnMovedEvent
	| ColumnRowGroupChangedEvent
	| ColumnPivotChangedEvent
	| ColumnPivotModeChangedEvent;

export const getColumnState = columnState => {
	if (!columnState) return [];
	const state = columnState.map(col => {
		const def = pick(
			[
				"colId",
				"hide",
				"pivot",
				"width",
				"rowGroup",
				"sort",
				"aggFunc",
				"pinned"
			],
			col
		);

		def.rowGroupIndex = col.rowGroup ? col.rowGroupIndex : null;
		def.sortIndex = col.sort ? col.sortIndex : null;
		def.pivotIndex = col.pivot ? col.pivotIndex : null;

		return def;
	});
	return state;
};

export function getLastRowIndex(request, results) {
	if (!results) return undefined;
	var currentLastRow = request.startRow + results.length;

	// if on or after the last block, work out the last row, otherwise return 'undefined'
	return currentLastRow < request.endRow ? currentLastRow : undefined;
}

export function removeDuplicates(array) {
	return Array.from(new Set(array.map(JSON.stringify))).map((val: string) =>
		JSON.parse(val)
	);
}
export function findCommonColumns(arr1: Array<Column>, arr2: Array<Column>) {
	const intersection = arr1.filter(item1 =>
		arr2.some(item2 => item1.name === item2.name)
	);
	return intersection;
}

export function findColumnDifference(
	list1: Array<Column>,
	list2: Array<Column>
) {
	const cmp = (x: Column, y: Column) => x.name === y.name;
	differenceWith(cmp, list1, list2);
}

export const addCellClassRuleForColId = curry((value, colId, arr) =>
	map(when(propEq("colId", colId), assoc("cellClassRules", value)), arr)
);

export const defaultSSRRequestParams: IServerSideGetRowsRequest = {
	startRow: 0,
	endRow: 1000,
	rowGroupCols: [],
	valueCols: [],
	pivotCols: [],
	pivotMode: false,
	groupKeys: [],
	filterModel: {},
	sortModel: []
};
