import { NgModule } from "@angular/core";
import { NgbModule, NgbPopoverModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

import { CronGenComponent } from "./cron-editor.component";
import { TimePickerComponent } from "./cron-time-picker.component";

@NgModule({
	imports: [CommonModule, FormsModule, NgbModule, NgbPopoverModule],
	exports: [CronGenComponent, TimePickerComponent],
	declarations: [CronGenComponent, TimePickerComponent]
})
export class CronEditorModule {}
