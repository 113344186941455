import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})

export class DataExploreModel {
delimiter:any;
headerRow:any;
enclosedfield:any;
configDescription:any;
fileName:any;
saveHivedetails:any;
saveTableColumns:any;
fileDetails:any;
columnCount:any;
hiveDatabaseName:any;
hiveTableName:any;
columnSlider:boolean;
columnName:any=[];
columnType:any=[];
totalFiles:any;
headerRecords:any;
savedRecords:any;
headerRows:any=[];
headerResults:any;
syncSlider:any;
editMode:any;
updatedRecords:any;
ingestJson:any;
ingestCreateDtl:any;
responseData:any;
fileSize:any;
conditionType:any;
headerCheck:any;
dataIngestionColumns:any=[];
}
