import {
	Component,
	OnInit,
	OnDestroy,
	AfterViewInit,
	EventEmitter
} from "@angular/core";
import { Router } from "@angular/router";
import { DataExplorerService } from "src/app/services/data-explorer/data-explorer.service";
import {
	MaterialisedJobs,
	DataExplorerJobDetailsList
} from "../../models/jobs/jobs.model";
import { interval } from "rxjs";
import { OrderPipe } from "ngx-order-pipe";
import * as $ from "jquery";
import swal from "sweetalert2";
import { VisualQueryBuilderService } from "../../components/data-explore/visual-query-builder/visual-query-builder.service";

@Component({
	selector: "app-data-jobs",
	templateUrl: "./data-jobs.component.html",
	styleUrls: ["./data-jobs.component.scss"]
})
export class DataJobsComponent implements OnInit, AfterViewInit, OnDestroy {
	interval;
	jobs: MaterialisedJobs;
	filteredJobs: DataExplorerJobDetailsList[];
	totalJobs = 0;
	p: number = 1;
	order: string = "name";
	reverse: boolean = false;
	loadFull;
	expand = true;
	collapse = true;
	jobDetails: any;
	selectedJob: any;
	status_running;
	status_finished;
	status_pending;
	status_error;
	jobCount;
	stepLog;
	selectAll = false;
	jobSelected = false;
	public focusSettingEventEmitter = new EventEmitter<boolean>();
	constructor(
		private dataExplorerService: DataExplorerService,
		private orderPipe: OrderPipe,
		private router: Router,
		private builderService: VisualQueryBuilderService
	) {
		// this.router.routeReuseStrategy.shouldReuseRoute = () => false;
	}

	ngAfterViewInit() {
		$(document).ready(function () {
			var contenth = $(window).height() - 110;
			var sidebarh = $(window).height() - 111;
			$(".pagec").css("height", contenth);
			$(".sidebar-wrapper").css("height", sidebarh);
		});
		this.focusSettingEventEmitter.emit(true);
	}

	ngOnInit() {
		this.loadFull = true;
		this.getJobs();
		// this.interval = setInterval(() => {
		//   this.applyFilter();
		// }, 10000);

		this.setOrder("name");
		this.getJobStatusCount();
		this.clearVal();
	}

	ngOnDestroy() {
		if (this.interval) {
			clearInterval(this.interval);
		}
	}
	clearInterval(interval) {
		this.interval = setInterval(() => {}, 0);
	}
	refreshTable() {
		this.loadFull = true;
		this.jobSelected = false;
		this.selectAll = false;
		this.applyFilter();
	}
	applyFilter() {
		if (this.selectedJob) this.getJobDeatils();
		let status;
		if (
			(this.status_running &&
				this.status_finished &&
				this.status_pending &&
				this.status_error) ||
			(!this.status_running &&
				!this.status_finished &&
				!this.status_pending &&
				!this.status_error)
		) {
			this.getJobs();
			return;
		} else if (this.status_running) status = "RUNNING";
		else if (this.status_finished) status = "FINISHED";
		else if (this.status_pending) status = "PENDING";
		else if (this.status_error) status = "ERROR";

		this.dataExplorerService
			.getMaterializedJobsbyStatus(status)
			.subscribe((data: MaterialisedJobs) => {
				this.filteredJobs = data.dataExplorerJobDetailsList;
				this.filteredJobs.filter(item => {
					item.status = item.status.toUpperCase();
					item.percent = Number(item.percentage.split("%")[0]);
					if (item.jobName.split(".").length == 3) {
						item.database = item.jobName.split(".")[1];
						item.tableName = item.jobName.split(".")[2];
					} else {
						item.database = item.jobName.split(".")[0];
						item.tableName = item.jobName.split(".")[1];
					}
				});
				this.jobs = data;
				this.totalJobs = this.jobs.dataExplorerJobDetailsList.length;
				this.loadFull = false;
			});
	}
	setOrder(value: string) {
		if (this.order === value) {
			this.reverse = !this.reverse;
		}

		this.order = value;
	}
	getJobs() {
		this.dataExplorerService
			.getMaterializedJobs()
			.subscribe((data: MaterialisedJobs) => {
				this.filteredJobs = data.dataExplorerJobDetailsList;
				this.filteredJobs.filter(item => {
					item.status = item.status.toUpperCase();
					item.percent = Number(item.percentage.split("%")[0]);
					if (item.jobName.split(".").length == 3) {
						item.database = item.jobName.split(".")[1];
						item.tableName = item.jobName.split(".")[2];
					} else {
						item.database = item.jobName.split(".")[0];
						item.tableName = item.jobName.split(".")[1];
					}
				});
				this.jobs = data;
				this.totalJobs = this.jobs.dataExplorerJobDetailsList.length;
				this.loadFull = false;
			});
	}
	getJobDeatils() {
		this.dataExplorerService
			.jobDetails(this.selectedJob.jobId)
			.subscribe(data => {
				this.jobDetails = data;
			});
	}
	getSplitedName(name, flag) {
		if (name.split(".").length == 3) {
			if (flag == "db") {
				return name.split(".")[1];
			} else return name.split(".")[2];
		} else {
			if (flag == "db") {
				return name.split(".")[0];
			} else return name.split(".")[1];
		}
	}
	deleteJob(job) {
		swal({
			type: "warning",
			title: "Are you sure you want to delete the job?",
			showCancelButton: true,
			confirmButtonText: "Yes",
			cancelButtonText: "No"
		}).then(
			status => {
				if (status.value == true) {
					this.loadFull = true;
					this.dataExplorerService.deletetMaterializedJobs(job.jobId).subscribe(
						data => {
							this.loadFull = false;
							swal("Success!", "Job has been deleted", "success");
							this.getJobs();
							this.getJobStatusCount();
						},
						error => {
							swal("Ooops!", error.msg.msg, "error");
							this.loadFull = false;
						}
					);
				}
			},
			dismiss => {
				if (dismiss === "cancel") {
				}
			}
		);
	}
	getJobStatusCount() {
		this.dataExplorerService.getJobStatusCount().subscribe((data: any) => {
			this.jobCount = data;
		});
	}
	getJobStepOut(job) {
		this.dataExplorerService
			.getJobStepOutput(job.jobId, job.id)
			.subscribe((data: any) => {
				this.stepLog = data.dataExplorerJobStepOutPut;
			});
	}
	selectAllBoxes() {
		let jobs: any;
		for (jobs of this.filteredJobs) {
			if (
				jobs.status === "DISCARDED" ||
				jobs.status === "ERROR" ||
				jobs.percent === 100
			)
				jobs.selected = this.selectAll;
		}
		this.jobSelected = this.selectAll;
	}
	checkAnyJobSelected() {
		let selected = true;
		let jobSelected = false;
		let jobs: any;
		for (jobs of this.filteredJobs) {
			if (!jobs.selected) selected = false;
			else jobSelected = true;
		}
		this.jobSelected = jobSelected;
		this.selectAll = selected;
	}
	deleteSelctedJobs() {
		swal({
			type: "warning",
			title: "Are you sure you want to delete this jobs?",
			showCancelButton: true,
			confirmButtonText: "Yes",
			cancelButtonText: "No"
		}).then(status => {
			if (status.value == true) {
				let delJobs = [];
				let jobs: any;
				for (jobs of this.filteredJobs) {
					if (jobs.selected) {
						delJobs.push(jobs.jobId);
					}
				}
				this.dataExplorerService.deleteMultipleJobs(delJobs).subscribe(
					(data: any[]) => {
						swal("Success", "Selected jobs deleted", "success");
						this.jobSelected = false;
						this.getJobs();
						this.getJobStatusCount();
					},
					error => {
						swal("Ooops", error.error.msg, "error");
					}
				);
			}
		});
	}

	clearVal() {
		this.builderService.getCatalog = undefined;
		this.builderService.getCol = undefined;
		this.builderService.getSchema = undefined;
		this.builderService.getTable = undefined;
		this.builderService.getmainQuery = undefined;
		this.builderService.join = undefined;
		this.builderService.result = undefined;
		this.builderService.tableList = undefined;
		this.builderService.tableList = undefined;
		this.builderService.filters = undefined;
		this.builderService.groupBy = undefined;
		this.builderService.sortBy = undefined;
		this.builderService.limits = undefined;
		this.builderService.multiColumns = undefined;
		this.builderService.colListing = undefined;
		this.builderService.tables = [];
		this.builderService.fromTable = [];
		this.builderService.fromField = [];
		this.builderService.fromselectedTable = undefined;
		this.builderService.toTable = undefined;
		this.builderService.toField = undefined;
		this.builderService.toselectedTable = undefined;
		this.builderService.selectedField = undefined;
		this.builderService.titleList = [];
		this.builderService.selectedTableCols = [];
		this.builderService.newselectedTableCols = undefined;
		this.builderService.castArray = [];
		this.builderService.selectedElementDtls = [];
		this.builderService.selectedTableArr = [];
		this.builderService.selectedTableSort = undefined;
		this.builderService.colListingArray = [];
		this.builderService.selectedJoinCols = [];
		this.builderService.newSelectedJoinCols;
		this.builderService.comboArray = [];
		this.builderService.noColumnerrorMsg = undefined;
		this.builderService.selectSortCols = undefined;
		this.builderService.selectSortColsArr = [];
		this.builderService.selectSortColsJoin = undefined;
		this.builderService.selectSortColsJoinArr = [];
		this.builderService.selectSortColsAll = undefined;
		this.builderService.selectSortColArrAll = [];
		this.builderService.selectSortColsJoinAll = undefined;
		this.builderService.selectSortColsJoinArrAll = [];
		this.builderService.countAll = undefined;
		this.builderService.analyzerQueryObj = undefined;
		this.builderService.removeerrMsg = undefined;
	}
}
