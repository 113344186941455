interface column {
	SchemaId?: any;
	catalog?: any;
	column?: any;
	last_modified?: any;
	schema?: any;
	table?: any;
	tableId?: any;
	type?: any;
	uuid?: any;
	version?: any;
}
interface prestoTableColumnList {
	prestoTableColumnList: column[];
}
interface dbTable {
	catalog?: any;
	last_modified?: any;
	schema?: any;
	schemaId?: any;
	table?: any;
	uuid?: any;
	version?: any;
	columns?: column[];
}
interface prestoSchemaTableList {
	prestoSchemaTableList: dbTable[];
}
interface schema {
	schema?: any;
	catalog?: any;
	uuid?: any;
	version?: any;
	last_modified?: any;
	connectionId?: any;
	tableList?: dbTable[];
}
interface prestoCatalogSchemaList {
	prestoCatalogSchemaList: schema[];
}
interface dbList {
	connectionId?: any;
	catalog?: any;
	dbType?: any;
	uuid?: any;
	version?: any;
	last_modified?: any;
	numberOfSchema?: any;
	schemaList?: schema[];
	qubzDataWarehouse?: any;
}
interface prestoData {
	prestoCatalogList: dbList[];
}

interface column {
	schedulerId?: any;
	name?: any;
	database?: any;
	table?: any;
	query?: any;
	cronString?: any;
	submitter?: any;
	triggerKeyName?: any;
	triggerKeyGroup?: any;
	status?: any;
	jobCurrentDate?: any;
}

interface MViewSchedulerDetailsList {
	MViewSchedulerDetailsList: column[];
}

import {
	Component,
	OnInit,
	AfterViewInit,
	Output,
	OnDestroy,
	ViewChild,
	EventEmitter,
	AfterContentChecked
} from "@angular/core";
import * as $ from "jquery";
import swal from "sweetalert2";
import {
	FormBuilder,
	FormGroup,
	FormArray,
	FormControl,
	Validators,
	NgForm
} from "@angular/forms";
import { DataExplorerService } from "../../services/data-explorer/data-explorer.service";
import { OrderPipe } from "ngx-order-pipe";
import { Subscription } from "rxjs";
import { MetadataService } from "../../services/ace-editor/metadata.service";
import { CompleterService } from "../../services/ace-editor/completer.service";
import { Router } from "@angular/router";
import { CommonServiceService } from "../../services/common-services/common-service.service";
import { VisualQueryBuilderService } from "./visual-query-builder/visual-query-builder.service";
import { AnalyzerComponent } from "../../components/analyzer/analyzer.component";
import { HttpClient } from "@angular/common/http";
import {
	GridApi,
	ColumnApi,
	ColumnState,
	GridOptions,
	GridReadyEvent,
	FilterChangedEvent,
	IServerSideGetRowsParams
} from "ag-grid-community";
import { GridColumnGroupState } from "../models/Analyzer";
import { head, isEmpty } from "ramda";
import { AnalyzerRequestonSqlEditor } from "../analyzer/analyzer.request.type";

import {
	addCellClassRuleForColId,
	CacheBlockSize,
	getColDefs,
	getContextMenuItems,
	getLastRowIndex,
	getRows,
	GridStateChangeEvent,
	partialGridOptions
} from "../analyzer/analyzer.utils";
import { CustomDateComponent } from "src/app/components/common/custom-date/custom-date.component";

@Component({
	selector: "app-data-explore",
	templateUrl: "./data-explore.component.html",
	styleUrls: ["./data-explore.component.scss"]
})
export class DataExploreComponent
	implements OnInit, AfterViewInit, AfterContentChecked, OnDestroy
{
	@ViewChild(AnalyzerComponent) AnalyzerComponent: AnalyzerComponent;
	load;
	showQubzType = false;
	showType = false;
	isFullscreen: boolean = false;
	typePassword;
	show_pwd: boolean = true;
	testStatus = false;
	saveStatus = false;
	testButton = true;
	form: FormGroup;
	selectedDbType;
	isActive: any[] = [];
	tableIsActive: any[] = [];
	columnIsActive: any[] = [];
	dbListArr: dbList[];
	popupName = "";
	isEdit = false;
	selectedConnectionId = null;
	showToggle: any[] = [];
	createModal: boolean;
	isQueryAvailable = true;
	qdwLoader;
	sourceLoader;
	showHide = true;
	fullSmallScreen = true;
	users = [
		{ id: "iqsandbox", name: "IQSandbox" },
		//{ id: 'qubz', name: 'QUBZDW' },
		{ id: "hive2", name: "Hive" },
		// {id: 'denodo', name: 'Denodo'},
		// {id: 'synapse', name: 'Azure Synapse Analytics'},
		{ id: "mysql", name: "MySQL" },
		{ id: "redshift", name: "Amazon Redshift " },
		{ id: "sqlserver", name: "Microsoft SQL Server" },
		{ id: "postgresql", name: "PostgreSQL" },
		{ id: "oracle", name: "Oracle" },
		{ id: "memsql", name: "MemSQL" },
		// {id: 'db2', name: 'DB2'},
		{ id: "mongo", name: "MongoDB" },
		{ id: "kafka", name: "Kafka" },
		{ id: "bigquery", name: "Google BigQuery" },
		{ id: "snowflake", name: "Snowflake" },
		{ id: "iq_warehouse", name: "IQWarehouse" }
		// { id: "salesforce", name: "Salesforce" } hiding for now. Will enable once we implement from backend.
	];
	dbTypes = [];
	dbImage = [
		{ type: "mysql", value: "../../../assets/images/mysql_ico.png" },
		{ type: "postgresql", value: "../../../assets/images/postgreysql_ico.png" },
		{ type: "redshift", value: "../../../assets/images/redshift_ico.png" },
		{ type: "sqlserver", value: "../../../assets/images/sqlserver_ico.png" },
		{ type: "oracle", value: "../../../assets/images/oracle_ico.png" },
		{ type: "hive2", value: "../../../assets/images/hive_ico.png" },
		{ type: "iq_warehouse", value: "../../../assets/images/warehouse_ico.png" },
		{ type: "memsql", value: "../../../assets/images/memsql_ico.png" },
		{ type: "mongodb", value: "../../../assets/images/mongo_ico.png" },
		{ type: "bigquery", value: "../../../assets/images/bigquery.png" },
		{ type: "kafka", value: "../../../assets/images/kafka.png" },
		{ type: "snowflake", value: "../../../assets/images/snoflake.png" }
		// { type: "salesforce", value: "../../../assets/images/salesforce.jpg" }  hiding for now. Will enable once we implement from backend.
	];
	result;
	order: string = "header.label";
	reverse: boolean = false;
	sortedCollection;
	limit: number = 5000;
	check: boolean = true;
	mainQuery;
	options;
	page: number = 1;
	status;
	error;
	startTime;
	cachedQueries = [];
	history: any;
	subscription: Subscription;
	queriesHistory: any;
	isShowquery: boolean = false;
	isShowquerydiv: boolean = false;
	queryView;
	catalogName;
	currentCatalog;
	currentSchema;
	currentTable;
	showModal: boolean;
	queryDescription: any;
	queryName: any;
	savedQueryList: any = [];
	loggedUser: any;
	savedQuerysubscription: Subscription;
	saveQury: any;
	showMatModal: boolean;
	tableNamePattern = "^[A-Za-z0-9_-]{1,100}$";
	showDatabaseNameInDropDown: boolean = true;
	showDatabaseNameInTextField: boolean = true;
	databaseList: any;
	selectedDb;
	selectedQw;
	selectedTable;
	selectedQwTable;
	loader;
	disableInputs: boolean = false;
	queries1 = [];
	currentQueries = [];
	indexTab;
	resultViewTab;
	curQuery: boolean;
	refresh;
	current: boolean;
	statusActive;
	loadResult;
	searchText = null;
	showViewDataTab: boolean = true;
	loadViewData: any;
	searchColumnNameTxt = null;
	@Output() filterEvent: EventEmitter<any> = new EventEmitter();
	viewTableDetails: any;
	step1: boolean = true;
	step2: boolean = false;
	step3: boolean = false;
	step4a: boolean = false;
	step4b: boolean = false;
	step5: boolean = false;
	step6: boolean = false;
	resultView;
	loadViewResult: any;
	viewDataCatalog;
	viewDataSchema;
	viewDataTable;
	viewDataError;
	queryViewData;
	resultViewStatus;
	resultViewTime;
	showViewDataMatModal: boolean;
	showViewDataDatabaseNameInDropDown: boolean = true;
	showviewDataDatabaseNameInTextField: boolean = true;
	expandCollapse: boolean = false;
	showkafka = {
		showkafka_c: false,
		showexplore_c: true
	};
	showDiv = {
		puopen: false,
		puopenbg: false,
		stepone: true,
		configstreaming: false,
		cancelbtn: true,
		nextbtn: true,
		backbtn: false,
		submitbtn: false
	};
	statusList = [
		{ name: "All", value: "All" },
		{ name: "Executing", value: "Executing" },
		{ name: "Success", value: "Success" },
		{ name: "Failed", value: "Failed" }
	];
	listData;
	metainfo;
	dataList;
	metadata$ = this.service.getMetadata();
	isKafkaFormValid: boolean;
	isJsonFormValid: boolean = true;
	kafkaForm: any;
	jsonForm: FormGroup;
	isDisabled: boolean = true;
	errorCron;
	cronlistData;
	scheduleNameError: any;
	public schedulerLisiting = [];
	viewscheduleNameError: any;
	generateScheduledTimes;
	output;
	pageNo: number = 1;
	pageSaveQuery: number = 1;
	isAnalyzerSection: boolean = true;
	exportSql = null;
	viewExportSql = null;
	currentCatalogDetails;
	// ag-grid configs
	gridOptions: GridOptions = {};
	api: GridApi;
	columnApi: ColumnApi;
	defaultColDef;
	columnState: ColumnState[] = [];
	columnGroupState: GridColumnGroupState[] = [];
	dataSource;
	frameworkComponents;
	searchiqsandboxschemaText = "";
	searchiqsandboxtblText = "";
	searchiqsandboxTable: boolean = true;
	searchdatasourcecatalogText = "";
	searchdatasourceschemaText = "";
	searchdatasourceschemaField: boolean = true;
	searchdatasourcetableField: boolean = true;
	searchdatasourcetblText = "";
	searchiqdansboxSchemaIcon: boolean = true;
	isFullqueryscreen: boolean = false;
	expand = false;
	collapse = false;
	isfullScreeneditor: boolean = false;
	userNameDetails;
	public infiniteInitialRowCount = 1000;
	filterModel: { [key: string]: any };
	cellClassRules: Record<string, { colId: string; cellClassRules: any }> = {};
	isAnalyzerEnabled: boolean = true;
	isCrudEnabled: boolean = true;
	isresultButtonEnabled: boolean = true;
	isresultTableEnabled: boolean = true;
	isExplainAnalyzeEnabled: boolean = true;
	isresultExplainAnalyze: boolean = true;
	statusexplainAnalyzeSection: boolean = true;
	isDDLButtonsEnabled: boolean = true;
	statusDDLSection: boolean = true;
	statusAnalyzerSection: boolean = true;
	isAnalyzerHidden: boolean = true;
	disabled: boolean = true;
	showExecutionTab: boolean = true;
	shownotExecutionTab: boolean = true;
	totalRows: any;
	disableRowCount: boolean;
	query: any;
	state: any;
	qubzLicenseExpire: any;
	qubzLicenseExpireMsg: any;
	MatLoad: boolean;

	showTable() {
		document.getElementById("show_table").classList.add("show_table");
		document.getElementById("show_graph").classList.remove("show_graph");
	}
	showGraph() {
		document.getElementById("show_graph").classList.add("show_graph");
		document.getElementById("show_table").classList.remove("show_table");
	}

	togglefulls() {
		this.fullSmallScreen = !this.fullSmallScreen;
	}

	fileToUpload: File = null;
	showAnalyzer: boolean = true;
	showAnalyzerTab: boolean = true;
	refreshDataSource;
	schCronString;
	viewcronString;
	//sandBoxCatalog;
	constructor(
		private formBuilder: FormBuilder,
		private dataExplorerService: DataExplorerService,
		private orderPipe: OrderPipe,
		private service: MetadataService,
		private completer: CompleterService,
		private router: Router,
		public commonServiceService: CommonServiceService,
		private builderService: VisualQueryBuilderService,
		private http: HttpClient
	) {
		this.sortedCollection = orderPipe.transform(this.result, "columnMetas");

		this.loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));

		this.defaultColDef = {
			resizable: true,
			sortable: true,
			filter: true,
			floatingFilter: true,
			enableValue: true,
			enableRowGroup: true,
			enablePivot: true
		};

		const gridOptions = partialGridOptions;
		gridOptions.getContextMenuItems = getContextMenuItems(
			({ colId, cellClassRules }) => {
				this.cellClassRules[colId] = cellClassRules;
			},
			({ colId }) => {
				delete this.cellClassRules[colId];
			}
		);
		this.gridOptions = gridOptions;

		this.frameworkComponents = {
			agDateInput: CustomDateComponent
		};
		localStorage.setItem("schemaDtliq", "");
		localStorage.setItem("catalogDtl", "");
		localStorage.setItem("schemaDtlds", "");
	}
	ngOnDestroy() {
		this.dataExplorerService.setFormData(null);
	}
	ngOnInit() {
		this.qubzLicenseExpire = JSON.parse(
			sessionStorage.getItem("qubzLicenseExpire")
		);
		this.qubzLicenseExpireMsg = JSON.parse(
			sessionStorage.getItem("qubzLicenseExpireMsg")
		);
		this.isShowquerydiv = false;
		this.initializeForm();
		this.getAllData();
		this.dbList();
		this.subscription = this.dataExplorerService
			.getHistory()
			.subscribe(newHistory => this.savedQueriesHistory());
		this.savedQueriesHistory();
		this.loggedUser = JSON.parse(sessionStorage.getItem("loggedUser"));
		this.savedQuerysubscription =
			this.dataExplorerService.queryUpdated$.subscribe(queryUpdated =>
				this.savedQueries()
			);
		this.searchText = "All";
		if (this.builderService.result) {
			this.output = this.builderService.result;
			this.mainQuery = this.output.query;
			this.builderService.getmainQuery = this.mainQuery;
			if (this.builderService.limits) {
				this.limit = this.builderService.limits;
			}
		}
		this.builderService.analyzerQueryObj = undefined;
		this.userNameDetails = JSON.parse(
			sessionStorage.getItem("userNameDetails")
		);

		localStorage.setItem(
			"enablefullScreen",
			JSON.stringify(this.isfullScreeneditor)
		);
		//localStorage.setItem("cronExpressionState", "");
		localStorage.setItem("defaultValue", "");

		localStorage.setItem("schCronString", "");
	}
	hasRole(roles) {
		let user = JSON.parse(sessionStorage.getItem("loggedUser"));
		for (let role of user.authorities) {
			if (role.authority == roles) return "admin";
		}
		return "user";
	}
	handleFileInput(files: FileList) {
		this.fileToUpload = files.item(0);
		if (files.item(0) && !this.validateFile(files.item(0).name)) {
			this.form.get("password").setErrors({ invalidJson: true });
			return false;
		}
	}
	validateFile(name: String) {
		var ext = name.substring(name.lastIndexOf(".") + 1);
		if (ext.toLowerCase() == "json") {
			return true;
		} else {
			return false;
		}
	}
	initializeForm() {
		this.form = this.formBuilder.group({
			sourceType: new FormControl({ value: null, disabled: this.isEdit }, [
				Validators.required
			]),
			catalogName: new FormControl({ value: null, disabled: this.isEdit }, [
				Validators.required,
				Validators.pattern("^[a-z0-9_-]+")
			]),
			hostName: new FormControl(null, [Validators.required]),
			userName: new FormControl(null, [Validators.required]),
			password: new FormControl(null, [Validators.required]),
			config: new FormControl(null, []),
			portNumber: new FormControl(null, [
				Validators.required,
				Validators.pattern("^[0-9]*$")
			]),
			database: new FormControl(null, [
				Validators.required,
				Validators.pattern("^[a-zA-Z0-9_-]+")
			])
		});
	}
	getAllData() {
		let user = JSON.parse(sessionStorage.getItem("loggedUser"));
				this.dataExplorerService.getAllUserMetadata(user.username).subscribe(data => {
					this.listData = data;
					this.metainfo = this.listData.autoCompleteCatalogList;
					let meta = [];
					for (let row of this.metainfo) {
						let catalog = [];
						for (let cat of row.schemas) {
							let tables = [];
							if (cat.tables && cat.tables.length > 0) {
								for (let table of cat.tables) {
									let columns = [];
									if (table.columns && table.columns.length > 0) {
										for (let column of table.columns) {
											columns.push({ Name: column.name, Children: [] });
										}
									}
									tables.push({ Name: table.name, Children: columns });
								}
							}
							catalog.push({ Name: cat.name, Children: tables });
						}
						meta.push({ Name: row.name, Children: catalog });
					}
					this.completer.setMetadata(meta);
				});
			}
	dbList() {
		this.load = true;
		this.dataExplorerService.getConnectionsList().subscribe(
			(data: prestoData) => {
				this.dbListArr = data.prestoCatalogList;
				for (let row of this.dbListArr) {
					if (row.qubzDataWarehouse) {
						// if (row.catalog == "iqsandbox") {
						// 	this.sandBoxCatalog = "sandbox";
						// }
						this.showSchemaList(row.connectionId, row.catalog);
						this.showToggle[row.catalog] = !this.showToggle[row.catalog];
					}
				}
				this.load = false;
				this.getAllData();
			},
			error => {
				this.load = false;
				swal("Failed to load Data", error.error.msg, "error");
			}
		);
	}
	showSchemaList(connectionId, catalog) {
		if (catalog == "iq_warehouse") {
			this.qdwLoader = true;
			if (this.showToggle[catalog] == true) {
				this.searchiqdansboxSchemaIcon = true;
				this.selectedQw = "";
				this.isActive = [];
				this.searchiqsandboxschemaText = "";
				this.searchiqsandboxTable = true;
				this.searchiqsandboxtblText = "";
			} else {
				this.searchiqdansboxSchemaIcon = false;
			}
		} else {
			this.sourceLoader = true;
			localStorage.setItem("catalogDtl", catalog);
			if (this.showToggle[catalog] == true) {
				this.selectedDb = "";
				this.selectedTable = "";
				this.isActive = [];
				this.searchdatasourcecatalogText = "";
				this.searchdatasourceschemaText = "";
				this.searchdatasourceschemaField = true;
				this.searchdatasourcetableField = true;
				this.searchdatasourcetblText = "";
			}
		}
		this.dataExplorerService.getSchemaList(connectionId).subscribe(
			(data: prestoCatalogSchemaList) => {
				if (this.dbListArr)
					this.dbListArr.map(item => {
						if (item.connectionId === connectionId) {
							if (!item.schemaList)
								item.schemaList = data.prestoCatalogSchemaList;
							if (catalog == "iq_warehouse") {
								this.databaseList = item.schemaList;
							} else {
								if (this.searchdatasourcecatalogText !== "") {
									if (this.showToggle[item.catalog] == true) {
										this.searchdatasourceschemaField = true;
									}
								}
							}
						}
					});
				this.qdwLoader = false;
				this.sourceLoader = false;
			},
			error => {
				this.qdwLoader = false;
				this.sourceLoader = false;
				swal("Failed to load Data", error.error.msg, "error");
			}
		);
	}
	schemaClick(schema, currentCatalogId, ev) {
		ev.stopPropagation();
		let currentSchemaId = schema.uuid;
		if (schema.catalog == "iq_warehouse") {
			this.qdwLoader = true;
			localStorage.setItem("schemaDtliq", JSON.stringify(schema));
		} else {
			this.sourceLoader = true;
			localStorage.setItem("schemaDtlds", JSON.stringify(schema));
		}
		// this.selectedDb=schema.uuid;
		this.dataExplorerService.getTableListOnSchema(currentSchemaId).subscribe(
			(data: prestoSchemaTableList) => {
				this.dbListArr.map(item => {
					if (item.connectionId === currentCatalogId) {
						item.schemaList.map(element => {
							if (element.uuid === currentSchemaId) {
								if (!element.tableList)
									element.tableList = data.prestoSchemaTableList;
								if (schema.catalog == "iq_warehouse") {
									if (this.searchiqsandboxschemaText !== "") {
										if (this.isActive[element.uuid] == true) {
											this.searchiqsandboxTable = false;
										}
									}
								} else {
									this.showdatasourcesearchFields(2, schema);
									if (this.searchdatasourceschemaText !== "") {
										if (this.isActive[element.uuid] == true) {
											this.searchdatasourcetableField = false;
										}
									}
								}
							}
						});
					}
				});
				this.qdwLoader = false;
				this.sourceLoader = false;
			},
			error => {
				this.qdwLoader = false;
				this.sourceLoader = false;
				swal("Failed to load Data", error.error.msg, "error");
			}
		);
	}
	tableClick(
		currentCatalog,
		currentSchema,
		currentTable,
		catalogId,
		schemaId,
		currentTableId,
		ev
	) {
		ev.stopPropagation();
		this.currentCatalog = currentCatalog;
		this.currentSchema = currentSchema;
		this.currentTable = currentTable;
		this.dataExplorerService.getColumnListOnTable(currentTableId).subscribe(
			(data: prestoTableColumnList) => {
				this.dbListArr.map(item => {
					if (item.connectionId === catalogId) {
						item.schemaList.map(element => {
							if (element.uuid === schemaId) {
								element.tableList.map(ele => {
									if (ele.uuid === currentTableId) {
										ele.columns = data.prestoTableColumnList;
									}
								});
							}
						});
					}
				});
				this.loader = false;
			},
			error => {
				this.loader = false;
				swal("Failed to load Data", error.error.msg, "error");
			}
		);
	}
	showData(currentCatalog, currentSchema, currentTable) {
		this.currentCatalog = currentCatalog;
		this.currentSchema = currentSchema;
		this.currentTable = currentTable;
		let param;
		param = {
			catalogName: this.currentCatalog,
			schema: this.currentSchema,
			table: this.currentTable
		};
		this.getData(param);
	}
	getData(param) {
		this.viewExportSql = param;
		this.resultView = {};
		this.resultViewStatus = "Executing";
		this.resultViewTime = new Date();
		this.loadViewResult = true;
		this.queryViewData =
			"select * from " +
			param.catalogName +
			"." +
			param.schema +
			"." +
			param.table;
		$(".result-c").addClass("showresult");
		this.dataExplorerService.getPrestoResult(param).subscribe(
			(data: string) => {
				this.resultView = data;
				this.resultViewStatus = "Success";
				this.cancelKafka();
				this.isQueryAvailable = false;
				if (this.resultView.SQLResult) {
					this.loadViewResult = false;
				} else {
					this.loadViewResult = true;
				}
			},
			error => {
				this.viewDataError = error;
				this.resultViewStatus = "Failed";
				this.loadViewResult = false;
			}
		);
	}
	getLimit(limit) {
		this.limit = limit;
	}
	isChecked() {
		this.check = !this.check;
		if (this.check) {
			if (this.builderService.limits) {
				this.limit = this.builderService.limits;
			} else {
				this.limit = 5000;
			}
		} else {
			this.limit = 0;
		}
	}
	onChange($event) {
		this.mainQuery = $event;
	}

	createServersideDataSource(p) {
		const datasource = {
			getRows: (params: IServerSideGetRowsParams) => {
				let ssrRequest = params.request;
				let sortModel;
				const { sortModel: sortOrder, ...request } = ssrRequest;

				sortModel = isEmpty(sortOrder)
					? !isEmpty(request.rowGroupCols)
						? [
								{
									colId: `${head(request.rowGroupCols).field}`,
									sort: "asc"
								}
						  ]
						: []
					: sortOrder; // use the sort order from the grid

				let param: AnalyzerRequestonSqlEditor = {
					tableName: null,
					itemsPerPage: CacheBlockSize,
					sqlText: p.sql,
					limit: p.limit,
					sortModel,
					...request
				};

				try {
					this.error = null;
					this.dataExplorerService.serverSideAnalyzer(param).subscribe(
						data => {
							this.result = data;
							this.dataExplorerService.getResultCount(param).subscribe((res : any) =>{
								this.totalRows = res.totalRowCount;
								this.handleApiResponse(params, this.result, p,this.totalRows);
							})
							
							const paramsNew = {
								sqlText: param.sqlText,
								limit:param.limit
							};
							
						},
						error => {
							params.fail();
							this.error = error;
							const index = this.queries1.findIndex(
								query => query.id === "test"
							);
							this.queries1.splice(index, 1);
							this.showExecutionTab = true;
							this.shownotExecutionTab = false;
							this.status = "Failed";
							this.statusAnalyzerSection = false;
							this.isresultButtonEnabled = true;
							this.isAnalyzerEnabled = true;
							this.isAnalyzerHidden = true;
							if (this.currentQueries.length > 0) {
								this.currentQueries = this.currentQueries;
							}

							this.currentQueries.push({
								sql: param.sqlText,
								savedAt: new Date(),
								status: this.status,
								startTime: this.startTime,
								result: this.error
							});
							this.queries1 = this.currentQueries;
							this.indexTab = this.error;
							this.cachedQueries = JSON.parse(
								localStorage.getItem("prestoQueries_history")
							);
							if (this.cachedQueries) {
								if (this.cachedQueries.length > 0) {
									this.cachedQueries = this.cachedQueries;
								}
							} else {
								this.cachedQueries = [];
							}
							this.cachedQueries.push({
								sql: param.sqlText,
								savedAt: new Date()
							});

							localStorage.setItem(
								"prestoQueries_history",
								JSON.stringify(this.cachedQueries)
							);
							this.history = localStorage.getItem("prestoQueries_history");
							this.dataExplorerService.savehistory(this.history);
						}
					);
					
				} catch (error) {
					params.fail();
					this.error = error;
					const index = this.queries1.findIndex(query => query.id === "test");
					this.queries1.splice(index, 1);
					this.showExecutionTab = true;
					this.shownotExecutionTab = false;
					this.status = "Failed";
					this.statusAnalyzerSection = false;
					this.isresultButtonEnabled = true;
					this.isAnalyzerEnabled = true;
					this.isAnalyzerHidden = true;
					if (this.currentQueries.length > 0) {
						this.currentQueries = this.currentQueries;
					}

					this.currentQueries.push({
						sql: param.sqlText,
						savedAt: new Date(),
						status: this.status,
						startTime: this.startTime,
						result: this.error
					});
					this.queries1 = this.currentQueries;
					this.indexTab = this.error;
					this.cachedQueries = JSON.parse(
						localStorage.getItem("prestoQueries_history")
					);
					if (this.cachedQueries) {
						if (this.cachedQueries.length > 0) {
							this.cachedQueries = this.cachedQueries;
						}
					} else {
						this.cachedQueries = [];
					}
					this.cachedQueries.push({
						sql: param.sqlText,
						savedAt: new Date()
					});

					localStorage.setItem(
						"prestoQueries_history",
						JSON.stringify(this.cachedQueries)
					);
					this.history = localStorage.getItem("prestoQueries_history");
					this.dataExplorerService.savehistory(this.history);
				}
			},
			destroy: () => {}
		};
		this.dataSource = datasource;
		return this.dataSource;
	}

	submit(mainQuery, limit) {
		if(this.qubzLicenseExpire){
			swal("Warning!", this.qubzLicenseExpireMsg, "success");
		}else{
			//get New Query Result
		if (localStorage.getItem("enablefullScreen") !== undefined) {
			this.isfullScreeneditor = JSON.parse(
				localStorage.getItem("enablefullScreen")
			);
			if (this.isfullScreeneditor == true) {
				this.isfullScreeneditor = false;
				localStorage.setItem(
					"enablefullScreen",
					JSON.stringify(this.isfullScreeneditor)
				);
			}
		}

		if (this.isFullqueryscreen == true) {
			this.isFullqueryscreen = false;
		}
		this.isAnalyzerEnabled = true;
		this.isAnalyzerHidden = true;

		this.resultViewTab = false;

		const res = mainQuery
			.toLowerCase()
			.split(/[\s,\?\,\.!]+/)
			.some(
				f => f === "insert" || f === "drop" || f === "create" || f === "delete" || f === "merge" || f === "update"
			);

		const res1 = mainQuery
			.toLowerCase()
			.split(/[\s,\?\,\.!]+/)
			.some(f => f === "limit");
		let queryNew;
		if (this.check) {
			if (res1) {
				let val = mainQuery.split("limit");
				queryNew = val[0];
			} else {
				queryNew = mainQuery.replace(/;\s*$/, "");
			}
		} else {
			queryNew = mainQuery.replace(/;\s*$/, "");
		}

		if (res) {
			this.isresultButtonEnabled = true;
			this.isAnalyzerEnabled = true;
			this.statusAnalyzerSection = true;
			this.isExplainAnalyzeEnabled = true;
			this.isresultExplainAnalyze = true;
			this.statusexplainAnalyzeSection = true;
			this.result = {};
			this.currentCatalog = "";
			this.isAnalyzerHidden = true;
			$(".result-c").addClass("showresult");
			this.queryView = queryNew;
			this.currentQueries.push({
				sql: queryNew,
				savedAt: new Date(),
				status: "Executing",
				startTime: new Date(),
				result: "",
				id: "test"
			});
			this.queries1 = this.currentQueries;
			const params = {
				sql: queryNew,
				limit: limit
			};
			this.showExecutionTab = false;
			this.shownotExecutionTab = true;
			// this.loadResult = true;
			this.status = "Executing";
			this.startTime = new Date();
			this.dataExplorerService.getNewQueryResult(params).subscribe(
				(data: string) => {
					this.result = data;
					if (this.result.SQLResult.results !== null) {
						this.isresultTableEnabled = false;
						this.isresultExplainAnalyze = true;
					} else {
						this.isresultTableEnabled = true;
						this.isresultExplainAnalyze = true;
					}
					// if(this.queries1.length>1){
					const index = this.queries1.findIndex(query => query.id === "test");
					this.queries1.splice(index, 1);
					// }
					this.isQueryAvailable = true;
					if (this.result.SQLResult) {
						$(".result-c").addClass("showresult");
					}
					this.showExecutionTab = true;
					this.shownotExecutionTab = false;
					this.status = "Success";
					this.statusDDLSection = false;
					this.isDDLButtonsEnabled = false;
					// this.isresultTableEnabled = false;
					this.isCrudEnabled = false;
					if (this.currentQueries.length > 0) {
						this.currentQueries = this.currentQueries;
					}
					this.currentQueries.push({
						sql: queryNew,
						savedAt: new Date(),
						status: this.status,
						startTime: this.startTime,
						result: this.result
					});
					this.queries1 = this.currentQueries;
					this.indexTab = this.result;
					this.cachedQueries = JSON.parse(
						localStorage.getItem("prestoQueries_history")
					);
					if (this.cachedQueries) {
						if (this.cachedQueries.length > 0) {
							this.cachedQueries = this.cachedQueries;
						}
					} else {
						this.cachedQueries = [];
					}
					this.cachedQueries.push({
						sql: queryNew,
						savedAt: new Date()
					});
					localStorage.setItem(
						"prestoQueries_history",
						JSON.stringify(this.cachedQueries)
					);
					this.history = localStorage.getItem("prestoQueries_history");
					this.dataExplorerService.savehistory(this.history);
				},
				error => {
					this.error = error;
					this.showExecutionTab = true;
					this.shownotExecutionTab = false;
					// if(this.queries1.length>1){
					const index = this.queries1.findIndex(query => query.id === "test");
					this.queries1.splice(index, 1);
					// }
					this.status = "Failed";
					this.statusDDLSection = false;
					this.isDDLButtonsEnabled = true;
					this.isresultTableEnabled = true;
					this.isCrudEnabled = true;
					if (this.currentQueries.length > 0) {
						this.currentQueries = this.currentQueries;
					}
					this.currentQueries.push({
						sql: queryNew,
						savedAt: new Date(),
						status: this.status,
						startTime: this.startTime,
						result: this.error
					});
					this.queries1 = this.currentQueries;
					this.indexTab = this.error;
					this.cachedQueries = JSON.parse(
						localStorage.getItem("prestoQueries_history")
					);
					if (this.cachedQueries) {
						if (this.cachedQueries.length > 0) {
							this.cachedQueries = this.cachedQueries;
						}
					} else {
						this.cachedQueries = [];
					}
					this.cachedQueries.push({
						sql: queryNew,
						savedAt: new Date()
					});
					localStorage.setItem(
						"prestoQueries_history",
						JSON.stringify(this.cachedQueries)
					);
					this.history = localStorage.getItem("prestoQueries_history");
					this.dataExplorerService.savehistory(this.history);
				}
			);
		} else {
			this.disabled = true;
			this.isCrudEnabled = true;
			this.isresultTableEnabled = true;
			this.isExplainAnalyzeEnabled = true;
			this.isresultExplainAnalyze = true;
			this.statusexplainAnalyzeSection = true;
			this.result = {};
			this.currentCatalog = "";
			$(".result-c").addClass("showresult");
			this.queryView = queryNew;
			let tablename = this.queryView.split(" ");
			this.currentCatalogDetails = tablename[3];
			this.currentQueries.push({
				sql: queryNew,
				savedAt: new Date(),
				status: "Executing",
				startTime: new Date(),
				result: "",
				id: "test"
			});
			this.queries1 = this.currentQueries;
			const params = {
				sql: queryNew,
				limit: limit
			};

			this.exportSql = params;

			// this.loadResult = true;
			this.showExecutionTab = false;
			this.shownotExecutionTab = true;
			this.status = "Executing";
			this.startTime = new Date();
			this.resetGridState();
			this.createServersideDataSource(params);
			this.api.setServerSideDatasource(this.dataSource);
			this.api.setColumnDefs([]);
		}
		}
		
	}

	handleApiResponse(params: IServerSideGetRowsParams, res, param, totalRows) {
		this.statusDDLSection = true;
		this.statusexplainAnalyzeSection = true;
		if (res.error) {
			params.fail();
			this.error = res.error;
			const index = this.queries1.findIndex(query => query.id === "test");
			this.queries1.splice(index, 1);
			this.showExecutionTab = true;
			this.shownotExecutionTab = false;
			this.status = "Failed";
			this.statusAnalyzerSection = false;
			this.isresultButtonEnabled = true;
			this.isAnalyzerEnabled = true;
			this.isAnalyzerHidden = true;
			if (this.currentQueries.length > 0) {
				this.currentQueries = this.currentQueries;
			}

			this.currentQueries.push({
				sql: param.sql,
				savedAt: new Date(),
				status: this.status,
				startTime: this.startTime,
				result: this.error
			});
			this.queries1 = this.currentQueries;
			this.indexTab = this.error;
			this.cachedQueries = JSON.parse(
				localStorage.getItem("prestoQueries_history")
			);
			if (this.cachedQueries) {
				if (this.cachedQueries.length > 0) {
					this.cachedQueries = this.cachedQueries;
				}
			} else {
				this.cachedQueries = [];
			}
			this.cachedQueries.push({
				sql: param.sql,
				savedAt: new Date()
			});

			localStorage.setItem(
				"prestoQueries_history",
				JSON.stringify(this.cachedQueries)
			);
			this.history = localStorage.getItem("prestoQueries_history");
			this.dataExplorerService.savehistory(this.history);
		} else {
			//this.columnState = this.columnApi.getColumnState();
			this.status = "Success";
			this.disabled = false;
			const rowData = getRows(res);
			const rowCount = getLastRowIndex(params.request, res.rows);
			let defs = params.api.getColumnDefs();
			if (isEmpty(defs)) {
				defs = getColDefs(res.columns);
			}

			Object.keys(this.cellClassRules).forEach(colId => {
				const cellClassRules = this.cellClassRules[colId];
				if (!isEmpty(cellClassRules)) {
					defs = addCellClassRuleForColId(cellClassRules, colId, defs);
				}
			});
			params.api.setColumnDefs(defs);
			params.success({ rowData, rowCount });

			const index = this.queries1.findIndex(query => query.id === "test");
			this.queries1.splice(index, 1);
			this.isQueryAvailable = true;
			this.showExecutionTab = true;
			this.shownotExecutionTab = false;
			this.statusAnalyzerSection = false;
			this.isresultButtonEnabled = false;
			this.isAnalyzerEnabled = false;
			this.isAnalyzerHidden = false;

			if (this.currentQueries.length > 0) {
				this.currentQueries = this.currentQueries;
			}
			this.currentQueries.push({
				sql: param.sql,
				savedAt: new Date(),
				status: this.status,
				startTime: this.startTime,
				result: this.result,
				totalRows: totalRows,
				columnState:this.columnState
			});
			this.queries1 = this.currentQueries;
			this.indexTab = this.result;
			this.cachedQueries = JSON.parse(
				localStorage.getItem("prestoQueries_history")
			);
			if (this.cachedQueries) {
				if (this.cachedQueries.length > 0) {
					this.cachedQueries = this.cachedQueries;
				}
			} else {
				this.cachedQueries = [];
			}
			this.cachedQueries.push({
				sql: param.sql,
				savedAt: new Date()
			});
			localStorage.setItem(
				"prestoQueries_history",
				JSON.stringify(this.cachedQueries)
			);
			this.history = localStorage.getItem("prestoQueries_history");
			this.dataExplorerService.savehistory(this.history);
		}
	}

	handlereApiResponse(params: IServerSideGetRowsParams, res,colState) {
		if (res.error) {
			params.fail();
			this.error = res.error;
		} else {
			this.disabled = false;
			const rowData = getRows(res);
			const rowCount = getLastRowIndex(params.request, res.rows);
			let defs = params.api.getColumnDefs();
			if (isEmpty(defs)) {
				defs = getColDefs(res.columns);
			}
			Object.keys(this.cellClassRules).forEach(colId => {
				const cellClassRules = this.cellClassRules[colId];
				if (!isEmpty(cellClassRules)) {
					defs = addCellClassRuleForColId(cellClassRules, colId, defs);
				}
			});
			params.api.setColumnDefs(defs);
			params.success({ rowData, rowCount });
			this.columnApi.applyColumnState({state: colState})
		}
	}

	resetGridState() {
		this.columnGroupState = null;
		this.columnState = null;
		this.filterModel = null;
	}

	onSearchChange($event): void {
		if ($event.value != "All") {
			this.result = null;
		}
		for (let query of this.queries1) {
			if (query.status == $event.value && $event.value != "All") {
				this.refreshCurQueryforTab(query.sql, query);
			} else if ($event.value == "All") {
				this.refreshCurQueryforTab(query.sql, query);
			}
		}
	}
	removeResult(queryInstance) {
		if (this.indexTab == queryInstance) {
			this.indexTab = null;
		}
		var index = this.queries1.indexOf(queryInstance);
		this.queries1.splice(index, 1);
		this.indexTab = this.queries1.length > 0 ? this.queries1[0] : null;
		if (this.queries1.length >= 1) {
			this.refreshCurQueryforTab(
				this.queries1[this.queries1.length - 1].sql,
				this.queries1[this.queries1.length - 1]
			);
		}
		if (this.queries1.length == 0) {
			this.resultViewTab = true;
		}
	}

	resetCurApiResponse(params: IServerSideGetRowsParams, res, param) {
		if (res.error) {
			params.fail();
			this.error = res.error;
			this.status = "Failed";
			this.refresh = this.error;
			this.indexTab = this.error;
		} else {
			const rowData = getRows(res);
			const rowCount = getLastRowIndex(params.request, res.rows);
			let defs = params.api.getColumnDefs();
			if (isEmpty(defs)) {
				defs = getColDefs(res.columns);
			}

			Object.keys(this.cellClassRules).forEach(colId => {
				const cellClassRules = this.cellClassRules[colId];
				if (!isEmpty(cellClassRules)) {
					defs = addCellClassRuleForColId(cellClassRules, colId, defs);
				}
			});
			params.api.setColumnDefs(defs);
			params.success({ rowData, rowCount });

			this.status = "Success";
			this.curQuery = true;
			this.indexTab = this.result;
			this.current = true;
			//this.exportSql = this.sanitate(sql);
		}
	}

	resetServerSideDataSource(p) {
		const refreshDataSource = {
			getRows: (params: IServerSideGetRowsParams) => {
				let ssrRequest = params.request;
				let sortModel;
				const { sortModel: sortOrder, ...request } = ssrRequest;

				sortModel = isEmpty(sortOrder)
					? !isEmpty(request.rowGroupCols)
						? [
								{
									colId: `${head(request.rowGroupCols).field}`,
									sort: "asc"
								}
						  ]
						: []
					: sortOrder; // use the sort order from the grid

				let param: AnalyzerRequestonSqlEditor = {
					tableName: null,
					itemsPerPage: CacheBlockSize,
					sqlText: p.sql,
					limit: p.limit,
					sortModel,
					...request
				};

				try {
					this.error = null;
					this.dataExplorerService.serverSideAnalyzer(param).subscribe(
						data => {
							this.result = data;
							this.resetCurApiResponse(params, this.result, p);
						},
						error => {
							params.fail();
							this.error = error;
						}
					);
				} catch (error) {
					params.fail();
					this.error = error;
				}
			},
			destroy: () => {}
		};
		this.refreshDataSource = refreshDataSource;
		return this.refreshDataSource;
	}

	refreshCurQuery(sql, limit) {
		if (this.limit) {
			this.limit = this.limit;
		} else {
			this.limit = 5000;
		}
		this.statusActive = "active";
		this.startTime = new Date();
		this.status = "Executing";
		this.result = null;
		localStorage.setItem("startTime", this.startTime);
		//this.load = true;
		const params = {
			sql: sql,
			limit: this.limit
		};
		this.api.setColumnDefs([]);
		this.resetGridState();
		this.resetServerSideDataSource(params);
		this.api.setServerSideDatasource(this.refreshDataSource);
	}

	refreshCurQueryforTab(sql, query) {
		if (this.check) {
			if (this.result.totLimit) {
				this.limit = this.result.totLimit;
			}
			if (this.limit) {
				this.limit = this.limit;
			} else {
				this.limit = 5000;
			}
		} else {
			this.limit = 0;
		}
		this.result = query.result;
		if (this.result.SQLResult !== undefined) {
			this.isAnalyzerEnabled = true;
			this.isCrudEnabled = false;
			this.isresultButtonEnabled = true;
			this.isDDLButtonsEnabled = false;
			this.isExplainAnalyzeEnabled = true;
			this.isresultExplainAnalyze = true;
			this.statusexplainAnalyzeSection = true;
			this.statusAnalyzerSection = true;
			this.statusDDLSection = false;
			this.isAnalyzerHidden = true;
			if (this.result.SQLResult.results !== null) {
				this.isresultTableEnabled = false;
			} else {
				this.isresultTableEnabled = true;
			}
			if (this.result.totLimit) {
				this.limit = this.result.totLimit;
			}
			this.startTime = query.startTime;
			if (query.status == "Success") {
				this.showExecutionTab = true;
				this.shownotExecutionTab = false;
				this.status = "Success";
				this.error = "";
				this.result = query.result;
				this.curQuery = true;
				this.indexTab = this.result;
				this.queryView = sql;
				//   this.sql=sql;
			} else if (query.status == "Executing") {
				this.showExecutionTab = false;
				this.shownotExecutionTab = true;
				this.status = "Executing";
			} else this.status = "Failed";
			this.showExecutionTab = true;
			this.shownotExecutionTab = false;
			this.error = query.result;
			this.indexTab = this.error;
		} else if (this.result.columns !== null) {
			this.isAnalyzerEnabled = false;
			this.isCrudEnabled = true;
			this.isresultButtonEnabled = false;
			this.isDDLButtonsEnabled = true;
			this.isExplainAnalyzeEnabled = true;
			this.isresultExplainAnalyze = true;
			this.statusexplainAnalyzeSection = true;
			this.statusAnalyzerSection = false;
			this.statusDDLSection = true;
			this.startTime = query.startTime;
			if (query.status == "Success") {
				this.resetGridState();
				this.query = query;
				this.state = this.query.columnState;
				this.showExecutionTab = true;
				this.shownotExecutionTab = false;
				this.isAnalyzerHidden = false;
				this.status = "Success";
				this.error = "";
				this.result = this.query.result;
				this.curQuery = true;
				this.indexTab = this.result;
				this.queryView = sql;
				this.totalRows = this.query.totalRows
				const refreshDtls = {
					getRows: (params: IServerSideGetRowsParams) => {
						let ssrRequest = params.request;
				let sortModel;
				const { sortModel: sortOrder, ...request } = ssrRequest;

				sortModel = isEmpty(sortOrder)
					? !isEmpty(request.rowGroupCols)
						? [
								{
									colId: `${head(request.rowGroupCols).field}`,
									sort: "asc"
								}
						  ]
						: []
					: sortOrder; // use the sort order from the grid

				let param: AnalyzerRequestonSqlEditor = {
					tableName: null,
					itemsPerPage: CacheBlockSize,
					sqlText: sql,
					limit: this.limit,
					sortModel,
					...request
				};		
				this.query.columnState
				this.dataExplorerService.serverSideAnalyzer(param).subscribe(
					data => {
						this.result = data;
						this.dataExplorerService.getResultCount(param).subscribe((res : any) =>{
							this.totalRows = res.totalRowCount;
							this.handlereApiResponse(params, this.result,this.state);
						})
					});
					}
				};
				this.api.setServerSideDatasource(refreshDtls);
				this.api.setColumnDefs([]);
			} else if (query.status == "Executing") {
				this.showExecutionTab = false;
				this.shownotExecutionTab = true;
				this.status = "Executing";
				this.statusAnalyzerSection = true;
				this.isresultButtonEnabled = true;
				this.isAnalyzerEnabled = true;
				this.isAnalyzerHidden = true;
				this.isShowquerydiv = false;
			} else {
				this.status = "Failed";
				this.showExecutionTab = true;
				this.shownotExecutionTab = false;
				this.error = query.result;
				this.indexTab = this.error;
				this.isAnalyzerEnabled = true;
				this.isAnalyzerHidden = true;
			}
		} else {
			this.isAnalyzerEnabled = true;
			this.isCrudEnabled = true;
			this.isresultButtonEnabled = true;
			this.isDDLButtonsEnabled = true;
			this.statusexplainAnalyzeSection = false;
			this.statusAnalyzerSection = true;
			this.statusDDLSection = true;
			this.isExplainAnalyzeEnabled = false;
			this.isresultExplainAnalyze = false;
			this.isresultTableEnabled = true;
			if (this.result.totLimit) {
				this.limit = this.result.totLimit;
			}
			this.startTime = query.startTime;
			if (query.status == "Success") {
				this.showExecutionTab = true;
				this.shownotExecutionTab = false;
				this.status = "Success";
				this.error = "";
				this.result = query.result;
				this.curQuery = true;
				this.indexTab = this.result;
				this.queryView = sql;
				//   this.sql=sql;
			} else if (query.status == "Executing") {
				this.showExecutionTab = false;
				this.shownotExecutionTab = true;
				this.status = "Executing";
			} else this.status = "Failed";
			this.showExecutionTab = true;
			this.shownotExecutionTab = false;
			this.error = query.result;
			this.indexTab = this.error;
		}
	}
	savedQueriesHistory() {
		this.queriesHistory = JSON.parse(
			localStorage.getItem("prestoQueries_history")
		);
		if (this.queriesHistory) {
			this.queriesHistory.sort(
				(a, b) => new Date(b.savedAt).getTime() - new Date(a.savedAt).getTime()
			);
		}
	}
	submitQuery(key, query) {
		if (key === "saveQuery") {
			this.submit(query.query, 5000);
		} else {
			this.submit(query.sql, 5000);
		}
	}
	removeQuery(query, index) {
		if(this.qubzLicenseExpire){
			swal("Warning!", this.qubzLicenseExpireMsg, "success");
		}else{
			swal({
				type: "warning",
				title: "Are you sure you want to  the query?",
				text: "All information associated to this query will be permanently deleted",
				showCancelButton: true,
				confirmButtonText: "Yes",
				cancelButtonText: "No"
			}).then(
				status => {
					if (status.value === true) {
						this.queriesHistory.splice(index, 1);
						localStorage.setItem(
							"prestoQueries_history",
							JSON.stringify(this.queriesHistory)
						);
						this.savedQueriesHistory();
						swal("Success!", "Query has been deleted", "success");
					}
				},
				dismiss => {
					if (dismiss === "cancel") {
					}
				}
			);
		}
	}
	columnClick(ev) {
		ev.stopPropagation();
	}
	loadDbImage(type, warehouse) {
		let img;
		if (!warehouse) {
			this.dbImage.filter(item => {
				if (item.type === type) img = item.value;
			});
		} else {
			img = "../../../assets/images/warehouse_ico.png";
		}

		return img;
	}
	createDataSource() {
		this.popupName = "Create Data Source";
		this.isEdit = false;
		this.checkWarehouseAvailablity();
		this.form.reset();
		this.createModal = true;
		this.cancelKafka();
	}
	deleteDatasourceConnection(currentCatalog, currentConnectionId, ev) {
		ev.stopPropagation();
		swal({
			type: "warning",
			title:
				"Do you want to proceed with deletion of data source for the catalog: " +
				currentCatalog.toUpperCase() +
				" ?",
			showCancelButton: true,
			confirmButtonText: "Yes",
			cancelButtonText: "No"
		}).then(
			status => {
				if (status.value === true) {
					this.load = true;
					let param;
					param = {
						connectionId: currentConnectionId
					};
					this.dataExplorerService.deleteConnectionDetails(param).subscribe(
						data => {
							let res = <any>{};
							res = data;
							this.dbList();
							this.load = false;
							this.showToggle = [];
							swal(
								"Success!",
								"Datasource connection deleted successfully",
								"success"
							);
						},
						error => {
							this.load = false;
							swal("Ooops", error.error.msg, "error");
						}
					);
				}
			},
			dismiss => {
				if (dismiss === "cancel") {
				}
			}
		);
	}
	fetchConnectionDataForEdit(currentConnectionId) {
		this.popupName = "Edit Data Source";
		this.isEdit = true;
		this.createModal = true;
		this.selectedConnectionId = currentConnectionId;
		this.load = true;

		this.form.reset();
		let param;
		param = {
			connectionId: currentConnectionId
		};
		this.dataExplorerService.getConnectionIdDetails(param).subscribe(
			data => {
				if (data["jdbcConnectionResponse"].qubzDataWarehouse == true) {
					data["jdbcConnectionResponse"].dbType = "iq_warehouse";
				}
				this.form.patchValue({
					sourceType: data["jdbcConnectionResponse"].dbType,
					catalogName: data["jdbcConnectionResponse"].catalogName,
					hostName: data["jdbcConnectionResponse"].hostname,
					userName: data["jdbcConnectionResponse"].username,
					config: data["jdbcConnectionResponse"].config,
					portNumber: data["jdbcConnectionResponse"].port,
					database: data["jdbcConnectionResponse"].defaultDatabase
				});
				this.checkWarehouseAvailablity();
				this.load = false;
			},
			error => {
				this.load = false;
				swal("Failed to load Connection Details", error.error.msg, "error");
			}
		);
	}
	changeConnectionDetails() {
		if (this.form.value.sourceType == "kafka") {
			this.showkafka.showkafka_c = true;
			this.showkafka.showexplore_c = false;
			this.createModal = false;
		} else {
			this.testStatus = false;
			this.saveStatus = false;
			this.testButton = true;
			this.selectedDbType = this.form.value.sourceType;
			if (this.form.value.sourceType == "iq_warehouse") {
				this.form.patchValue({
					catalogName: "iq_warehouse"
				});
			}
		}
	}
	cancelKafka() {
		this.showkafka.showkafka_c = false;
		this.showkafka.showexplore_c = true;
	}
	checkWarehouseAvailablity() {
		this.dbTypes = [];
		if (this.dbListArr.find(({ catalog }) => catalog == "iq_warehouse")) {
			for (let param of this.users) {
				if (param.id != "iq_warehouse" || this.isEdit)
					this.dbTypes = [...this.dbTypes, param];
			}
		} else {
			for (let param of this.users) {
				this.dbTypes = [...this.dbTypes, param];
			}
		}
	}
	ngAfterViewInit() {
		$(document).ready(function () {
			var contenth = $(window).height() - 110;
			var sidebarh = $(window).height() - 111;
			$(".pagec").css("height", contenth);
			$(".sidebar-wrapper").css("height", sidebarh);

			function treeheight() {
				var pageheight_p = $(".pagec").height() - 240;
				var pageheight = pageheight_p * 0.5;
				$(".tabletree_parent").css("height", pageheight);
			}
			setTimeout(treeheight, 2);
		});
		$(window).resize(function () {
			function treeheight() {
				var pageheight_p = $(".pagec").height() - 240;
				var pageheight = pageheight_p * 0.5;
				$(".tabletree_parent").css("height", pageheight);
			}
			setTimeout(treeheight, 2);
		});
		$(".submitbtn").click(function () {
			$(".result-c").addClass("showresult");
		});
		$(".clicktable").click(function () {
			$(".tab-pane").removeClass("active");
			$(".tabitem").removeClass("active");
			function showtab() {
				$("#tabfour").addClass("active");
				$(".datalink").addClass("active");
			}
			setTimeout(showtab, 2);
		});
		$(".datasourcelink").click(function () {
			$(".tab-pane").removeClass("active");
			$(".tabitem").removeClass("active");
			function showdatalink() {
				$("#tabfive").addClass("active");
				$(".datasources").addClass("active");
			}
			setTimeout(showdatalink, 2);
		});
	}
	testConnection() {
		this.disableInputs = true;
		this.load = true;
		let connection;
		const formData: FormData = new FormData();
		if (this.form.value.sourceType == "iq_warehouse") {
			connection = {
				hostname: this.form.value.hostName,
				username: this.form.value.userName,
				password: this.form.value.password,
				port: this.form.value.portNumber,
				defaultDatabase: this.form.value.database,
				dbType: "hive2",
				qubzDataWarehouse: true,
				config: this.form.value.config,
				catalogName: this.form.value.catalogName
			};
		} else if (this.form.value.sourceType == "bigquery") {
			formData.append("keyFile", this.fileToUpload, this.fileToUpload.name);
			connection = {
				hostname: this.form.value.hostName,
				username: this.form.value.userName,
				port: this.form.value.portNumber,
				defaultDatabase: this.form.value.database,
				dbType: this.form.value.sourceType,
				additionalConfig: this.form.value.config,
				catalogName: this.form.value.catalogName
			};
		} else {
			connection = {
				hostname: this.form.value.hostName,
				username: this.form.value.userName,
				password: this.form.value.password,
				port: this.form.value.portNumber,
				defaultDatabase: this.form.value.database,
				dbType: this.form.value.sourceType,
				qubzDataWarehouse: false,
				config: this.form.value.config,
				catalogName: this.form.value.catalogName
			};
		}
		let data = <any>{};
		if (this.form.value.sourceType == "bigquery") {
			this.dataExplorerService
				.testConnectionBigQurey(formData, connection)
				.subscribe(
					data => {
						this.load = false;
						this.testStatus = true;
						this.testButton = false;
						swal("Success!", "Connection tested successfully", "success");
					},
					error => {
						swal(
							"Ooops!",
							"Connection failed. <br> Please check the configuration details.",
							"error"
						);
						this.testStatus = false;
						this.saveStatus = false;
						this.load = false;
					}
				);
		} else {
			this.dataExplorerService.testConnectionDetails(connection).subscribe(
				data => {
					let res = <any>{};
					res = data;
					this.disableInputs = false;
					// this.load = false;
					if (res.connectionTestResponse.status == "SUCCESS") {
						swal("Success!", "Connection tested successfully", "success");
						this.testStatus = true;
						this.testButton = false;
						this.showModal = false;
						this.load = false;
					} else {
						swal(
							"Ooops!",
							"Connection failed. <br> Please check the configuration details.",
							"error"
						);
						this.testStatus = false;
						this.saveStatus = false;
						this.load = false;
					}
				},
				error => {
					this.load = false;
					this.disableInputs = false;
					swal("Failed", error.error.msg, "error");
				}
			);
		}
	}
	saveConnection() {
		this.disableInputs = true;
		this.load = true;
		let connection;
		const formData: FormData = new FormData();
		if (this.form.value.sourceType == "iq_warehouse") {
			connection = {
				hostname: this.form.value.hostName,
				username: this.form.value.userName,
				password: this.form.value.password,
				port: this.form.value.portNumber,
				defaultDatabase: this.form.value.database,
				dbType: "hive2",
				qubzDataWarehouse: true,
				config: this.form.value.config,
				catalogName: this.form.value.catalogName,
				dataExplorer: true
			};
		} else if (this.form.value.sourceType == "bigquery") {
			formData.append("keyFile", this.fileToUpload, this.fileToUpload.name);
			connection = {
				hostname: this.form.value.hostName,
				username: this.form.value.userName,
				port: this.form.value.portNumber,
				defaultDatabase: this.form.value.database,
				dbType: this.form.value.sourceType,
				additionalConfig: this.form.value.config,
				catalogName: this.form.value.catalogName
			};
		} else {
			connection = {
				hostname: this.form.value.hostName,
				username: this.form.value.userName,
				password: this.form.value.password,
				port: this.form.value.portNumber,
				defaultDatabase: this.form.value.database,
				dbType: this.form.value.sourceType,
				config: this.form.value.config,
				catalogName: this.form.value.catalogName,
				dataExplorer: true
			};
		}
		if (this.isEdit) {
			let data = <any>{};
			let param;
			param = {
				connectionId: this.selectedConnectionId
			};
			connection.connectionId = this.selectedConnectionId;
			this.dataExplorerService
				.editConnectionDetails(connection, param)
				.subscribe(
					data => {
						let res = <any>{};
						res = data;
						this.disableInputs = false;
						this.load = false;
						this.saveStatus = true;
						this.testStatus = false;
						this.createModal = false;
						this.dbList();
						this.showToggle = [];
						swal(
							"Success!",
							"Connection information updated successfully",
							"success"
						);
					},
					error => {
						swal("Ooops!", error.error.msg, "error");
						this.disableInputs = false;
						this.load = false;
					}
				);
		} else {
			let data = <any>{};
			if (this.form.value.sourceType == "bigquery") {
				this.dataExplorerService
					.saveConnectionBigQurey(formData, connection)
					.subscribe(
						data => {
							let res = <any>{};
							res = data;
							this.load = false;
							this.saveStatus = true;
							this.testStatus = false;
							this.dbList();
							this.createModal = false;
							this.showToggle = [];
							this.disableInputs = false;
							swal(
								"Success!",
								"Connection information has been saved",
								"success"
							);
						},
						error => {
							swal("Ooops!", error.error.msg, "error");
							this.disableInputs = false;
							this.load = false;
						}
					);
			} else {
				this.dataExplorerService.saveConnectionDetails(connection).subscribe(
					data => {
						let res = <any>{};
						res = data;
						this.load = false;
						this.saveStatus = true;
						this.testStatus = false;
						this.dbList();
						this.createModal = false;
						this.showToggle = [];
						this.disableInputs = false;
						swal(
							"Success!",
							"Connection information has been saved",
							"success"
						);
					},
					error => {
						swal("Ooops!", error.error.msg, "error");
						this.disableInputs = false;
						this.load = false;
					}
				);
			}
		}
	}
	togglePassword() {
		this.typePassword = !this.typePassword;
	}
	//save query  starts
	showSaveModal() {
		this.showModal = true;
		this.queryName = undefined;
		this.queryDescription = undefined;
	}
	close() {
		this.showModal = false;
		this.createModal = false;
	}
	postQuery() {
		for (let [key, value] of Object.entries(this.cachedQueries)) {
			let res = <any>{};
			res = value;
			this.saveQury = res.sql;
		}
		if (!this.saveQury) this.saveQury = this.queryView;
		const params = {
			userName: this.loggedUser.username
		};
		this.dataExplorerService
			.getSavedQueries(params.userName)
			.subscribe(data => {
				let res = <any>{};
				res = data;
				this.savedQueryList = res.dataExplorerQueryList;
				if (
					this.savedQueryList.find(
						ob => ob["name"].toLowerCase() === this.queryName.toLowerCase()
					)
				) {
					//swal('Oops...', "Query with this name"  +    '['+ this.queryName+']'  +     "already exist!", 'error');
					swal({
						type: "error",
						title: "Oops...",
						html:
							"Query with this name" +
							"&nbsp" +
							"[" +
							this.queryName +
							"]" +
							"&nbsp" +
							"already exists!"
					});
					this.showModal = false;
				} else {
					const param = {
						comment: this.queryDescription,
						name: this.queryName,
						query: this.saveQury,
						userName: this.loggedUser.username
					};
					this.dataExplorerService.saveQuery(param).subscribe(
						data => {
							swal("Success!", "New query saved", "success");
							this.showModal = false;
							this.savedQuerysubscription =
								this.dataExplorerService.queryUpdated$.subscribe(queryUpdated =>
									this.savedQueries()
								);
						},
						error => {
							swal({
								type: "error",
								title: " OOPS...",
								text: error.error.msg,
								confirmButtonText: "Ok"
							}).then(
								status => {
									if (status.value == true) {
									}
								},
								dismiss => {
									if (dismiss === "cancel") {
									}
								}
							);
						}
					);
				}
			});
	}
	deleteQuery(id: string) {
		if(this.qubzLicenseExpire){
			swal("Warning!", this.qubzLicenseExpireMsg, "success");
		}else{
			swal({
				type: "warning",
				title: "Are you sure you want to delete query?",
				text: "All information associated to this query will be permanently deleted",
				showCancelButton: true,
				confirmButtonText: "Yes",
				cancelButtonText: "No"
			}).then(
				status => {
					if (status.value === true) {
						this.dataExplorerService.removeQuery(id).subscribe(
							data => {
								swal("Success!", "Query has been deleted", "success");
								this.savedQueries();
							},
							error => {
								swal("Oops...", error.error.msg, "error");
							}
						);
					}
				},
				dismiss => {
					if (dismiss === "cancel") {
					}
				}
			);
		}
	}
	savedQueries() {
		const params = {
			userName: this.loggedUser.username
		};
		this.dataExplorerService
			.getSavedQueries(params.userName)
			.subscribe(data => {
				let res = <any>{};
				res = data;
				this.savedQueryList = res.dataExplorerQueryList;
			});
	}
	//save query ends
	//material view Starts
	materialize(form: NgForm) {
		this.showMatModal = true;
		form.reset();
		this.form.value.conditonType = "exist";
		this.showDatabaseNameInDropDown = false;
		this.showDatabaseNameInTextField = true;
		form.controls["dbName"].reset();
		this.showDatabaseNameInDropDown = false;
		this.showDatabaseNameInTextField = true;
		this.showHide = true;
		this.isDisabled = true;
		this.cronlistData = undefined;
		this.scheduleNameError = "";
		this.viewscheduleNameError = "";
		this.errorCron = "";
	}
	closeMaterialModal() {
		this.showMatModal = false;
		this.form.reset();
		this.showDatabaseNameInDropDown = true;
		this.showDatabaseNameInTextField = true;
	}
	/**
	 * check table name in hive
	 */
	checkDuplicateTable() {
		this.MatLoad=true;
		this.dataExplorerService
			.getListofDatabaseTables(this.form.value.dbName.toLowerCase())
			.subscribe(
				data => {
					let respose: any;
					respose = data;
					if (respose.indexOf(this.form.value.name.toLowerCase()) == -1) {
						this.saveMaterialize();
					} else {
						swal(
							"Oops...",
							"Table name already exist, please use some unique names",
							"error"
						);
					}
					this.MatLoad=false;
				},
				error => {
					swal("Oops...", error.error.msg, "error");
					this.MatLoad=false;
				}
			);
	}
	/**
	 * Materialized view creation submission
	 */
	saveMaterialize() {
		//this.load = true;
		const param = { sql: this.queryView, limit: this.limit };
		this.dataExplorerService
			.saveMaterializedView(
				this.form.value.dbName.toLowerCase(),
				this.form.value.name.toLowerCase(),
				param
			)

			.subscribe(
				data => {
					swal("Success!", "Materialization job submitted", "success");
					//this.load = false;
					this.showMatModal = false;
				},
				error => {
					swal("Oops...", error.error.msg, "error");
					this.showMatModal = false;
					//this.load = false;
				}
			);
	}

	getDatabaseName(form: NgForm) {
		if (form.value.conditonType == "new") {
			this.showDatabaseNameInTextField = false;
			this.showDatabaseNameInDropDown = true;
			form.controls["dbName"].reset();
			this.isDisabled = true;
			this.cronlistData = undefined;
		} else {
			this.showDatabaseNameInDropDown = false;
			this.showDatabaseNameInTextField = true;
			form.controls["dbName"].reset();
			this.isDisabled = true;
			this.cronlistData = undefined;
		}
	}

	resetMetadata() {
		this.sourceLoader = true;
		this.dataExplorerService.resetMetadata().subscribe(
			data => {
				this.sourceLoader = false;
				this.dbList();
				swal("Success!", "Metadata is successfully refreshed", "success");
			},
			error => {
				this.sourceLoader = false;
				swal("Oops...", "Some error occured", "error");
			}
		);
	}
	resetSingleCatalog(catalogName, connectionId) {
		if (catalogName == "iq_warehouse") this.qdwLoader = true;
		else this.sourceLoader = true;
		this.dataExplorerService.singleCatalogRefresh(connectionId).subscribe(
			data => {
				this.sourceLoader = false;
				this.qdwLoader = false;
				this.dbList();
				this.showToggle = [];
				this.searchdatasourcecatalogText = "";
				swal("Success!", catalogName + " is successfully refreshed", "success");
			},
			error => {
				this.sourceLoader = false;
				this.qdwLoader = false;
				swal("Oops...", "Some error occured", "error");
			}
		);
	}
	//material view ends

	/*
	 *Delete Qubz datawarehouse database
	 */
	deleteQwDatabase(schema) {
		if(this.qubzLicenseExpire){
			swal("Warning!", this.qubzLicenseExpireMsg, "success");
		}else{
			swal({
				type: "warning",
				title:
					"Are you sure you want delete " + schema.schema.toUpperCase() + "?",
				text: "All tables associated with this database will be permanently deleted",
				showCancelButton: true,
				confirmButtonText: "Yes",
				cancelButtonText: "No"
			}).then(status => {
				if (status.value == true) {
					this.load = true;
					this.dataExplorerService.deleteDatabase(schema.uuid).subscribe(
						data => {
							this.load = false;
							this.dbList();
							this.showToggle = [];
							swal(
								"Success!",
								schema.schema + " is successfully removed",
								"success"
							);
						},
						error => {
							this.load = false;
							swal("Oops...", "Some error occured", "error");
						}
					);
				}
			});
		}
	}
	/*
	 *Delete table from Qubz datawarehouse database
	 */
	deleteQwDatabaseTable(table) {
		if(this.qubzLicenseExpire){
			swal("Warning!", this.qubzLicenseExpireMsg, "success");
		}else{
			swal({
				type: "warning",
				title: "Are you sure you want delete " + table.table.toUpperCase() + "?",
				text: "All records will be permanently deleted",
				showCancelButton: true,
				confirmButtonText: "Yes",
				cancelButtonText: "No"
			}).then(status => {
				if (status.value == true) {
					this.load = true;
					this.dataExplorerService.deleteTable(table.uuid).subscribe(
						data => {
							this.load = false;
							this.dbList();
							this.showToggle = [];
							swal(
								"Success!",
								table.table + " is successfully removed",
								"success"
							);
						},
						error => {
							this.load = false;
							swal("Oops...", "Some error occured", "error");
						}
					);
				}
			});
		}
	}
	searchFilter(filter) {
		this.filterEvent.emit(filter);
	}

	setOrder(value: string) {
		if (this.order === value) {
			this.reverse = !this.reverse;
		}
		this.order = value;
	}

	showViewData(viewDataCatalog, viewDataSchema, viewDataTable, viewTableId) {
		this.cancelKafka();
		if (this.result == undefined) {
			this.resultViewTab = true;
		}
		this.step4b = true;
		this.step4a = false;
		this.step1 = false;
		this.step5 = true;
		this.step6 = false;
		this.step2 = false;
		this.step3 = false;
		this.showViewDataTab = false;
		this.showAnalyzerTab = true;
		this.viewDataCatalog = viewDataCatalog;
		this.viewDataSchema = viewDataSchema;
		this.viewDataTable = viewDataTable;
		this.searchColumnNameTxt = null;
		if(this.qubzLicenseExpire){
			swal("Warning!", this.qubzLicenseExpireMsg, "success");
			this.step4b = false;
			this.step1 = true;
			this.showViewDataTab=true
		}else{
		//this.showAnalyzer = true;
		//this.AnalyzerComponent.showAnalyzer = true;

		let param = {
			catalogName: this.viewDataCatalog,
			schema: this.viewDataSchema,
			table: this.viewDataTable
		};
		this.loadViewData = true;
		//if(localStorage.getItem('msgtoSaveData')){
		this.getData(param);
		//}
		this.dataExplorerService.getColumnListOnTable(viewTableId).subscribe(
			(data: prestoTableColumnList) => {
				this.viewTableDetails = data.prestoTableColumnList;
				this.loadViewData = false;
			},
			error => {
				this.loadViewData = false;
				swal("Failed to load Data", error.error.msg, "error");
			}
		);
		}
	}

	showTab(tab) {
		if (tab == "newQuery") {
			this.step1 = true;
			this.step2 = false;
			this.step3 = false;
			this.step4a = false;
			this.step4b = false;
			this.isAnalyzerSection = true;
			this.showViewDataTab = true;
			this.showAnalyzerTab = true;
			// if (this.showAnalyzerTab == true) {
			// 	this.AnalyzerComponent.showResultinDataExplorer = true;
			// }
		} else if (tab == "savedQuery") {
			this.step2 = true;
			this.step1 = false;
			this.step3 = false;
			this.step4a = false;
			this.step4b = false;
			this.isAnalyzerSection = true;
			this.showViewDataTab = true;
			this.showAnalyzerTab = true;
			// if (this.showAnalyzerTab == true) {
			// 	this.AnalyzerComponent.showResultinDataExplorer = true;
			// }
		} else if (tab == "queriedHistory") {
			this.step3 = true;
			this.step2 = false;
			this.step1 = false;
			this.step4a = false;
			this.step4b = false;
			this.isAnalyzerSection = true;
			this.showViewDataTab = true;
			this.showAnalyzerTab = true;
			// if (this.showAnalyzerTab == true) {
			// 	this.AnalyzerComponent.showResultinDataExplorer = true;
			// }
		} else if (tab == "analyzeData") {
			this.step4a = true;
			this.step4b = false;
			this.step3 = false;
			this.step2 = false;
			this.step1 = false;
			this.isAnalyzerSection = false;
			this.AnalyzerComponent.showResultinDataExplorer = false;
		} else {
			this.step4a = false;
			this.step4b = true;
			this.step3 = false;
			this.step2 = false;
			this.step1 = false;
			this.isAnalyzerSection = true;
			if (this.result == null || this.result.SQLResult == null) {
				this.resultViewTab = true;
			}
		}
	}

	setTab(nm) {
		if (nm == "result") {
			this.step5 = true;
			this.step6 = false;
		} else {
			this.step5 = false;
			this.step6 = true;
		}
	}

	//material view for Data Tab Starts
	viewDatamaterialize(form: NgForm) {
		this.showViewDataMatModal = true;
		form.reset();
		this.form.value.viewDataconditonType = "exist";
		this.showViewDataDatabaseNameInDropDown = false;
		this.showviewDataDatabaseNameInTextField = true;
		form.controls["viewDatadbName"].reset();
		this.showHide = true;
		this.isDisabled = true;
	}

	/**
	 * check table name in hive
	 */
	checkViewDataDuplicateTable() {
		this.dataExplorerService
			.getListofDatabaseTables(this.form.value.viewDatadbName.toLowerCase())
			.subscribe(
				data => {
					let respose: any;
					respose = data;
					if (
						respose.indexOf(this.form.value.viewDataname.toLowerCase()) == -1
					) {
						this.saveViewDataMaterialize();
					} else {
						swal(
							"Oops...",
							"Table name already exist, please use some unique names",
							"error"
						);
					}
				},
				error => {
					swal("Oops...", error.error.msg, "error");
				}
			);
	}

	closeViewDataMaterialModal() {
		this.showViewDataMatModal = false;
		this.form.reset();
		this.showViewDataDatabaseNameInDropDown = true;
		this.showviewDataDatabaseNameInTextField = true;
	}

	getViewDataDatabaseName(form: NgForm) {
		if (form.value.viewDataconditonType == "new") {
			this.showviewDataDatabaseNameInTextField = false;
			this.showViewDataDatabaseNameInDropDown = true;
			form.controls["viewDatadbName"].reset();
			this.isDisabled = true;
		} else {
			this.showViewDataDatabaseNameInDropDown = false;
			this.showviewDataDatabaseNameInTextField = true;
			form.controls["viewDatadbName"].reset();
			this.isDisabled = true;
		}
	}

	/**
	 * Materialized view creation submission
	 */
	saveViewDataMaterialize() {
		this.load = true;
		const param = { sql: this.queryViewData, limit: this.limit };
		this.dataExplorerService
			.saveMaterializedView(
				this.form.value.viewDatadbName.toLowerCase(),
				this.form.value.viewDataname.toLowerCase(),
				param
			)
			.subscribe(
				data => {
					swal("Success!", "Materialization job submitted", "success");
					this.load = false;
					this.showViewDataMatModal = false;
				},
				error => {
					swal("Oops...", error.error.msg, "error");
					this.showViewDataMatModal = false;
					this.load = false;
				}
			);
	}
	kafkaFormValid(status: boolean) {
		this.isKafkaFormValid = status;
	}

	kafkaFormData(form: any) {
		this.kafkaForm = form;
	}
	jsonFormData(form: FormGroup) {
		this.jsonForm = form;
	}
	jsonFormDataValid(status: boolean) {
		this.isJsonFormValid = status;
	}
	submitData() {
		let body;
		swal({
			type: "warning",
			title: "Are you sure to save the streaming table ?",
			showCancelButton: true,
			confirmButtonText: "Yes",
			cancelButtonText: "No"
		}).then(
			status => {
				if (status.value == true) {
					let errors: any = null;
					this.load = true;
					let server = [];
					this.kafkaForm.servers.forEach(element => {
						server.push(element.host + ":" + element.port);
					});
					let table = {};
					this.jsonForm.value.tableColumns.forEach(ele => {
						if (ele.comment !== "derived time dimension") {
							table = {
								...table,
								[ele.columnName]: ele.columnName
							};
						}
					});
					let colData = [];
					this.jsonForm.value.tableColumns.map(item => {
						if (item.comment !== "derived time dimension")
							colData = [
								...colData,
								{
									name: item.columnName,
									datatype: item.columnType,
									comment: item.comment,
									id: item.id,
									colName: item.columnName
								}
							];
						else
							colData = [
								...colData,
								{
									name: item.columnName,
									datatype: item.columnType,
									id: item.id,
									comment: item.comment,
									colName: item.columnName
								}
							];
					});
					body = {
						catalogName: this.kafkaForm.value.catalogName,
						publicName: this.kafkaForm.value.publicName,
						description: this.kafkaForm.value.description,
						tableNames:
							this.kafkaForm.value.schema +
							"." +
							this.kafkaForm.value.tableName,
						topic: this.kafkaForm.topic,
						connectorName: "kafka",
						hideInternalColumns: false,
						nodes: server,

						columns: colData
					};
					this.dataExplorerService.saveKafkaStreamingCatalog(body).subscribe(
						result => {
							swal(
								"Success!",
								"Kafka source successfully added",
								"success"
							).then(status => {
								if (status.value == true) {
									this.dbList();
									this.cancelKafka();
									this.load = false;
								}
							});
						},
						error => {
							errors = error;
							swal("Failed to save Kafka source", errors.error.msg, "error");
							this.load = false;
						},
						() => {
							if (!errors) {
								swal(
									"Success!",
									"Kafka source successfully added",
									"success"
								).then(status => {
									if (status.value == true) {
										this.dbList();
										this.cancelKafka();
										this.load = false;
									}
								});
							}
						}
					);
				}
			},
			dismiss => {
				if (dismiss === "cancel") {
				}
			}
		);
	}

	buttonToggle(screen, form: NgForm) {
		this.generateScheduledTimes = "";
		if (screen == 1) {
			if (form.value.conditonType == "exist") {
				if (form.value.dbName !== null && form.value.name !== null) {
					if (this.showHide == true) {
						this.isDisabled = false;
					} else {
						this.isDisabled = true;
						if (
							form.value.scheduleName !== null &&
							this.schCronString !== null
						) {
							this.isDisabled = true;
						}
						if (form.value.scheduleName == "" && this.schCronString !== "") {
							this.isDisabled = true;
						}

						if (form.value.scheduleName !== "" && this.schCronString == "") {
							this.isDisabled = true;
						}

						if (form.value.scheduleName == "" && this.schCronString == "") {
							this.isDisabled = true;
						}
					}
				}

				if (form.value.dbName == null && form.value.name !== "") {
					if (this.showHide == false) {
						if (
							form.value.scheduleName !== null &&
							this.schCronString !== null
						) {
							this.isDisabled = true;
						}
					} else {
						this.isDisabled = true;
					}
				}

				if (form.value.dbName !== null && form.value.name == "") {
					if (this.showHide == false) {
						if (
							form.value.scheduleName !== null &&
							this.schCronString !== null
						) {
							this.isDisabled = true;
						}
					} else {
						this.isDisabled = true;
					}
				}
			} else {
				if (form.value.dbName !== null && form.value.name !== null) {
					if (this.showHide == true) {
						this.isDisabled = false;
					} else {
						this.isDisabled = true;
						if (
							form.value.scheduleName !== null &&
							this.schCronString !== null
						) {
							this.isDisabled = true;
						}
						if (form.value.scheduleName == "" && this.schCronString !== "") {
							this.isDisabled = true;
						}

						if (form.value.scheduleName !== "" && this.schCronString == "") {
							this.isDisabled = true;
						}

						if (form.value.scheduleName == "" && this.schCronString == "") {
							this.isDisabled = true;
						}
					}
				}

				if (form.value.dbName == "" && form.value.name !== "") {
					if (this.showHide == false) {
						if (
							form.value.scheduleName !== null &&
							this.schCronString !== null
						) {
							this.isDisabled = true;
						}
					} else {
						this.isDisabled = true;
					}
				}

				if (form.value.dbName !== null && form.value.name == "") {
					if (this.showHide == false) {
						if (
							form.value.scheduleName !== null &&
							this.schCronString !== null
						) {
							this.isDisabled = true;
						}
					} else {
						this.isDisabled = true;
					}
				}
			}
		} else {
			if (form.value.viewDataconditonType == "exist") {
				if (
					form.value.viewDatadbName !== null &&
					form.value.viewDataname !== null
				) {
					if (this.showHide == true) {
						this.isDisabled = false;
					} else {
						this.isDisabled = true;
						if (
							form.value.viewschedulerNames !== null &&
							this.viewcronString !== null
						) {
							this.isDisabled = true;
						}
						if (
							form.value.viewschedulerNames == "" &&
							this.viewcronString !== ""
						) {
							this.isDisabled = true;
						}

						if (
							form.value.viewschedulerNames !== "" &&
							this.viewcronString == ""
						) {
							this.isDisabled = true;
						}

						if (
							form.value.viewschedulerNames == "" &&
							this.viewcronString == ""
						) {
							this.isDisabled = true;
						}
					}
				}

				if (
					form.value.viewDatadbName == null &&
					form.value.viewDataname !== ""
				) {
					if (this.showHide == false) {
						if (
							form.value.viewschedulerNames !== null &&
							this.viewcronString !== null
						) {
							this.isDisabled = true;
						}
					} else {
						this.isDisabled = true;
					}
				}

				if (
					form.value.viewDatadbName !== null &&
					form.value.viewDataname == ""
				) {
					if (this.showHide == false) {
						if (
							form.value.viewschedulerNames !== null &&
							this.viewcronString !== null
						) {
							this.isDisabled = true;
						}
					} else {
						this.isDisabled = true;
					}
				}
			} else {
				if (
					form.value.viewDatadbName !== null &&
					form.value.viewDataname !== null
				) {
					if (this.showHide == true) {
						this.isDisabled = false;
					} else {
						this.isDisabled = true;
						if (
							form.value.viewschedulerNames !== null &&
							this.viewcronString !== null
						) {
							this.isDisabled = true;
						}
						if (
							form.value.viewschedulerNames == "" &&
							this.viewcronString !== ""
						) {
							this.isDisabled = true;
						}

						if (
							form.value.viewschedulerNames !== "" &&
							this.viewcronString == ""
						) {
							this.isDisabled = true;
						}

						if (
							form.value.viewschedulerNames == "" &&
							this.viewcronString == ""
						) {
							this.isDisabled = true;
						}
					}
				}

				if (form.value.viewDatadbName == "" && form.value.viewDataname !== "") {
					if (this.showHide == false) {
						if (
							form.value.viewschedulerNames !== null &&
							this.viewcronString !== null
						) {
							this.isDisabled = true;
						}
					} else {
						this.isDisabled = true;
					}
				}

				if (
					form.value.viewDatadbName !== null &&
					form.value.viewDataname == ""
				) {
					if (this.showHide == false) {
						if (
							form.value.viewschedulerNames !== null &&
							this.viewcronString !== null
						) {
							this.isDisabled = true;
						}
					} else {
						this.isDisabled = true;
					}
				}
			}
		}
	}

	saveLogs() {
		var today = new Date();
		let param = {
			username: this.loggedUser.username,
			targetPage: "data-explorer",
			day: today.getDay(),
			month: today.getMonth(),
			year: today.getFullYear()
		};
		this.commonServiceService.saveLogs(param).subscribe(data => {});
	}

	//onVisualClick() {
	//this.form.controls["ds"].markAsTouched();
	//if(this.form.controls["ds"].valid )
	//this.router.navigateByUrl('/visualquerybuild/');
	//}

	navigateAnalyzer(viewDataCatalog, viewDataSchema, viewDataTable) {
		if(this.qubzLicenseExpire){
			swal("Warning!", this.qubzLicenseExpireMsg, "success");
			this.step4b = false;
			this.step1 = true;
			this.showViewDataTab=true
		}else{
			this.showAnalyzerTab = false;
		this.showViewDataTab = true;
		this.isAnalyzerSection = false;
		this.step4a = true;
		this.step4b = false;
		this.step1 = false;
		this.step5 = false;
		this.step6 = false;
		this.step2 = false;
		this.step3 = false;
		//this.showAnalyzer = false;
		this.AnalyzerComponent.showAnalyzer = true;
		this.AnalyzerComponent.onViewTableSelected(
			viewDataCatalog,
			viewDataSchema,
			viewDataTable
		);
		}
	}
	errorPopUp(){
		if(this.qubzLicenseExpire){
			swal("Warning!", this.qubzLicenseExpireMsg, "success");
		}
	}

	sanitate(sql: any) {
		return encodeURIComponent(sql.replace(/\n/g, " "));
	}

	downloadResult(tab) {
		var form = document.createElement("form");
		form.target = "view";
		form.method = "POST";
		form.action = DataExplorerService.url + "/result/download/";
		var params;
		if (tab == 1) {
			params = {
				limit: this.exportSql.limit,
				sql: this.exportSql.sql
			};
		} else {
			params = {
				limit: this.limit,
				sql: this.queryViewData
			};
		}
		let newSql = JSON.stringify(params).trim();

		for (var i in JSON.parse(newSql)) {
			if (params.hasOwnProperty(i)) {
				var input = document.createElement("input");
				input.type = "hidden";
				input.name = i;
				input.value = params[i];
				form.appendChild(input);
			}
		}

		document.body.appendChild(form);
		form.submit();
		window.open("", "view");
	}

	onGridColumnStateChange(_event: GridStateChangeEvent) {
		this.columnState = this.columnApi.getColumnState();
		this.query.columnState = this.columnState;
		// this.columnGroupState = this.columnApi.getColumnGroupState();
		// this.indexTab.columnGroupState = this.columnGroupState;
	}

	onFilterChanged($event: FilterChangedEvent) {
		this.filterModel = $event.api.getFilterModel();
	}

	public onGridReady(params: GridReadyEvent) {
		this.api = params.api;
		this.columnApi = params.columnApi;
		this.api.setServerSideDatasource(this.dataSource);
		this.api.setColumnDefs([]);
	}

	showiqsandboxsearchFields(db) {
		let schemaDtl;

		if (this.searchiqsandboxschemaText !== "") {
			schemaDtl = JSON.parse(localStorage.getItem("schemaDtliq"));
			for (let param of db.schemaList) {
				if (param.schema == schemaDtl.schema) {
					if (this.isActive[param.uuid] == true) {
						this.searchiqsandboxTable = false;
					}
				}
			}
		} else {
			schemaDtl = JSON.parse(localStorage.getItem("schemaDtliq"));
			for (let param of db.schemaList) {
				if (param.schema == schemaDtl.schema) {
					this.selectedQw = "";
					this.isActive[schemaDtl.uuid] = false;
					this.searchiqsandboxTable = true;
					this.searchiqsandboxtblText = "";
				}
			}
		}
	}

	showdatasourcesearchFields(c, db) {
		let catalog;
		let schemaDtl;
		if (c == 1) {
			if (this.searchdatasourcecatalogText !== "") {
				if (db !== "") {
					catalog = db;
					for (let param of this.dbListArr) {
						if (param.catalog == catalog) {
							if (this.showToggle[catalog] == true) {
								this.searchdatasourceschemaField = false;
							}
						}
					}
				} else {
					catalog = localStorage.getItem("catalogDtl");
					for (let param of this.dbListArr) {
						if (param.catalog == catalog) {
							if (this.showToggle[catalog] == true) {
								this.searchdatasourceschemaField = false;
							}
						}
					}
				}
			} else {
				if (db !== "") {
					catalog = db;
					for (let param of this.dbListArr) {
						if (param.catalog == catalog) {
							this.showToggle[catalog] = false;
							this.searchdatasourceschemaField = true;
							this.searchdatasourceschemaText = "";
							this.searchdatasourcetableField = true;
							this.searchdatasourcetblText = "";
							this.selectedDb = "";
							this.selectedTable = "";
							this.isActive = [];
						}
					}
				} else {
					catalog = localStorage.getItem("catalogDtl");
					for (let param of this.dbListArr) {
						if (param.catalog == catalog) {
							this.showToggle[catalog] = false;
							this.searchdatasourceschemaField = true;
							this.searchdatasourceschemaText = "";
							this.searchdatasourcetableField = true;
							this.searchdatasourcetblText = "";
							this.selectedDb = "";
							this.selectedTable = "";
							this.isActive = [];
						}
					}
				}
			}
		} else {
			if (this.searchdatasourceschemaText !== "") {
				schemaDtl = JSON.parse(localStorage.getItem("schemaDtlds"));
				if (db.schemaList !== undefined) {
					for (let param of db.schemaList) {
						if (param.schema == schemaDtl.schema) {
							if (this.isActive[schemaDtl.uuid] == true) {
								this.searchdatasourcetableField = false;
							}
						}
					}
				}
			} else {
				schemaDtl = JSON.parse(localStorage.getItem("schemaDtlds"));
				if (db.schemaList !== undefined) {
					for (let param of db.schemaList) {
						if (param.schema == schemaDtl.schema) {
							this.selectedDb = "";
							this.selectedTable = "";
							this.isActive[schemaDtl.uuid] = false;
							this.searchdatasourcetableField = true;
							this.searchdatasourcetblText = "";
						}
					}
				}
			}
		}
	}

	fullScreen() {
		this.isFullqueryscreen = !this.isFullqueryscreen;
		localStorage.setItem(
			"enablefullScreen",
			JSON.stringify(this.isFullqueryscreen)
		);
	}

	resetcatalogDataSource(catalogName, connectionId) {
		if (catalogName !== "iq_warehouse") this.sourceLoader = true;
		else this.qdwLoader = true;
		this.dataExplorerService.singleCatalogRefresh(connectionId).subscribe(
			data => {
				this.sourceLoader = false;
				this.qdwLoader = false;
				this.dbList();
				this.showToggle = [];
				this.searchdatasourcecatalogText = "";
				swal("Success!", catalogName + " is successfully refreshed", "success");
			},
			error => {
				this.sourceLoader = false;
				this.qdwLoader = false;
				swal("Oops...", "Some error occured", "error");
			}
		);
	}

	ngAfterContentChecked(): void {}

	explainAnalyze(query, limit) {
		if(this.qubzLicenseExpire){
			swal("Warning!", this.qubzLicenseExpireMsg, "success");
		}else{
			const res = query
			.toLowerCase()
			.split(/[\s,\?\,\.!]+/)
			.some(f => f === "limit");
		let queryNew;

		if (this.check) {
			if (res) {
				let val = query.split("limit");
				queryNew = val[0];
			} else {
				queryNew = query.replace(/;\s*$/, "");
			}
		} else {
			queryNew = query.replace(/;\s*$/, "");
		}

		if (localStorage.getItem("enablefullScreen") !== undefined) {
			this.isfullScreeneditor = JSON.parse(
				localStorage.getItem("enablefullScreen")
			);
			if (this.isfullScreeneditor == true) {
				this.isfullScreeneditor = false;
				localStorage.setItem(
					"enablefullScreen",
					JSON.stringify(this.isfullScreeneditor)
				);
			}
		}

		if (this.isFullqueryscreen == true) {
			this.isFullqueryscreen = false;
		}
		this.resultViewTab = false;
		this.isAnalyzerEnabled = true;
		this.isCrudEnabled = true;
		this.statusAnalyzerSection = true;
		this.statusDDLSection = true;
		this.isDDLButtonsEnabled = true;
		this.isresultButtonEnabled = true;
		this.isAnalyzerHidden = true;
		this.result = {};
		this.currentCatalog = "";
		$(".result-c").addClass("showresult");
		this.queryView = queryNew;
		let tablename = this.queryView.split(" ");
		this.currentCatalogDetails = tablename[3];
		this.currentQueries.push({
			sql: queryNew,
			savedAt: new Date(),
			status: "Executing",
			startTime: new Date(),
			result: "",
			id: "test"
		});
		this.queries1 = this.currentQueries;
		const params = {
			sql: queryNew,
			limit: limit
		};

		this.exportSql = params;
		this.showExecutionTab = false;
		this.shownotExecutionTab = true;
		this.status = "Executing";
		this.startTime = new Date();
		const param = {
			limit: limit,
			sqlText: queryNew
		};
		this.dataExplorerService.explainAnalyze(param).subscribe(
			data => {
				this.result = data;
				const index = this.queries1.findIndex(
					queryNew => queryNew.id === "test"
				);
				this.queries1.splice(index, 1);
				// }
				this.isQueryAvailable = true;
				if (this.result.error) {
					this.error = this.result.error;
					this.showExecutionTab = true;
					this.shownotExecutionTab = false;
					this.statusexplainAnalyzeSection = false;
					this.isresultExplainAnalyze = true;
					this.isExplainAnalyzeEnabled = true;
					this.status = "Failed";
					if (this.currentQueries.length > 0) {
						this.currentQueries = this.currentQueries;
					}
					this.currentQueries.push({
						sql: queryNew,
						savedAt: new Date(),
						status: this.status,
						startTime: this.startTime,
						result: this.error
					});
					this.queries1 = this.currentQueries;
					this.indexTab = this.error;
				} else {
					this.status = "Success";
					this.showExecutionTab = true;
					this.shownotExecutionTab = false;
					this.statusexplainAnalyzeSection = false;
					this.isresultExplainAnalyze = false;
					this.isExplainAnalyzeEnabled = false;
					if (this.currentQueries.length > 0) {
						this.currentQueries = this.currentQueries;
					}
					this.currentQueries.push({
						sql: queryNew,
						savedAt: new Date(),
						status: this.status,
						startTime: this.startTime,
						result: this.result
					});
					this.queries1 = this.currentQueries;
					this.indexTab = this.result;
				}
			},
			error => {
				this.error = error;
				this.showExecutionTab = true;
				this.shownotExecutionTab = false;
				const index = this.queries1.findIndex(
					queryNew => queryNew.id === "test"
				);
				this.queries1.splice(index, 1);
				this.status = "Failed";
				this.statusexplainAnalyzeSection = false;
				this.isresultExplainAnalyze = true;
				this.isExplainAnalyzeEnabled = true;
				if (this.currentQueries.length > 0) {
					this.currentQueries = this.currentQueries;
				}
				this.currentQueries.push({
					sql: queryNew,
					savedAt: new Date(),
					status: this.status,
					startTime: this.startTime,
					result: this.error
				});
				this.queries1 = this.currentQueries;
				this.indexTab = this.error;
			}
		);
		}
	}
}
