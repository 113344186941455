import { Injectable } from '@angular/core';
import { HttpClient,HttpParams } from '@angular/common/http';
import { Config } from '../../config';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {
  static url = Config.url + '/users';
  constructor(private http: HttpClient) { }
  userList(pageNumber,size) {
    const params = new HttpParams()
    .set('page', pageNumber)
    .set('size', size)
    return this.http.get(UserManagementService.url,{params});
  }
  addUser(body) {
    return this.http.post(UserManagementService.url, body);
  }
  deleteUser(userId) {
    return this.http.delete(UserManagementService.url + '/' + userId);
  }
  updateStatus(userId, body) {
    return this.http.put(UserManagementService.url + '/' + userId + '/status', body);
  }
  updatePassword(userId, body) {
    return this.http.put(UserManagementService.url + '/' + userId + '/password', body);
  }
  updateUser(userId, body) {
    return this.http.put(UserManagementService.url + '/' + userId, body);
  }
}
