export enum keywords {
	BREAK = "break",
	CASE = "case",
	VAR = "var",
	MODULE = "module",
	PUBLIC = "public",
	FINALLY = "finally",
	NULL = "null",
	IN = "in",
	ANY = "any",
	PACKAGE = "package",
	NEW = "new",
	CONTINUE = "continue",
	AS = "as",
	IF = "if",
	NUMBER = "number",
	TYPE = "type",
	PRVATE = "private",
	FOR = "for",
	SUPER = "super",
	RETURN = "return",
	EXTENDS = "extends",
	IMPLEMENTS = "implements",
	TRY = "try",
	DO = "do",
	THROW = "throw",
	STRING = "string",
	INSTANCEOF = "instanceof",
	ENUM = "enum",
	WHILE = "while",
	THIS = "this",
	TRUE = "true",
	STATIC = "static",
	INTERFACE = "interface",
	YIELD = "yield",
	CATCH = "catch",
	SWITCH = "switch",
	ELSE = "else",
	GET = "get",
	TYPEOF = "typeof",
	EXPORT = "export",
	VOID = "void",
	FALSE = "false",
	LET = "let",
	FUNCTION = "function",
	CONST = "const",
	DATE = "date",
	VARCHAR = "varchar",
	INTEGER = "integer",
	INT = "int",
	FLOAT = "float",
	DECIMAL = "decimal",
	REAL = "real",
	BYTE = "byte",
	BIGINT = "bigint",
	TIME = "time",
	TIMESTAMP = "timestamp",
	ARRAY = "array",
	BINARY = "binary",
	BIT = "bit",
	BLOB = "blob",
	BOOLEAN = "boolean",
	CHAR = "char",
	CLOB = "clob",
	DATALINK = "datalink",
	DISTINCT = "distinct",
	DOUBLE = "double",
	JAVA_OBJECT = "java_object",
	LONGNVARCHAR = "longnvarchar",
	LONGVARBINARY = "longvarbinary",
	LONGVARCHAR = "longvarchar",
	NCHAR = "nchar",
	NCLOB = "nclob",
	NUMERIC = "numeric",
	NVARCHAR = "nvarchar",
	// OTHER = "other",
	// REF = "ref",
	// REF_CURSOR = "ref_cursor",
	// ROWID = "rowid",
	SMALLINT = "smallint",
	SQLXML = "sqlxml",
	STRUCT = "struct",
	TIME_WITH_TIMEZONE = "time_with_timezone",
	TIMESTAMP_WITH_TIMEZONE = "timestamp_with_timezone",
	TINYINT = "tinyint",
	VARBINARY = "varbinary",
	EQUAL = "equal",
	EQUALS = "equals",
	ABSTRACT = "abstract",
	CLASS = "class",
	DEFAULT = "default",
	FINAL = "final",
	IMPORT = "import",
	LONG = "long",
	NATIVE = "native",
	PROTECTED = "protected",
	SHORT = "short",
	STRICTFP = "strictfp",
	SYNCHRONIZED = "synchronized",
	THROWS = "throws",
	TRANSIENT = "transient",
	VOLATILE = "volatile",
	GOTO = "goto",
	DROP = "drop",
	CREATE = "create",
	INSERT = "insert",
	SELECT = "select",
	FROM = "from",
	ALTER = "alter",
	TRUNCATE = "truncate",
	AND = "and",
	OR = "or",
	ADD = "add",
	UPDATE = "update",
	SET = "set",
	DELETE = "delete",
	ORDERBY = "orderby",
	ASC = "asc",
	DESC = "desc",
	BETWEEN = "between",
	WHERE = "where",
	NOT = "not",
	LIMIT = "limit",
	KEY = "key",
	HAVING = "having",
	JOIN = "join",
	UNION = "union",
	LIKE = "like",
	PROCEDURE = "procedure"
}
