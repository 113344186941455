import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ColumnEl, IMetaColumn, JoinEl } from "../data";
import { VisualQueryBuilderService } from "../visual-query-builder.service";

@Component({
	selector: "app-join",
	templateUrl: "./join.component.html",
	styleUrls: ["./join.component.scss"]
})
export class JoinComponent implements OnInit {
	@Input() visualQueryForm;
	@Input() metaColumns: IMetaColumn[] = [];
	@Input() metaTabels: String[] = [];
	@Input() from: string = "";
	@Output() change: EventEmitter<JoinEl[]> = new EventEmitter();
	isEdit: boolean = false;
	@Input() elements: JoinEl[];
	@Input() noJoinErrMsg: boolean = true;
	@Input() SortComponent;
	isWarning;
	@Input() invalidJoinMsg: boolean = true;

	element: JoinEl = {
		from: { table: "", field: "", by: "", type: "", alias: "" },
		to: { table: "", field: "", by: "", type: "", alias: "" },
		join: "INNER JOIN",
		cols: []
	};
	constructor(
		public modalService: NgbModal,
		private builderService: VisualQueryBuilderService
	) {
		this.elements = [];
	}
	ngOnInit(): void {
		this.isEdit =
			this.elements &&
			this.elements.length > 0 &&
			this.elements[0].from.table != "";
	}
	toggle() {
		if (!this.from) {
			this.isWarning = true;
			return;
		}
		this.isWarning = false;
		this.isEdit = !this.isEdit;
		if (this.isEdit) {
			this.addEl();
		}
		if (!this.isEdit || !this.elements) {
			this.elements = [];
			this.change.emit(this.elements);
		}
	}
	addEl() {
		// let e = this.elements.filter(f => f.from.field == '' || f.to.field == '')
		// if (e.length > 0) {
		//   return;
		// }
		// this.builderService.selectedTableCols=[];
		// this.builderService.newselectedTableCols=undefined;
		if (this.elements == null) this.elements = [];
		let size = this.elements.length;
		if (size == 0)
			this.elements.push({
				from: { table: this.from, field: "", by: "", type: "", alias: "" },
				to: { table: "", field: "", by: "", type: "", alias: "" },
				join: "INNER JOIN",
				cols: []
			});
		else
			this.elements.push({
				from: {
					table: this.elements[size - 1].to.table,
					field: "",
					by: "",
					type: "",
					alias: ""
				},
				to: { table: "", field: "", by: "", type: "", alias: "" },
				join: "INNER JOIN",
				cols: []
			});
		this.change.emit(this.elements);
	}

	colDialog2(el) {
		this.element = el;
	}
	delEl(el) {
		let index = this.elements.indexOf(el);
		if (index != -1) this.elements.splice(index, 1);
		this.builderService.fromTable.pop();
		if (this.elements.length == 0) this.isEdit = false;
		this.change.emit(this.elements);
	}

	changeItem(el: JoinEl) {
		this.change.emit(this.elements);
	}
	ngChanges() {}

	showMessage() {
		this.noJoinErrMsg = false;
	}

	clearVal() {
		if (this.builderService.sqlQuery.join == undefined) {
			this.isEdit = false;
			this.elements = [];
		}
	}
}
