import { Component, OnInit, Input } from "@angular/core";

@Component({
	selector: "app-data-source-steptwo",
	templateUrl: "./data-source-steptwo.component.html",
	styleUrls: ["./data-source-steptwo.component.scss"]
})
export class DataSourceSteptwoComponent implements OnInit {
	@Input() showsourceDiv: any;
	@Input() sourceType: any;
	@Input() catalogFormValue: any;
	@Input() sourceFormValue: any;
	fileName;
	publishedName;
	constructor() {}
	tags = [];

	ngOnInit() {
		if (this.showsourceDiv.stepthree == true) {
			this.showsourceDiv.stepone = false;
			this.showsourceDiv.steptwo = false;
		}

		if (this.catalogFormValue !== undefined) {
			if (this.catalogFormValue.publishName == true) {
				this.publishedName = "Published";
			} else {
				this.publishedName = "Unpublished";
			}
		}else if(this.sourceType=="iq_warehouse"){
			this.catalogFormValue = {
				catalogName:"iq_warehouse",
				publicName:"iq_warehouse"
			}
			
		}

		if (
			this.sourceType === "bigquery" &&
			this.sourceFormValue !== undefined &&
			this.sourceFormValue.password !== null
		) {
			this.fileName = this.sourceFormValue.password.split("\\");
		}

		let tags;
		let ntag;
		if (
			localStorage.getItem("tag") !== "" &&
			localStorage.getItem("tag") != null
		) {
			tags = JSON.parse(localStorage.getItem("tag"));

			for (let tag of tags) {
				ntag = tag.tagName;
				this.tags.push(ntag);
			}
		}
	}
}
