import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	AfterContentChecked
} from "@angular/core";
import {
	FormBuilder,
	FormGroup,
	FormControl,
	Validators
} from "@angular/forms";

import { Router } from "@angular/router";

@Component({
	selector: "app-data-source-stepone",
	templateUrl: "./data-source-stepone.component.html",
	styleUrls: ["./data-source-stepone.component.scss"]
})
export class DataSourceSteponeComponent implements OnInit, AfterContentChecked {
	catalogForm: FormGroup;
	@Input() showsourceDiv: any;
	@Input() sourceType: any;
	@Input() isEdit;
	@Input() testStatus;
	@Input() saveStatus;
	@Input() testButton;
	@Input() selectedDbType;
	isPattern: boolean = false;
	isCatalogRequired: boolean = false;
	@Output() catalogFormValid = new EventEmitter<any>();
	@Output() catalogFormData = new EventEmitter<any>();
	@Output() checkcatalogpublicSame = new EventEmitter<any>();
	@Input() catalogpublicSame;
	@Input() catalogFormValue;
	sourceForm: FormGroup;
	@Output() sourceFormValid = new EventEmitter<any>();
	@Output() sourceFormData = new EventEmitter<any>();
	@Input() fileToUpload: File = null;
	@Input() sourceFormValue: any;
	@Output() fileUpload = new EventEmitter<any>();
	publishName = false;
	@Input() fileName;
	typePassword;
	publicName;
	catalogName;
	dbImage = [
		{ type: "mysql", value: "../../../assets/images/mysql.svg" },
		{ type: "postgresql", value: "../../../assets/images/postgresql_logo.svg" },
		{ type: "redshift", value: "../../../assets/images/redshift.svg" },
		{ type: "sqlserver", value: "../../../assets/images/sqlserver.svg" },
		{ type: "oracle", value: "../../../assets/images/oracle-icon.svg" },
		{ type: "hive2", value: "../../../assets/images/hive2.png" },
		//{ type: "iq_warehouse", value: "../../../assets/images/warehouse.svg" },
		{ type: "memsql", value: "../../../assets/images/memsql.svg" },
		{ type: "mongodb", value: "../../../assets/images/mongodb.svg" },
		{ type: "bigquery", value: "../../../assets/images/bigquery.svg" },
		{ type: "kafka", value: "../../../assets/images/catalog_kafka.svg" },
		{ type: "snowflake", value: "../../../assets/images/snowflake-icon.svg" },
		{ type: "iqsandbox", value: "../../../assets/images/iqsandbox_icon.svg" }
		// { type: "salesforce", value: "../../../assets/images/salesforce-logo.svg" } hiding for now. Will enable once we implement from backend.
	];
	@Input() step1;
	@Input() step2;
	isTagEnabled: boolean = true;
	showCustomTag: boolean = true;
	tagsData = [];
	selectedValue: any = [];
	indexposition: any;
	newTagData;
	searchText = null;
	tagName;
	iqsandboxtooltip;
	@Input() publicNamekeywordcheck: boolean = false;
	@Output() publicNamekeywordCheck = new EventEmitter<any>();
	@Input() catalogNamekeywordcheck: boolean = false;
	@Output() catalogNamekeywordCheck = new EventEmitter<any>();

	publicNamekeyworderrormsg;
	catalogNamekeyworderrormsg;

	constructor(private formBuilder: FormBuilder, private router: Router) {
		this.initializecatalogForm();
	}

	ngOnInit() {
		this.initializeSourceForm();
		if (this.showsourceDiv.stepone == true) {
			this.showsourceDiv.steptwo = false;
			this.showsourceDiv.stepthree = false;
			this.catalogForm.reset();
			if (this.catalogFormValue !== undefined) {
				this.catalogForm.patchValue(this.catalogFormValue);
			}
			this.catalogForm.valueChanges.subscribe(val => {
				this.catalogFormValid.emit(this.catalogForm.valid);
				this.catalogFormData.emit(this.catalogForm.value);
			});
			if (
				localStorage.getItem("tag") !== "" &&
				localStorage.getItem("tag") != null
			) {
				this.newTagData = JSON.parse(localStorage.getItem("tag"));
				if (this.newTagData.length > 0) {
					this.showCustomTag = false;
				} else {
					this.showCustomTag = true;
				}
			}

			if (
				this.catalogForm.value.publicName !== null &&
				this.catalogForm.value.publicName
					.toLowerCase()
					.split(/[\s,\?\,\.!]+/)
					.some(f => f === "iq_warehouse" || f === "iqwarehouse")
			) {
				this.publicNamekeywordcheck = true;
				this.publicNamekeyworderrormsg =
					this.catalogForm.value.publicName.toLowerCase() +
					" " +
					"is reserved" +
					" " +
					"please try something else";
				this.publicNamekeywordCheck.emit(this.publicNamekeywordcheck);
			} else {
				this.publicNamekeywordcheck = false;
				this.publicNamekeyworderrormsg = "";
				this.publicNamekeywordCheck.emit(this.publicNamekeywordcheck);
			}
			if (
				this.catalogForm.value.catalogName !== null &&
				this.catalogForm.value.catalogName
					.toLowerCase()
					.split(/[\s,\?\,\.!]+/)
					.some(f => f === "iq_warehouse" || f === "iqwarehouse")
			) {
				this.catalogNamekeywordcheck = true;
				this.catalogNamekeyworderrormsg =
					this.catalogForm.value.catalogName.toLowerCase() +
					" " +
					"is reserved" +
					" " +
					"please try something else";
				this.catalogNamekeywordCheck.emit(this.catalogNamekeywordcheck);
			} else {
				this.catalogNamekeywordcheck = false;
				this.catalogNamekeyworderrormsg = "";
				this.catalogNamekeywordCheck.emit(this.catalogNamekeywordcheck);
			}
			this.checkCatlogPublicSame();
		} else if (this.showsourceDiv.steptwo == true) {
			this.showsourceDiv.stepone = false;
			this.showsourceDiv.stepthree = false;

			if (this.isEdit == false) {
				this.sourceForm.reset();
			}
			if (this.sourceFormValue !== undefined) {
				this.sourceForm.patchValue(this.sourceFormValue);
			}

			this.sourceForm.valueChanges.subscribe(val => {
				this.sourceFormValid.emit(this.sourceForm.valid);
				this.sourceFormData.emit(this.sourceForm.value);
			});
		}
	}

	initializecatalogForm() {
		this.catalogForm = this.formBuilder.group({
			publicName: new FormControl({ value: null, disabled: this.isEdit }, [
				Validators.required,
				Validators.pattern("^[a-z0-9_-]+")
			]),

			catalogName: new FormControl({ value: null, disabled: this.isEdit }, [
				Validators.required,
				Validators.pattern("^[a-z0-9_-]+")
			]),

			description: new FormControl(null),
			publishName: new FormControl(false, [])
		});
	}

	changeConnectionDetails() {
		if (this.isEdit == true) {
			localStorage.setItem("sourceResponse", "");
		}
		this.testStatus = false;
		this.saveStatus = false;
		this.testButton = true;
		this.selectedDbType = this.sourceType;
		if (this.selectedDbType == "hive2") {
			this.testStatus = true;
		}
		if (this.selectedDbType == "iq_warehouse") {
			this.catalogForm.patchValue({
				catalogName: "iq_warehouse"
			});
			this.testStatus = true;
		}
		// else if (this.catalogForm.value.sourceType == "iq_warehouse") {
		// 	this.testStatus = true;
		// }

		if (
			this.catalogForm.value.publicName !== null &&
			this.catalogForm.value.publicName
				.toLowerCase()
				.split(/[\s,\?\,\.!]+/)
				.some(f => f === "iq_warehouse" || f === "iqwarehouse")
		) {
			this.publicNamekeywordcheck = true;
			this.publicNamekeyworderrormsg =
				this.catalogForm.value.publicName.toLowerCase() +
				" " +
				"is reserved" +
				" " +
				"please try something else";
			this.publicNamekeywordCheck.emit(this.publicNamekeywordcheck);
		} else {
			this.publicNamekeywordcheck = false;
			this.publicNamekeyworderrormsg = "";
			this.publicNamekeywordCheck.emit(this.publicNamekeywordcheck);
		}
		if (
			this.catalogForm.value.catalogName !== null &&
			this.catalogForm.value.catalogName
				.toLowerCase()
				.split(/[\s,\?\,\.!]+/)
				.some(f => f === "iq_warehouse" || f === "iqwarehouse")
		) {
			this.catalogNamekeywordcheck = true;
			this.catalogNamekeyworderrormsg =
				this.catalogForm.value.catalogName.toLowerCase() +
				" " +
				"is reserved" +
				" " +
				"please try something else";
			this.catalogNamekeywordCheck.emit(this.catalogNamekeywordcheck);
		} else {
			this.catalogNamekeywordcheck = false;
			this.catalogNamekeyworderrormsg = "";
			this.catalogNamekeywordCheck.emit(this.catalogNamekeywordcheck);
		}
	}

	checkCatlogPublicSame() {
		if(!this.isEdit){
			if (
				this.catalogForm.value.catalogName !== "" &&
				this.catalogForm.value.catalogName !== null
			) {
				if (
					this.catalogForm.value.catalogName.toLowerCase() ==
					this.catalogForm.value.publicName.toLowerCase()
				) {
					this.catalogpublicSame = true;
					this.isPattern = false;
					this.isCatalogRequired = false;
				} else {
					this.catalogpublicSame = false;
					this.isPattern = true;
					this.isCatalogRequired = false;
				}
			} else {
				this.catalogpublicSame = false;
				this.isPattern = true;
				this.isCatalogRequired = false;
			}
			this.checkcatalogpublicSame.emit(this.catalogpublicSame);
		}else{
			if (
				this.catalogForm.value.publicName !== "" &&
				this.catalogForm.value.publicName !== null
			) {
				if (
					this.catalogForm.value.publicName.toLowerCase() ==
					this.catalogForm.value.catalogName.toLowerCase()
				) {
					this.catalogpublicSame = true;
					// this.isPattern = false;
					// this.isCatalogRequired = false;
				} else {
					this.catalogpublicSame = false;
					// this.isPattern = true;
					// this.isCatalogRequired = false;
				}
			} else {
				this.catalogpublicSame = false;
				// this.isPattern = false;
				// this.isCatalogRequired = true;
			}
			this.checkcatalogpublicSame.emit(this.catalogpublicSame);
		}

	}

	initializeSourceForm() {
		if (this.sourceType == "snowflake") {
			this.sourceForm = this.formBuilder.group({
				hostName: new FormControl(null, [Validators.required,Validators.pattern(/^\S*$/)]),
				userName: new FormControl(null, [Validators.required]),
				password: new FormControl(null, [Validators.required]),
				config: new FormControl(null, []),
				portNumber: new FormControl(null, [
					Validators.required,
					Validators.pattern("^[0-9]*$")
				]),
				database: new FormControl(null, [
					Validators.required,
					Validators.pattern("^[a-zA-Z0-9_-]+")
				]),
				warehouse: new FormControl(null, [Validators.required])
			});
		} else {
			this.sourceForm = this.formBuilder.group({
				hostName: new FormControl(null, [Validators.required,Validators.pattern(/^\S*$/)]),
				userName: new FormControl(null, [Validators.required]),
				password: new FormControl(null, [Validators.required]),
				config: new FormControl(null, []),
				portNumber: new FormControl(null, [
					Validators.required,
					Validators.pattern("^[0-9]*$")
				]),
				database: new FormControl(null, [
					Validators.required,
					Validators.pattern("^[a-zA-Z0-9_-]+")
				])
			});
		}
	}

	handleFileInput(files: FileList) {
		this.fileToUpload = files.item(0);
		if (files.item(0) && !this.validateFile(files.item(0).name)) {
			this.sourceForm.get("password").setErrors({ invalidJson: true });
			return false;
		}
		this.fileUpload.emit(this.fileToUpload);
	}

	validateFile(name: String) {
		var ext = name.substring(name.lastIndexOf(".") + 1);
		if (ext.toLowerCase() == "json") {
			return true;
		} else {
			return false;
		}
	}

	ngAfterContentChecked(): void {
		if (this.fileName !== undefined) {
			this.sourceForm.patchValue({
				password: this.fileName
			});
		}
		if (this.selectedDbType == "iq_warehouse") {
			this.catalogForm.patchValue({
				catalogName: "iq_warehouse"
			});
			this.testStatus = true;
		} else if (this.sourceType == "iq_warehouse") {
			this.testStatus = true;
		}

		let catalogObj;
		let sourceObj;
		if (this.isEdit == true) {
			if (this.step1 == true) {
				this.step2 = false;
				let jddbcResponse;
				if (
					localStorage.getItem("catalogObj") !== "" &&
					localStorage.getItem("catalogObj") !== null
				) {
					let catObj = JSON.parse(localStorage.getItem("catalogObj"));
					this.publicName = catObj.publicName;
					this.catalogName = catObj.catalogName;
				}
				if (
					localStorage.getItem("catalogResponse") !== "" &&
					localStorage.getItem("catalogResponse") !== null
				) {
					jddbcResponse = JSON.parse(localStorage.getItem("catalogResponse"));
					catalogObj = {
						publicName: jddbcResponse.publicName,
						catalogName: jddbcResponse.catalogName,
						description: jddbcResponse.description,
						publishName: jddbcResponse.publish
					};
					localStorage.setItem("catalogObj", JSON.stringify(catalogObj));
					this.catalogFormValue = catalogObj;
					let tags = jddbcResponse.tags;
					if (tags !== null) {
						this.showCustomTag = false;
						this.newTagData = tags;
						this.tagsData = this.newTagData;
						localStorage.setItem("tag", JSON.stringify(this.tagsData));
					}

					if (this.catalogFormValue !== undefined) {
						this.catalogForm.patchValue(this.catalogFormValue);
					}
					localStorage.setItem("catalogResponse", "");
					localStorage.setItem("sourceResponse", JSON.stringify(jddbcResponse));
				}
			} else if (this.step2 == true) {
				this.step1 = false;
				if (localStorage.getItem("sourceResponse") !== "") {
					let sourcejddbcResponse = JSON.parse(
						localStorage.getItem("sourceResponse")
					);
					let pwd;
					if (
						this.sourceType == "bigquery" &&
						sourcejddbcResponse.password == "password"
					) {
						if (this.fileToUpload) {
							pwd = this.fileToUpload.name;
							this.fileUpload.emit(this.fileToUpload);
						} else {
							pwd = sourcejddbcResponse.password;
						}
					} else {
						pwd = sourcejddbcResponse.password;
					}

					sourceObj = {
						hostName: sourcejddbcResponse.hostname,
						userName: sourcejddbcResponse.username,
						config: sourcejddbcResponse.config,
						portNumber: sourcejddbcResponse.port,
						database: sourcejddbcResponse.defaultDatabase,
						password: pwd,
						warehouse: sourcejddbcResponse.snowflakeWarehouse
					};

					this.sourceFormValue = sourceObj;
					if (this.sourceFormValue !== undefined) {
						this.sourceForm.patchValue(this.sourceFormValue);
					}

					this.sourceForm.valueChanges.subscribe(val => {
						this.sourceFormValid.emit(this.sourceForm.valid);
						this.sourceFormData.emit(this.sourceForm.value);
					});
				}
			}
		} else {
			if (this.showsourceDiv.stepone == true) {
				if (localStorage.getItem("resetCatalog") == "plzreset") {
					this.catalogpublicSame = false;
					this.catalogForm.reset();
					this.catalogFormValue = {};
					localStorage.setItem("resetCatalog", "");
					localStorage.setItem("resetSource", "plzreset");
				} else {
					if (this.sourceType == "iq_warehouse") {
						let sandboxcatalogObj = {
							publicName: "iq_warehouse",
							catalogName: "iq_warehouse",
							publishName: "",
							descriptiton: ""
						};
						this.iqsandboxtooltip = "iqwarehouse";
						this.catalogFormValue = sandboxcatalogObj;
						if (this.catalogFormValue !== undefined) {
							this.catalogForm.patchValue(this.catalogFormValue);
						}
						this.catalogForm.valueChanges.subscribe(val => {
							this.catalogFormValid.emit(this.catalogForm.valid);
							this.catalogFormData.emit(this.catalogForm.value);
						});
					}
				}
			}

			if (this.showsourceDiv.steptwo == true) {
				if (localStorage.getItem("resetSource") == "plzreset") {
					this.sourceForm.reset();
					this.sourceFormValue = {};
					localStorage.setItem("resetSource", "");
				}
			}
		}
	}

	togglePassword() {
		this.typePassword = !this.typePassword;
	}

	loadDbImage(type) {
		let img;
		this.dbImage.filter(item => {
			if (item.type === type) img = item.value;
		});

		return img;
	}

	enableAddBtn() {
		if (this.tagName !== "") {
			this.isTagEnabled = false;
		} else {
			this.isTagEnabled = true;
		}
	}

	addTag() {
		if (
			this.selectedValue.length != 0 &&
			this.selectedValue != undefined &&
			this.selectedValue.tagName !== ""
		) {
			if (this.newTagData) {
				this.tagsData = this.newTagData;
			}
			this.tagsData.splice(this.indexposition + 1, 0, this.selectedValue);
			this.tagsData.sort((a, b) => (a.tagName < b.tagName ? -1 : 1));
			this.reduce();
			this.selectedValue = [];
			this.tagName = undefined;
			this.isTagEnabled = true;
			this.showCustomTag = false;
			this.searchText = null;
		} else {
			this.isTagEnabled = true;
			this.tagName = undefined;
			this.searchText = null;
		}
	}

	onSelect(event) {
		let tagObj = {
			tagName: event
		};
		if (
			tagObj.tagName == " " ||
			tagObj.tagName == "  " ||
			tagObj.tagName == "   " ||
			tagObj.tagName == "    " ||
			tagObj.tagName == "    " ||
			tagObj.tagName == "     " ||
			tagObj.tagName == "      " ||
			tagObj.tagName == "       " ||
			tagObj.tagName == "        " ||
			tagObj.tagName == "         " ||
			tagObj.tagName == "          " ||
			tagObj.tagName == "           " ||
			tagObj.tagName == "            " ||
			tagObj.tagName == "             " ||
			tagObj.tagName == "              " ||
			tagObj.tagName == "                " ||
			tagObj.tagName == "                 " ||
			tagObj.tagName == "                  " ||
			tagObj.tagName == "                   " ||
			tagObj.tagName == "                     " ||
			tagObj.tagName == "                     " ||
			tagObj.tagName == "                     " ||
			tagObj.tagName == "               " ||
			tagObj.tagName == "                    " ||
			tagObj.tagName == "                      " ||
			tagObj.tagName == "                       " ||
			tagObj.tagName == "                        " ||
			tagObj.tagName == "                         " ||
			tagObj.tagName == "                          " ||
			tagObj.tagName == "                           " ||
			tagObj.tagName == "                             " ||
			(tagObj.tagName == "                              " &&
				tagObj.tagName.length == 30)
		) {
			tagObj.tagName = "";
		}
		this.selectedValue = tagObj;
	}

	removeTag(index) {
		if (this.newTagData) {
			this.tagsData = this.newTagData;
		}
		if (this.tagsData.length == 1) {
			this.isTagEnabled = true;
			this.showCustomTag = true;
			this.searchText = null;
		}
		this.tagsData.splice(index, 1);
		localStorage.setItem("tag", JSON.stringify(this.tagsData));
	}
	selectedTag(index) {
		this.indexposition = index;
	}

	reduce() {
		let res = [];
		if (this.tagsData.length > 0) {
			this.tagsData.map(function (item) {
				var existItem = res.find(x => x.tagName === item.tagName);
				if (!existItem) {
					res.push(item);
				}
			});
			this.newTagData = res;
			localStorage.setItem("tag", JSON.stringify(this.newTagData));
		}
	}
}
