import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Config } from '../../config';


@Injectable({
  providedIn: 'root'
})
export class CacheService {

  static url = Config.url + '/cache';
  constructor(private http: HttpClient) { }
  

  reloadConfig(config) {
      return this.http.post(CacheService.url + '/announce/config', config);
  }

  clean(name,type,action,event) {
      return this.http.put(CacheService.url  + '/announce' + '/' + name + '/' + type +'/'+ action +'/' , event);
  }

  clearCache(req) {
       return this.http.post(CacheService.url + '/migration', req);
  }


}
