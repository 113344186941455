import { Injectable } from "@angular/core";
import { Observable, BehaviorSubject } from "rxjs";
import { FormGroup, FormBuilder, FormArray, Validators } from "@angular/forms";
import { SqlQuery } from "./sql-query";
import { VisualQueryForm } from "./visual-query-form";
import { QueryFilter } from "./query-filter/sql-query-filter";
import { FilterForm } from "./query-filter/models/filter-form";
import { types } from "./query-filter/models/operator";

@Injectable()
export class VisualQueryBuilderService {
	private builderForm: BehaviorSubject<FormGroup | undefined>;
	builderForm$: Observable<FormGroup>;
	public sqlQuery: SqlQuery;
	constructor(private fb: FormBuilder) {}
	colListing: any;
	join;
	getCatalog;
	getSchema;
	getTable;
	getCol;
	tableList;
	result;
	getmainQuery;
	filters;
	groupBy;
	sortBy;
	limits;
	multiColumns;
	tables = [];
	fromTable = [];
	fromField = [];
	fromselectedTable;
	toTable;
	toField;
	toselectedTable;
	selectedField;
	conditionalValue;
	titleList = [];
	selectedTableCols = [];
	newselectedTableCols;
	castArray = [];
	selectedElementDtls = [];
	selectedTableArr = [];
	selectedTableSort;
	colListingArray = [];
	selectedJoinCols = [];
	newSelectedJoinCols;
	comboArray = [];
	noColumnerrorMsg;
	comboList;
	joinComboArr = [];
	joinComboList;
	cleargrpBy;
	selectSortCols;
	selectSortColsArr = [];
	selectSortColsJoin;
	selectSortColsJoinArr = [];
	selectSortColsAll;
	selectSortColArrAll = [];
	selectSortColsJoinAll;
	selectSortColsJoinArrAll = [];
	clearElements;
	//aliasName;
	newColArray = [];
	newColList;
	countAll;
	analyzerQueryObj;
	removeerrMsg;

	setForm(query: SqlQuery) {
		this.sqlQuery = query;
		this.builderForm = new BehaviorSubject(
			this.fb.group(new VisualQueryForm(this.fb, query))
		);
		if (!query.filters) this.addFilter();
		this.builderForm$ = this.builderForm.asObservable();
	}

	reset() {
		this.sqlQuery = undefined;
		this.builderForm = undefined;
	}

	addFilter() {
		const currentForm = this.builderForm.getValue();
		const currentFilters = currentForm.get("filters") as FormArray;

		currentFilters.push(this.fb.group(new FilterForm(new QueryFilter())));

		this.builderForm.next(currentForm);
	}

	deleteFilter(i: number) {
		const currentForm = this.builderForm.getValue();
		const currentFilters = currentForm.get("filters") as FormArray;

		currentFilters.removeAt(i);

		this.builderForm.next(currentForm);
	}

	isTypeString(type): boolean {
		return [
			types.CHAR,
			types.LONGNVARCHAR,
			types.NCHAR,
			types.NVARCHAR,
			types.VARCHAR,
			types.VARCHAR500,
			types.VARCHAR65535,
			types.VARCHAR300,
			types.VARCHAR90,
			types.VARCHAR45,
			types.VARCHAR50,
			types.VARCHAR40,
			types.VARCHAR14,
			types.VARCHAR16,
			types.VARCHAR33,
			types.VARCHAR39,
			types.VARCHAR7,
			types.VARCHAR5,
			types.VARCHAR17,
			types.VARCHAR19,
			types.VARCHAR1,
			types.VARCHAR10,
			types.VARCHAR52,
			types.VARCHAR13,
			types.VARCHAR36,
			types.VARCHAR32,
			types.VARCHAR130,
			types.VARCHAR30,
			types.VARCHAR3,
			types.VARCHAR18,
			types.VARCHAR22,
			types.VARCHAR25,
			types.VARCHAR24,
			types.VARCHAR20,
			types.VARCHAR23,
			types.VARCHAR29,
			types.VARCHAR26,
			types.VARCHAR2,
			types.VARCHAR12,
			types.VARCHAR6,
			types.VARCHAR31,
			types.VARCHAR9,
			types.VARCHAR200,
			types.VARCHAR100,
			types.VARCHAR255,
			types.VARCHAR1024,
			types.VARCHAR99,
			types.VARCHAR125,
			types.VARCHAR256,
			types.VARCHAR2000,
			types.VARCHAR250,
			types.VARCHAR20000
		].includes(type);
	}
}
