import { Injectable } from '@angular/core';
import { HttpClient,HttpParams,HttpEvent, HttpRequest } from '@angular/common/http';
import { Config } from '../../config';
@Injectable({
  providedIn: 'root'
})
export class TemplateServiceService {

  constructor(private http: HttpClient) { }
  static url = Config.url + '/users';
  getSavedTemplate(){
    const user = JSON.parse(sessionStorage.getItem('loggedUser'));
    return this.http.get(TemplateServiceService.url+'/'+user.username+'/colortheme');
  }
  saveColorTemplate(color){
    let user = JSON.parse(sessionStorage.getItem('loggedUser'));
    user.colorTheme=color;
    return this.http.put(TemplateServiceService.url+'/'+user.username+'/colortheme',{colorTheme:color});
  }
}
