import { Component, OnInit } from '@angular/core';
import { LicenceService } from '../../../services/licence/licence.service';
import swal from 'sweetalert2';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
@Component({
  selector: 'app-licence-details',
  templateUrl: './licence-details.component.html',
  styleUrls: ['./licence-details.component.scss']
})
export class LicenceDetailsComponent implements OnInit {
  public licence;
  constructor(private licenceService:LicenceService,private router: Router) { }

  ngOnInit() {
    this.licenceService.getLicence().subscribe(
      data=>{
        
        this.licence=data;
      }, (error) => {
        swal("Failed",error.error.msg, "error");
      });
  }
  
  decline(){
    this.router.navigate(["dashboard"]);
  }
}
