import { Component, OnInit } from "@angular/core";
import { DataExplorerService } from "../../services/data-explorer/data-explorer.service";
import swal from "sweetalert2";
import { Router } from "@angular/router";

@Component({
	selector: "app-data-request",
	templateUrl: "./data-request.component.html",
	styleUrls: ["./data-request.component.scss"]
})
export class DataRequestComponent implements OnInit {
	constructor(
		private dataExplorerService: DataExplorerService,
		private router: Router
	) {}
	catalogRequest;
	catalogRequestContent = [];
	page: number = 0;
	order: string = "requestId";
	reverse: boolean = true;
	pendingCount;
	approvedCount;
	rejectedCount;
	searchText = null;
	pendingStatus;
	approvedStatus;
	rejectedStatus;
	load: boolean;
	isExpand: boolean = true;
	adminReqModalList: boolean;
	revokedCount;
	revokedStatus;
	newCatalogReq;

	ngOnInit() {
		this.getcatlalogRequestList();
		localStorage.setItem("requestContentDetails", "");
	}

	getcatlalogRequestList() {
		let size;
		this.load = true;
		this.dataExplorerService.getCatalogRequest(this.page, 10, "").subscribe(
			data => {
				localStorage.setItem("selectedCatObj", "");
				this.searchText = null;
				this.load = false;
				this.catalogRequest = data;
				if (this.catalogRequest.page.totalElements !== 0) {
					size = this.catalogRequest.page.totalElements;
				} else {
					size = 10;
				}
				this.dataExplorerService
					.getCatalogRequest(this.page, size, "")
					.subscribe(
						data => {
							this.load = false;
							this.newCatalogReq = data;
							localStorage.setItem(
								"newObj",
								JSON.stringify(this.newCatalogReq)
							);
							this.getstatusCount(this.newCatalogReq);
						},
						error => {
							this.load = false;
							swal("Failed to load Data!", error.error.msg, "error");
						}
					);
			},
			error => {
				this.load = false;
				swal("Failed to load Data!", error.error.msg, "error");
			}
		);
	}

	setOrder(value: string) {
		if (this.order === value) {
			this.reverse = !this.reverse;
		}
		this.order = value;
	}

	getstatusCount(req) {
		this.pendingCount = req.page.content.filter(
			n => n.status === "PENDING"
		).length;
		this.approvedCount = req.page.content.filter(
			n => n.status === "APPROVED"
		).length;
		this.rejectedCount = req.page.content.filter(
			n => n.status === "REJECTED"
		).length;
		this.revokedCount = req.page.content.filter(
			n => n.status === "REVOKED"
		).length;
	}

	delete(id) {
		this.load = true;
		this.dataExplorerService.deleteRequest(id).subscribe(
			data => {
				this.load = false;
				this.getcatlalogRequestList();
			},
			error => {
				this.load = false;
				swal("Oops!", error.error.msg, "error");
			}
		);
	}

	refreshStatus(st) {
		let status;
		let isComplete;
		this.searchText = null;
		if (st !== 5) {
			if (this.pendingStatus == true) {
				if (this.pendingCount !== 0) {
					status = "PENDING";
					isComplete = false;
					this.dataExplorerService
						.getCatalogRequest(this.page, this.pendingCount, status)
						.subscribe(
							data => {
								this.newCatalogReq = data;
							},
							error => {
								this.load = false;
								swal("Failed to load Data!", error.error.msg, "error");
							}
						);
				} else {
					this.newCatalogReq = undefined;
				}
			} else if (this.approvedStatus == true) {
				isComplete = true;
				status = "APPROVED";
				if (this.approvedCount !== 0) {
					this.dataExplorerService
						.getCatalogRequest(this.page, this.approvedCount, status)
						.subscribe(
							data => {
								this.newCatalogReq = data;
							},
							error => {
								this.load = false;
								swal("Failed to load Data!", error.error.msg, "error");
							}
						);
				} else {
					this.newCatalogReq = undefined;
				}
			} else if (this.rejectedStatus == true) {
				isComplete = true;
				status = "REJECTED";
				if (this.rejectedCount !== 0) {
					this.dataExplorerService
						.getCatalogRequest(this.page, this.rejectedCount, status)
						.subscribe(
							data => {
								this.newCatalogReq = data;
							},
							error => {
								this.load = false;
								swal("Failed to load Data!", error.error.msg, "error");
							}
						);
				} else {
					this.newCatalogReq = undefined;
				}
			} else if (this.revokedStatus == true) {
				isComplete = true;
				status = "REVOKED";
				if (this.rejectedCount !== 0) {
					this.dataExplorerService
						.getCatalogRequest(this.page, this.revokedCount, status)
						.subscribe(
							data => {
								this.newCatalogReq = data;
							},
							error => {
								this.load = false;
								swal("Failed to load Data!", error.error.msg, "error");
							}
						);
				} else {
					this.newCatalogReq = undefined;
				}
			} else {
				this.page = 0;
				status = "";
				isComplete = "";
				this.pendingStatus = false;
				this.approvedStatus = false;
				this.rejectedStatus = false;
				this.revokedStatus = false;
				if (
					this.pendingCount !== 0 ||
					this.approvedCount !== 0 ||
					(this.rejectedCount !== 0 && this.revokedCount !== 0)
				) {
					this.getcatlalogRequestList();
				} else {
					this.newCatalogReq = undefined;
				}
			}
		} else {
			this.page = 0;
			this.pendingStatus = false;
			this.approvedStatus = false;
			this.rejectedStatus = false;
			this.revokedStatus = false;
			this.getcatlalogRequestList();
		}
	}

	isExpandToggle() {
		this.isExpand = !this.isExpand;
	}

	getadminReqDetails(content) {
		this.router.navigate(["/requests/requestAccessDetails"]);
		localStorage.setItem("requestContentDetails", JSON.stringify(content));
	}

	trackById(index: number, item) {
		return item.requestId;
	}
}
