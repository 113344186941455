interface column {
	SchemaId?: any;
	catalog?: any;
	column?: any;
	last_modified?: any;
	schema?: any;
	table?: any;
	tableId?: any;
	type?: any;
	uuid?: any;
	version?: any;
}
interface prestoTableColumnList {
	prestoTableColumnList: column[];
}
interface dbTable {
	catalog?: any;
	last_modified?: any;
	schema?: any;
	schemaId?: any;
	table?: any;
	uuid?: any;
	version?: any;
	columns?: column[];
}
interface prestoSchemaTableList {
	prestoSchemaTableList: dbTable[];
}
interface schema {
	schema?: any;
	catalog?: any;
	uuid?: any;
	version?: any;
	last_modified?: any;
	connectionId?: any;
	tableList?: dbTable[];
}
interface prestoCatalogSchemaList {
	prestoCatalogSchemaList: schema[];
}

interface dbList {
	connectionId?: any;
	catalog?: any;
	dbType?: any;
	uuid?: any;
	version?: any;
	last_modified?: any;
	numberOfSchema?: any;
	schemaList?: schema[];
	qubzDataWarehouse?: any;
}

interface prestoData {
	prestoCatalogList: dbList[];
}

import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import {
	FormGroup,
	FormControl,
	FormBuilder,
	Validators,
	FormArray,
	NgForm
} from "@angular/forms";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CoreServicesService } from "src/app/services/core-services/core-services.service";
import { DataExplorerService } from "../../../../services/data-explorer/data-explorer.service";
import { ToastrService } from "ngx-toastr";
import swal from "sweetalert2";
import { VisualQueryBuilderService } from "../visual-query-builder.service";

@Component({
	selector: "is-query-column",
	styleUrls: ["./columns.component.scss"],
	templateUrl: "./columns.component.html"
})
export class ColumnsComponent implements OnInit {
	data: any;
	titleMessage: string;
	bodyMessage: string;
	id: string;
	emails: string;
	selTable: string;
	isTable: boolean = true;
	toTable: boolean = true;
	public joinQueryForm: FormGroup;
	catlogListArr;
	catlogschemListing;
	tableListing;
	columnListing;
	showFirstColumns: boolean = false;
	showSecondColumn: boolean = false;
	removeerrMsg: boolean = true;
	showColumns: boolean = true;
	filteredTablesFrom;
	notableMsg: boolean = true;
	filteredFieldsFrom;
	filteredTablesTo;
	filteredFieldsTo;
	selToTable;

	@Output() output: EventEmitter<any> = new EventEmitter();
	constructor(
		public activeModal: NgbActiveModal,
		private coreService: CoreServicesService,
		private toastr: ToastrService,
		private dataExplorerService: DataExplorerService,
		private builderService: VisualQueryBuilderService
	) {}

	ngOnInit() {
		this.joinQueryForm = new FormGroup({
			catalog: new FormControl(null, [Validators.required]),
			schema: new FormControl(null, [Validators.required]),
			table: new FormControl(null, [Validators.required]),
			columns: new FormControl()
		});
		var body = document.body;
		body.classList.add("qbmodal");
		this.catalogList();
		if (this.builderService.fromTable.length > 0) {
			this.filteredTablesFrom = this.remove_duplicates(
				this.builderService.fromTable
			);
		}

		if (this.builderService.fromField.length > 0) {
			this.filteredFieldsFrom = this.builderService.fromField;
			if (this.builderService.fromselectedTable) {
				this.getcolumns(this.builderService.fromselectedTable);
			}
		}

		if (this.builderService.toField) {
			this.filteredFieldsTo = this.builderService.toField;
			if (this.builderService.toselectedTable) {
				this.getcolumnList(this.builderService.toselectedTable);
				this.showSecondColumn = true;
			}
		}
	}

	remove_duplicates(arr) {
		let obj = {};
		for (let i = 0; i < arr.length; i++) {
			obj[arr[i]] = true;
		}
		arr = [];
		for (let key in obj) {
			arr.push(key);
		}
		return arr;
	}

	ngOnDestroy() {
		var body = document.body;
		body.classList.remove("qbmodal");
	}
	getTables() {
		let mTables = this.data.metaTabels;
		return mTables;
	}

	getcolumnList(table) {
		this.selToTable = table;
		for (let param of this.builderService.tables) {
			for (let com of param) {
				if (table == com.catalog + "." + com.schema + "." + com.table) {
					this.dataExplorerService.getColumnListOnTable(com.uuid).subscribe(
						(data: prestoTableColumnList) => {
							this.columnListing = data.prestoTableColumnList;
							//this.builderService.colListing=this.columnListing;
							if (this.columnListing.length > 0) {
								this.removeerrMsg = true;
							} else {
								this.removeerrMsg = false;
							}
						},
						error => {
							swal("Failed to load Data", error.error.msg, "error");
							this.showColumns = true;
						}
					);
				}
			}
		}
	}

	getcolumns(table) {
		this.showFirstColumns = true;
		this.selTable = table;
		for (let param of this.builderService.tables) {
			for (let com of param) {
				if (table == com.catalog + "." + com.schema + "." + com.table) {
					this.dataExplorerService.getColumnListOnTable(com.uuid).subscribe(
						(data: prestoTableColumnList) => {
							this.columnListing = data.prestoTableColumnList;
							this.showColumns = false;
							//this.builderService.colListing=this.columnListing;
							if (this.columnListing.length > 0) {
								this.removeerrMsg = true;
							} else {
								this.removeerrMsg = false;
							}
						},
						error => {
							swal("Failed to load Data", error.error.msg, "error");
							this.showColumns = true;
						}
					);
				}
			}
		}
	}

	selcol(selection) {
		this.output.emit(selection);
		this.ngOnDestroy();
	}

	catalogList() {
		this.dataExplorerService.getConnectionsList().subscribe(
			(data: prestoData) => {
				this.catlogListArr = data.prestoCatalogList;
			},
			error => {
				swal("Failed to load Data", error.error.msg, "error");
			}
		);
	}

	schemaList(event, f: FormGroup) {
		this.notableMsg = true;
		f.controls["schema"].reset();
		f.controls["table"].reset();
		f.controls["columns"].reset();
		this.tableListing = undefined;
		this.columnListing = undefined;

		if (event !== undefined) {
			this.dataExplorerService.getSchemaList(event.connectionId).subscribe(
				(data: prestoCatalogSchemaList) => {
					this.catlogschemListing = data.prestoCatalogSchemaList;
				},
				error => {
					swal("Failed to load Data", error.error.msg, "error");
				}
			);
		} else {
			this.catlogschemListing = undefined;
			f.controls["schema"].reset();
			f.controls["table"].reset();
			this.tableListing = undefined;
			f.controls["columns"].reset();
			this.tableListing = undefined;
			this.columnListing = undefined;
		}
	}

	tableList(event, f: FormGroup) {
		f.controls["table"].reset();
		f.controls["columns"].reset();
		this.columnListing = undefined;
		if (event !== undefined) {
			this.dataExplorerService.getTableListOnSchema(event.uuid).subscribe(
				(data: prestoSchemaTableList) => {
					this.tableListing = data.prestoSchemaTableList;
					if (this.tableListing.length > 0) {
						this.notableMsg = true;
					} else {
						this.notableMsg = false;
					}
					this.builderService.toTable = this.tableListing;
					this.builderService.tables.push(this.tableListing);
				},
				error => {
					swal("Failed to load Data", error.error.msg, "error");
				}
			);
		} else {
			this.tableListing = undefined;
			f.controls["table"].reset();
			f.controls["columns"].reset();
			this.tableListing = undefined;
			this.columnListing = undefined;
		}
	}

	columnList(table) {
		this.selToTable = table;
		this.joinQueryForm.controls["columns"].reset();
		for (let tab of this.builderService.toTable) {
			let nm = this.selTable.split(".", 3);
			if (nm[2] == tab.table) {
				this.dataExplorerService.getColumnListOnTable(tab.uuid).subscribe(
					(data: prestoTableColumnList) => {
						this.showSecondColumn = true;
						this.columnListing = data.prestoTableColumnList;
						this.builderService.colListing = this.columnListing;
						if (this.columnListing.length > 0) {
							this.removeerrMsg = true;
						} else {
							this.removeerrMsg = false;
						}
					},
					error => {
						swal("Failed to load Data", error.error.msg, "error");
					}
				);
			}
		}
	}

	closeFirstTable() {
		this.showFirstColumns = false;
		this.activeModal.close(true);
	}

	closeSecondTable() {
		this.showSecondColumn = false;
		this.activeModal.close(true);
	}
}
