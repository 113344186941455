import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thousandSuff'
})
export class CustomPipe implements PipeTransform {

  transform(number: any, args?: any): any {
    if(number == 0) {
      return 0;
  }
  else
  {        
    // hundreds
    if(number <= 999){
      return number ;
    }
    // thousands
    else if(number >= 1000 && number <= 999999){
      return (number / 1000).toFixed(2) + 'K';
    }
    // millions
    else if(number >= 1000000 && number <= 999999999){
      return (number / 1000000).toFixed(2) + 'M';
    }
    // billions
    else if(number >= 1000000000 && number <= 999999999999){
      return (number / 1000000000).toFixed(2) + 'B';
    }
    else
      return number ;
    }


  }

}