import { Component, OnInit } from "@angular/core";
import { DataExplorerService } from "../../../services/data-explorer/data-explorer.service";
import * as moment from "moment";
import swal from "sweetalert2";
import { Router } from "@angular/router";

@Component({
	selector: "app-data-request-details",
	templateUrl: "./data-request-details.component.html",
	styleUrls: ["./data-request-details.component.scss"]
})
export class DataRequestDetailsComponent implements OnInit {
	load;
	userNameDetails;
	contentDetails;
	selectedCatalogObj = {
		catalogDataSourceType: "",
		catalogDescription: "",
		catalogId: "",
		catalogName: "",
		catalogPublicName: "",
		isCompleted: false,
		processedBy: "",
		processedDate: "",
		processorId: "",
		requestId: "",
		reason: "",
		resolutionReason: "",
		requestedDate: "",
		requesterId: "",
		requesterName: "",
		status: "",
		requestTracker: []
	};
	isApproveRejectDisabled: boolean = false;
	rejectModal: boolean;
	approvalModal: boolean;
	rejectReason;
	approvalReason;
	isDisabled: boolean = true;
	//hideLine: boolean = true;
	trackedRequestedDate;
	trackedRequestedName;
	trackedRequestedReason;
	trackedProcessDate;
	trackedProcessName;
	trackedProcessedReason;
	trackedRevokedDate;
	trackedRevokedName;
	trackedRevokedReason;

	constructor(
		private dataExplorerService: DataExplorerService,
		private router: Router
	) {}

	ngOnInit() {
		if (localStorage.getItem("requestContentDetails") !== "") {
			this.contentDetails = JSON.parse(
				localStorage.getItem("requestContentDetails")
			);
			this.getRequestAccessDetails(this.contentDetails);
		}
	}

	getRequestAccessDetails(content) {
		let trackerSplit;
		let approveTrackerSplit;
		let revokedSplit;
		let trackerTimeSplit;
		let trackerReasonSPlit;
		let approvedtrackertimeSplit;
		let approvedtrackerreasonSplit;
		let revokedtrackertimesSplit;
		let revokedreasontrackerSplit;
		if (
			content.status == "APPROVED" ||
			content.status == "REJECTED" ||
			content.status == "REVOKED"
		) {
			this.isApproveRejectDisabled = true;
			//this.hideLine = false;
		} else {
			this.isApproveRejectDisabled = false;
			//this.hideLine = true;
		}
		//this.adminReqModalList = true;
		this.selectedCatalogObj = content;
		this.selectedCatalogObj.requestedDate = moment(
			content.requestedDate
		).format("YYYY-MM-DD: hh:mm:ssZ");

		this.selectedCatalogObj.processedDate = moment(
			content.processedDate
		).format("YYYY-MM-DD: hh:mm:ssZ");

		if (this.selectedCatalogObj.catalogDataSourceType == undefined) {
			this.selectedCatalogObj.catalogDataSourceType = "";
		}

		if (this.selectedCatalogObj.catalogDescription == undefined) {
			this.selectedCatalogObj.catalogDescription = "";
		}

		if (this.selectedCatalogObj.requestId == undefined) {
			this.selectedCatalogObj.requestId = "";
		}

		if (this.selectedCatalogObj.catalogName == undefined) {
			this.selectedCatalogObj.catalogName = "";
		}

		if (this.selectedCatalogObj.catalogPublicName == undefined) {
			this.selectedCatalogObj.catalogPublicName = "";
		}

		if (this.selectedCatalogObj.requestedDate == undefined) {
			this.selectedCatalogObj.requestedDate = "";
		}

		if (this.selectedCatalogObj.status == undefined) {
			this.selectedCatalogObj.status = "";
		}

		if (this.selectedCatalogObj.reason == undefined) {
			this.selectedCatalogObj.reason = "";
		}

		if (this.selectedCatalogObj.processedBy == undefined) {
			this.selectedCatalogObj.processedBy = "";
		}

		if (this.selectedCatalogObj.processorId == undefined) {
			this.selectedCatalogObj.processorId = "";
		}

		if (this.selectedCatalogObj.resolutionReason == undefined) {
			this.selectedCatalogObj.resolutionReason = "";
		}

		if (this.selectedCatalogObj.requesterId == undefined) {
			this.selectedCatalogObj.requesterId = "";
		}

		if (this.selectedCatalogObj.requesterName == undefined) {
			this.selectedCatalogObj.requesterName = "";
		}

		if (this.selectedCatalogObj.processedDate == undefined) {
			this.selectedCatalogObj.processedDate = "";
		}

		localStorage.setItem("selectedCatObj", JSON.stringify(content));

		if (this.selectedCatalogObj.requestTracker.length > 1) {
			if (
				this.selectedCatalogObj.status == "APPROVED" ||
				this.selectedCatalogObj.status == "REJECTED"
			) {
				trackerSplit = this.selectedCatalogObj.requestTracker[0].split("T");
				trackerTimeSplit = trackerSplit[1].split(".");
				trackerReasonSPlit = trackerTimeSplit[1].split("'-'");

				this.trackedRequestedDate = trackerSplit[0] + " " + trackerTimeSplit[0];
				this.trackedRequestedName = trackerReasonSPlit[1];
				this.trackedRequestedReason = trackerReasonSPlit[2];

				approveTrackerSplit =
					this.selectedCatalogObj.requestTracker[1].split("T");
				approvedtrackertimeSplit = approveTrackerSplit[1].split("'-'");
				approvedtrackerreasonSplit = approvedtrackertimeSplit[0].split("Z");
				this.trackedProcessDate =
					approveTrackerSplit[0] + " " + approvedtrackerreasonSplit[0];

				this.trackedProcessName = approvedtrackertimeSplit[1];
				this.trackedProcessedReason = approvedtrackertimeSplit[2];
			} else {
				trackerSplit = this.selectedCatalogObj.requestTracker[0].split("T");
				trackerTimeSplit = trackerSplit[1].split(".");
				trackerReasonSPlit = trackerTimeSplit[1].split("'-'");

				this.trackedRequestedDate = trackerSplit[0] + " " + trackerTimeSplit[0];
				this.trackedRequestedName = trackerReasonSPlit[1];
				this.trackedRequestedReason = trackerReasonSPlit[2];

				approveTrackerSplit =
					this.selectedCatalogObj.requestTracker[1].split("T");
				approvedtrackertimeSplit = approveTrackerSplit[1].split("'-'");
				approvedtrackerreasonSplit = approvedtrackertimeSplit[0].split("Z");
				this.trackedProcessDate =
					approveTrackerSplit[0] + " " + approvedtrackerreasonSplit[0];

				this.trackedProcessName = approvedtrackertimeSplit[1];
				this.trackedProcessedReason = approvedtrackertimeSplit[2];
				revokedSplit = this.selectedCatalogObj.requestTracker[2].split("T");
				revokedtrackertimesSplit = revokedSplit[1].split("'-'");
				revokedreasontrackerSplit = revokedtrackertimesSplit[0].split("Z");
				this.trackedRevokedDate =
					revokedSplit[0] + " " + revokedreasontrackerSplit[0];
				this.trackedRevokedName = revokedtrackertimesSplit[1];
				this.trackedRevokedReason = revokedtrackertimesSplit[2];
			}
		} else {
			for (let param of this.selectedCatalogObj.requestTracker) {
				if (this.selectedCatalogObj.status == "PENDING") {
					trackerSplit = param.split("T");
					trackerTimeSplit = trackerSplit[1].split(".");
					trackerReasonSPlit = trackerTimeSplit[1].split("'-'");
					this.trackedRequestedDate =
						trackerSplit[0] + " " + trackerTimeSplit[0];
					this.trackedRequestedName = trackerReasonSPlit[1];
					this.trackedRequestedReason = trackerReasonSPlit[2];
				} else {
					this.selectedCatalogObj.requestTracker = [];
				}
			}
		}
	}

	showModal(check) {
		if (check == 1) {
			this.approvalModal = true;
			this.rejectModal = false;
		} else {
			this.rejectModal = true;
			this.approvalModal = false;
		}
	}

	submit(c) {
		let msg;
		let catlogOBj;
		let status;

		if (c == 1) {
			status = "REJECTED";
			msg = "User request rejected";
		} else {
			status = "APPROVED";
			msg = "User request approved";
		}

		this.selectedCatalogObj.isCompleted = true;
		if (localStorage.getItem("selectedCatObj") !== undefined) {
			catlogOBj = JSON.parse(localStorage.getItem("selectedCatObj"));
			this.userNameDetails = JSON.parse(
				sessionStorage.getItem("userNameDetails")
			);

			let processedDate = moment().format("YYYY-MM-DDTHH:mm:ss") + "Z";
			let requestedDate =
				moment(catlogOBj.requestedDate).format("YYYY-MM-DDTHH:mm:ss") + "Z";
			this.selectedCatalogObj.catalogDataSourceType =
				catlogOBj.catalogDataSourceType;
			this.selectedCatalogObj.catalogDescription = catlogOBj.catalogDescription;
			this.selectedCatalogObj.catalogId = catlogOBj.catalogId;
			this.selectedCatalogObj.catalogName = catlogOBj.catalogName;
			this.selectedCatalogObj.catalogPublicName = catlogOBj.catalogPublicName;
			this.selectedCatalogObj.processedBy = this.userNameDetails.username;
			this.selectedCatalogObj.processedDate = processedDate;
			this.selectedCatalogObj.processorId = this.userNameDetails.uuid;
			this.selectedCatalogObj.reason = catlogOBj.reason;
			this.selectedCatalogObj.requestId = catlogOBj.requestId;
			this.selectedCatalogObj.requesterId = catlogOBj.requesterId;
			this.selectedCatalogObj.requestedDate = requestedDate;
			this.selectedCatalogObj.requesterName = catlogOBj.requesterName;
			this.selectedCatalogObj.status = status;
		}

		const param = {
			catalogDataSourceType: this.selectedCatalogObj.catalogDataSourceType,
			catalogDescription: this.selectedCatalogObj.catalogDescription,
			catalogId: this.selectedCatalogObj.catalogId,
			catalogName: this.selectedCatalogObj.catalogName,
			catalogPublicName: this.selectedCatalogObj.catalogPublicName,
			isCompleted: this.selectedCatalogObj.isCompleted,
			processedBy: this.selectedCatalogObj.processedBy,
			processedDate: this.selectedCatalogObj.processedDate,
			processorId: this.selectedCatalogObj.processorId,
			reason: this.selectedCatalogObj.reason,
			resolutionReason: this.selectedCatalogObj.resolutionReason,
			requestId: this.selectedCatalogObj.requestId,
			requestedDate: this.selectedCatalogObj.requestedDate,
			requesterId: this.selectedCatalogObj.requesterId,
			requesterName: this.selectedCatalogObj.requesterName,
			status: this.selectedCatalogObj.status,
			requestTracker: this.selectedCatalogObj.requestTracker
		};

		this.load = true;
		this.dataExplorerService.updateCatalogRequest(param).subscribe(
			data => {
				swal("Success!", msg, "success").then(status => {
					if (status.value == true) {
						this.load = false;
						this.close(c);
						this.router.navigate(["/requests"]);
					}
				});
			},
			error => {
				this.load = false;
				this.close(c);
				swal("Oops!", error.error.msg, "error");
			}
		);
	}
	close(c) {
		if (c == 1) {
			this.rejectReason = "";
			this.rejectModal = false;
		} else {
			this.approvalReason = "";
			this.approvalModal = false;
		}
	}

	checkVal(c) {
		if (c == 1) {
			if (this.rejectReason !== "") {
				this.selectedCatalogObj.resolutionReason = this.rejectReason;
				this.isDisabled = false;
			} else {
				this.isDisabled = true;
				this.selectedCatalogObj.resolutionReason = "";
			}
		} else {
			if (this.approvalReason !== "") {
				this.selectedCatalogObj.resolutionReason = this.approvalReason;
			} else {
				this.selectedCatalogObj.resolutionReason = "";
			}
		}
	}
}
