import { Component ,OnInit, AfterViewInit } from '@angular/core';
import {SlimLoadingBarService} from 'ng2-slim-loading-bar';
import * as $ from 'jquery';
import {TemplateServiceService} from './services/template/template-service.service';
import { NavigationCancel,
        Event,
        NavigationEnd,
        NavigationError,
        NavigationStart,
        Router } from '@angular/router';
declare var redthemefun: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit{
  ngAfterViewInit() {
     //document.getElementById('qubzfooter').classList.add("footer-display"); 
    $(document).ready(function(){
      var contenth = $( window ).height() - 110 ;
      var sidebarh = $( window ).height() - 111 ;
      $(".pagec").css("height", contenth );
      $(".sidebar-wrapper").css("height", sidebarh );
    });
  }

  constructor(private _loadingBar: SlimLoadingBarService, private _router: Router,private templateServiceService:TemplateServiceService) {
    this._router.events.subscribe((event: Event) => {
      this.navigationInterceptor(event);
    });
  }
  private navigationInterceptor(event: Event): void {
    if (event instanceof NavigationStart) {
      this._loadingBar.start();
    }
    if (event instanceof NavigationEnd) {
      this._loadingBar.complete();
    }
    if (event instanceof NavigationCancel) {
      this._loadingBar.stop();
    }
    if (event instanceof NavigationError) {
      this._loadingBar.stop();
    }
  }

  title = 'rest';
  ngOnInit() {
  }

}
