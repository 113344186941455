export class Operation {
    value: string;
    label: string;
    acceptedTypes: string[];
  }
  
  export enum types {
    ARRAY = "ARRAY",
    BIGINT = "BIGINT",
    BINARY = "BINARY",
    BIT = "BIT",
    BLOB = "BLOB",
    BOOLEAN = "BOOLEAN",
    CHAR = "CHAR",
    CLOB = "CLOB",
    DATALINK = "DATALINK",
    DATE = "DATE",
    DECIMAL = "DECIMAL",
    DISTINCT = "DISTINCT",
    DOUBLE = "DOUBLE",
    FLOAT = "FLOAT",
    INTEGER = "INTEGER",
    JAVA_OBJECT = "JAVA_OBJECT",
    LONGNVARCHAR = "LONGNVARCHAR",
    LONGVARBINARY = "LONGVARBINARY",
    LONGVARCHAR = "LONGVARCHAR",
    NCHAR = "NCHAR",
    NCLOB = "NCLOB",
    NULL = "NULL",
    NUMERIC = "NUMERIC",
    NVARCHAR = "NVARCHAR",
    OTHER = "OTHER",
    REAL = "REAL",
    REF = "REF",
    REF_CURSOR = "REF_CURSOR",
    ROWID = "ROWID",
    SMALLINT = "SMALLINT",
    SQLXML = "SQLXML",
    STRUCT = "STRUCT",
    TIME = "TIME",
    TIME_WITH_TIMEZONE = "TIME_WITH_TIMEZONE",
    TIMESTAMP = "TIMESTAMP",
    TIMESTAMP3 = "TIMESTAMP(3)",
    TIMESTAMP_WITH_TIMEZONE = "TIMESTAMP_WITH_TIMEZONE",
    TINYINT = "TINYINT",
    VARBINARY = "VARBINARY",
    VARCHAR = "VARCHAR",
    VARCHAR500="VARCHAR(500)",
    VARCHAR65535="VARCHAR(65535)",
    DECIMAL10="DECIMAL(10,0)",
    VARCHAR300="VARCHAR(300)",
    VARCHAR90="VARCHAR(90)", 
    VARCHAR45="VARCHAR(45)", 
    VARCHAR50="VARCHAR(50)", 
    VARCHAR40="VARCHAR(40)", 
    VARCHAR14="VARCHAR(14)", 
    VARCHAR16="VARCHAR(16)", 
    VARCHAR33="VARCHAR(33)",
    VARCHAR39="VARCHAR(39)", 
    VARCHAR7="VARCHAR(7)", 
    VARCHAR5="VARCHAR(5)", 
    VARCHAR17="VARCHAR(17)", 
    VARCHAR19="VARCHAR(19)", 
    VARCHAR1="VARCHAR(1)", 
    VARCHAR10="VARCHAR(10)", 
    VARCHAR52="VARCHAR(52)", 
    VARCHAR13="VARCHAR(13)", 
    VARCHAR36="VARCHAR(36)", 
    VARCHAR32="VARCHAR(32)", 
    VARCHAR130="VARCHAR(130)", 
    VARCHAR30="VARCHAR(30)", 
    VARCHAR3="VARCHAR(3)", 
    VARCHAR18="VARCHAR(18)", 
    VARCHAR22="VARCHAR(22)", 
    VARCHAR25="VARCHAR(25)", 
    VARCHAR24="VARCHAR(24)", 
    VARCHAR20="VARCHAR(20)",
    VARCHAR23="VARCHAR(23)", 
    VARCHAR29="VARCHAR(29)", 
    VARCHAR26="VARCHAR(26)", 
    VARCHAR2="VARCHAR(2)", 
    VARCHAR12="VARCHAR(12)", 
    VARCHAR6="VARCHAR(6)", 
    VARCHAR31="VARCHAR(31)", 
    VARCHAR9="VARCHAR(9)", 
    VARCHAR200="VARCHAR(200)", 
    VARCHAR100="VARCHAR(100)", 
    VARCHAR255="VARCHAR(255)",
    VARCHAR1024="VARCHAR(1024)", 
    VARCHAR99="VARCHAR(99)", 
    VARCHAR125="VARCHAR(125)", 
    VARCHAR256="VARCHAR(256)", 
    VARCHAR2000="VARCHAR(2000)", 
    VARCHAR250="VARCHAR(250)", 
    VARCHAR20000="VARCHAR(20000)"
  }
  
  export const operators: Operation[] = [
    { label: 'Is',                       value: '=' ,          acceptedTypes: Object.values(types)},
    { label: 'Is Not',                   value: '!=',          acceptedTypes: Object.values(types)},
    // { label: 'Is Empty',                 value: 'IS NULL',     acceptedTypes: Object.values(types) },
    { label: 'Greater than',             value: '>',           acceptedTypes: [types.BIGINT, types.DECIMAL, types.DOUBLE, types.NUMERIC, types.REAL, types.FLOAT, types.INTEGER, types.TINYINT]},
    { label: 'Less than',                value: '<',           acceptedTypes: [types.BIGINT, types.DECIMAL, types.DOUBLE, types.NUMERIC, types.REAL, types.FLOAT, types.INTEGER, types.TINYINT]},
    // { label: 'Between',               value: 'BETWEEN',     [types.BIGINT, types.DECIMAL, types.DOUBLE, types.NUMERIC, types.REAL, types.FLOAT, types.INTEGER, types.TINYINT]},
    { label: 'Greater than or equal to', value: '>=',          acceptedTypes: [types.BIGINT, types.DECIMAL, types.DOUBLE, types.NUMERIC, types.REAL, types.FLOAT, types.INTEGER, types.TINYINT]},
    { label: 'Less than or equal to',    value: '<=',          acceptedTypes: [types.BIGINT, types.DECIMAL, types.DOUBLE, types.NUMERIC, types.REAL, types.FLOAT, types.INTEGER, types.TINYINT]},
    // { label: 'Contains',                 value: 'IN',          acceptedTypes: Object.values(types) },
    // { label: 'Does not contain',         value: 'NOT IN',      acceptedTypes: Object.values(types) },
    { label: 'Starts with',              value: 'LIKE',        acceptedTypes: [types.CHAR, types.LONGNVARCHAR, types.NCHAR, types.NVARCHAR, types.VARCHAR] },
    { label: 'Ends with',                value: 'LIKE',    acceptedTypes: [types.CHAR, types.LONGNVARCHAR, types.NCHAR, types.NVARCHAR, types.VARCHAR] },
    { label: 'Is Empty',                 value: 'IS NULL',     acceptedTypes: Object.values(types) },
    { label: 'Not Empty',                value: 'IS NOT NULL', acceptedTypes: Object.values(types) }
  ]
  